import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import BXIToken from "../../assets/BXITokenIcon.png";
import CloseIcon from "../../assets/Images/FilterIcons/Close.png";
import SortIcon from "../../assets/Images/FilterIcons/Sort.png";
import TuneIcon from "@mui/icons-material/Tune";
import {
  Box,
  FormControl,
  FormControlLabel,
  Input,
  Modal,
  Paper,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
// import ToastComp from "../Hooks/ToastComp";
import { styled } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
import useGetLoggedInUser from "../../Hooks/LoggedInUser/useGetLoggedInUser";
import { getProduct } from "../../redux/action/Home-Filter/products";
import sendEvent from "../../utils/sendEvents";
import VoucherIcon2 from "../../assets/Images/MarketplaceImages/VoucherIcon2.svg";
import ProductIcon2 from "../../assets/Images/MarketplaceImages/ProductIcon2.svg";
import { Autocomplete } from "@mui/material";
import AndhraPradeshStateIcon from "../../assets/Images/States/Andhra.png";
import ArunachalPradeshIcon from "../../assets/Images/States/ArunachalPradesh.png";
import AssamIcon from "../../assets/Images/States/Assam.png";
import BiharStateIcon from "../../assets/Images/States/Bihar.png";
import ChhattisgarhStateIcon from "../../assets/Images/States/Chhattisgarh.png";
import GoaIcon from "../../assets/Images/States/Goa.png";
import GujaratStateIcon from "../../assets/Images/States/Gujarat.png";
import HimachalPradeshIcon from "../../assets/Images/States/HP.png";
import HaryanaIcon from "../../assets/Images/States/Haryana.png";
import JammuKashmirIcon from "../../assets/Images/States/J&K.png";
import JharkhandStateIcon from "../../assets/Images/States/Jharkhand.png";
import KarnatakaStateIcon from "../../assets/Images/States/Karnataka.png";
import KeralaStateIcon from "../../assets/Images/States/Kerala.png";
import MadhyaPradeshStateIcon from "../../assets/Images/States/MP.png";
import MaharastraStateIcon from "../../assets/Images/States/Maharashtra.png";
import ManipurIcon from "../../assets/Images/States/Manipur.png";
import MeghalayaIcon from "../../assets/Images/States/Meghalaya.png";
import MizoramIcon from "../../assets/Images/States/Mizoram.png";
import NagalandIcon from "../../assets/Images/States/Nagaland.png";
import OdishaStateIcon from "../../assets/Images/States/Odisha.png";
import PunjabIcon from "../../assets/Images/States/Punjab.png";
import RajasthanStateIcon from "../../assets/Images/States/Rajasthan.png";
import SikkimIcon from "../../assets/Images/States/Sikkim.png";
import TamilNaduStateIcon from "../../assets/Images/States/TamilNadu.png";
import TripuraIcon from "../../assets/Images/States/Tripura.png";
import UttarPradeshStateIcon from "../../assets/Images/States/UP.png";
import UttarakhandIcon from "../../assets/Images/States/Uttrakhand.png";
import WestBengalStateIcon from "../../assets/Images/States/WestBengal.png";
import TelanganaStateIcon from "../../assets/Images/States/telanganaStateIcon.png";
import axios from "axios";
import CommaSeparator from "../CommaSeprator";

const StateObjectArray = [
  { Img: MaharastraStateIcon, text: "Maharashtra" },
  { Img: RajasthanStateIcon, text: "Rajasthan" },
  { Img: GujaratStateIcon, text: "Gujarat" },
  { Img: MadhyaPradeshStateIcon, text: "Madhya Pradesh" },
  { Img: KarnatakaStateIcon, text: "Karnataka" },
  { Img: UttarPradeshStateIcon, text: "Uttar Pradesh" },
  { Img: TamilNaduStateIcon, text: "Tamil Nadu" },
  { Img: AndhraPradeshStateIcon, text: "Andhra Pradesh" },
  { Img: TelanganaStateIcon, text: "Telangana" },
  { Img: KeralaStateIcon, text: "Kerala" },
  { Img: WestBengalStateIcon, text: "West Bengal" },
  { Img: OdishaStateIcon, text: "Odisha" },
  { Img: JharkhandStateIcon, text: "Jharkhand" },
  { Img: BiharStateIcon, text: "Bihar" },
  { Img: ChhattisgarhStateIcon, text: "Chhattisgarh" },
  { Img: ArunachalPradeshIcon, text: "Arunachal Pradesh" },
  { Img: AssamIcon, text: "Assam" },
  { Img: ManipurIcon, text: "Manipur" },
  { Img: MeghalayaIcon, text: "Meghalaya" },
  { Img: MizoramIcon, text: "Mizoram" },
  { Img: NagalandIcon, text: "Nagaland" },
  { Img: SikkimIcon, text: "Sikkim " },
  { Img: TripuraIcon, text: "Tripura" },
  { Img: HaryanaIcon, text: "Haryana" },
  { Img: PunjabIcon, text: "Punjab" },
  { Img: JammuKashmirIcon, text: "Jammu & Kashmir" },
  { Img: HimachalPradeshIcon, text: "Himachal Pradesh" },
  { Img: UttarakhandIcon, text: "Uttarakhand" },
  { Img: GoaIcon, text: "Goa" },
];

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    mx: "auto",
  },
  searchInput: {
    marginRight: 2,
  },
  filterButton: {
    marginLeft: 2,
  },
  select: {
    minWidth: 120,
  },
  largerIcon: {
    fontSize: 24, // Adjust the size as needed
  },
}));

export let States = null;
export let PriceRange = null;
export let Product_Qty = null;
export let Clear = null;
export let Refresh = null;
export let PriceShortHightToLow = null;
export let PriceShortLowToHight = null;
export let WhatsNew = null;
export let Popularity = null;
export let Voucher = null;
export let ProductsFilter = null;
export let IsBestSeller = null;
export let IsReadyStock = null;
export let Search = "";
export let Tag = "";
export let ProductDescriptionSearch = "";
export let ProductCompanyNameSearch = "";
export let ProductSubtittleSearch = "";
export let MediaFilter = "";
export let SelectedCompany = "";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#2261a2",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "lightgray" : "red",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const SearchBar = ({ sendDataToParent }) => {
  const classes = useStyles();
  const [allProductSearch, setAllProductSearch] = useState([]);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [whatsNew, setWhatsNew] = useState("");
  const [isBestSeller, setIsBestSeller] = useState(false);
  const [isReadyStock, setIsReadyStock] = useState(false);
  const [popularity, setPopularity] = useState(false);
  const [storeFilter, setStoreFilter] = useState();
  const [sortByView, setSortByView] = useState();
  const [ProductLocationState, setstatefilter] = useState([]);
  const [pricerange, setPriceRange] = useState(1000000);
  const [ProductRating, setStoreRating] = useState([]);
  const [ProductQty, setProductQty] = useState("");
  const [ProductDeliveryType, setStoreDeliveryType] = useState();
  const [clear, setClear] = useState("");
  // const [defaults, setDefault] = useState();
  const [refresh, setRefresh] = useState(false);
  const [priceShortHightToLow, setPriceShortHightToLow] = useState(false);
  const [priceShortLowToHight, setPriceShortLowToHight] = useState(false);
  const [storeAllFilterData, setStoreAllFilterData] = useState();
  const [voucher, setVoucher] = useState(false);
  const [productsFilter, setProductsFilter] = useState(false);
  const [mediaFilter, setMediaFilter] = useState(false);
  const [search, setSearch] = useState("");
  const [tag, setTag] = useState("");
  const [descriptionSearch, setDescriptionSearch] = useState("");
  const [productCompanyNameSearch, setProductCompanyNameSearch] = useState("");
  const [productSubtittleSearch, setProductSubtittleSearch] = useState("");
  const [FilterType, setFilterType] = useState("all");
  const [all, setAll] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [taxSwitch, setTaxSwitch] = useState(false);

  const inputRef = useRef(null);
  const handleStyleChange = () => {
    inputRef.current.style.color = "#C3CAD9";
    inputRef.current.style.fontSize = "14px";
    inputRef.current.style.fontFamily = "Poppins";
  };

  const filterByCompany = async () => {
    await axios
      .get(`product/get_allproducts_bycompany`, { withCredentials: true })
      .then((res) => {
        setAllProductSearch(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    filterByCompany();
  }, []);

  const handleBlur = () => {
    setIsExpanded(false);
  };

  const { data: loggedInUserData } = useGetLoggedInUser();

  const { company: CompanyDetails } = useSelector(
    (state) => state.companyDetails
  );

  States = ProductLocationState;
  PriceRange = pricerange;
  Product_Qty = ProductQty;
  Clear = clear;
  Refresh = refresh;
  PriceShortHightToLow = priceShortHightToLow;
  PriceShortLowToHight = priceShortLowToHight;
  WhatsNew = whatsNew;
  Popularity = popularity;
  Voucher = voucher;
  ProductsFilter = productsFilter;
  IsBestSeller = isBestSeller;
  IsReadyStock = isReadyStock;
  Search = search;
  Tag = tag;
  ProductDescriptionSearch = descriptionSearch;
  ProductCompanyNameSearch = productCompanyNameSearch;
  ProductSubtittleSearch = productSubtittleSearch;
  MediaFilter = mediaFilter;
  SelectedCompany = selectedCompany;

  useEffect(() => {
    if (
      loggedInUserData &&
      loggedInUserData.data &&
      loggedInUserData.data._id
    ) {
      dispatch(
        getProduct(
          search,
          pricerange,
          sortByView,
          ProductQty,
          clear,
          CompanyDetails ? CompanyDetails._id : "",
          refresh,
          loggedInUserData.data._id,
          priceShortHightToLow,
          priceShortLowToHight,
          whatsNew,
          selectedCompany,
          "",
          popularity,
          voucher,
          "",
          productsFilter,
          IsBestSeller,
          IsReadyStock,
          tag,
          descriptionSearch,
          productCompanyNameSearch,
          productSubtittleSearch,
          States
        )
      );
    }
  }, [
    loggedInUserData,
    dispatch,
    pricerange,
    sortByView,
    ProductQty,
    storeFilter,
    clear,
    CompanyDetails,
    refresh,
    priceShortHightToLow,
    priceShortLowToHight,
    whatsNew,
    selectedCompany,
    ProductLocationState,
    popularity,
    voucher,
    productsFilter,
    isBestSeller,
    isReadyStock,
    search,
    tag,
    descriptionSearch,
    productSubtittleSearch,
    mediaFilter,
    all,
  ]);

  const handleSearchTypeChange = (event) => {
    setFilterType(event.target.value);
    let type = event.target.value;
    if (type === "media") {
      setMediaFilter(true);
      setProductsFilter(false);
      setVoucher(false);
      handleClose();
      sendEvent("Click on product icon");
      setAll(false);
    } else if (type === "product") {
      setMediaFilter(false);
      setProductsFilter(true);
      setVoucher(false);
      handleClose();
      setAll(false);
      sendEvent("Click on product icon");
    } else if (type === "voucher") {
      setMediaFilter(false);
      setProductsFilter(false);
      setVoucher(true);
      handleClose();
      setAll(false);
      sendEvent("Click on product icon");
    } else if (type === "all") {
      setMediaFilter(false);
      setProductsFilter(false);
      setVoucher(false);
      handleClose();
      setAll(true);
      sendEvent("Click on clear filter");
    }
  };

  const handleSwitch = () => {
    setTaxSwitch(!taxSwitch);
    sendDataToParent(taxSwitch);
  };

  useEffect(() => {
    handleSwitch();
  }, []);

  const { products, loading } = useSelector((state) => state.products);

  const handleSearch = (event, newValue) => {
    setSelectedCompany(newValue);
    const uniqueCompanyNames = products?.products
      ?.map((option) => option?.SellerCompanyId?.companyName || "")
      .filter(Boolean);
    const filteredResults = uniqueCompanyNames.filter((name) =>
      name.toLowerCase().includes(newValue?.toLowerCase())
    );
  };

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        width: "89%",
        mx: "auto",
        boxShadow: "none",
        background: "#f3f6f9",
        display: "flex",
        gap: "20px",
        position: "fixed",
        marginTop: "10px",
        height: "80px",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          width: "100%",
          maxWidth: "80%",
          mx: "auto",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            width: "80%",
            border: "none",
            height: "45px",
            borderRadius: "25px",
            background: "#fff",
            p: 0,
            display: "flex",
            boxShadow: "#2261a2 0px 0px 0px 0.5px;",
          }}
        >
          <Box
            sx={{
              width: "15%",
              bgcolor: "transparent",
              borderRight: "2px solid #dee2e6",
            }}
          >
            <Select
              select
              label="Search Type"
              defaultValue="All"
              value={FilterType}
              onChange={handleSearchTypeChange}
              variant="standard"
              disableUnderline
              className={classes.searchInput}
              IconComponent={() => (
                <ArrowDropDownIcon
                  className={classes.largerIcon}
                  fontSize="larger"
                />
              )}
              sx={{
                width: "100%",
                maxWidth: "110px",
                border: "none",
                outline: "none",
                height: "100%",
                background: "transparent",
                margin: "10px",
                color: "#2261A2",
                fontSize: "15px",
                marginLeft: "17px",
                marginTop: "0px",
              }}
            >
              <MenuItem value="all" sx={{ color: "#2261A2" }}>
                All
              </MenuItem>
              <MenuItem value="product" sx={{ color: "#2261A2" }}>
                Product
              </MenuItem>
              <MenuItem value="media" sx={{ color: "#2261A2" }}>
                Media
              </MenuItem>
              <MenuItem value="voucher" sx={{ color: "#2261A2" }}>
                Voucher
              </MenuItem>
            </Select>
          </Box>
          <Box
            sx={{
              background: "#fff",
              width: "4%",
              height: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <SearchIcon fontSize="large" color="disabled" />
          </Box>
          <input
            onClick={() => {
              handleStyleChange();
              sendEvent("Click on search expand");
            }}
            ref={inputRef}
            onChange={(e) => {
              setSearch(e.target.value);
              setTag(e.target.value);
              setDescriptionSearch(e.target.value);
              setProductCompanyNameSearch(e.target.value);
              setProductSubtittleSearch(e.target.value);
            }}
            label="Search"
            placeholder="Search"
            variant="outlined"
            className={classes.searchInput}
            InputProps={{ disableUnderline: true }}
            style={{
              color: "#2261a2",
              width: "75%",
              boxShadow: "none",
              border: "none",
              height: "93%",
              fontSize: "16px",
            }}
            onBlur={handleBlur}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{
              height: "100%",
              width: "4.5%",
              mr: "05px",
              borderRadius: "0px 27px 27px 0px",
              background: "#fff",
              width: "50px",
              border: "none",
              marginTop: "0px",
              boxShadow: "none",
              ":hover": {
                background: "#fff",
                boxShadow: "none",
              },
            }}
            onClick={() => {
              handleOpen();
              sendEvent("Click on filter");
            }}
            disableRipple
            disableFocusRipple
            disableTouchRipple
          >
            <TuneIcon fontSize="large" color="primary" />
          </Button>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={FilterModalStyle}>
              <Paper
                elevation={0}
                sx={{
                  width: "80%",
                  bgcolor: "transparent",
                  mx: "auto",
                  boxShadow: "none",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                    mt: 1,
                    height: "50px",
                  }}
                >
                  <Typography sx={{ ...FilterTitle, cursor: "default" }}>
                    Sort by
                  </Typography>
                </Box>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    value={age}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value="">
                      <Typography
                        onClick={() => {
                          setPriceShortLowToHight(false);
                          sendEvent("Click on price low to high");
                          setIsBestSeller(false);
                          sendEvent(false);
                          setIsReadyStock(false);
                          setPriceShortHightToLow(false);
                        }}
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          color: "#2E2E2E",
                          fontWeight: 500,
                        }}
                      >
                        Default
                      </Typography>
                    </MenuItem>
                    <MenuItem value={10}>
                      {" "}
                      <Typography
                        onClick={() => {
                          setPriceShortLowToHight(true);
                          sendEvent("Click on price low to high");
                          setIsBestSeller(false);
                          setIsReadyStock(false);
                          setPriceShortHightToLow(false);
                        }}
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          color: "#2E2E2E",
                          fontWeight: 500,
                        }}
                      >
                        Price (Low to High)
                      </Typography>
                    </MenuItem>
                    <MenuItem value={20}>
                      <Typography
                        onClick={() => {
                          setPriceShortHightToLow(true);
                          sendEvent("Click on price high to low");
                          setIsBestSeller(false);
                          setIsReadyStock(false);
                          setPriceShortLowToHight(false);
                        }}
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          color: "#2E2E2E",
                          fontWeight: 500,
                        }}
                      >
                        Price (High to Low)
                      </Typography>
                    </MenuItem>

                    <MenuItem value={30}>
                      <Typography
                        onClick={() => {
                          setIsReadyStock(true);
                          setIsBestSeller(false);
                          setPriceShortHightToLow(false);
                          setPriceShortLowToHight(false);
                          sendEvent("Click on whats new");
                        }}
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          color: "#2E2E2E",
                          fontWeight: 500,
                        }}
                      >
                        ReadyStock
                      </Typography>
                    </MenuItem>
                    <MenuItem value={40}>
                      <Typography
                        onClick={() => {
                          setIsBestSeller(true);
                          sendEvent("Click on popularity");
                          setIsReadyStock(false);
                          setPriceShortHightToLow(false);
                          setPriceShortLowToHight(false);
                        }}
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          color: "#2E2E2E",
                          fontWeight: 500,
                        }}
                      >
                        BestSeller
                      </Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Paper>

              <Paper
                elevation={0}
                sx={{
                  width: "80%",
                  bgcolor: "transparent",
                  mx: "auto",
                  boxShadow: "none",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                    mt: 0,
                    height: "50px",
                  }}
                >
                  <Typography sx={{ ...FilterTitle, cursor: "default" }}>
                    Location
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                    height: "0px",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "110px",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    alignContent: "center",
                    maxWidth: "385px",

                    overflow: "auto",
                    "::-webkit-scrollbar": {
                      display: "flex",
                      height: "6px",
                    },
                    gap: "10px",
                    mt: 0,
                    cursor: "pointer",
                  }}
                >
                  {StateObjectArray?.map((res, idx) => {
                    return (
                      <Box
                        key={idx}
                        onClick={() => {
                          if (ProductLocationState.includes(res.text)) {
                            setstatefilter(
                              ProductLocationState.filter(
                                (item) => item !== res.text
                              )
                            );
                          } else {
                            setstatefilter([...ProductLocationState, res.text]);
                          }
                        }}
                        sx={{
                          width: "100%",
                          minWidth: "90px",
                          height: "90px",
                          display: "grid",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          borderRadius: "12px",
                          bgcolor: "rgba(243, 246, 249, 0.6)",
                          position: "relative",
                          "&:hover": {
                            border: "0.1px solid #445FD2",
                            boxShadow:
                              "inset 4px -4px 4px rgba(246, 246, 246, 0.25), inset -4px 4px 4px rgba(246, 246, 246, 0.25)",
                          },
                        }}
                      >
                        {ProductLocationState.includes(res.text) ? (
                          <CheckIcon
                            onClick={() => {
                              sendEvent("Click on check button");
                            }}
                            sx={{
                              position: "absolute",
                              marginBottom: "7rem",
                              marginLeft: "7rem",
                              fontSize: "12px",
                              padding: "4px",
                              color: "#445FD2",
                              background: "#FFFFFF",
                              border: "2px solid rgba(206, 206, 206, 0.5)",
                              boxShadow:
                                "inset 4px -4px 4px rgba(246, 246, 246, 0.25), inset -4px 4px 4px rgba(246, 246, 246, 0.25)",
                              borderRadius: 100,
                            }}
                          />
                        ) : null}
                        <Box>
                          <img
                            src={res.Img}
                            alt=""
                            width={"50px"}
                            height="auto"
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          />
                        </Box>
                        <Typography
                          sx={{
                            ...FilterTextStyleOne,
                            fontSize: "9px",
                            textAlign: "center",
                            ml: 0,
                            color: ProductLocationState.includes(res.text)
                              ? "#445FD2"
                              : "#B2BCCF",
                          }}
                        >
                          {res.text}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Paper>
              <Paper
                elevation={0}
                sx={{
                  width: "80%",
                  bgcolor: "transparent",
                  mx: "auto",
                  boxShadow: "none",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                    mt: 0,
                    height: "50px",
                  }}
                >
                  <Typography sx={FilterTitle}>Price Range</Typography>
                </Box>

                <Box
                  sx={{
                    width: "auto",
                    height: "60px",
                    alignItems: "center",
                    alignContent: "center",
                    maxWidth: "385px",
                    overflowX: "scroll",
                    gap: "10px",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                    px: 2,
                    mt: 1,
                    bgcolor: "#E0F0FF",
                    borderRadius: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      minWidth: "310px",
                      height: "1px",
                      pt: 1.5,
                      pb: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        ...FilterTitle,
                        fontSize: "12px",
                        lineHeight: "10px",
                      }}
                    >
                      Price Range
                    </Typography>
                    <Typography
                      sx={{
                        ...FilterTitle,
                        fontSize: "12px",
                        lineHeight: "10px",
                      }}
                    >
                      <Box
                        component="img"
                        sx={{ width: "12px", height: "12px", mr: "1px" }}
                        src={BXIToken}
                      />
                      {/* {pricerange} */}
                      <CommaSeparator Price={pricerange} />
                    </Typography>
                  </Box>
                  <Slider
                    value={pricerange}
                    aria-label="Small steps"
                    size="small"
                    step={100000}
                    min={0}
                    max={1000000}
                    onChange={(e) => setPriceRange(e.target.value)}
                  />
                </Box>
              </Paper>

              <Paper
                elevation={0}
                sx={{
                  width: "80%",
                  bgcolor: "transparent",
                  mx: "auto",
                  boxShadow: "none",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                    mt: 2,
                    height: "auto",
                  }}
                >
                  <Typography sx={{ ...FilterTitle, cursor: "default" }}>
                    Company Name
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "60px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                    mt: 0,
                    borderRadius: "10px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    sx={{
                      width: "100%",
                      height: "52px",
                      background: "#F8FAFB",
                      borderRadius: "10px",
                      border: "none",
                    }}
                    options={
                      Array?.from(
                        new Set(
                          allProductSearch?.map(
                            (option) => option?.companyName || ""
                          )
                        )
                      ) || []
                    }
                    value={selectedCompany}
                    onChange={handleSearch}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Company"
                        sx={{
                          width: "100%",
                          height: "52px",
                          background: "#F8FAFB",
                          borderRadius: "10px",
                          "& fieldset": { border: "none" },
                          color: "#445FD2",
                        }}
                      />
                    )}
                  />
                </Box>
              </Paper>

              <Paper
                elevation={0}
                sx={{
                  width: "80%",
                  bgcolor: "transparent",
                  mx: "auto",
                  boxShadow: "none",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100px",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    alignContent: "center",
                    mt: 0,
                    borderRadius: "10px",
                  }}
                >
                  <Button
                    value="reset"
                    variant="outlined"
                    onClick={(e) => {
                      setstatefilter([]);
                      setPriceRange(1000000);
                      setProductQty("");
                      setSelectedCompany("");
                      setClear(e.target.value);
                      sendEvent("Click on Reset");
                      setOpen(false);
                    }}
                    sx={{
                      width: "200px",
                      height: "42px",
                      borderRadius: "6px",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "13px",
                      lineHeight: "20px",
                      textAlign: "center",
                      color: "#0053ad",
                      textTransform: "none",
                    }}
                  >
                    Clear Filter
                  </Button>
                </Box>
              </Paper>
            </Box>
          </Modal>
        </Box>
        <Box
          sx={{
            width: "20%",
            height: "45px",
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
            borderRadius: "25px",
            boxShadow: "#2261a2 0px 0px 0px 0.5px;",
            px: "15px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "13px",
              color: "#2261a2",
              fontWeight: 500,
            }}
          >
            Display total with taxes
          </Typography>
          <FormControlLabel
            control={
              <IOSSwitch sx={{ m: 1 }} onChange={() => handleSwitch()} />
            }
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default SearchBar;

const FilterModalStyle = {
  position: "absolute",
  width: "90%",
  maxWidth: { xl: "430px", lg: "430px", md: "430px", sm: "400px", xs: "350px" },
  height: "100%",
  right: "5%",
  top: "04%",
  maxHeight: "60vh",
  overflowY: "scroll",
  background: "#FFFFFF",
  borderRadius: "10px",
  "&::-webkit-scrollbar": {
    display: "none",
  },
};

const FilterTitle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "28px",

  color: "#2E2E2E",
};

const FilterTextStyleOne = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  color: "#B2BCCF",
  ml: 1.5,
};
