import {
  Box,
  Button,
  Fade,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import sendEvent from "../../utils/sendEvents";
import CommaSeprator from "../../components/CommaSeprator";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import InfoIcon from "@mui/icons-material/Info";
import useGetCompanyTypeData from "../../Hooks/CompanyData/useGetCompanyTypeData";
import useGetLoggedInUser from "../../Hooks/LoggedInUser/useGetLoggedInUser";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(` background: #445fd2;
     width:auto;
      `);

const AdminReview = (props) => {
  const navigate = useNavigate();
  const { data: CompanyData } = useGetLoggedInUser();
  const draftdummy = CompanyData?.data?.CompanyAvatar?.url;

  const { data: CompanyTypeData } = useGetCompanyTypeData(
    CompanyData?.data?.companyType
  );

  const NaviGateFunction = (product) => {
    if (product.ListingType === "Voucher") {
      if (CompanyTypeData?.data?.CompanyTypeName === "Textile") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate(
            "/home/textileVoucher/textilegeneralinformation/" + product._id
          );
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/textileVoucher/textiletechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/textileVoucher/textilegolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Review") {
          navigate("/home/textileVoucher/textiletechinfo/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "Office Supply") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/officesupply/officesupplyproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/officesupply/officesupplytechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/officesupply/officesupplygolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Review") {
          navigate("/home/officesupply/officesupplytechinfo/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "Lifestyle") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate(
            "/home/lifestyleVoucher/lifestylegeneralinformation/" + product._id
          );
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/lifestyleVoucher/lifestyletechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/lifestyleVoucher/lifestylegolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Review") {
          navigate("/home/lifestyleVoucher/lifestyletechinfo/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "Electronics") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate(
            "/home/electronicsVoucher/electronicsgeneralinformation" +
              product._id
          );
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate(
            "/home/electronicsVoucher/electronicstechinfo/" + product._id
          );
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/electronicsVoucher/electronicsgolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Review") {
          navigate(
            "/home/electronicsVoucher/electronicstechinfo/" + product._id
          );
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "FMCG") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/fmcg/fmcgproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/fmcg/fmcgtechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/fmcg/fmcggolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Review") {
          navigate("/home/fmcg/fmcgtechinfo/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "Mobility") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/mobility/mobilityproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/mobility/mobilitytechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/mobility/mobilitygolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Review") {
          navigate("/home/mobility/mobilitytechinfo/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "QSR") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/restaurant/restaurantproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/restaurant/restauranttechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/restaurant/restaurantgolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Review") {
          navigate("/home/restaurant/restauranttechinfo/" + product._id);
        }
      }
      if (CompanyTypeData?.data?.CompanyTypeName === "Others") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/others/othersproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/others/otherstechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/others/othersgolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Review") {
          navigate("/home/others/othersproductinfo/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "Hotel") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/hotelsVoucher/hotelsproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/hotelsVoucher/hotelstechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/hotelsVoucher/hotelsgolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "Review") {
          navigate("/home/hotelsVoucher/hotelstechinfo/" + product._id);
        }
      } else if (
        CompanyTypeData?.data?.CompanyTypeName === "Airlines Tickets"
      ) {
        if (product.ProductUploadStatus === "productinformation") {
          navigate(
            "/home/airlineVoucher/airlinegeneralinformation/" + product._id
          );
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/vouchers/voucherdesign/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "Review") {
          navigate(
            "/home/airlineVoucher/airlinegeneralinformation/" + product._id
          );
        }
      } else if (
        CompanyTypeData?.data?.CompanyTypeName === "Entertainment & Events"
      ) {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/eeVoucher/eegeneralinformation/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "Review") {
          navigate("/home/textileVoucher/textiletechinfo/" + product._id);
        }
      }
    } else {
      if (CompanyTypeData?.data?.CompanyTypeName === "Textile") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/textile/texttileproductInfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/textile/technicalinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/textile/golive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "Office Supply") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/officesupply/officesupplyproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/officesupply/officesupplytechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/officesupply/officesupplygolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "Lifestyle") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/lifestyle/lifestyleproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/lifestyle/lifestyletechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/lifestyle/lifestylegolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (
          product.ProductUploadStatus === "Review" &&
          product?.reviewReasonNavigation === "GoLive"
        ) {
          navigate("/home/lifestyle/lifestylegolive/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "Electronics") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/electronics/electronicsproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/electronics/electronicstechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/electronics/electronicsgolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "FMCG") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/fmcg/fmcgproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/fmcg/fmcgtechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/fmcg/fmcggolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "Mobility") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/mobility/mobilityproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/mobility/mobilitytechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/mobility/mobilitygolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "QSR") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/restaurant/restaurantproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/restaurant/restauranttechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/restaurant/restaurantgolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        }
      } else if (
        CompanyTypeData?.data?.CompanyTypeName === "Media" &&
        product.ProductCategoryName === "MediaOnline"
      ) {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/mediaonline/mediaonlineproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/mediaonline/mediaonlinetechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/mediaonline/mediaonlinegolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/mediaonlineproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/mediaonlineproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Review") {
          navigate("/home/mediaonline/mediaonlineproductinfo/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "Media") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/mediaoffline/mediaofflineproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/mediaoffline/mediaofflinetechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/mediaoffline/mediaofflinegolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/mediaonlineproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/mediaonlineproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Review") {
          navigate("/home/mediaoffline/mediaofflineproductinfo/" + product._id);
        }
      }
      if (CompanyTypeData?.data?.CompanyTypeName === "Others") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/others/othersproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/others/otherstechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/others/othersgolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (product.ProductUploadStatus === "pendingapproval") {
          navigate("/home/allproductpreview/" + product._id);
        } else if (product.ProductUploadStatus === "Approved") {
          navigate("/home/allproductpreview/" + product._id);
        }
      } else if (CompanyTypeData?.data?.CompanyTypeName === "Hotel") {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/hotelsVoucher/hotelsproductinfo/" + product._id);
        } else if (product.ProductUploadStatus === "technicalinformation") {
          navigate("/home/hotelsVoucher/hotelstechinfo/" + product._id);
        } else if (product.ProductUploadStatus === "golive") {
          navigate("/home/hotelsVoucher/hotelsgolive/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        }
      } else if (
        CompanyTypeData?.data?.CompanyTypeName === "Airlines Tickets"
      ) {
        if (product.ProductUploadStatus === "productinformation") {
          navigate(
            "/home/airlineVoucher/airlinegeneralinformation/" + product._id
          );
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        }
      } else if (
        CompanyTypeData?.data?.CompanyTypeName === "Entertainment & Events"
      ) {
        if (product.ProductUploadStatus === "productinformation") {
          navigate("/home/eeVoucher/eegeneralinformation/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Value Voucher / Gift Cards "
        ) {
          navigate("/home/valueandgiftvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "pendingapproval" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        } else if (
          product.ProductUploadStatus === "Approved" &&
          product.VoucherType === "Offer Specific"
        ) {
          navigate("/home/spacificvoucher/" + product._id);
        }
      }
    }
  };

  return (
    <Grid
      container
      sx={{
        mt: 2,
        height: "100%",
      }}
    >
      {props?.data?.map((res, idx) => {
        return (
          <>
            {props?.loader === true ? (
              <Paper
                onClick={() => {
                  sendEvent("Click on Review");
                }}
                sx={{
                  width: "100%",
                  height: "100%",
                  maxHeight: "100%",
                  position: "absolute",
                  top: 300,
                  left: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "transparent",
                  zIndex: 1000,
                }}
                elevation={0}
              >
                <div className="triple-spinner"></div>
              </Paper>
            ) : (
              <Grid
                key={idx}
                item
                xl={3}
                lg={3}
                md={4}
                sm={6}
                xs={12}
                sx={{ mt: 3 }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    bgcolor: "transparent",
                    borderRadius: "15px",
                    maxWidth: "280px",
                    mx: "auto",
                  }}
                >
                  <Box
                    sx={{
                      boxSizing: "border-box",
                      width: {
                        xl: "100%",
                        lg: "99%",
                        md: "98%",
                        sm: "97%",
                        xs: "96%",
                      },
                      height: "auto",
                      minHeight: "280px",
                      bgcolor: "#fff",
                      borderRadius: "13.6px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      border: "0.1px solid transparent",
                      backgroundImage:
                        "linear-gradient(180deg,#C64091 0%,#C64091 47.4%,#C64091 100%)",
                      transition: "box-shadow .1s",
                      "&:hover": {
                        boxShadow: "0px 0px 17px #ff864547",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        mx: "auto",
                        height: "auto",
                        minHeight: "270px",
                        bgcolor: "#fff",
                        borderRadius: "13px",
                        padding: "5px",
                        cursor: "pointer",
                      }}
                    >
                      {res?.reviewReason === null ||
                      res?.reviewReason === undefined ? (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            minWidth: "250px",
                            backgroundColor: "white",
                            pointerEvents: "none",
                          }}
                        >
                          <CustomTooltip
                            sx={{ backgroundColor: "white" }}
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 400 }}
                          >
                            <InfoIcon
                              sx={{
                                width: "28px",
                                height: "auto",
                                cursor: "pointer",
                                color: "white",
                              }}
                            />
                          </CustomTooltip>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            minWidth: "250px",
                          }}
                        >
                          <CustomTooltip
                            sx={{ backgroundColor: "red" }}
                            title={
                              <Typography sx={ToolTextStyle}>
                                {res?.reviewReason}
                              </Typography>
                            }
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 400 }}
                          >
                            <InfoIcon
                              sx={{
                                width: "28px",
                                height: "auto",
                                cursor: "pointer",
                                color: "red",
                              }}
                            />
                          </CustomTooltip>
                        </Box>
                      )}

                      <Box
                        sx={{
                          width: "90%",
                          height: "auto",
                          minHeight: "160px",
                          maxHeight: "160px",
                          mx: "auto",
                          pt: 2,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={
                            res?.ProductImages?.at(0)?.url === null ||
                            res?.ProductImages?.at(0)?.url === undefined
                              ? res?.VoucherImages?.at(0)?.url || draftdummy
                              : res?.ProductImages?.at(0)?.url || draftdummy
                          }
                          alt="img"
                          style={{
                            width: "auto",
                            maxWidth: "230px",
                            maxHeight: "130px",
                            height: "auto",
                            marginLeft: "auto",
                            marginRight: "auto",
                            borderRadius: "10px",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          maxWidth: "270px",
                          mx: "auto",
                          height: "auto",
                          display: "grid",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Paper
                          sx={{
                            width: "90%",
                            mx: "auto",
                            height: "auto",
                            minWidth: "240px",
                            maxWidth: "275px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignContent: "center",
                          }}
                          elevation={0}
                        >
                          <Typography
                            sx={{
                              ...MainTextStyle,
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {res?.ProductName}
                          </Typography>
                          <Typography
                            sx={{
                              ...PriceTextStyle,
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                              alignContent: "center",
                            }}
                          >
                            <img
                              src="https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/BXITokenIcon.png"
                              alt="Icon"
                              style={{
                                width: "18px",
                                height: "18px",
                                marginRight: "0.5rem",
                              }}
                            />
                            <CommaSeprator
                              Price={
                                res?.ProductsVariantions?.at(0)?.DiscountedPrice
                                  ? res?.ProductsVariantions?.at(0)
                                      ?.DiscountedPrice
                                  : res?.ProductsVariantions?.at(0)
                                      ?.PricePerUnit
                              }
                            />
                          </Typography>
                        </Paper>
                        <Paper
                          sx={{
                            width: "90%",
                            height: "auto",
                            mx: "auto",
                            minWidth: "240px",
                            maxWidth: "275px",
                          }}
                          elevation={0}
                        >
                          <Typography sx={BottomTextStyle}>
                            {res?.ProductDescription}
                          </Typography>

                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {res?.reviewReason ? (
                              <Button
                                sx={{
                                  width: "305px",
                                  textTransform: "none",
                                  mx: "auto",
                                  color: "#6B7A99",
                                  fontFamily: "Poppins",
                                  fontSize: "17px",
                                  fontWeight: 600,
                                }}
                                onClick={() => {
                                  NaviGateFunction(res);
                                }}
                              >
                                Edit
                              </Button>
                            ) : (
                              <Button
                                sx={{
                                  width: "305px",
                                  textTransform: "none",
                                  mx: "auto",
                                  color: "#6B7A99",
                                  fontFamily: "Poppins",
                                  fontSize: "17px",
                                  fontWeight: 600,
                                }}
                                onClick={() => {
                                  NaviGateFunction(res);
                                }}
                              >
                                View
                              </Button>
                            )}
                          </Box>
                        </Paper>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            )}
          </>
        );
      })}
    </Grid>
  );
};

export default AdminReview;

const MainTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "16px", lg: "16px", md: "14px", sm: "12px", xs: "12px" },
  lineHeight: "30px",
  width: "100%",
  color: "#141414",
  maxWidth: "160px",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const PriceTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: { xl: "14px", lg: "14px", md: "13px", sm: "13px", xs: "13px" },
  lineHeight: "24px",
  color: "#717171",
};

const BottomTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "11px",
  lineHeight: "16px",
  color: "#6B7A99",
  textAlign: "left",
  mt: 1,
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const ToolTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "10px",
  lineHeight: "13px",
  color: "#fff",
  textAlign: "center",
  cursor: "pointer",
  bgcolor: "red",
};
