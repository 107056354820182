import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import MainLogo from "./MainLogo";
import ReCAPTCHA from "react-google-recaptcha";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { RecapctchSiteKey } from "../../utils/captchApiKey";
import mainImg from "../../assets/Images/register/forget.svg";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useCaptchaVerify, useForgetPassEmailSubmit } from "../../Hooks/Auth";
import * as StylesOnBoarding from "../../components/common/Styled/StylesOnBoarding";
const Forget = () => {
  const recaptcha = useRef();
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState();
  const [captchaValue, setCaptchValue] = useState("");
  const { mutate: mutateForgetPassEmailSubmit } = useForgetPassEmailSubmit();
  // api captcha
  const { mutate: CaptchaVerifyMutate, isLoading: CaptchaVerifyLoading } =
    useCaptchaVerify();
  // Adding here forget password
  const addForgetPassword = () => {
    CaptchaVerifyMutate(captchaValue, {
      onSuccess: (res) => {
        if (res.data.success) {
          mutateForgetPassEmailSubmit(
            { email: userEmail },
            {
              onSuccess: (data) => {
                navigate("/forgetpasswordotp", {
                  state: { email: userEmail },
                });
              },
              onError: (error) => {
                return toast.error("Email doesn't exist");
              },
            }
          );
        } else {
          setCaptchValue("");
          recaptcha.current.reset();
          toast.error("Captcha Not Verified", {});
          return;
        }
      },
      onError: (err) => {
        setCaptchValue("");
        toast.error("Captcha Not Verified", {});
      },
    });
  };
  return (
    <>
      <ToastContainer />
      <Paper elevation={0} sx={StylesOnBoarding.mainCommonPage}>
        {/* <Stepper /> */}
        <Grid
          container
          sx={{
            background: "#fff",
            height: "100vh",
            width: {
              xl: "110%",
              lg: "110%",
              md: "110%",
              sm: "100%",
              xs: "100%",
            },
          }}
        >
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginTop: "-25rem",
            }}
          >
            <Grid
              container
              sx={{
                height: {
                  xl: "30%",
                  lg: "30%",
                  md: "30%",
                  sm: "10%",
                  xs: "10%",
                },
              }}
            >
              <MainLogo />
            </Grid>
            <Typography sx={StylesOnBoarding.HeadText}>
              Forgot Password
            </Typography>
            <Box
              mt={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                width: {
                  xl: "75%",
                  lg: "75%",
                  md: "75%",
                  sm: "90%",
                  xs: "90%",
                },
                gap: "20px",
              }}
            >
              <ThemeProvider theme={outerTheme}>
                <Box
                  sx={{
                    width: "88%",
                    height: "auto",
                    borderRadius: "12px",
                    border: "1px #CCCCCC",
                    marginTop: "03px",
                    position: "relative",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 400,
                    bgcolor: "transparent",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      left: "10%",
                      top: "-20%",
                      bgcolor: "#fff",
                      px: 1,
                      fontSize: {
                        xl: "14px",
                        lg: "14px",
                        md: "12px",
                        sm: "12px",
                        xs: "12px",
                      },
                    }}
                  >
                    <label
                      style={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 400,

                        color: "#6B7A99",
                      }}
                    >
                      Email Address
                    </label>
                  </Box>
                  <input
                    type={"text"}
                    placeholder="bxi@bxi.com"
                    style={{
                      mt: 2,
                      width: "97%",
                      height: "58px",
                      borderRadius: "14px",
                      padding: "0 1rem",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 400,
                      border: "1px solid #CCCCCC",
                      textAlign: "left",
                      color: "#6B7A99",
                    }}
                    onChange={(e) => setUserEmail(e.target.value)}
                  />
                </Box>
              </ThemeProvider>

              <Grid
                container
                mt={3}
                sx={{
                  width: "90%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    mb: 2,
                  }}
                >
                  <ReCAPTCHA
                    ref={recaptcha}
                    value={captchaValue}
                    onChange={(e) => {
                      setCaptchValue(recaptcha.current.getValue());
                    }}
                    sitekey={RecapctchSiteKey}
                  />
                </Box>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      background: "  #375DBB",
                      height: "40px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      fontSize: "14px",
                      textTransform: "none",
                      fontFamily: "Poppins",
                      borderRadius: "6px",
                    }}
                    disabled={captchaValue === "" ? true : false}
                    onClick={addForgetPassword}
                  >
                    Forgot Password
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: {
                xl: "flex",
                lg: "flex",
                md: "flex",
                sm: "none",
                xs: "none",
              },
            }}
          >
            <img
              src={mainImg}
              alt="img"
              style={{
                height: "auto",
                width: "100%",
                maxHeight: "100vh",
                marginRight: "150px",
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default Forget;

const login = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xl: "3.2rem",
    lg: "3.1rem",
    md: "3rem",
    sm: "2.8rem",
    xs: "2.6rem",
  },
  color: "#6B7A99",
};

const outerTheme = createTheme({
  palette: {
    primary: {
      main: "#6B7A99",
    },
  },
  typography: {
    fontSize: 20,
  },
});
