import { Paper, Box, Typography, Button, Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import { useNavigate, useParams } from "react-router-dom";
import DocDownloadImg from "../../assets/Images/CommonImages/DocDownload.png";
import PrintPurchaseOrder from "../../assets/Images/CommonImages/Print.png";
import { useReactToPrint } from "react-to-print";
import {
  AddressInformation,
  BarterCoinDetails,
  InvoiceList,
  OrderStatus,
  ProductDetail,
} from "./OrderDetailsChildComp";
import LeftArrow from "../../assets/Images/payment/LeftArrow.png";
import BXIReleasePage from "./EscrowPayment/BXIReleasePage.jsx";
import axios from "axios";
import ProgressBar from "../../components/ProgressBar/OrderProgressBar.jsx";

const OrderDetails = () => {
  let { id, type } = useParams();
  const [calculationData, setCalculationData] = useState({
    TotalAdditionalCostWtihoutGSTInBXI: 0,
    TotalPriceWithoutGST: 0,
    GrandTotal: 0,
  });

  const [OrderData, setOrderData] = useState();
  const [bxiReleaseTrue, setBxiReleaseTrue] = useState(false);
  const ViewingType = type;

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      await axios
        .get(`order/get_order_by_id/${id}`, {
          withCredentials: true,
        })
        .then((res) => {
          setOrderData(res);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let TotalAdditionalCostWtihoutGSTInBXI = 0;
    let TotalPriceWithoutGST = 0;
    let GrandTotal = 0;

    OrderData?.data?.PurchaseOrderData?.ProductData?.forEach((el) => {
      if (OrderData?.data?.IsVoucher) {
        TotalAdditionalCostWtihoutGSTInBXI +=
          el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI || 0;
      } else {
        TotalAdditionalCostWtihoutGSTInBXI +=
          el?.AdditionCost?.TotalAdditionalCostWtihoutGSTInBXI || 0;
      }

      TotalPriceWithoutGST += el?.TotalPriceWithoutGST || 0;

      if (el?.ProductTypeName === "Media") {
        GrandTotal += el?.TotalPriceWithoutGST || 0;
      } else if (OrderData?.data?.IsVoucher) {
        GrandTotal +=
          (el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI || 0) +
          (el?.TotalPriceWithoutGST || 0)
            ? (el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI || 0) +
              (el?.TotalPriceWithoutGST || 0)
            : el?.TotalPriceInBXI || 0;
      } else {
        GrandTotal +=
          (el?.AdditionCost?.TotalAdditionalCostWtihoutGSTInBXI || 0) +
          (el?.TotalPriceWithoutGST || 0)
            ? (el?.AdditionCost?.TotalAdditionalCostWtihoutGSTInBXI || 0) +
              (el?.TotalPriceWithoutGST || 0)
            : el?.TotalPriceInBXI || 0;
      }
    });

    setCalculationData({
      TotalAdditionalCostWtihoutGSTInBXI,
      TotalPriceWithoutGST,
      GrandTotal,
    });
  }, [OrderData]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleChildData = (dataFromChild) => {
    setBxiReleaseTrue(dataFromChild);
  };

  const ConfirmOrderDelivery = async () => {
    const confirmation = window.confirm(
      "Are you sure you want to confirm the order delivery?"
    );
    if (confirmation) {
      fetchData();
      setBxiReleaseTrue(true);
    }
  };

  const userhubtype = localStorage.getItem("userhubtype");

  const withdraw_to_bank = async (data) => {
    try {
      await axios
        .post(`escrow/escrow_withdraw_toBank`, {
          OrderId: data,
        })
        .then((res) => {});
    } catch (error) {}
  };

  useEffect(() => {
    if (OrderData?.data?.escrowSellerReleaseResponse?.success === true) {
      withdraw_to_bank(OrderData?.data?._id);
    }
  }, [OrderData?.data?._id]);

  return (
    <Paper
      sx={{
        bgcolor: "transparent",
        width: "100%",
        height: "100%",
      }}
      elevation={0}
    >
      <BreadCrumbHeader MainText={"Order Details"} Margin={false} />
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          height: "120px",
          borderRadius: "20px",
          mb: "20px",
        }}
        elevation={0}
      >
        <ProgressBar
          type={
            OrderData?.data?.IsMedia
              ? "mediaordersummary"
              : OrderData?.data?.IsVoucher
              ? "voucherordersummary"
              : "productordersummary"
          }
          Data={OrderData?.data}
          userhubtype={userhubtype}
        />
      </Paper>

      <Paper sx={PaperStyle} elevation={0}>
        <Box sx={{ display: "grid" }}>
          <Typography
            sx={{
              marginLeft: "13px",
              color: "#6B7A99",
              fontSize: "15px",
              display: "flex",
            }}
          >
            Order ID:{" "}
            <span
              style={{
                fontFamily: "Poppins",
                fontWeight: 600,
                marginLeft: "5px",
                color: userhubtype === "seller" ? "#c64091" : "#2261A2",
              }}
            >
              {OrderData?.data?.OrderId}{" "}
            </span>{" "}
          </Typography>
          <Typography
            sx={{
              marginLeft: "13px",
              color: "#6B7A99",
              fontSize: "15px",
              display: "flex",
            }}
          >
            Date
            <span
              style={{
                fontFamily: "Poppins",
                fontWeight: 600,
                marginLeft: "5px",
                color: userhubtype === "seller" ? "#c64091" : "#2261A2",
              }}
            >
              {new Date(OrderData?.data?.createdAt).toDateString("en-IN")}
            </span>
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            marginRight: "13px",
            width: "60px",
            justifyContent: "space-between",
          }}
        >
          <Box
            component="img"
            src={PrintPurchaseOrder}
            sx={{ width: "22px", height: "auto", cursor: "pointer" }}
            onClick={handlePrint}
          />
          <Box
            component="img"
            src={DocDownloadImg}
            sx={{ width: "21px", height: "auto", cursor: "pointer" }}
            onClick={handlePrint}
          />
        </Box>
      </Paper>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: {
            xl: "row",
            lg: "row",
            md: "row",
          },
          justifyContent: "space-evenly",
        }}
        ref={componentRef}
      >
        <Box
          sx={{
            width: "60%",
            // mx: "auto",
            height: "100%",
            minHeight: "200px",
            p: "20px",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              background: "white",
              minHeight: "500px",
              height: "auto",
              borderRadius: "10px",
              p: "25px",
            }}
          >
            <Typography
              style={{
                ...OrderSummaryTypo,
                color: userhubtype === "seller" ? "#c64091" : "#2261A2",
              }}
            >
              <Box
                component="img"
                src={LeftArrow}
                alt="LeftArrow"
                sx={{
                  width: {
                    xl: "22px",
                    lg: "22px",
                    md: "18px",
                    sm: "16px",
                    xs: "16px",
                  },
                  height: "auto",
                  cursor: "pointer",
                }}
                onClick={() => navigate(-1)}
              />{" "}
              Order Summary
            </Typography>
            <ProductDetail data={OrderData?.data} />
            <BarterCoinDetails data={calculationData} OrderData={OrderData} />
          </Box>
          <AddressInformation OrderData={OrderData} ViewingType={type} />
        </Box>
        <Box
          sx={{
            width: "30%",
            mx: "auto",
            height: "67rem",
            minHeight: "200px",
            p: "25px",
            backgroundColor: "white",
            borderRadius: "10px",
            mt: 2.8,
          }}
        >
          <InvoiceList OrderData={OrderData} />
          {type !== "buyer" &&
          OrderData?.data?.escrowSellerReleaseResponse?.success === true ? (
            <Box
              sx={{
                width: "100%",
                height: "auto",
                mt: 2,
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <Typography
                sx={ProductPriceTextStyle}
                fontWeight={400}
                color="#000"
              >
                Escrow Payment
              </Typography>
              <Button
                type="submit"
                disabled
                sx={{
                  textTransform: "none",
                  width: "100px",
                  height: "30px",
                  bgcolor: "green",
                  fontSize: "14px",
                  color: "#fff",
                }}
              >
                <span style={{ color: "white" }}>
                  {" "}
                  {OrderData?.data?.escrowSellerReleaseResponse?.success ===
                  true
                    ? "Released"
                    : "Not Released"}
                </span>
              </Button>
            </Box>
          ) : null}

          {ViewingType === "seller" ? null : (
            <OrderStatus
              OrderData={OrderData?.data}
              deliveryConfirm={ConfirmOrderDelivery}
            />
          )}
        </Box>
      </Box>
      <BXIReleasePage
        OrderData={OrderData?.data}
        modalOpen={bxiReleaseTrue}
        modalState={handleChildData}
        ReleaseEscrowFund={bxiReleaseTrue}
      />
    </Paper>
  );
};

export default OrderDetails;

const ProductPriceTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  color: "#000",
  fontSize: {
    xl: "14px",
    lg: "14px",
    md: "14px",
    sm: "12px",
    xs: "12px",
  },
  lineHeight: {
    xl: "15px",
    lg: "15px",
    md: "19px",
    sm: "18px",
    xs: "17px",
  },
  alignContent: "center",
  alignItems: "center",
};

const OrderSummaryTypo = {
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "16px",
  color: "#2261A2",
  borderBottom: "1px solid #E4E4E4",
  paddingBottom: "4px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "10px",
};

const PaperStyle = {
  width: "100%",
  mx: "auto",
  height: "80px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  alignContent: "center",
  borderRadius: "10px",
};
