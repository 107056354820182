import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, Grid, Paper, Tab, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useProductAddToCart } from "../../../Hooks/ProductActions/useProductAddToCart";
import GoLeft from "../../../assets/Images/CommonImages/GoLeft.png";
import Weight from "../../../assets/Images/Weight.svg";
import BXITokenIcon from "../../../assets/Stack of Coins.svg";
import BreadCrumbHeader from "../../../components/Header/BreadCrumbHeader";
import { GetProductByIdAction } from "../../../redux/action/ProductActions/GetProductByIdAction";
import { useUpdateProductQuery } from "../../AddProductAllPages/Textile/ProductInfo/ProductHooksQuery";
import { useGetCompanyDetails } from "../../../Hooks/Auth";
import useGetCompanyTypeData from "../../../Hooks/CompanyData/useGetCompanyTypeData";
import CarouselForProductHorizontal from "../../../components/Carousel/CarouselForProductHorizontal";

import CommaSeprator from "../../../components/CommaSeprator";
import FeatureName from "../../../components/FeatureName";
import ProductProgressBar from "../../../components/ProgressBar/ProductProgressBar";

function DiscountedPrice({ regularPrice, discountPrice, percentage }) {
  const discount = regularPrice - discountPrice;
  const discountPercent = (discount / regularPrice) * 100;
  const formattedDiscountPercent = discountPercent.toFixed(2);
  const gstPrice = discountPrice / (1 + percentage / 100);
  const gstamount = discountPrice - gstPrice;
  const formattedgstPrice = gstPrice?.toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formattedgstamount = gstamount?.toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row",
          width: "auto",
          mt: "10px",
          marginBottom: "-11px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: "18px",
            lineHeight: "36px",
            letterSpacing: "0.06em",
            textTransform: "capitalize",
            mt: 0.2,
            color: "#DC3737",
          }}
        >
          {formattedDiscountPercent > 0 && (
            <div>-{formattedDiscountPercent}%</div>
          )}{" "}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "36px",
            letterSpacing: "0.06em",
            textTransform: "capitalize",
            color: "#6B7A99",
          }}
        >
          &nbsp;{discountPrice}
          &nbsp;({formattedgstPrice}
          <img
            src={BXITokenIcon}
            style={{
              width: "20px",
              height: "auto",
            }}
            alt="BXI Token"
          />{" "}
          + {formattedgstamount}₹
          <span
            style={{
              fontSize: "15px",
              fontWeight: 400,
            }}
          >
            GST
          </span>
          )
        </Typography>
      </Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "36px",
          letterSpacing: "0.06em",
          textTransform: "capitalize",

          color: "#6B7A99",
          textDecoration: "line-through",
        }}
      >
        {formattedDiscountPercent > 0 && (
          <div>
            MRP: <CommaSeprator Price={regularPrice} />
          </div>
        )}
      </Typography>
      <br />
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "15px",
          lineHeight: "10px",
          textTransform: "none",
          color: "#6B7A99",
        }}
      >
        All prices are inclusive of Taxes
      </Typography>
    </div>
  );
}

const AllProductPreviewPage = () => {
  const { data: CompanyData } = useGetCompanyDetails();
  const { data: CompanyTypeData } = useGetCompanyTypeData(
    CompanyData?.data?.companyType
  );
  let { id } = useParams();
  let ProductId = id;
  const dispatch = useDispatch();
  const [value, setValue] = React.useState("1");
  const [currentImage, setCurrentImage] = useState(0);
  const [GetProductByIdData, setGetProductByIdData] = useState();
  const [VariationToMap, setVariationToMap] = useState();
  const [showSizechart, setShowSizechart] = useState(false);
  const [storeTechnicalInfo, setStoreTechnicalInfo] = useState();
  const [keyfeatureName, setKeyFeatureName] = useState();
  const [pagename, setPageName] = useState("");

  const [borderColor, setBorderColor] = useState(true);
  const [color, setColor] = useState("");

  const PageNameFunction = () => {
    if (CompanyTypeData?.data?.CompanyTypeName === "Textile") {
      setPageName("Textile");
    } else if (CompanyTypeData?.data?.CompanyTypeName === "Office Supply") {
      setPageName("Office Supply");
    } else if (CompanyTypeData?.data?.CompanyTypeName === "Lifestyle") {
      setPageName("Lifestyle");
    } else {
      setPageName("");
    }
  };

  async function GetProductByid() {
    await axios
      .get(`product/get_product_byId/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setGetProductByIdData(res?.data);
        setStoreVariationData(res?.data?.ProductsVariantions[0]?._id);
        setStoreTechnicalInfo(res?.data?.ProductFeatures);
      });
  }

  async function GetFeaturesNames() {
    await axios
      .get("keyfeature/get_KeyFeatures_ByName", {
        withCredentials: true,
      })
      .then((res) => {
        setKeyFeatureName(res.data);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    GetFeaturesNames();
    PageNameFunction();
  }, []);
  const location = useLocation();
  const navigate = useNavigate();

  const [storeVariationData, setStoreVariationData] = useState();

  let ColorData = {};

  GetProductByIdData?.ProductsVariantions?.map((item, index) => {
    if (index === 0) {
      ColorData = item;
    }
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data: mutateCartData, mutate: addtocart } = useProductAddToCart();

  const technicalinfo = [
    {
      name: "Before Weight",
      img: Weight,
      val: GetProductByIdData?.ProductTechInfo?.WeightBeforePackingPerUnit,
      measure: GetProductByIdData?.WeightBeforePackingPerUnitMeasurUnit,
    },
  ];

  const ImageDataArray = GetProductByIdData?.ProductImages;

  let sdgfusdgf;
  GetProductByIdData?.ProductsVariantions?.forEach((item) => {
    if (item?._id === storeVariationData) {
      sdgfusdgf = item.PricePerUnit;
      return sdgfusdgf;
    }
  });
  let priceone = storeVariationData?.PricePerUnit
    ? storeVariationData?.PricePerUnit
    : sdgfusdgf;

  let oneone;
  let PriceOfSample;
  let QuantityOfSample;
  GetProductByIdData?.ProductsVariantions?.forEach((item) => {
    if (item?._id === storeVariationData) {
      oneone = item.DiscountedPrice;
      PriceOfSample = item.PriceOfSample;
      QuantityOfSample = item.SampleAvailability;
      return oneone;
    }
  });

  let priceTwo = storeVariationData?.DiscountedPrice
    ? storeVariationData?.DiscountedPrice
    : oneone;

  useEffect(() => {
    dispatch(GetProductByIdAction(ProductId));
  }, [dispatch]);

  let samplestate = false;
  GetProductByIdData?.ProductsVariantions?.map((item, index) => {
    if (item.SampleAvailability) {
      return (samplestate = true);
    } else {
      return (samplestate = false);
    }
  });

  useEffect(() => {
    ColorData = {};
  }, [color, storeVariationData]);

  useEffect(() => {
    if (mutateCartData) {
      toast.success("Added to Cart", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [mutateCartData]);

  let NewdataArray = [];

  for (let i = 0; i < GetProductByIdData?.ProductsVariantions?.length; i++) {
    let ProductColor = GetProductByIdData?.ProductsVariantions[i].ProductColor;
    let finddata = NewdataArray.find((d) => d.ProductColor === ProductColor);
    if (finddata) {
      finddata.ProductVariations.push(
        GetProductByIdData?.ProductsVariantions[i]
      );
    } else {
      NewdataArray.push({
        ProductColor: GetProductByIdData?.ProductsVariantions[i].ProductColor,
        ProductVariations: [GetProductByIdData?.ProductsVariantions[i]],
      });
    }
  }
  const {
    mutate: updateProduct,
    data: productData,
    error: RegisterError,
  } = useUpdateProductQuery();

  const naviagte = useNavigate();

  const uploadProduct = () => {
    updateProduct(
      { id, ProductUploadStatus: "pendingapproval" },

      {
        onSuccess: (response) => {
          toast.success("Once uploaded, changes are subject to approval.", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          if (response?.data?.ProductUploadStatus === "pendingapproval") {
            setTimeout(() => {
              naviagte("/home/sellerhub");
            }, [2000]);
          }
        },
      }
    );
  };

  useEffect(() => {
    GetProductByid();
  }, []);

  function getSizeCategory(ProductSize, MeasurementUnit, length) {
    let CustomSize = ProductSize === length + MeasurementUnit;
    if (!ProductSize) {
      return "Sizes";
    }

    const includesAny = (str, units) =>
      units?.some((unit) => str?.includes(unit));
    if (CustomSize) {
      return "Sizes";
    } else {
      if (
        includesAny(MeasurementUnit, [
          "ml",
          "oz",
          "l",
          "cu ft",
          "g",
          "kg",
          "lbs",
        ])
      ) {
        return "Volume";
      } else if (
        includesAny(ProductSize, ["ml", "oz", "l", "cu ft", "g", "kg", "lbs"])
      ) {
        return "Volume";
      } else if (includesAny(ProductSize, ["months", "Years", "days"])) {
        return "Shelf Life";
      } else if (includesAny(ProductSize, ["C", "F"])) {
        return "Temperature";
      } else if (includesAny(ProductSize, ["mAh", "Ah", "Kwh"])) {
        return "BatteryCapacity";
      } else if (
        includesAny(ProductSize, ["g", "%DV", "tonne", "kg", "mlg", "mig"])
      ) {
        return "Nutrition";
      } else if (includesAny(ProductSize, ["W", "KW", "HP", "V", "A"])) {
        return "PowerConsumption";
      } else if (includesAny(ProductSize, ["PX", "DPI", "PPI", "LPI"])) {
        return "Resolutions";
      } else if (includesAny(ProductSize, ["Hz", "KHz", "MHz", "GHz"])) {
        return "Frequency";
      } else if (includesAny(ProductSize, ["psi", "kPa"])) {
        return "Pressure";
      } else if (ProductSize.includes("DB")) {
        return "Amplification";
      } else if (ProductSize.includes("cal")) {
        return "CalorieCount";
      } else if (ProductSize.includes("%")) {
        return "Humidity";
      } else {
        return "Sizes";
      }
    }
  }

  const backbutton = () => {
    if (GetProductByIdData?.ProductCategoryName === "Textile") {
      navigate("/home/textile/golive/" + id);
    } else if (GetProductByIdData?.ProductCategoryName === "Office Supply") {
      navigate("/home/officesupply/officesupplygolive/" + id);
    } else if (GetProductByIdData?.ProductCategoryName === "Lifestyle") {
      navigate("/home/lifestyle/lifestylegolive/" + id);
    } else if (GetProductByIdData?.ProductCategoryName === "FMCG") {
      navigate("/home/fmcg/fmcggolive/" + id);
    } else if (GetProductByIdData?.ProductCategoryName === "Mobility") {
      navigate("/home/mobility/mobilitygolive/" + id);
    } else if (GetProductByIdData?.ProductCategoryName === "QSR") {
      navigate("/home/restaurant/restaurantgolive/" + id);
    } else if (GetProductByIdData?.ProductCategoryName === "Electronics") {
      navigate("/home/electronics/electronicsgolive/" + id);
    } else if (GetProductByIdData?.ProductCategoryName === "Others") {
      navigate("/home/others/othersgolive/" + id);
    }
  };

  return (
    <React.Fragment>
      <Paper elevation={0} sx={{ bgcolor: "transparent", boxShadow: "none" }}>
        <BreadCrumbHeader
          MainText={GetProductByIdData?.ProductCategoryName}
          showbreadcrumb={false}
        />

        {GetProductByIdData?.ProductUploadStatus === "Approved" ? null : (
          <ProductProgressBar />
        )}
        <Paper
          elevation={0}
          sx={{
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              borderBottom: "2px solid rgba(236, 236, 236, 1)",
              width: "95%",
              mx: "auto",
              position: "relative",
            }}
          >
            <Box
              sx={{
                width: "100%",
                mx: "auto",
                // textAlign: "end",
                height: "60px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {GetProductByIdData?.ProductUploadStatus === "Approved" ? (
                <Box
                  component="img"
                  src={GoLeft}
                  alt="GoLeft"
                  sx={{
                    height: "10px",
                    width: "25px",
                    position: "absolute",
                    left: "0",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/home/sellerhub");
                  }}
                />
              ) : (
                <Box
                  component="img"
                  src={GoLeft}
                  alt="GoLeft"
                  onClick={backbutton}
                  sx={{
                    height: "10px",
                    width: "25px",
                    position: "absolute",
                    left: "0",
                    cursor: "pointer",
                  }}
                />
              )}
              {GetProductByIdData?.ProductUploadStatus === "Approved" ? (
                <Typography sx={mainText}> Preview Page</Typography>
              ) : (
                <Typography sx={mainText}>Preview Page</Typography>
              )}
            </Box>
          </Box>

          {GetProductByIdData?.ProductCategoryName === "Textile" ||
          GetProductByIdData?.ProductCategoryName === "Lifestyle" ||
          GetProductByIdData?.ProductCategoryName === "Office Supply" ||
          GetProductByIdData?.ProductCategoryName === "Others" ||
          !GetProductByIdData?.ProductCategoryName ? (
            <Grid
              container
              sx={{
                width: "90%",
                mx: "auto",
                mt: 0,
                display: "flex",
                gap: "20px",
              }}
            >
              <Grid
                item
                xl={5}
                lg={5}
                md={12}
                sm={12}
                xs={12}
                sx={{ ...fixGrid, mt: 1 }}
              >
                <CarouselForProductHorizontal ImageDataArray={ImageDataArray} />
              </Grid>

              <Grid
                item
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                sx={{ ...fixGrid, mt: 0 }}
              >
                <Box sx={{ marginTop: "0px" }}>
                  <Typography sx={{ ...semi }}>
                    {GetProductByIdData?.ProductName}
                  </Typography>
                  <CommaSeprator />
                  <DiscountedPrice
                    regularPrice={
                      GetProductByIdData &&
                      GetProductByIdData?.ProductsVariantions?.length > 0 &&
                      GetProductByIdData?.ProductsVariantions[0]?.PricePerUnit
                    }
                    discountPrice={
                      GetProductByIdData &&
                      GetProductByIdData?.ProductsVariantions?.length > 0 &&
                      GetProductByIdData?.ProductsVariantions[0]
                        ?.DiscountedPrice
                    }
                    percentage={
                      GetProductByIdData &&
                      GetProductByIdData?.ProductsVariantions?.length > 0 &&
                      GetProductByIdData?.ProductsVariantions[0]?.GST
                    }
                  />

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Box
                      sx={{
                        height: "auto",
                        width: "30%",
                      }}
                    >
                      {GetProductByIdData?.ProductCategoryName === "QSR" ||
                      GetProductByIdData?.ProductCategoryName ===
                        "FMCG" ? null : (
                        <>
                          <Typography
                            sx={{ ...semiSub, fontSize: "16px", color: "grey" }}
                          >
                            Colors
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              gap: "10px",
                            }}
                          >
                            {GetProductByIdData &&
                              NewdataArray?.map((res, idx) => {
                                return (
                                  <Box
                                    key={idx}
                                    onClick={() => {
                                      setBorderColor(res?.ProductColor);
                                    }}
                                    sx={{
                                      background: res?.ProductColor,
                                      width: "15%",
                                      height: "100%",
                                      minHeight: "35px",
                                      borderRadius: "0.5rem",
                                      cursor: "pointer",
                                      transition:
                                        res?.ProductColor === borderColor
                                          ? "0.2s linear"
                                          : null,
                                      boxShadow:
                                        res?.ProductColor === borderColor
                                          ? "5px 5px 5px grey"
                                          : null,
                                      border:
                                        res?.ProductColor === borderColor
                                          ? "2px solid blue"
                                          : "2px solid #000",
                                    }}
                                  ></Box>
                                );
                              })}
                          </Box>
                        </>
                      )}
                    </Box>
                    {GetProductByIdData?.gender === null ||
                    GetProductByIdData?.gender === undefined ? null : (
                      <Box>
                        <Typography
                          sx={{ ...semiSub, color: "grey", fontSize: "16px" }}
                        >
                          Gender
                        </Typography>
                        <Typography
                          sx={{
                            ...tableData,
                            textAlign: "center",
                            lineHeight: "4rem",
                          }}
                        >
                          {GetProductByIdData?.gender}
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  <Box
                    mt={6}
                    sx={{
                      width: "100%",
                      mx: "auto",
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                        <Box sx={ProductVariationStyle}>
                          <Typography
                            sx={{
                              ...tableHeader,
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "grey",
                            }}
                          >
                            {getSizeCategory(
                              GetProductByIdData?.ProductsVariantions[0]
                                ?.ProductSize,
                              GetProductByIdData?.ProductsVariantions[0]
                                ?.MeasurementUnit,
                              GetProductByIdData?.ProductsVariantions.at(0)?.[
                                "length"
                              ]
                            )}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                        <Box sx={ProductVariationStyle}>
                          <Typography
                            sx={{
                              ...tableHeader,
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "grey",
                            }}
                          >
                            {" "}
                            Min QTY
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                        <Box sx={ProductVariationStyle}>
                          <Typography
                            sx={{
                              ...tableHeader,
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "grey",
                            }}
                          >
                            {" "}
                            Max QTY
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                        <Box sx={ProductVariationStyle}>
                          <Typography
                            sx={{
                              ...tableHeader,
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "grey",
                            }}
                          >
                            {" "}
                            GST
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                        <Box sx={ProductVariationStyle}>
                          <Typography
                            sx={{
                              ...tableHeader,
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "grey",
                            }}
                          >
                            {" "}
                            Product ID
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {
                        // filter the data from the array
                        GetProductByIdData?.ProductsVariantions?.map(
                          (res, idx) => {
                            return (
                              <Grid
                                container
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                }}
                              >
                                <Grid
                                  item
                                  xl={2.4}
                                  lg={2.4}
                                  md={2.4}
                                  sm={2.4}
                                  xs={2.4}
                                >
                                  <Box sx={ProductVariationStyle}>
                                    {res.ShoeSize === null ||
                                    res.ShoeSize === undefined ? (
                                      <Typography
                                        sx={{
                                          ...tableData,
                                          fontSize: "14px",
                                        }}
                                      >
                                        {res.ProductSize ||
                                          res.NutritionInfo ||
                                          res.powerConsumption}
                                        {res.ProductSize === null ||
                                        res.ProductSize === undefined ? (
                                          <Typography sx={tableData}>
                                            {res.length}&nbsp;
                                            {res.MeasurementUnit}
                                          </Typography>
                                        ) : null}
                                      </Typography>
                                    ) : (
                                      <Typography sx={tableData}>
                                        &nbsp;{res.ShoeSize}&nbsp;
                                        {res.MeasurementUnit}
                                      </Typography>
                                    )}
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xl={2.4}
                                  lg={2.4}
                                  md={2.4}
                                  sm={2.4}
                                  xs={2.4}
                                >
                                  <Box sx={ProductVariationStyle}>
                                    <Typography
                                      sx={{
                                        ...tableData,
                                        fontSize: "14px",
                                      }}
                                    >
                                      {res.MinOrderQuantity}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xl={2.4}
                                  lg={2.4}
                                  md={2.4}
                                  sm={2.4}
                                  xs={2.4}
                                >
                                  <Box sx={ProductVariationStyle}>
                                    <Typography
                                      sx={{
                                        ...tableData,
                                        fontSize: "14px",
                                      }}
                                    >
                                      {res.MaxOrderQuantity}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xl={2.4}
                                  lg={2.4}
                                  md={2.4}
                                  sm={2.4}
                                  xs={2.4}
                                >
                                  <Box sx={ProductVariationStyle}>
                                    <Typography
                                      sx={{
                                        ...tableData,
                                        fontSize: "14px",
                                      }}
                                    >
                                      {res.GST} &nbsp;%
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xl={2.4}
                                  lg={2.4}
                                  md={2.4}
                                  sm={2.4}
                                  xs={2.4}
                                >
                                  <Box sx={ProductVariationStyle}>
                                    <Typography
                                      sx={{
                                        ...tableData,
                                        fontSize: "14px",
                                      }}
                                    >
                                      {res.ProductIdType}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            );
                          }
                        )
                      }
                    </Grid>
                  </Box>
                  <Box
                    mt={4}
                    sx={{
                      borderTop: "2px solid rgba(238, 238, 238, 1)",
                      display: "flex",
                      gap: "20px",
                    }}
                  >
                    {/* <Typography sx={find}>Download Size Chart </Typography> */}
                    <Typography
                      sx={{ ...chart, cursor: "pointer" }}
                      onMouseEnter={() => setShowSizechart(true)}
                      onMouseLeave={() => setShowSizechart(false)}
                    >
                      Size Chart{" "}
                    </Typography>
                    {GetProductByIdData?.SizeChart?.at(0)?.url === undefined ||
                    GetProductByIdData?.SizeChart?.at(0)?.url === null ? (
                      showSizechart ? (
                        <Typography
                          sx={{
                            ...chart,
                            position: "absolute",
                            height: "300px",
                            width: "auto",
                            right: "5%",
                            zIndex: 10,
                          }}
                        >
                          Size Chart Unavailable
                        </Typography>
                      ) : null
                    ) : showSizechart ? (
                      <Box
                        component="img"
                        src={GetProductByIdData?.SizeChart?.at(0)?.url}
                        alt="hello"
                        onMouseEnter={() => setShowSizechart(true)}
                        onMouseLeave={() => setShowSizechart(false)}
                        sx={{
                          position: "absolute",
                          height: "300px",
                          width: "auto",
                          right: "5%",
                          zIndex: 10,
                        }}
                      />
                    ) : null}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Box>
              <Grid container>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={12} sx={{ mt: 1 }}>
                  <Box
                    style={{
                      width: "632px",
                      maxHeight: "432px",
                      cursor: "pointer",
                    }}
                  >
                    <CarouselForProductHorizontal
                      ImageDataArray={ImageDataArray}
                    />
                  </Box>
                </Grid>
                <Grid item xl={1} lg={1} md={1}></Grid>
                <Grid
                  item
                  xl={6.5}
                  lg={6.5}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ ...fixGrid, mt: 0, ml: 5 }}
                >
                  <Box sx={{ marginTop: "0px" }}>
                    <Typography sx={{ ...semi, fontSize: "20px" }}>
                      {GetProductByIdData?.ProductName}
                    </Typography>
                    <CommaSeprator />
                    <Box sx={{ mt: 1 }}>
                      <DiscountedPrice
                        regularPrice={
                          GetProductByIdData &&
                          GetProductByIdData?.ProductsVariantions?.length > 0 &&
                          GetProductByIdData?.ProductsVariantions[0]
                            ?.PricePerUnit
                        }
                        discountPrice={
                          GetProductByIdData &&
                          GetProductByIdData?.ProductsVariantions?.length > 0 &&
                          GetProductByIdData?.ProductsVariantions[0]
                            ?.DiscountedPrice
                        }
                        percentage={
                          GetProductByIdData &&
                          GetProductByIdData?.ProductsVariantions?.length > 0 &&
                          GetProductByIdData?.ProductsVariantions[0]?.GST
                        }
                      />
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Box
                        sx={{
                          height: "auto",
                          width: "30%",
                        }}
                      >
                        {GetProductByIdData?.ProductCategoryName === "QSR" ||
                        GetProductByIdData?.ProductCategoryName ===
                          "FMCG" ? null : (
                          <>
                            <Typography
                              sx={{
                                ...semiSub,
                                fontSize: "16px",
                                color: "grey",
                              }}
                            >
                              Colors
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                gap: "10px",
                              }}
                            >
                              {GetProductByIdData &&
                                NewdataArray?.map((res, idx) => {
                                  return (
                                    <Box
                                      key={idx}
                                      onClick={() => {
                                        setBorderColor(res?.ProductColor);
                                      }}
                                      sx={{
                                        background: res?.ProductColor,
                                        width: "15%",
                                        height: "100%",
                                        minHeight: "35px",
                                        borderRadius: "0.5rem",
                                        cursor: "pointer",
                                        transition:
                                          res?.ProductColor === borderColor
                                            ? "0.2s linear"
                                            : null,
                                        boxShadow:
                                          res?.ProductColor === borderColor
                                            ? "5px 5px 5px grey"
                                            : null,
                                        border:
                                          res?.ProductColor === borderColor
                                            ? "2px solid blue"
                                            : "2px solid #000",
                                      }}
                                    ></Box>
                                  );
                                })}
                            </Box>
                          </>
                        )}
                      </Box>

                      {GetProductByIdData?.gender === null ||
                      GetProductByIdData?.gender === undefined ? null : (
                        <Box>
                          <Typography
                            sx={{ ...semiSub, color: "grey", fontSize: "16px" }}
                          >
                            Gender
                          </Typography>
                          <Typography
                            sx={{
                              ...tableData,
                              textAlign: "start",
                              lineHeight: "4rem",
                            }}
                          >
                            {GetProductByIdData?.gender}
                          </Typography>
                        </Box>
                      )}
                    </Box>

                    <Box
                      mt={6}
                      sx={{
                        width: "100%",
                        mx: "auto",
                      }}
                    >
                      <Grid
                        container
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {GetProductByIdData.length > 0 ? (
                          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                            <Box sx={ProductVariationStyle}>
                              <Typography
                                sx={{
                                  ...tableHeader,
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  color: "grey",
                                }}
                              >
                                {" "}
                                Sizes
                              </Typography>
                            </Box>
                          </Grid>
                        ) : null}

                        {GetProductByIdData?.ProductCategoryName === "FMCG" ? (
                          <>
                            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                              <Box sx={ProductVariationStyle}>
                                <Typography
                                  sx={{
                                    ...tableHeader,
                                    fontSize: "16px",
                                    fontWeight: 600,
                                    color: "grey",
                                  }}
                                >
                                  {getSizeCategory(
                                    GetProductByIdData?.ProductsVariantions[0]
                                      ?.ProductSize,
                                    GetProductByIdData?.ProductsVariantions[0]
                                      ?.MeasurementUnit,
                                    GetProductByIdData?.ProductsVariantions.at(
                                      0
                                    )?.["length"]
                                  )}
                                </Typography>
                              </Box>
                            </Grid>
                          </>
                        ) : null}

                        {GetProductByIdData?.ProductsVariantions[0]?.flavor ===
                          undefined ||
                        GetProductByIdData?.ProductsVariantions[0]?.flavor ===
                          null ? null : (
                          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                            <Box sx={ProductVariationStyle}>
                              <Typography
                                sx={{
                                  ...tableHeader,
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  color: "grey",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {" "}
                                Flavor
                              </Typography>
                            </Box>
                          </Grid>
                        )}

                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <Box sx={ProductVariationStyle}>
                            <Typography
                              sx={{
                                ...tableHeader,
                                fontSize: "16px",
                                fontWeight: 600,
                                color: "grey",
                              }}
                            >
                              {" "}
                              Min QTY
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <Box sx={ProductVariationStyle}>
                            <Typography
                              sx={{
                                ...tableHeader,
                                fontSize: "16px",
                                fontWeight: 600,
                                color: "grey",
                              }}
                            >
                              {" "}
                              Max QTY
                            </Typography>
                          </Box>
                        </Grid>
                        {GetProductByIdData?.ProductCategoryName === "QSR" ? (
                          <>
                            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                              <Box sx={ProductVariationStyle}>
                                <Typography
                                  sx={{
                                    ...tableHeader,
                                    fontSize: "14px",
                                  }}
                                >
                                  {" "}
                                  HSN
                                </Typography>
                              </Box>
                            </Grid>
                          </>
                        ) : null}
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <Box sx={ProductVariationStyle}>
                            <Typography
                              sx={{
                                ...tableHeader,
                                fontSize: "16px",
                                fontWeight: 600,
                                color: "grey",
                              }}
                            >
                              {" "}
                              GST
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <Box sx={ProductVariationStyle}>
                            <Typography
                              sx={{
                                ...tableHeader,
                                fontSize: "16px",
                                fontWeight: 600,
                                color: "grey",
                              }}
                            >
                              {" "}
                              Product ID
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {GetProductByIdData?.ProductsVariantions &&
                          GetProductByIdData?.ProductsVariantions?.map(
                            (res, idx) => {
                              return (
                                <Grid
                                  container
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  {GetProductByIdData.ProductSize ===
                                    undefined ||
                                  GetProductByIdData.ProductSize ===
                                    null ? null : (
                                    <Grid
                                      item
                                      xl={2}
                                      lg={2}
                                      md={2}
                                      sm={2}
                                      xs={2}
                                    >
                                      <Box sx={ProductVariationStyle}>
                                        {res.ShoeSize === null ||
                                        res.ShoeSize === undefined ? (
                                          <Typography
                                            sx={{
                                              ...tableData,
                                              fontSize: "14px",
                                            }}
                                          >
                                            {res.ProductSize ||
                                              res.NutritionInfo ||
                                              res.powerConsumption}
                                            {res.ProductSize === null ||
                                            res.ProductSize === undefined ? (
                                              <Typography sx={tableData}>
                                                {res.length}&nbsp;
                                                {res.MeasurementUnit}
                                              </Typography>
                                            ) : null}
                                          </Typography>
                                        ) : (
                                          <Typography sx={tableData}>
                                            &nbsp;{res.ShoeSize}&nbsp;
                                            {res.MeasurementUnit}
                                          </Typography>
                                        )}
                                      </Box>
                                    </Grid>
                                  )}

                                  {GetProductByIdData?.ProductCategoryName ===
                                  "FMCG" ? (
                                    <>
                                      <Grid
                                        item
                                        xl={2}
                                        lg={2}
                                        md={2}
                                        sm={2}
                                        xs={2}
                                      >
                                        <Box sx={ProductVariationStyle}>
                                          <Typography
                                            sx={{
                                              ...tableData,
                                              fontSize: "14px",
                                            }}
                                          >
                                            {res?.ProductSize ||
                                              res?.NutritionInfo ||
                                              res?.powerConsumption}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </>
                                  ) : null}

                                  {GetProductByIdData?.ProductsVariantions[0]
                                    ?.flavor === undefined ||
                                  GetProductByIdData?.ProductsVariantions[0]
                                    ?.flavor === null ? null : (
                                    <Grid
                                      item
                                      xl={2}
                                      lg={2}
                                      md={2}
                                      sm={2}
                                      xs={2}
                                    >
                                      <Box sx={ProductVariationStyle}>
                                        {res.flavor ? (
                                          <Typography
                                            sx={{
                                              ...tableData,
                                              fontSize: "14px",
                                            }}
                                          >
                                            {res.flavor}
                                          </Typography>
                                        ) : (
                                          <Typography
                                            sx={{
                                              ...tableData,
                                              fontSize: "14px",
                                            }}
                                          >
                                            -
                                          </Typography>
                                        )}
                                      </Box>
                                    </Grid>
                                  )}

                                  <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                    <Box sx={ProductVariationStyle}>
                                      <Typography
                                        sx={{
                                          ...tableData,
                                          fontSize: "14px",
                                        }}
                                      >
                                        {res.MinOrderQuantity}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                    <Box sx={ProductVariationStyle}>
                                      <Typography
                                        sx={{
                                          ...tableData,
                                          fontSize: "14px",
                                        }}
                                      >
                                        {res.MaxOrderQuantity}
                                      </Typography>
                                    </Box>
                                  </Grid>

                                  {GetProductByIdData?.ProductCategoryName ===
                                  "QSR" ? (
                                    <>
                                      <Grid
                                        item
                                        xl={2}
                                        lg={2}
                                        md={2}
                                        sm={2}
                                        xs={2}
                                      >
                                        <Box sx={ProductVariationStyle}>
                                          <Typography
                                            sx={{
                                              ...tableData,
                                              fontSize: "14px",
                                            }}
                                          >
                                            {res?.HSN}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </>
                                  ) : null}

                                  <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                    <Box sx={ProductVariationStyle}>
                                      <Typography
                                        sx={{
                                          ...tableData,
                                          fontSize: "14px",
                                        }}
                                      >
                                        {res.GST} &nbsp;%
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                    <Box sx={ProductVariationStyle}>
                                      <Typography
                                        sx={{
                                          ...tableData,
                                          fontSize: "14px",
                                        }}
                                      >
                                        {res.ProductIdType}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                              );
                            }
                          )}
                      </Grid>
                    </Box>

                    <Box
                      mt={4}
                      sx={{
                        borderTop: "2px solid rgba(238, 238, 238, 1)",
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      {/* <Typography sx={find}>Download Size Chart </Typography> */}
                      <Typography
                        sx={{ ...chart, cursor: "pointer" }}
                        onMouseEnter={() => setShowSizechart(true)}
                        onMouseLeave={() => setShowSizechart(false)}
                      >
                        Size Chart{" "}
                      </Typography>
                      {GetProductByIdData?.SizeChart?.at(0)?.url ===
                        undefined ||
                      GetProductByIdData?.SizeChart?.at(0)?.url === null ? (
                        showSizechart ? (
                          <Typography
                            sx={{
                              ...chart,
                              position: "absolute",
                              height: "300px",
                              width: "auto",
                              right: "5%",
                              zIndex: 10,
                            }}
                          >
                            Size Chart Unavailable
                          </Typography>
                        ) : null
                      ) : showSizechart ? (
                        <Box
                          component="img"
                          src={GetProductByIdData?.SizeChart?.at(0)?.url}
                          alt="hello"
                          onMouseEnter={() => setShowSizechart(true)}
                          onMouseLeave={() => setShowSizechart(false)}
                          sx={{
                            position: "absolute",
                            height: "300px",
                            width: "auto",
                            right: "5%",
                            zIndex: 10,
                          }}
                        />
                      ) : null}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}

          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ mt: 6 }}>
              <Box
                sx={{
                  width: {
                    xl: "100%",
                    lg: "100%",
                    md: "100%",
                    sm: "100%",
                    xs: "100%",
                  },
                  typography: "body1",
                }}
              >
                <TabContext value={value}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                    }}
                  >
                    {GetProductByIdData?.ProductCategoryName === "Textile" ||
                    GetProductByIdData?.ProductCategoryName === "Lifestyle" ||
                    GetProductByIdData?.ProductCategoryName ===
                      "Office Supply" ||
                    GetProductByIdData?.ProductCategoryName === "Others" ||
                    !GetProductByIdData?.ProductCategoryName ? (
                      <>
                        <TabList
                          onChange={handleChange}
                          sx={{ width: "95%" }}
                          variant="fullWidth"
                        >
                          <Tab
                            label={
                              <Typography sx={tabTexts}>Description</Typography>
                            }
                            value="1"
                          />
                          <Tab
                            label={
                              <Typography sx={tabTexts}>
                                Price & Availability
                              </Typography>
                            }
                            value="2"
                          />
                          <Tab
                            label={
                              <Typography sx={tabTexts}>
                                Technical Information
                              </Typography>
                            }
                            value="3"
                          />
                          <Tab
                            label={
                              <Typography sx={tabTexts}>
                                Key Features
                              </Typography>
                            }
                            value="4"
                          />
                        </TabList>
                      </>
                    ) : (
                      <>
                        <TabList
                          onChange={handleChange}
                          sx={{ width: "95%" }}
                          variant="fullWidth"
                        >
                          <Tab
                            label={
                              <Typography sx={tabTexts}>Description</Typography>
                            }
                            value="1"
                          />
                          <Tab
                            label={
                              <Typography sx={tabTexts}>
                                Price & Availability
                              </Typography>
                            }
                            value="2"
                          />
                          <Tab
                            label={
                              <Typography sx={tabTexts}>
                                Technical Information
                              </Typography>
                            }
                            value="3"
                          />
                          <Tab
                            label={
                              <Typography sx={tabTexts}>
                                Key Features
                              </Typography>
                            }
                            value="4"
                          />
                        </TabList>
                      </>
                    )}
                  </Box>
                  <Box>
                    <TabPanel value="1">
                      {CompanyTypeData?.data?.CompanyTypeName === "Textile" ||
                      CompanyTypeData?.data?.CompanyTypeName === "Lifestyle" ||
                      CompanyTypeData?.data?.CompanyTypeName ===
                        "Office Supply" ||
                      CompanyTypeData?.data?.CompanyTypeName ===
                        "Others" ? null : (
                        <>
                          <Grid
                            container
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: "column",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                gap: 0.5,
                              }}
                            >
                              <Typography
                                sx={{
                                  ...product,
                                  fontSize: "20px",
                                  color: "#6B7A99",
                                }}
                              >
                                {GetProductByIdData?.ProductSubtittle}
                                {GetProductByIdData?.ProductSubtitle}
                              </Typography>
                              <Typography
                                sx={{
                                  ...tabSubText,
                                  fontSize: "16px",
                                  color: "#6B7A99",
                                  fontWeight: 400,
                                }}
                              >
                                {GetProductByIdData?.ProductDescription}
                              </Typography>
                            </Box>
                          </Grid>

                          {GetProductByIdData?.ModelName === undefined ||
                          GetProductByIdData?.ModelName === null ? null : (
                            <Box
                              mt={1}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "2px",
                              }}
                            >
                              <Typography sx={{ ...product, fontSize: "18px" }}>
                                Model Name
                              </Typography>

                              <Typography
                                sx={{
                                  ...packHead,
                                  color: "#6B7A99",
                                  fontWeight: 400,
                                  fontSize: "16px",
                                }}
                              >
                                {GetProductByIdData?.ModelName}
                              </Typography>
                            </Box>
                          )}

                          {GetProductByIdData?.demoInstallation === undefined ||
                          GetProductByIdData?.demoInstallation ===
                            null ? null : (
                            <Box
                              mt={2}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "2px",
                              }}
                            >
                              <Typography sx={{ ...product, fontSize: "18px" }}>
                                Service Available
                              </Typography>

                              <Box>
                                {GetProductByIdData?.demoInstallation ===
                                "true" ? (
                                  <Typography
                                    sx={{
                                      ...packHead,
                                      color: "#6B7A99",
                                      fontWeight: 400,
                                      fontSize: "16px",
                                    }}
                                  >
                                    demo & Installation : Yes
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{
                                      ...packHead,
                                      color: "#6B7A99",
                                      fontWeight: 400,
                                      fontSize: "16px",
                                    }}
                                  >
                                    demo & Installation : No
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          )}
                        </>
                      )}

                      <Grid
                        container
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            gap: 0.5,
                          }}
                        >
                          {GetProductByIdData?.ProductCategoryName ===
                            "Textile" ||
                          GetProductByIdData?.ProductCategoryName ===
                            "Lifestyle" ||
                          GetProductByIdData?.ProductCategoryName ===
                            "Office Supply" ||
                          GetProductByIdData?.ProductCategoryName ===
                            "Others" ? (
                            <>
                              <Typography
                                sx={{
                                  ...product,
                                  fontSize: "20px",
                                  color: "#6B7A99",
                                }}
                              >
                                {GetProductByIdData &&
                                  GetProductByIdData.ProductSubtittle &&
                                  GetProductByIdData?.ProductSubtitle}
                              </Typography>

                              <Typography
                                sx={{
                                  ...tabSubText,
                                  fontSize: "16px",
                                  color: "#6B7A99",
                                  fontWeight: 400,
                                  textAlign: "start",
                                }}
                              >
                                {GetProductByIdData &&
                                  GetProductByIdData.ProductDescription}
                              </Typography>
                            </>
                          ) : null}

                          {GetProductByIdData?.ProductsVariantions
                            ?.SampleAvailability === undefined ||
                          GetProductByIdData?.ProductsVariantions
                            ?.SampleAvailability === null ||
                          GetProductByIdData?.ProductsVariantions
                            ?.SampleAvailability === 0 ? (
                            <>
                              <Typography
                                sx={{
                                  ...product,
                                  fontSize: "18px",
                                  mt: 2,
                                  color: "#156DB6",
                                }}
                              >
                                Sample Details
                              </Typography>

                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "2px",
                                  flexDirection: "column",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <Typography
                                    sx={{ ...available, fontSize: "14px" }}
                                  >
                                    Sample Available :&nbsp;
                                  </Typography>

                                  {samplestate ? (
                                    <Typography
                                      sx={{
                                        ...available,
                                        fontWeight: 500,
                                        width: "20%",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Yes
                                    </Typography>
                                  ) : (
                                    <Typography
                                      sx={{
                                        ...available,
                                        fontWeight: 500,
                                        width: "20%",
                                        fontSize: "14px",
                                      }}
                                    >
                                      No
                                    </Typography>
                                  )}
                                </Box>

                                {samplestate ? (
                                  <>
                                    {GetProductByIdData?.ProductsVariantions?.map(
                                      (item) => {
                                        return (
                                          <>
                                            <Box
                                              sx={{
                                                display: "flex",
                                                gap: "10px",
                                                width: "700px",
                                              }}
                                            >
                                              <Box
                                                sx={{
                                                  width: "100%",
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <Grid
                                                  container
                                                  sx={{
                                                    display: "flex",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <Grid
                                                    item
                                                    xl={6}
                                                    lg={6}
                                                    md={6}
                                                    sm={12}
                                                    xs={12}
                                                    sx={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      gap: "20px",
                                                      width: "100%",
                                                    }}
                                                  >
                                                    <Box
                                                      sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        width: "100%",
                                                      }}
                                                    >
                                                      <Typography
                                                        sx={{
                                                          ...available,
                                                          width: "100%",
                                                          fontWeight: 500,
                                                          fontSize: "18px",
                                                          lineHeight: "27px",
                                                          color: "grey",
                                                          mt: 1,
                                                        }}
                                                      >
                                                        Minimum Order Quantity
                                                      </Typography>
                                                      <Typography
                                                        sx={{
                                                          ...available,
                                                          lineHeight: "20px",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "#6B7A99",
                                                          width: "100%",
                                                        }}
                                                      >
                                                        {
                                                          item.SampleAvailability
                                                        }
                                                      </Typography>
                                                    </Box>
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xl={6}
                                                    lg={6}
                                                    md={6}
                                                    sm={12}
                                                    xs={12}
                                                    sx={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                      gap: "20px",
                                                    }}
                                                  >
                                                    <Box sx={{}}>
                                                      <Typography
                                                        sx={{
                                                          ...available,
                                                          width: "100%",
                                                          fontWeight: 500,
                                                          fontSize: "18px",
                                                          lineHeight: "27px",
                                                          color: "grey",
                                                          mt: 1,
                                                        }}
                                                      >
                                                        Price Of Sample
                                                      </Typography>
                                                      <Box
                                                        sx={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "flex-start",
                                                          flexDirection: "row",
                                                          width: "auto",
                                                          mt: "10px",
                                                          marginBottom: "-11px",
                                                        }}
                                                      >
                                                        <Typography
                                                          sx={{
                                                            ...available,
                                                            lineHeight: "20px",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "#6B7A99",
                                                          }}
                                                        >
                                                          {item.PriceOfSample}
                                                        </Typography>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                        <img
                                                          src={BXITokenIcon}
                                                          style={{
                                                            width: "20px",
                                                            height: "auto",
                                                          }}
                                                          alt="BXI Token"
                                                        />{" "}
                                                      </Box>
                                                    </Box>
                                                  </Grid>
                                                </Grid>
                                              </Box>
                                            </Box>
                                          </>
                                        );
                                      }
                                    )}
                                  </>
                                ) : null}
                              </Box>
                            </>
                          ) : null}

                          {GetProductByIdData?.productContentInformation ===
                            undefined ||
                          GetProductByIdData?.productContentInformation ===
                            null ? null : (
                            <Box
                              mt={2}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "2px",
                              }}
                            >
                              <Typography
                                sx={{
                                  ...packHead,
                                  fontFamily: "Poppins",
                                  fontStyle: "normal",
                                  fontWeight: 600,
                                  // color: "#",
                                  color: "#156DB6",
                                  fontSize: "18px",
                                }}
                              >
                                Product Content
                              </Typography>

                              <Typography
                                sx={{
                                  ...packHead,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "#6B7A99",
                                }}
                              >
                                {GetProductByIdData?.productContentInformation}
                              </Typography>
                            </Box>
                          )}

                          <Box sx={{ display: "flex", gap: "10px" }}>
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <Grid
                                container
                                sx={{
                                  display: "flex",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  mt={3}
                                  sx={{
                                    color: "#6B7A99",
                                    display: "flex",
                                    gap: "10px",
                                    flexDirection: "column",
                                    width: "100%",
                                  }}
                                >
                                  <Box>
                                    <Typography
                                      sx={{
                                        ...product,
                                        fontSize: "18px",
                                        color: "#156DB6",
                                      }}
                                    >
                                      Product Pickup Location & Pincode
                                    </Typography>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "50px",
                                        mt: 1,
                                      }}
                                    >
                                      <Box>
                                        <Typography
                                          sx={{
                                            ...available,
                                            width: "100%",
                                            fontWeight: 500,
                                            fontSize: "18px",
                                            lineHeight: "27px",
                                            color: "grey",
                                          }}
                                        >
                                          Region
                                        </Typography>
                                        <Typography
                                          sx={{
                                            ...available,
                                            lineHeight: "20px",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "#6B7A99",
                                            width: "100%",
                                            mt: 1,
                                          }}
                                        >
                                          {
                                            GetProductByIdData?.LocationDetails
                                              ?.region
                                          }
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          ml: 7,
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            ...available,
                                            width: "100%",
                                            fontWeight: 500,
                                            fontSize: "18px",
                                            lineHeight: "27px",
                                            color: "grey",
                                          }}
                                        >
                                          State
                                        </Typography>
                                        <Typography
                                          sx={{
                                            ...available,
                                            lineHeight: "20px",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "#6B7A99",
                                            width: "100%",
                                            mt: 1,
                                          }}
                                        >
                                          {
                                            GetProductByIdData?.LocationDetails
                                              ?.state
                                          }
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          ml: 7,
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            ...available,
                                            width: "100%",
                                            fontWeight: 500,
                                            fontSize: "18px",
                                            lineHeight: "27px",
                                            color: "grey",
                                          }}
                                        >
                                          City
                                        </Typography>
                                        <Typography
                                          sx={{
                                            ...available,
                                            lineHeight: "20px",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "#6B7A99",
                                            width: "100%",
                                            mt: 1,
                                          }}
                                        >
                                          {
                                            GetProductByIdData?.LocationDetails
                                              ?.city
                                          }
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          ml: 7,
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            ...available,
                                            width: "100%",
                                            fontWeight: 500,
                                            fontSize: "18px",
                                            lineHeight: "27px",
                                            color: "grey",
                                          }}
                                        >
                                          Landmark
                                        </Typography>
                                        <Typography
                                          sx={{
                                            ...available,
                                            lineHeight: "20px",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "#6B7A99",
                                            width: "100%",
                                            mt: 1,
                                          }}
                                        >
                                          {
                                            GetProductByIdData?.LocationDetails
                                              ?.landmark
                                          }
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          ml: 7,
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            ...available,
                                            width: "100%",
                                            fontWeight: 500,
                                            fontSize: "18px",
                                            lineHeight: "27px",
                                            color: "grey",
                                          }}
                                        >
                                          Pincode
                                        </Typography>
                                        <Typography
                                          sx={{
                                            ...available,
                                            lineHeight: "20px",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "#6B7A99",
                                            width: "100%",
                                            mt: 1,
                                          }}
                                        >
                                          {
                                            GetProductByIdData?.LocationDetails
                                              ?.pincode
                                          }
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>

                                  {GetProductByIdData?.formofProduct ===
                                    undefined ||
                                  GetProductByIdData?.formofProduct ===
                                    null ? null : (
                                    <Box
                                      mt={2}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "2px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          ...packHead,
                                          fontFamily: "Poppins",
                                          fontStyle: "normal",
                                          fontWeight: 600,
                                          // color: "#6B7A99",
                                          color: "#156DB6",
                                        }}
                                      >
                                        Form
                                      </Typography>

                                      <Typography
                                        sx={{
                                          ...packHead,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "#6B7A99",
                                        }}
                                      >
                                        {GetProductByIdData?.formofProduct}
                                      </Typography>
                                    </Box>
                                  )}

                                  {GetProductByIdData?.listperiod ===
                                    undefined ||
                                  GetProductByIdData?.listperiod === null ||
                                  GetProductByIdData?.listperiod ===
                                    "undefined" ||
                                  GetProductByIdData?.listperiod ===
                                    "" ? null : (
                                    <Box
                                      mt={2}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "2px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          ...product,
                                          color: "#156DB6",
                                          fontSize: "18px",
                                        }}
                                      >
                                        Listed This Product For
                                      </Typography>

                                      <Typography
                                        sx={{
                                          ...packHead,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "#6B7A99",
                                        }}
                                      >
                                        {GetProductByIdData?.listperiod}&nbsp;
                                        Days
                                      </Typography>
                                    </Box>
                                  )}

                                  {GetProductByIdData?.OtherCost &&
                                  GetProductByIdData?.OtherCost?.length !==
                                    0 ? (
                                    <Box mt={2}>
                                      <Typography
                                        sx={{
                                          ...product,
                                          fontWeight: 600,
                                          fontSize: "18px",
                                          lineHeight: "30px",
                                          color: "#156DB6",
                                        }}
                                      >
                                        Additional Cost
                                      </Typography>
                                      {GetProductByIdData?.OtherCost?.length ===
                                      0
                                        ? ""
                                        : GetProductByIdData?.OtherCost?.map(
                                            (cost) => {
                                              const newValue =
                                                cost?.CostPrice.toFixed(2);
                                              return (
                                                <>
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      // gap: "60px",
                                                      justifyContent:
                                                        "space-between",
                                                      mt: 1,
                                                      width: "70%",
                                                    }}
                                                  >
                                                    <Box sx={{}}>
                                                      <Typography
                                                        sx={{
                                                          ...listText,
                                                          wordWrap:
                                                            "break-word",
                                                        }}
                                                      >
                                                        {" "}
                                                        &#8226; &nbsp;&nbsp;{" "}
                                                        {
                                                          cost.AdCostApplicableOn
                                                        }{" "}
                                                      </Typography>
                                                    </Box>

                                                    <Box sx={{}}>
                                                      <Typography
                                                        sx={{
                                                          ...listText,
                                                          wordWrap:
                                                            "break-word",
                                                        }}
                                                      >
                                                        {" "}
                                                        {cost.ReasonOfCost}{" "}
                                                      </Typography>
                                                    </Box>

                                                    <Box sx={{}}>
                                                      <Typography
                                                        sx={{
                                                          ...listText,
                                                          wordWrap:
                                                            "break-word",
                                                        }}
                                                      >
                                                        HSN - {cost.AdCostHSN}{" "}
                                                      </Typography>
                                                    </Box>

                                                    <Box sx={{}}>
                                                      <Typography
                                                        sx={{
                                                          ...listText,
                                                          wordWrap:
                                                            "break-word",
                                                        }}
                                                      >
                                                        GST - {cost.AdCostGST} %
                                                      </Typography>
                                                    </Box>
                                                    <Box
                                                      sx={{
                                                        gap: "5px",

                                                        display: "flex",
                                                      }}
                                                    >
                                                      <Typography
                                                        sx={{
                                                          ...listText,
                                                          wordWrap:
                                                            "break-word",
                                                          color:
                                                            "rgba(68, 95, 210, 1)",
                                                        }}
                                                      >
                                                        <CommaSeprator
                                                          Price={newValue}
                                                        />
                                                        {/* {newValue} */}
                                                      </Typography>
                                                      <Typography>
                                                        {cost.currencyType ===
                                                        "BXITokens" ? (
                                                          <Box
                                                            component="img"
                                                            src={BXITokenIcon}
                                                            alt="token"
                                                            sx={{
                                                              height: "auto",
                                                              width: "20px",
                                                              marginTop: "2px",
                                                            }}
                                                          />
                                                        ) : (
                                                          <Typography
                                                            sx={{
                                                              fontSize: "14px",
                                                              ml: 1,
                                                            }}
                                                          >
                                                            ₹
                                                          </Typography>
                                                        )}
                                                      </Typography>
                                                    </Box>
                                                  </Box>
                                                </>
                                              );
                                            }
                                          )}
                                    </Box>
                                  ) : (
                                    <Typography
                                      sx={{
                                        ...packHead,
                                        fontFamily: "Poppins",
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        color: "#6B7A99",
                                        fontSize: "1.8rem",
                                      }}
                                    >
                                      Additional Cost :{" "}
                                      <span
                                        style={{
                                          ...packHead,
                                          color: "#6B7A99",
                                          fontWeight: 400,
                                          fontSize: "18px",
                                        }}
                                      >
                                        No
                                      </span>
                                    </Typography>
                                  )}

                                  {GetProductByIdData?.ProductDetails
                                    ?.registrationdetails === undefined ||
                                  GetProductByIdData?.ProductDetails
                                    ?.registrationdetails === null ? null : (
                                    <Box
                                      mt={2}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "2px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          ...packHead,
                                          fontFamily: "Poppins",
                                          fontStyle: "normal",
                                          fontWeight: 600,
                                          color: "#156DB6",
                                          fontSize: "18px",
                                        }}
                                      >
                                        Registration Details
                                      </Typography>

                                      <Typography
                                        sx={{
                                          ...packHead,
                                          color: "#6B7A99",
                                          fontWeight: 400,
                                          fontSize: "14px",
                                        }}
                                      >
                                        {
                                          GetProductByIdData?.ProductDetails
                                            ?.registrationdetails
                                        }
                                      </Typography>
                                    </Box>
                                  )}

                                  {GetProductByIdData?.ProductDetails
                                    ?.taxesdetails === undefined ||
                                  GetProductByIdData?.ProductDetails
                                    ?.taxesdetails === null ? null : (
                                    <Box
                                      mt={2}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "2px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          ...packHead,
                                          fontFamily: "Poppins",
                                          fontStyle: "normal",
                                          fontWeight: 600,
                                          color: "#156DB6",
                                          fontSize: "18px",
                                        }}
                                      >
                                        Tax Details
                                      </Typography>

                                      <Typography
                                        sx={{
                                          ...packHead,
                                          color: "#6B7A99",
                                          fontWeight: 400,
                                          fontSize: "14px",
                                        }}
                                      >
                                        {
                                          GetProductByIdData?.ProductDetails
                                            ?.taxesdetails
                                        }
                                      </Typography>
                                    </Box>
                                  )}

                                  {GetProductByIdData?.ProductDetails
                                    ?.insurancedetails === undefined ||
                                  GetProductByIdData?.ProductDetails
                                    ?.insurancedetails === null ? null : (
                                    <Box
                                      mt={2}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "2px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          ...packHead,
                                          fontFamily: "Poppins",
                                          fontStyle: "normal",
                                          fontWeight: 600,
                                          color: "#156DB6",
                                          fontSize: "18px",
                                        }}
                                      >
                                        Insurance Details
                                      </Typography>

                                      <Typography
                                        sx={{
                                          ...packHead,
                                          color: "#6B7A99",
                                          fontWeight: 400,
                                          fontSize: "14px",
                                        }}
                                      >
                                        {
                                          GetProductByIdData?.ProductDetails
                                            ?.insurancedetails
                                        }
                                      </Typography>
                                    </Box>
                                  )}

                                  {GetProductByIdData?.ManufacturingDate ? (
                                    <Grid
                                      container
                                      sx={{
                                        display: "flex",
                                        width: "50%",
                                      }}
                                      mt={2}
                                    >
                                      <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        xs={12}
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          gap: "20px",
                                          width: "100%",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              ...available,
                                              width: "100%",
                                              fontWeight: 500,
                                              fontSize: "18px",
                                              lineHeight: "27px",
                                              color: "#6B7A99",
                                            }}
                                          >
                                            Manufacturing Date
                                          </Typography>
                                          <Typography
                                            sx={{
                                              ...available,
                                              lineHeight: "20px",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "#6B7A99",
                                              width: "100%",
                                              mt: 1,
                                            }}
                                          >
                                            {new Date(
                                              GetProductByIdData?.ManufacturingDate
                                            ).toLocaleDateString()}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid
                                        item
                                        lg={6}
                                        xl={6}
                                        md={6}
                                        sm={12}
                                        xs={12}
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          gap: "20px",
                                        }}
                                      >
                                        <Box>
                                          <Typography
                                            sx={{
                                              ...available,
                                              width: "100%",
                                              fontWeight: 500,
                                              fontSize: "18px",
                                              color: "#6B7A99",
                                            }}
                                          >
                                            Expiry Date{" "}
                                          </Typography>
                                          <Typography
                                            sx={{
                                              ...available,
                                              lineHeight: "20px",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "#6B7A99",
                                              mt: 1,
                                            }}
                                          >
                                            {GetProductByIdData?.ExpiryDate ? (
                                              <>
                                                {new Date(
                                                  GetProductByIdData?.ExpiryDate
                                                ).toLocaleDateString()}
                                              </>
                                            ) : (
                                              "Not Given"
                                            )}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  ) : GetProductByIdData?.ManufacturingData ? (
                                    <Grid
                                      container
                                      sx={{
                                        display: "flex",
                                        width: "50%",
                                      }}
                                      mt={2}
                                    >
                                      <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        xs={12}
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          gap: "20px",
                                          width: "100%",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              ...available,
                                              width: "100%",
                                              fontWeight: 500,
                                              fontSize: "18px",
                                              lineHeight: "27px",
                                              color: "#6B7A99",
                                            }}
                                          >
                                            Manufacturing Date
                                          </Typography>
                                          <Typography
                                            sx={{
                                              ...available,
                                              lineHeight: "20px",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "#6B7A99",
                                              width: "100%",
                                              mt: 1,
                                            }}
                                          >
                                            {new Date(
                                              GetProductByIdData?.ManufacturingData
                                            ).toLocaleDateString()}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid
                                        item
                                        lg={6}
                                        xl={6}
                                        md={6}
                                        sm={12}
                                        xs={12}
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          gap: "20px",
                                        }}
                                      >
                                        <Box>
                                          <Typography
                                            sx={{
                                              ...available,
                                              width: "100%",
                                              fontWeight: 500,
                                              fontSize: "18px",
                                              color: "#6B7A99",
                                            }}
                                          >
                                            Expiry Date{" "}
                                          </Typography>
                                          <Typography
                                            sx={{
                                              ...available,
                                              lineHeight: "20px",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "#6B7A99",
                                              mt: 1,
                                            }}
                                          >
                                            {GetProductByIdData?.ExpiryDate ? (
                                              <>
                                                {new Date(
                                                  GetProductByIdData?.ExpiryDate
                                                ).toLocaleDateString()}
                                              </>
                                            ) : (
                                              "Not Given"
                                            )}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  ) : null}
                                </Box>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>

                      {/* Tabpanel 3 data */}
                      <Box
                        mt={2}
                        sx={{
                          borderTop: "0.2px solid #156DB6",
                          mt: 4,
                        }}
                      >
                        <Typography sx={{ ...pack, color: "#156DB6", mt: 2 }}>
                          Technical Information
                        </Typography>

                        <Box
                          mt={1}
                          sx={{
                            display: "flex",
                            gap: "10px",
                            width: "500px",
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            {GetProductByIdData?.ProductTechInfo?.Warranty ===
                              undefined ||
                            GetProductByIdData?.ProductTechInfo?.Warranty ===
                              null ? null : (
                              <Grid
                                item
                                xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "20px",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...available,
                                      width: "100%",
                                      fontWeight: 500,
                                      fontSize: "18px",
                                      lineHeight: "27px",
                                      color: "#156DB6",
                                    }}
                                  >
                                    Warranty
                                  </Typography>
                                  <Typography
                                    sx={{
                                      ...available,
                                      lineHeight: "20px",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "#6B7A99",
                                      width: "100%",
                                      mt: 1,
                                    }}
                                  >
                                    {
                                      GetProductByIdData?.ProductTechInfo
                                        ?.Warranty
                                    }{" "}
                                    {GetProductByIdData?.WarrantyPeriod ===
                                      null ||
                                    GetProductByIdData?.WarrantyPeriod ===
                                      undefined
                                      ? GetProductByIdData?.UnitOfTime
                                      : GetProductByIdData?.WarrantyPeriod}
                                  </Typography>
                                </Box>
                              </Grid>
                            )}

                            {GetProductByIdData?.ProductTechInfo?.Guarantee ===
                              undefined ||
                            GetProductByIdData?.ProductTechInfo?.Guarantee ===
                              null ? null : (
                              <Grid
                                item
                                lg={6}
                                xl={6}
                                md={6}
                                sm={12}
                                xs={12}
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "20px",
                                }}
                              >
                                <Box sx={{}}>
                                  <Typography
                                    sx={{
                                      ...available,
                                      width: "100%",
                                      fontWeight: 500,
                                      fontSize: "18px",
                                      lineHeight: "27px",
                                      color: "#156DB6",
                                    }}
                                  >
                                    Guarantee
                                  </Typography>
                                  <Typography
                                    sx={{
                                      ...available,
                                      lineHeight: "20px",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "#6B7A99",
                                      mt: 1,
                                    }}
                                  >
                                    {
                                      GetProductByIdData?.ProductTechInfo
                                        ?.Guarantee
                                    }{" "}
                                    {GetProductByIdData?.GuaranteePeriod ===
                                      null ||
                                    GetProductByIdData?.GuaranteePeriod ===
                                      undefined
                                      ? GetProductByIdData?.UnitOfTime
                                      : GetProductByIdData?.GuaranteePeriod}
                                  </Typography>
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </Box>

                        {GetProductByIdData?.ProductTechInfo
                          ?.WeightBeforePackingPerUnit ? (
                          <Box
                            mt={1}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              width: "95%",
                            }}
                          >
                            <Typography
                              mt={2}
                              sx={{
                                ...product,
                                fontSize: "18px",
                                color: "#156DB6",
                                fontWeight: 500,
                              }}
                            >
                              Packaging Information
                            </Typography>

                            {technicalinfo?.map((val) => {
                              const newVal = Number(val?.val).toFixed(2);
                              return (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    // justifyContent: "space-between",
                                    width: "12%",
                                    gap: "25px",
                                    mt: 2,
                                  }}
                                >
                                  <Box component="img" src={val.img} />
                                  <Box
                                    sx={{
                                      width: "100%",
                                      minWidth: "185px",
                                    }}
                                  >
                                    <Typography
                                      sx={{ ...packHead, fontSize: "16px" }}
                                    >
                                      {val.name}
                                    </Typography>
                                    {/* <Typography sx={packVal}>
                                  {val.name === "Before Weight" ||
                                  val.name === "After Weight"
                                    ? newVal + " kg"
                                    : newVal + " cm"}
                                </Typography> */}
                                    {GetProductByIdData?.WeightBeforePackingPerUnitMeasurUnit ===
                                      undefined ||
                                    GetProductByIdData?.WeightBeforePackingPerUnitMeasurUnit ===
                                      null ? (
                                      <Typography sx={packVal}>
                                        {newVal}
                                        {GetProductByIdData?.UnitOfWeight}
                                      </Typography>
                                    ) : GetProductByIdData?.WeightBeforePackingPerUnitMeasurUnit ===
                                      "Grams" ? (
                                      <Typography sx={packVal}>
                                        {newVal} {val.measure}
                                      </Typography>
                                    ) : (
                                      <Typography sx={packVal}>
                                        {newVal} {val.measure}
                                      </Typography>
                                    )}
                                  </Box>
                                </Box>
                              );
                            })}
                          </Box>
                        ) : null}

                        {GetProductByIdData?.ProductTechInfo
                          ?.PackagingInfoPerUnit === undefined ||
                        GetProductByIdData?.ProductTechInfo
                          ?.PackagingInfoPerUnit === null ? null : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "2px",
                              mt: 3,
                            }}
                          >
                            <Typography
                              sx={{
                                ...packHead,
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 600,
                                // color: "#6B7A99",
                                color: "#156DB6",
                                fontSize: "18px",
                              }}
                            >
                              Packaging Information Per Unit
                            </Typography>

                            <Typography
                              sx={{
                                ...packHead,
                                color: "#6B7A99",
                                fontWeight: 500,
                                fontSize: "14px",
                              }}
                            >
                              {
                                GetProductByIdData?.ProductTechInfo
                                  ?.PackagingInfoPerUnit
                              }
                            </Typography>
                          </Box>
                        )}

                        {GetProductByIdData?.ProductTechInfo
                          ?.LegalInformation === undefined ||
                        GetProductByIdData?.ProductTechInfo
                          ?.LegalInformation === null ? null : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "2px",
                              mt: 3,
                            }}
                          >
                            <Typography
                              sx={{
                                ...packHead,
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 600,
                                // color: "grey",
                                color: "#156DB6",
                                fontSize: "18px",
                              }}
                            >
                              Legal Information
                            </Typography>

                            <Typography
                              sx={{
                                ...packHead,
                                color: "#6B7A99",
                                fontWeight: 500,
                                fontSize: "14px",
                              }}
                            >
                              {
                                GetProductByIdData?.ProductTechInfo
                                  ?.LegalInformation
                              }
                            </Typography>
                          </Box>
                        )}

                        {GetProductByIdData?.ProductTechInfo
                          ?.PackagingAndDeliveryInstructionsIfAny ===
                          undefined ||
                        GetProductByIdData?.ProductTechInfo
                          ?.PackagingAndDeliveryInstructionsIfAny ===
                          null ? null : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "2px",
                              mt: 3,
                            }}
                          >
                            <Typography
                              mt={1}
                              sx={{
                                ...product,
                                fontSize: "18px",
                                color: "#156DB6",
                                fontWeight: 500,
                              }}
                            >
                              Packaging and Delivery Instructions
                            </Typography>

                            <Typography
                              sx={{
                                ...packHead,
                                color: "#6B7A99",
                                fontWeight: 500,
                                fontSize: "14px",
                              }}
                            >
                              {
                                GetProductByIdData?.ProductTechInfo
                                  ?.PackagingAndDeliveryInstructionsIfAny
                              }
                            </Typography>
                          </Box>
                        )}
                        {GetProductByIdData?.ProductTechInfo
                          ?.InstructionsToUseProduct === null ||
                        GetProductByIdData?.ProductTechInfo
                          ?.InstructionsToUseProduct === undefined ? null : (
                          <Box
                            mt={1}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "2px",
                            }}
                          >
                            <Typography
                              sx={{
                                ...product,
                                fontWeight: 600,
                                fontSize: "18px",
                                lineHeight: "30px",
                                color: "#156DB6",
                                fontWeight: 500,
                                mt: 3,
                              }}
                            >
                              Instructions to use product
                            </Typography>

                            <Typography
                              sx={{
                                ...packHead,
                                color: "#6B7A99",
                                fontWeight: 500,
                                fontSize: "14px",
                              }}
                            >
                              {
                                GetProductByIdData?.ProductTechInfo
                                  ?.InstructionsToUseProduct
                              }
                            </Typography>
                          </Box>
                        )}
                      </Box>

                      {/* Tabpanel 4 data */}
                      <Box sx={{ mt: 2 }}>
                        <Typography sx={{ ...pack, color: "#156DB6" }}>
                          Key Features
                        </Typography>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Grid
                            container
                            mt={0.5}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              width: "100%",
                            }}
                          >
                            {storeTechnicalInfo?.map((res) => {
                              return (
                                <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                                  <Box
                                    sx={{
                                      px: 2,
                                      display: "flex",
                                      // flexWrap: "wrap",
                                      textAlign: "start",
                                      flexDirection: "row",
                                      gap: "100px",
                                      mt: 1,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        gap: "20px",
                                        width: "100%",
                                        mt: 2,
                                      }}
                                    >
                                      {/* <Box
                                      component="img"
                                      src={bxifeature}
                                      sx={{ height: "80px", width: "30px" }}
                                    /> */}
                                      <FeatureName name={res?.name} />
                                      <Box
                                        sx={{
                                          width: "80%",
                                          maxWidth: "825px",
                                          height: "auto",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        <Typography
                                          sx={{ ...packHead, color: "grey" }}
                                        >
                                          {res.name}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            ...packVal,
                                            fontSize: "1.5rem",
                                            mt: 0.5,
                                          }}
                                        >
                                          {res.description}{" "}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Box>
                      </Box>
                    </TabPanel>
                    <TabPanel value="2">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        {CompanyTypeData?.data?.CompanyTypeName === "Textile" ||
                        CompanyTypeData?.data?.CompanyTypeName ===
                          "Lifestyle" ||
                        CompanyTypeData?.data?.CompanyTypeName ===
                          "Office Supply" ||
                        CompanyTypeData?.data?.CompanyTypeName ===
                          "Others" ? null : (
                          <>
                            <Box sx={{ mt: 1 }}>
                              <Typography
                                sx={{
                                  color: "#156DB6",
                                  fontSize: "24px",
                                  fontFamily: "Poppins",
                                  fontWeight: 600,
                                }}
                              >
                                Price & Availability
                              </Typography>
                            </Box>

                            {GetProductByIdData?.ModelName === undefined ||
                            GetProductByIdData?.ModelName === null ? null : (
                              <Box
                                mt={1}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "2px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    ...product,
                                    fontSize: "18px",
                                    color: "grey",
                                  }}
                                >
                                  Model Name
                                </Typography>

                                <Typography
                                  sx={{
                                    ...packHead,
                                    color: "#6B7A99",
                                    fontWeight: 400,
                                    fontSize: "16px",
                                  }}
                                >
                                  {GetProductByIdData?.ModelName}
                                </Typography>
                              </Box>
                            )}

                            {GetProductByIdData?.demoInstallation ===
                              undefined ||
                            GetProductByIdData?.demoInstallation ===
                              null ? null : (
                              <Box
                                mt={2}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "2px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    ...product,
                                    fontSize: "18px",
                                    color: "grey",
                                  }}
                                >
                                  Service Available
                                </Typography>

                                <Box>
                                  {GetProductByIdData?.demoInstallation ===
                                  "true" ? (
                                    <Typography
                                      sx={{
                                        ...packHead,
                                        color: "grey",
                                        fontWeight: 400,
                                        fontSize: "16px",
                                      }}
                                    >
                                      demo & Installation : Yes
                                    </Typography>
                                  ) : (
                                    <Typography
                                      sx={{
                                        ...packHead,
                                        color: "grey",
                                        fontWeight: 400,
                                        fontSize: "16px",
                                      }}
                                    >
                                      demo & Installation : No
                                    </Typography>
                                  )}
                                </Box>
                              </Box>
                            )}
                          </>
                        )}

                        {GetProductByIdData?.ProductsVariantions
                          ?.SampleAvailability === undefined ||
                        GetProductByIdData?.ProductsVariantions
                          ?.SampleAvailability === null ||
                        GetProductByIdData?.ProductsVariantions
                          ?.SampleAvailability === 0 ? null : (
                          <>
                            <Typography
                              sx={{
                                ...product,
                                fontSize: "18px",
                                mt: 2,
                                color: "#156DB6",
                              }}
                            >
                              Sample Details
                            </Typography>

                            <Box
                              sx={{
                                display: "flex",
                                gap: "2px",
                                flexDirection: "column",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Typography
                                  sx={{ ...available, fontSize: "14px" }}
                                >
                                  Sample Available :&nbsp;
                                </Typography>

                                {samplestate ? (
                                  <Typography
                                    sx={{
                                      ...available,
                                      fontWeight: 500,
                                      width: "20%",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Yes
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{
                                      ...available,
                                      fontWeight: 500,
                                      width: "20%",
                                      fontSize: "14px",
                                    }}
                                  >
                                    No
                                  </Typography>
                                )}
                              </Box>

                              {samplestate ? (
                                <>
                                  {GetProductByIdData?.ProductsVariantions?.map(
                                    (item) => {
                                      return (
                                        <>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              gap: "10px",
                                              width: "700px",
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <Grid
                                                container
                                                sx={{
                                                  display: "flex",
                                                  width: "100%",
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xl={6}
                                                  lg={6}
                                                  md={6}
                                                  sm={12}
                                                  xs={12}
                                                  sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: "20px",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      width: "100%",
                                                    }}
                                                  >
                                                    <Typography
                                                      sx={{
                                                        ...available,
                                                        width: "100%",
                                                        fontWeight: 500,
                                                        fontSize: "18px",
                                                        lineHeight: "27px",
                                                        color: "grey",
                                                      }}
                                                    >
                                                      Minimum Order Quantity
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        ...available,
                                                        lineHeight: "20px",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "#6B7A99",
                                                        width: "100%",
                                                      }}
                                                    >
                                                      {item.SampleAvailability}
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xl={6}
                                                  lg={6}
                                                  md={6}
                                                  sm={12}
                                                  xs={12}
                                                  sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: "20px",
                                                  }}
                                                >
                                                  <Box sx={{}}>
                                                    <Typography
                                                      sx={{
                                                        ...available,
                                                        width: "100%",
                                                        fontWeight: 500,
                                                        fontSize: "18px",
                                                        lineHeight: "27px",
                                                        color: "grey",
                                                      }}
                                                    >
                                                      Price Of Sample
                                                    </Typography>
                                                    <Box
                                                      sx={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "flex-start",
                                                        flexDirection: "row",
                                                        width: "auto",
                                                        mt: "10px",
                                                        marginBottom: "-11px",
                                                      }}
                                                    >
                                                      <Typography
                                                        sx={{
                                                          ...available,
                                                          lineHeight: "20px",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "#6B7A99",
                                                        }}
                                                      >
                                                        {item.PriceOfSample}
                                                      </Typography>
                                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                                      <img
                                                        src={BXITokenIcon}
                                                        style={{
                                                          width: "20px",
                                                          height: "auto",
                                                        }}
                                                        alt="BXI Token"
                                                      />{" "}
                                                    </Box>
                                                  </Box>
                                                </Grid>
                                              </Grid>
                                            </Box>
                                          </Box>
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              ) : null}
                            </Box>
                          </>
                        )}

                        {GetProductByIdData?.productContentInformation ===
                          undefined ||
                        GetProductByIdData?.productContentInformation ===
                          null ? null : (
                          <Box
                            mt={2}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "2px",
                            }}
                          >
                            <Typography
                              sx={{
                                ...packHead,
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 600,
                                color: "#156DB6",
                                fontSize: "18px",
                              }}
                            >
                              Product Content
                            </Typography>

                            <Typography
                              sx={{
                                ...packHead,
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "#6B7A99",
                              }}
                            >
                              {GetProductByIdData?.productContentInformation}
                            </Typography>
                          </Box>
                        )}

                        <Box sx={{ display: "flex", gap: "10px" }}>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <Grid
                              container
                              sx={{
                                display: "flex",
                                width: "100%",
                              }}
                            >
                              <Box
                                mt={3}
                                sx={{
                                  color: "#6B7A99",
                                  display: "flex",
                                  gap: "10px",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <Box>
                                  <Typography
                                    sx={{
                                      ...product,
                                      fontSize: "18px",
                                      color: "#156DB6",
                                    }}
                                  >
                                    Product Pickup Location & Pincode
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: "50px",
                                      mt: 1,
                                    }}
                                  >
                                    <Box>
                                      <Typography
                                        sx={{
                                          ...available,
                                          width: "100%",
                                          fontWeight: 500,
                                          fontSize: "18px",
                                          lineHeight: "27px",
                                          color: "grey",
                                        }}
                                      >
                                        Region
                                      </Typography>
                                      <Typography
                                        sx={{
                                          ...available,
                                          lineHeight: "20px",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "#6B7A99",
                                          width: "100%",
                                          mt: 1,
                                        }}
                                      >
                                        {
                                          GetProductByIdData?.LocationDetails
                                            ?.region
                                        }
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        ml: 7,
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          ...available,
                                          width: "100%",
                                          fontWeight: 500,
                                          fontSize: "18px",
                                          lineHeight: "27px",
                                          color: "grey",
                                        }}
                                      >
                                        State
                                      </Typography>
                                      <Typography
                                        sx={{
                                          ...available,
                                          lineHeight: "20px",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "#6B7A99",
                                          width: "100%",
                                          mt: 1,
                                        }}
                                      >
                                        {
                                          GetProductByIdData?.LocationDetails
                                            ?.state
                                        }
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        ml: 7,
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          ...available,
                                          width: "100%",
                                          fontWeight: 500,
                                          fontSize: "18px",
                                          lineHeight: "27px",
                                          color: "grey",
                                        }}
                                      >
                                        City
                                      </Typography>
                                      <Typography
                                        sx={{
                                          ...available,
                                          lineHeight: "20px",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "#6B7A99",
                                          width: "100%",
                                          mt: 1,
                                        }}
                                      >
                                        {
                                          GetProductByIdData?.LocationDetails
                                            ?.city
                                        }
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        ml: 7,
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          ...available,
                                          width: "100%",
                                          fontWeight: 500,
                                          fontSize: "18px",
                                          lineHeight: "27px",
                                          color: "grey",
                                        }}
                                      >
                                        Landmark
                                      </Typography>
                                      <Typography
                                        sx={{
                                          ...available,
                                          lineHeight: "20px",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "#6B7A99",
                                          width: "100%",
                                          mt: 1,
                                        }}
                                      >
                                        {
                                          GetProductByIdData?.LocationDetails
                                            ?.landmark
                                        }
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        ml: 7,
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          ...available,
                                          width: "100%",
                                          fontWeight: 500,
                                          fontSize: "18px",
                                          lineHeight: "27px",
                                          color: "grey",
                                        }}
                                      >
                                        Pincode
                                      </Typography>
                                      <Typography
                                        sx={{
                                          ...available,
                                          lineHeight: "20px",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "#6B7A99",
                                          width: "100%",
                                          mt: 1,
                                        }}
                                      >
                                        {
                                          GetProductByIdData?.LocationDetails
                                            ?.pincode
                                        }
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>

                                {GetProductByIdData?.formofProduct ===
                                  undefined ||
                                GetProductByIdData?.formofProduct ===
                                  null ? null : (
                                  <Box
                                    mt={2}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "2px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        ...packHead,
                                        fontFamily: "Poppins",
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        color: "#6B7A99",
                                      }}
                                    >
                                      Form
                                    </Typography>

                                    <Typography
                                      sx={{
                                        ...packHead,
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "#6B7A99",
                                      }}
                                    >
                                      {GetProductByIdData?.formofProduct}
                                    </Typography>
                                  </Box>
                                )}

                                {GetProductByIdData?.listperiod === undefined ||
                                GetProductByIdData?.listperiod === null ||
                                GetProductByIdData?.listperiod ===
                                  "undefined" ||
                                GetProductByIdData?.listperiod === "" ? null : (
                                  <Box
                                    mt={2}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "2px",
                                    }}
                                  >
                                    <Typography
                                      sx={{ ...product, fontSize: "18px" }}
                                    >
                                      Listed This Product For
                                    </Typography>

                                    <Typography
                                      sx={{
                                        ...packHead,
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "#6B7A99",
                                      }}
                                    >
                                      {GetProductByIdData?.listperiod}&nbsp;
                                      Days
                                    </Typography>
                                  </Box>
                                )}

                                {GetProductByIdData?.OtherCost &&
                                GetProductByIdData?.OtherCost?.length !== 0 ? (
                                  <Box mt={2}>
                                    <Typography
                                      sx={{
                                        ...product,
                                        fontWeight: 600,
                                        fontSize: "18px",
                                        lineHeight: "30px",
                                        color: "#156DB6",
                                      }}
                                    >
                                      Additional Cost
                                    </Typography>
                                    {GetProductByIdData?.OtherCost?.length === 0
                                      ? ""
                                      : GetProductByIdData?.OtherCost?.map(
                                          (cost) => {
                                            const newValue =
                                              cost?.CostPrice.toFixed(2);
                                            return (
                                              <>
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    // gap: "60px",
                                                    justifyContent:
                                                      "space-between",
                                                    mt: 1,
                                                    width: "70%",
                                                  }}
                                                >
                                                  <Box sx={{}}>
                                                    <Typography
                                                      sx={{
                                                        ...listText,
                                                        wordWrap: "break-word",
                                                      }}
                                                    >
                                                      {" "}
                                                      &#8226; &nbsp;&nbsp;{" "}
                                                      {
                                                        cost.AdCostApplicableOn
                                                      }{" "}
                                                    </Typography>
                                                  </Box>

                                                  <Box sx={{}}>
                                                    <Typography
                                                      sx={{
                                                        ...listText,
                                                        wordWrap: "break-word",
                                                      }}
                                                    >
                                                      {" "}
                                                      {cost.ReasonOfCost}{" "}
                                                    </Typography>
                                                  </Box>

                                                  <Box sx={{}}>
                                                    <Typography
                                                      sx={{
                                                        ...listText,
                                                        wordWrap: "break-word",
                                                      }}
                                                    >
                                                      HSN - {cost.AdCostHSN}{" "}
                                                    </Typography>
                                                  </Box>

                                                  <Box sx={{}}>
                                                    <Typography
                                                      sx={{
                                                        ...listText,
                                                        wordWrap: "break-word",
                                                      }}
                                                    >
                                                      GST - {cost.AdCostGST} %
                                                    </Typography>
                                                  </Box>
                                                  <Box
                                                    sx={{
                                                      gap: "5px",

                                                      display: "flex",
                                                    }}
                                                  >
                                                    <Typography
                                                      sx={{
                                                        ...listText,
                                                        wordWrap: "break-word",
                                                      }}
                                                    >
                                                      <CommaSeprator
                                                        Price={newValue}
                                                      />
                                                      {/* {newValue} */}
                                                    </Typography>
                                                    <Typography>
                                                      {cost.currencyType ===
                                                      "BXITokens" ? (
                                                        <Box
                                                          component="img"
                                                          src={BXITokenIcon}
                                                          alt="token"
                                                          sx={{
                                                            height: "auto",
                                                            width: "20px",
                                                            marginTop: "2px",
                                                          }}
                                                        />
                                                      ) : (
                                                        <Typography
                                                          sx={{
                                                            fontSize: "20px",
                                                            ml: 1,
                                                            color: "grey",
                                                          }}
                                                        >
                                                          ₹
                                                        </Typography>
                                                      )}
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                              </>
                                            );
                                          }
                                        )}
                                  </Box>
                                ) : (
                                  <Typography
                                    sx={{
                                      ...product,
                                      fontWeight: 600,
                                      fontSize: "18px",
                                      lineHeight: "30px",
                                      color: "#156DB6",
                                    }}
                                  >
                                    Additional Cost :{" "}
                                    <span
                                      style={{
                                        ...packHead,
                                        color: "#6B7A99",
                                        fontWeight: 400,
                                        fontSize: "18px",
                                      }}
                                    >
                                      No
                                    </span>
                                  </Typography>
                                )}

                                {GetProductByIdData?.ProductDetails
                                  ?.registrationdetails === undefined ||
                                GetProductByIdData?.ProductDetails
                                  ?.registrationdetails === null ? null : (
                                  <Box
                                    mt={2}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "2px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        ...packHead,
                                        fontFamily: "Poppins",
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        color: "#6B7A99",
                                        fontSize: "1.8rem",
                                      }}
                                    >
                                      Registration Details
                                    </Typography>

                                    <Typography
                                      sx={{
                                        ...packHead,
                                        color: "#6B7A99",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                      }}
                                    >
                                      {
                                        GetProductByIdData?.ProductDetails
                                          ?.registrationdetails
                                      }
                                    </Typography>
                                  </Box>
                                )}

                                {GetProductByIdData?.ProductDetails
                                  ?.taxesdetails === undefined ||
                                GetProductByIdData?.ProductDetails
                                  ?.taxesdetails === null ? null : (
                                  <Box
                                    mt={2}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "2px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        ...packHead,
                                        fontFamily: "Poppins",
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        color: "#6B7A99",
                                        fontSize: "1.8rem",
                                      }}
                                    >
                                      Tax Details
                                    </Typography>

                                    <Typography
                                      sx={{
                                        ...packHead,
                                        color: "#6B7A99",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                      }}
                                    >
                                      {
                                        GetProductByIdData?.ProductDetails
                                          ?.taxesdetails
                                      }
                                    </Typography>
                                  </Box>
                                )}

                                {GetProductByIdData?.ProductDetails
                                  ?.insurancedetails === undefined ||
                                GetProductByIdData?.ProductDetails
                                  ?.insurancedetails === null ? null : (
                                  <Box
                                    mt={2}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "2px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        ...packHead,
                                        fontFamily: "Poppins",
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        color: "#6B7A99",
                                        fontSize: "1.8rem",
                                      }}
                                    >
                                      Insurance Details
                                    </Typography>

                                    <Typography
                                      sx={{
                                        ...packHead,
                                        color: "#6B7A99",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                      }}
                                    >
                                      {
                                        GetProductByIdData?.ProductDetails
                                          ?.insurancedetails
                                      }
                                    </Typography>
                                  </Box>
                                )}

                                {GetProductByIdData?.ManufacturingDate ? (
                                  <Grid
                                    container
                                    sx={{
                                      display: "flex",
                                      width: "50%",
                                    }}
                                    mt={2}
                                  >
                                    <Grid
                                      item
                                      xl={6}
                                      lg={6}
                                      md={6}
                                      sm={12}
                                      xs={12}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "20px",
                                        width: "100%",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          width: "100%",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            ...available,
                                            width: "100%",
                                            fontWeight: 500,
                                            fontSize: "18px",
                                            lineHeight: "27px",
                                            color: "#6B7A99",
                                          }}
                                        >
                                          Manufacturing Date
                                        </Typography>
                                        <Typography
                                          sx={{
                                            ...available,
                                            lineHeight: "20px",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "#6B7A99",
                                            width: "100%",
                                            mt: 1,
                                          }}
                                        >
                                          {new Date(
                                            GetProductByIdData?.ManufacturingDate
                                          ).toLocaleDateString()}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                    <Grid
                                      item
                                      lg={6}
                                      xl={6}
                                      md={6}
                                      sm={12}
                                      xs={12}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "20px",
                                      }}
                                    >
                                      <Box>
                                        <Typography
                                          sx={{
                                            ...available,
                                            width: "100%",
                                            fontWeight: 500,
                                            fontSize: "18px",
                                            color: "#6B7A99",
                                          }}
                                        >
                                          Expiry Date{" "}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            ...available,
                                            lineHeight: "20px",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "#6B7A99",
                                            mt: 1,
                                          }}
                                        >
                                          {GetProductByIdData?.ExpiryDate ? (
                                            <>
                                              {new Date(
                                                GetProductByIdData?.ExpiryDate
                                              ).toLocaleDateString()}
                                            </>
                                          ) : (
                                            "Not Given"
                                          )}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                ) : GetProductByIdData?.ManufacturingData ? (
                                  <Grid
                                    container
                                    sx={{
                                      display: "flex",
                                      width: "50%",
                                    }}
                                    mt={2}
                                  >
                                    <Grid
                                      item
                                      xl={6}
                                      lg={6}
                                      md={6}
                                      sm={12}
                                      xs={12}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "20px",
                                        width: "100%",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          width: "100%",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            ...available,
                                            width: "100%",
                                            fontWeight: 500,
                                            fontSize: "18px",
                                            lineHeight: "27px",
                                            color: "#6B7A99",
                                          }}
                                        >
                                          Manufacturing Date
                                        </Typography>
                                        <Typography
                                          sx={{
                                            ...available,
                                            lineHeight: "20px",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "#6B7A99",
                                            width: "100%",
                                            mt: 1,
                                          }}
                                        >
                                          {new Date(
                                            GetProductByIdData?.ManufacturingData
                                          ).toLocaleDateString()}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                    <Grid
                                      item
                                      lg={6}
                                      xl={6}
                                      md={6}
                                      sm={12}
                                      xs={12}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "20px",
                                      }}
                                    >
                                      <Box>
                                        <Typography
                                          sx={{
                                            ...available,
                                            width: "100%",
                                            fontWeight: 500,
                                            fontSize: "18px",
                                            color: "#6B7A99",
                                          }}
                                        >
                                          Expiry Date{" "}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            ...available,
                                            lineHeight: "20px",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "#6B7A99",
                                            mt: 1,
                                          }}
                                        >
                                          {GetProductByIdData?.ExpiryDate ? (
                                            <>
                                              {new Date(
                                                GetProductByIdData?.ExpiryDate
                                              ).toLocaleDateString()}
                                            </>
                                          ) : (
                                            "Not Given"
                                          )}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                ) : null}
                              </Box>
                            </Grid>
                          </Box>
                        </Box>
                      </Box>
                    </TabPanel>
                    <TabPanel value="3">
                      <Box>
                        <Typography sx={{ ...pack, color: "#156DB6" }}>
                          Technical Information
                        </Typography>

                        <Box
                          mt={1}
                          sx={{
                            display: "flex",
                            gap: "10px",
                            width: "500px",
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            {GetProductByIdData?.ProductTechInfo?.Warranty ===
                              undefined ||
                            GetProductByIdData?.ProductTechInfo?.Warranty ===
                              null ? null : (
                              <Grid
                                item
                                xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "20px",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...available,
                                      width: "100%",
                                      fontWeight: 500,
                                      fontSize: "18px",
                                      lineHeight: "27px",
                                      color: "grey",
                                    }}
                                  >
                                    Warranty
                                  </Typography>
                                  <Typography
                                    sx={{
                                      ...available,
                                      lineHeight: "20px",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "#6B7A99",
                                      width: "100%",
                                    }}
                                  >
                                    {
                                      GetProductByIdData?.ProductTechInfo
                                        ?.Warranty
                                    }{" "}
                                    {GetProductByIdData?.WarrantyPeriod ===
                                      null ||
                                    GetProductByIdData?.WarrantyPeriod ===
                                      undefined
                                      ? GetProductByIdData?.UnitOfTime
                                      : GetProductByIdData?.WarrantyPeriod}
                                  </Typography>
                                </Box>
                              </Grid>
                            )}
                            {GetProductByIdData?.ProductTechInfo?.Guarantee ===
                              undefined ||
                            GetProductByIdData?.ProductTechInfo?.Guarantee ===
                              null ? null : (
                              <Grid
                                item
                                lg={6}
                                xl={6}
                                md={6}
                                sm={12}
                                xs={12}
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "20px",
                                }}
                              >
                                <Box sx={{}}>
                                  <Typography
                                    sx={{
                                      ...available,
                                      width: "100%",
                                      fontWeight: 500,
                                      fontSize: "18px",
                                      lineHeight: "27px",
                                      color: "grey",
                                    }}
                                  >
                                    Guarantee{" "}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      ...available,
                                      lineHeight: "20px",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "#6B7A99",
                                    }}
                                  >
                                    {
                                      GetProductByIdData?.ProductTechInfo
                                        ?.Guarantee
                                    }{" "}
                                    {GetProductByIdData?.GuaranteePeriod ===
                                      null ||
                                    GetProductByIdData?.GuaranteePeriod ===
                                      undefined
                                      ? GetProductByIdData?.UnitOfTime
                                      : GetProductByIdData?.GuaranteePeriod}
                                  </Typography>
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </Box>

                        {GetProductByIdData?.ProductTechInfo
                          ?.WeightBeforePackingPerUnit ? (
                          <Box
                            mt={1}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              width: "95%",
                            }}
                          >
                            <Typography
                              mt={2}
                              sx={{
                                ...product,
                                fontSize: "18px",
                                color: "#156DB6",
                              }}
                            >
                              Packaging Information
                            </Typography>

                            {technicalinfo?.map((val) => {
                              const newVal = Number(val?.val).toFixed(2);
                              return (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "12%",
                                    gap: "25px",
                                    mt: 2,
                                  }}
                                >
                                  <Box component="img" src={val.img} />
                                  <Box
                                    sx={{
                                      width: "100%",
                                      minWidth: "185px",
                                    }}
                                  >
                                    <Typography
                                      sx={{ ...packHead, fontSize: "16px" }}
                                    >
                                      {val.name}
                                    </Typography>

                                    {GetProductByIdData?.WeightBeforePackingPerUnitMeasurUnit ===
                                      undefined ||
                                    GetProductByIdData?.WeightBeforePackingPerUnitMeasurUnit ===
                                      null ? (
                                      <Typography sx={packVal}>
                                        {newVal}
                                        {GetProductByIdData?.UnitOfWeight}
                                      </Typography>
                                    ) : GetProductByIdData?.WeightBeforePackingPerUnitMeasurUnit ===
                                      "Grams" ? (
                                      <Typography sx={packVal}>
                                        {newVal} {val.measure}
                                      </Typography>
                                    ) : (
                                      <Typography sx={packVal}>
                                        {newVal} {val.measure}
                                      </Typography>
                                    )}
                                  </Box>
                                </Box>
                              );
                            })}
                          </Box>
                        ) : null}
                        {GetProductByIdData?.ProductTechInfo
                          ?.PackagingInfoPerUnit === undefined ||
                        GetProductByIdData?.ProductTechInfo
                          ?.PackagingInfoPerUnit === null ? null : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "2px",
                              mt: 3,
                            }}
                          >
                            <Typography
                              sx={{
                                ...packHead,
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 600,
                                color: "#156DB6",
                                fontSize: "18px",
                              }}
                            >
                              Packaging Information Per Unit
                            </Typography>

                            <Typography
                              sx={{
                                ...packHead,
                                color: "#6B7A99",
                                fontWeight: 500,
                                fontSize: "14px",
                              }}
                            >
                              {
                                GetProductByIdData?.ProductTechInfo
                                  ?.PackagingInfoPerUnit
                              }
                            </Typography>
                          </Box>
                        )}

                        {GetProductByIdData?.ProductTechInfo
                          ?.LegalInformation === undefined ||
                        GetProductByIdData?.ProductTechInfo
                          ?.LegalInformation === null ? null : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "2px",
                              mt: 3,
                            }}
                          >
                            <Typography
                              sx={{
                                ...packHead,
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 600,
                                color: "#156DB6",
                                fontSize: "18px",
                              }}
                            >
                              Legal Information
                            </Typography>

                            <Typography
                              sx={{
                                ...packHead,
                                color: "#6B7A99",
                                fontWeight: 500,
                                fontSize: "14px",
                              }}
                            >
                              {
                                GetProductByIdData?.ProductTechInfo
                                  ?.LegalInformation
                              }
                            </Typography>
                          </Box>
                        )}

                        {GetProductByIdData?.ProductTechInfo
                          ?.PackagingAndDeliveryInstructionsIfAny ===
                          undefined ||
                        GetProductByIdData?.ProductTechInfo
                          ?.PackagingAndDeliveryInstructionsIfAny ===
                          null ? null : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "2px",
                              mt: 3,
                            }}
                          >
                            <Typography
                              mt={1}
                              sx={{
                                ...product,
                                fontSize: "18px",
                                color: "#156DB6",
                              }}
                            >
                              Packaging and Delivery Instructions
                            </Typography>

                            <Typography
                              sx={{
                                ...packHead,
                                color: "#6B7A99",
                                fontWeight: 500,
                                fontSize: "14px",
                              }}
                            >
                              {
                                GetProductByIdData?.ProductTechInfo
                                  ?.PackagingAndDeliveryInstructionsIfAny
                              }
                            </Typography>
                          </Box>
                        )}
                        {GetProductByIdData?.ProductTechInfo
                          ?.InstructionsToUseProduct === null ||
                        GetProductByIdData?.ProductTechInfo
                          ?.InstructionsToUseProduct === undefined ? null : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "2px",
                              mt: 3,
                            }}
                          >
                            <Typography
                              mt={1}
                              sx={{
                                ...product,
                                fontWeight: 600,
                                fontSize: "18px",
                                lineHeight: "30px",
                                mt: 1,
                                color: "#156DB6",
                              }}
                            >
                              Instructions to use product
                            </Typography>

                            <Typography
                              sx={{
                                ...packHead,
                                color: "#6B7A99",
                                fontWeight: 500,
                                fontSize: "14px",
                              }}
                            >
                              {
                                GetProductByIdData?.ProductTechInfo
                                  ?.InstructionsToUseProduct
                              }
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </TabPanel>
                    <TabPanel value="4">
                      <Box>
                        <Typography sx={{ ...pack, color: "#156DB6" }}>
                          Key Features
                        </Typography>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Grid
                            container
                            mt={0.5}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              width: "100%",
                            }}
                          >
                            {storeTechnicalInfo?.map((res) => {
                              return (
                                <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                                  <Box
                                    sx={{
                                      px: 2,
                                      display: "flex",
                                      // flexWrap: "wrap",
                                      textAlign: "start",
                                      flexDirection: "row",
                                      gap: "100px",
                                      mt: 1,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        gap: "20px",
                                        width: "100%",
                                        mt: 2,
                                      }}
                                    >
                                      <FeatureName name={res?.name} />
                                      <Box
                                        sx={{
                                          width: "80%",
                                          maxWidth: "825px",
                                          height: "auto",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        <Typography
                                          sx={{ ...packHead, color: "grey" }}
                                        >
                                          {res.name}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            ...packVal,
                                            fontSize: "1.5rem",
                                            mt: 0.5,
                                          }}
                                        >
                                          {res.description}{" "}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Box>
                      </Box>
                    </TabPanel>
                  </Box>
                </TabContext>
              </Box>
            </Grid>
          </Grid>

          {GetProductByIdData?.ProductUploadStatus === "Approved" ||
          GetProductByIdData?.ProductUploadStatus ===
            "pendingapproval" ? null : (
            <Box sx={{ width: "95%", mx: "auto", mt: 2 }}>
              <Button sx={uploadBtn} onClick={uploadProduct}>
                Upload Product
              </Button>
            </Box>
          )}
        </Paper>
      </Paper>
    </React.Fragment>
  );
};

export default AllProductPreviewPage;

const mainText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "24px",
  color: "#6B7A99",
  p: 3,
};

const fixGrid = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

const semi = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xl: "2.2rem",
    lg: "2.2rem",
    md: "2.2rem",
    sm: "2rem",
    xs: "2rem",
  },
  color: "#4D4D4D",
  textAlign: {
    x: "start",
    lg: "start",
    md: "start",
    sm: "start",
    xs: "center",
  },
  mt: { xl: 0, lg: 0, md: 0, sm: 0, xs: 2 },
};

const tabTexts = {
  textTransform: "none",
  fontSize: {
    xl: "1.6rem",
    lg: "1.6rem",
    md: "1.4rem",
    sm: "1.2rem",
    xs: "1.2rem",
  },
};

const semiSub = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "40px",
  color: "#6B7A99",
  fontSize: {
    xl: "22px",
    lg: "22px",
    md: "22px",
    sm: "20px",
    xs: "20px",
  },
  mt: 2,
  textAlign: {
    x: "start",
    lg: "start",
    md: "start",
    sm: "start",
    xs: "center",
  },
  ml: { xl: 2, lg: 2, md: 0, sm: 0, xs: 2 },
};

const tableHeader = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "1.6rem",
    lg: "1.6rem",
    md: "1.6rem",
    sm: "1.4rem",
    xs: "1rem",
  },
  color: "#6B7A99",
};

const tableData = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "1.4rem",
    lg: "1.4rem",
    md: "1.6rem",
    sm: "1.6rem",
    xs: "1.5rem",
  },
  color: "#B1B1B1",
  lineHeight: "4rem",
};

const chart = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "1.6rem",
    lg: "1.6rem",
    md: "1.4rem",
    sm: "1.3rem",
    xs: "1.3rem",
  },
  lineHeight: "5rem",
  color: "#445FD2",
};

const tabSubText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "18px",
    lg: "18px",
    md: "18px",
    sm: "18px",
    xs: "18px",
  },
  width: "95%",
  textAlign: "justify",
  color: "#6B7A99",
};

const available = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 200,
  fontSize: {
    xl: "2rem",
    lg: "2rem",
    md: "1.6rem",
    sm: "1.5rem",
    xs: "1.5rem",
  },
  color: "#6B7A99",
};

const product = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "20px",
    lg: "20px",
    md: "20px",
    sm: "20px",
    xs: "18px",
  },
  color: "#6B7A99",
};

const listText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "1.4rem",
    lg: "1.4rem",
    md: "1.4rem",
    sm: "1.4rem",
    xs: "1.4rem",
  },
  color: "grey",
};

const pack = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "2.4rem",
    lg: "2.4rem",
    md: "2.2rem",
    sm: "2rem",
    xs: "2rem",
  },
  textAlign: {
    xl: "start",
    lg: "start",
    md: "start",
    sm: "start",
    xs: "center",
  },
  color: "#6B7A99",
};

const packHead = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "1.8rem",
    lg: "1.8rem",
    md: "1.6rem",
    sm: "1.2rem",
    xs: "1.2rem",
  },
  color: "#ADB8CC",
};

const packVal = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "1.4rem",
    lg: "1.4rem",
    md: "1.4rem",
    sm: "1.4rem",
    xs: "1.4rem",
  },
  color: "#6B7A99",
};

const uploadBtn = {
  width: "100%",
  background: "#C64091",
  "&:hover": {
    background: "#C64091",
  },
  color: "#fff",
  fontFamily: "Work Sans",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: 18,
  borderRadius: "0.6rem",
  textTransform: "none",
  mb: "4rem",
};

const ProductVariationStyle = {
  width: "auto",
};
