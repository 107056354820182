import {
  Box,
  Button,
  Grid,
  Pagination,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommaSeparator from "../../components/CommaSeprator.js";
import CompanyName from "../../components/CompanyName";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import axios from "axios";
import SkeletonComponent from "../../components/SkeletonComponent.jsx";
import NoDataFound from "../../components/NoDataFound/NoDataFound.jsx";
import sendEvents from "../../utils/sendEvents.js";
const CommissionList = () => {
  const StyledPagination = styled(Pagination)({
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#c64091",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#c64091",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#c64091",
      fontWeight: 800,
    },
  });

  const [currentPage, setCurrentPage] = React.useState(1);
  const [loadingInvoice, setLoadingInvoice] = React.useState(true);
  const dispatch = useDispatch();
  const { invoices, loading } = useSelector((state) => state.invoices);
  const [paginationCount, setPaginationCount] = React.useState(0);
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const navigate = useNavigate();
  const handleClick = (OrderId) => {
    navigate(`/home/commission/` + OrderId);
  };

  const [CommissionData, setCommissionData] = useState();

  const getAllOrder = async () => {
    await axios
      .get(`commission/get_all_bxi_commision_invoice?page=${currentPage}`)
      .then((res) => {
        setCommissionData(res.data?.Data);
        setPaginationCount(Math.round(res.data.DataCount / 10));
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getAllOrder();
  }, [currentPage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingInvoice(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Paper elevation={0} sx={{ bgcolor: "transparent", boxShadow: "none" }}>
        <BreadCrumbHeader MainText={"BXI Commission"} />

        {loadingInvoice ? (
          <>
            <SkeletonComponent />
          </>
        ) : (
          <>
            <Grid container>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "17px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  padding: "2rem",
                }}
                gap={2}
              >
                {loading === true ? (
                  <Paper
                    sx={{
                      width: "100%",
                      height: "50vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      backgroundColor: "transparent",
                      zIndex: 1000,
                    }}
                    elevation={0}
                  >
                    <CircularProgress
                      disableShrink
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: "center",
                        height: "10%",
                        width: "15%",
                      }}
                    />
                  </Paper>
                ) : (
                  <TableContainer>
                    <Table sx={{ minWidth: 650, "& td": { border: 0 } }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left" sx={tablehead}>
                            No
                          </TableCell>
                          <TableCell align="left" sx={tablehead}>
                            Name/Client
                          </TableCell>
                          <TableCell align="left" sx={tablehead}>
                            Order Id
                          </TableCell>
                          <TableCell align="left" sx={tablehead}>
                            Date
                          </TableCell>
                          <TableCell align="left" sx={tablehead}>
                            GSTAmount
                          </TableCell>
                          <TableCell align="left" sx={tablehead}>
                            Amount
                          </TableCell>
                          <TableCell align="left" sx={tablehead}></TableCell>
                          <TableCell align="left" sx={tablehead}></TableCell>
                          <TableCell align="left" sx={tablehead}>
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {CommissionData?.length > 0 ? (
                          CommissionData?.map((el, idx) => {
                            return (
                              <>
                                <TableRow>
                                  <TableCell align="left">
                                    {" "}
                                    <Typography sx={tableText}>
                                      {idx + 1}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                      gap={2}
                                    >
                                      <Box>
                                        <Typography sx={tableText}>
                                          <CompanyName
                                            CompanyId={
                                              el?.BuyerDetails?.BuyerCompanyId
                                            }
                                            CompanyName={
                                              el?.BuyerDetails?.BuyerCompanyName
                                            }
                                          />
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Typography sx={tableText}>
                                      {el?.OrderId}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Typography sx={tableText}>
                                      {new Date(
                                        el?.createdAt
                                      ).toLocaleDateString("en-IN")}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="left">
                                    {" "}
                                    <Typography sx={tableText}>
                                      {" "}
                                      <CommaSeparator
                                        Price={el?.Commission?.CommissionGST}
                                      />
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Typography sx={tableText}>
                                      <CommaSeparator
                                        Price={el?.Commission?.CommissionPrice}
                                      />
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Typography sx={tableText}>
                                      {el?.TotalTaxValue}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="left"></TableCell>
                                  <TableCell align="left">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      sx={{
                                        backgroundColor: "#c64091",
                                        "&:hover": {
                                          backgroundColor: "#c64091",
                                        },
                                      }}
                                      onClick={() => {
                                        handleClick(el._id);
                                        sendEvents("Click on view");
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontFamily: "Kumbh Sans",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          fontSize: "1.4rem",
                                          textTransform: "none",
                                          p: 1,
                                        }}
                                      >
                                        View
                                      </Typography>
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })
                        ) : (
                          <TableRow width="100%">
                            <TableCell colSpan={9} align="center">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  // width: "100%",
                                }}
                              >
                                <NoDataFound />
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Grid>
            </Grid>
            {CommissionData?.length > 0 ? (
              <Box sx={{ display: "flex", justifyContent: "center" }} mt={2}>
                <Stack spacing={2}>
                  <StyledPagination
                    count={paginationCount}
                    color="primary"
                    page={currentPage}
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton
                  />
                </Stack>
              </Box>
            ) : null}
          </>
        )}
      </Paper>
    </>
  );
};

export default CommissionList;
const tablehead = {
  fontFamily: "Kumbh Sans",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  color: "#929EAE",
};

const tableText = {
  fontFamily: "Kumbh Sans",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  color: "#1B212D",
};
