import {
  Box,
  Grid,
  Paper,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import * as Styles from "../../components/common/Styled/Styles";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import useGetCompanyMemberById from "../../Hooks/CompanyMember/useGetCompanyMemberById";

const useGetAllRoles = () =>
  useQuery("all-roles", async () => {
    return (await axios.get("/api/v1/roles/all-roles")).data;
  });

export const EditMember = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const [productRights, setProductRights] = useState("viewonly");
  const [roleAndPermission, setRoleAndPermission] = useState("Yes");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const {
    data: MemberData,
    isLoading: MemberDataIsLoading,
    error: MemberDataError,
  } = useGetCompanyMemberById(id);
  const [rightsarray, setRightsarray] = useState(MemberData?.rightsarray);

  async function handleRightsArray(rights) {
    if (rightsarray.includes(rights)) {
      const filteredArray = rightsarray.filter((item) => item !== rights);
      setRightsarray(filteredArray);
      return;
    }
    setRightsarray([...rightsarray, rights]);
  }

  const ProductRightsChange = (event) => {
    setProductRights(event.target.value);
  };

  const RoleAndPermissionChange = (event) => {
    setRoleAndPermission(event.target.value);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  // For PAssword Strong
  const isStrongPassword = (password) => {
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    return (
      password.length >= minLength && hasUppercase && hasLowercase && hasNumber
    );
  };

  // Check Email
  const isValidPhoneNumber = (value) => {
    // Regular expression for a simple phone number format (10 digits)
    return /^\d{10}$/.test(value);
  };

  const { data: roledata } = useGetAllRoles();

  // Role of the User
  const addUserFormSubmitHandler = handleSubmit(async (formData) => {
    if (!MemberData?.role) {
      toast.error("Please Select Role", {
        position: "top-center",
        autoClose: 3000,
      });
      return;
    } else if (!formData.tokenlimit) {
      toast.error("Please Select Token Limit", {
        position: "top-center",
        autoClose: 3000,
      });
      return;
    } else if (rightsarray?.length <= 0) {
      toast.error("Please Select Rights", {
        position: "top-center",
        autoClose: 3000,
      });
      return;
    } else {
      await axios
        .patch(
          "assign/update_IamUser",
          {
            id: id,
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            roleId: formData.roleId.toString() || MemberData?.role,
            password: formData.password,
            confirmpassword: formData.confirmpassword,
            tokens: formData.tokens,
            tokenlimit: formData.tokenlimit,
            productRights: productRights,
            rightsarray: rightsarray,
            roleAndPermission: roleAndPermission,
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res.data) {
            toast.success("Member Updated Successfully");
            setTimeout(() => {
              navigate("/home/dashboard");
            }, 2000);
          }
        });
    }
  });
  return (
    <Paper
      sx={{
        width: "100%",
        height: "100%",
        bgcolor: "transparent",
        boxShadow: "none",
      }}
      elevation={0}
    >
      <BreadCrumbHeader MainText={"Edit User Details"} />
      <Paper
        sx={{
          width: "100%",
          mx: "auto",
          height: "100%",
          borderRadius: "17px",
        }}
        elevation={0}
      >
        <Grid container>
          <Box sx={Styles.AdjAddmember}>
            <Grid item xl={12} lg={12} sd={12} xs={12} md={12}>
              <Box
                sx={{
                  width: "100%",
                  height: "auto",
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    width: "80%",
                    ml: 0,
                    mr: "auto",
                  }}
                >
                  <Typography
                    sx={{
                      ...Styles.TitletextStyle,
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "#6B7A99",
                    }}
                  >
                    Name/Email/ Phone No. of User
                  </Typography>
                  <Typography sx={Styles.TitleDescriptionStyle}>
                    Type a name & Email for login in to the account.
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 3,
                }}
              >
                <Box sx={adjInput}>
                  <Typography sx={Styles.TitletextStyle}>
                    Member Name{" "}
                  </Typography>
                  <input
                    style={Styles.InputStyle}
                    fullWidth
                    defaultValue={MemberData?.name}
                    {...register("name", {
                      required: "Name is required",
                    })}
                    onKeyDown={(e) => {
                      if (e.key === " " && e.target.selectionStart === 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors.name && (
                    <p style={{ color: "red" }}>{errors.name.message}</p>
                  )}
                </Box>
                <Box sx={adjInput}>
                  <Typography sx={Styles.TitletextStyle}>
                    User Email (Login Username){" "}
                  </Typography>
                  <input
                    style={Styles.InputStyle}
                    fullWidth
                    defaultValue={MemberData?.email}
                    {...register("email", {
                      required: "Email is required",
                    })}
                    onKeyDown={(e) => {
                      if (e.key === " " && e.target.selectionStart === 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors.email && (
                    <p style={{ color: "red" }}>{errors.email.message}</p>
                  )}
                </Box>
                <Box sx={adjInput}>
                  <Typography sx={Styles.TitletextStyle}>
                    Member Phone no.{" "}
                  </Typography>
                  <input
                    style={Styles.InputStyle}
                    fullWidth
                    defaultValue={MemberData?.phone}
                    {...register("phone", {
                      required: "Phone is required",

                      validate: (value) =>
                        isValidPhoneNumber(value) ||
                        "Please enter a valid Phone number",
                    })}
                    onKeyDown={(e) => {
                      if (e.key === " " && e.target.selectionStart === 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors.phone && (
                    <p style={{ color: "red" }}>{errors.phone.message}</p>
                  )}
                </Box>
              </Box>
            </Grid>
          </Box>
        </Grid>

        <Grid container>
          <Box sx={Styles.AdjAddmember}>
            <Grid item xl={12} lg={12} sd={12} xs={12} md={12}>
              <Box
                sx={{
                  width: "100%",
                  height: "auto",
                }}
              >
                <Box
                  sx={{
                    width: "80%",
                    ml: 0,
                    mr: "auto",
                  }}
                >
                  <Typography
                    sx={{
                      ...Styles.TitletextStyle,
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "#6B7A99",
                    }}
                  >
                    Role & Rights of the User
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  height: "auto",
                  mt: 3,
                }}
              >
                <Typography
                  sx={{ ...Styles.TitletextStyle, lineHeight: "30px" }}
                >
                  Role of the User
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  width: "100%",
                  flexWrap: "wrap",
                  height: "255px",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  alignContent: "center",
                  bgcolor: "transparent",
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "352px",
                    height: "90px",
                    display: "grid",
                  }}
                >
                  <select
                    style={{
                      ...Styles.InputStyle,
                      width: "50%",
                      borderRedius: "10px",
                      minWidth: "366px",
                      marginTop: "40px",
                    }}
                    fullWidth
                    placeholder="Select"
                    {...register("roleId")}
                  >
                    {" "}
                    <option value="" disabled selected>
                      {" "}
                      <Typography>{MemberData?.roleName}</Typography>
                    </option>
                    {roledata?.roles?.map((role) => {
                      return (
                        <option key={role._id} value={role?._id}>
                          {role.RoleName}
                        </option>
                      );
                    })}
                  </select>
                </Box>

                <Grid container>
                  <Box
                    sx={{
                      width: "100%",
                      height: "auto",
                      mt: 3,
                    }}
                  >
                    <Typography
                      sx={{ ...Styles.TitletextStyle, fontWeight: 400 }}
                    >
                      Rights of the User
                    </Typography>
                  </Box>

                  <Grid item xl={6} lg={6} sd={6} xs={6} md={6}>
                    <Box sx={Styles.AdjCheckBox}>
                      <input
                        type="checkbox"
                        {...register("productRights")}
                        style={{
                          marginBottom: "26px",
                        }}
                        onChange={(e) => {
                          handleRightsArray(e.target.value);
                        }}
                        value={"purchase"}
                        defaultChecked={MemberData?.rightsarray?.includes(
                          "purchase"
                        )}
                      />
                      <Typography sx={Styles.CheckboxtextStyle}>
                        Purchase<br></br>
                        <span style={Styles.check}>
                          ( Can burn the Barter Coins )
                        </span>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        ...Styles.AdjCheckBox,
                        mt: 2,
                      }}
                    >
                      <input
                        type="checkbox"
                        {...register("purchaseRights")}
                        style={{
                          marginBottom: "26px",
                        }}
                        onChange={(e) => {
                          handleRightsArray(e.target.value);
                        }}
                        value={"sales"}
                        defaultChecked={MemberData?.rightsarray?.includes(
                          "sales"
                        )}
                      />
                      <Typography sx={Styles.CheckboxtextStyle}>
                        Sales<br></br>{" "}
                        <span style={Styles.check}>
                          ( Can earn the Barter Coins ){" "}
                        </span>
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xl={6} lg={6} sd={6} xs={6} md={6}>
                    <Box sx={Styles.AdjCheckBox}>
                      <input
                        type="checkbox"
                        {...register("salesRights")}
                        style={{
                          marginBottom: "26px",
                        }}
                        onChange={(e) => {
                          handleRightsArray(e.target.value);
                        }}
                        value={"finance"}
                        defaultChecked={MemberData?.rightsarray?.includes(
                          "finance"
                        )}
                      />
                      <Typography
                        sx={{ ...Styles.CheckboxtextStyle, width: "auto" }}
                      >
                        Finance<br></br>
                        <span style={Styles.check}>
                          ( Can see the summary and statements )
                        </span>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        ...Styles.AdjCheckBox,
                        mt: 2,
                      }}
                    >
                      <input
                        type="checkbox"
                        {...register("financeRights")}
                        style={{
                          marginBottom: "26px",
                        }}
                        onChange={(e) => {
                          handleRightsArray(e.target.value);
                        }}
                        value={"custom"}
                        defaultChecked={MemberData?.rightsarray?.includes(
                          "custom"
                        )}
                      />
                      <Typography
                        sx={{ ...Styles.CheckboxtextStyle, width: "auto" }}
                      >
                        Custom permissions
                        <br></br>
                        <span style={Styles.check}>
                          ( Super admin can select the permissions to be given )
                        </span>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Grid item xl={8} lg={8} sd={12} xs={12} md={12}>
                  <Box
                    sx={{
                      display: "grid",
                      height: "100px",
                      justifyContent: "space-between",
                      alignItems: "center",
                      bgcolor: "transparent",
                    }}
                  >
                    <Box
                      sx={{
                        height: "-1px",
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 2,
                      }}
                    >
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={ProductRightsChange}
                        value={productRights}
                      >
                        <Typography
                          sx={{ ...Styles.TitletextStyle, fontWeight: 400 }}
                        >
                          Edit Rights of User (Rights to View and Edit Profile
                          Page)
                        </Typography>
                        <FormControlLabel
                          value="viewonly"
                          onChange={ProductRightsChange}
                          defaultChecked={
                            MemberData?.productRights === "viewonly"
                          }
                          control={
                            <Radio
                              sx={{
                                color: "#445FD2",
                                "& .MuiSvgIcon-root": {
                                  fontSize: 22,
                                },
                              }}
                            />
                          }
                          label={<StyledLabel>View Only</StyledLabel>}
                        />
                        <FormControlLabel
                          value="view&edit"
                          sx={{
                            ml: 5,
                          }}
                          control={
                            <Radio
                              sx={{
                                color: "#445FD2",
                                "& .MuiSvgIcon-root": {
                                  fontSize: 22,
                                },
                              }}
                            />
                          }
                          defaultChecked={
                            MemberData?.productRights === "view&edit"
                          }
                          label={<StyledLabel>View & Edit</StyledLabel>}
                        />
                      </RadioGroup>
                    </Box>
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Box>
        </Grid>

        <Grid container>
          <Box sx={Styles.AdjAddmember}>
            <Grid item xl={12} lg={12} sd={12} xs={12} md={12}>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "700px",
                  height: "auto",
                }}
              >
                <Box
                  sx={{
                    width: "80%",
                    ml: 0,
                    mr: "auto",
                    mt: 7,
                  }}
                >
                  <Typography sx={{ ...Styles.TitletextStyle }}>
                    Barter Coin Details
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      mt: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        ...Styles.TitleDescriptionStyle,
                        fontSize: "16px",
                        fontWeight: 400,
                        color: "#ADB8CC",
                        mt: 1,
                      }}
                    >
                      Add Barter Coin Limit
                    </Typography>
                    &nbsp;&nbsp;&nbsp;
                    <select
                      style={{
                        ...Styles.InputStyle,
                        width: "30%",
                        color: "#445FD2",
                        borderRedius: "10px",
                      }}
                      fullWidth
                      placeholder="Select"
                      {...register("tokenlimit")}
                    >
                      <option value="50000">
                        Burn Upton 50000 Barter Coins
                      </option>
                      <option value="100000">
                        Burn Upton 100000 Barter Coins{" "}
                      </option>
                      <option value="250000">
                        Burn Upton 250000 Barter Coins
                      </option>
                      <option value="500000">
                        Burn Upton 500000 Barter Coins
                      </option>
                    </select>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Box>
        </Grid>

        <Box
          sx={{
            width: "100%",
            maxWidth: "700px",
            height: "auto",
          }}
        >
          <Box
            sx={{
              width: "80%",
              ml: 8,
              mr: "auto",
              mt: 3,
            }}
          >
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                <Typography sx={{ ...Styles.TitletextStyle, fontWeight: 400 }}>
                  Can user edit roles, rights and permissions of other users?
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={roleAndPermission}
                onChange={RoleAndPermissionChange}
              >
                <FormControlLabel
                  value="Yes"
                  control={
                    <Radio
                      sx={{
                        color: "#445FD2",
                        "& .MuiSvgIcon-root": {
                          fontSize: 22,
                        },
                      }}
                    />
                  }
                  defaultChecked={MemberData?.roleAndPermission === "Yes"}
                  label={<StyledLabel>Yes</StyledLabel>}
                />

                <FormControlLabel
                  value="No"
                  sx={{
                    ml: 5,
                  }}
                  control={
                    <Radio
                      sx={{
                        color: "#445FD2",
                        "& .MuiSvgIcon-root": {
                          fontSize: 22,
                        },
                      }}
                    />
                  }
                  defaultChecked={MemberData?.roleAndPermission === "No"}
                  label={<StyledLabel>No</StyledLabel>}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>

        <Grid container>
          <Box sx={Styles.AdjAddmember}>
            <Box
              sx={{
                width: "100%",
                maxWidth: "700px",
                height: "100%",
                mt: 0,
              }}
            >
              <Box
                sx={{
                  width: "80%",
                  ml: 0,
                  mr: "auto",
                }}
              >
                <Typography sx={Styles.TitletextStyle}>
                  Password Details
                </Typography>

                <Grid item xl={12} lg={12} sd={12} xs={12} md={12}>
                  <Box
                    sx={{
                      display: "grid",
                      minWidth: "425px",
                      height: "100px",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        height: "auto",
                        justifyContent: "space-between",
                        alignItems: "center",
                        bgcolor: "transparent",
                        mt: "10px",
                      }}
                    >
                      <Box sx={adjInput}>
                        <Typography sx={Styles.TitletextStyle}>
                          Enter Password{" "}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          <input
                            style={Styles.InputStyle}
                            fullWidth
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter Password"
                            {...register("password", {
                              required: true,
                              validate: isStrongPassword,
                            })}
                            onKeyDown={(e) => {
                              if (
                                e.key === " " &&
                                e.target.selectionStart === 0
                              ) {
                                e.preventDefault();
                              }
                            }}
                            defaultValue={MemberData?.password}
                          />
                          <Typography
                            sx={{
                              fontSize: "12px",
                              cursor: "pointer",
                              mt: "0px",
                              cursor: "pointer",
                              position: "absolute",
                              right: "2%",
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </Typography>
                        </Box>
                        {errors.password && (
                          <p
                            style={{
                              color: "red",
                            }}
                          >
                            Password should be at least 8 characters long and
                            contain uppercase letters, lowercase letters, and
                            numbers.
                          </p>
                        )}
                      </Box>
                      <Box
                        sx={{
                          ...adjInput,
                          ml: 10,
                        }}
                      >
                        <Typography sx={Styles.TitletextStyle}>
                          Confirm Password{" "}
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          <input
                            style={Styles.InputStyle}
                            fullWidth
                            type={showPasswordConfirm ? "text" : "password"}
                            placeholder="Confirm Password"
                            {...register("confirmPassword", {
                              required: "Confirm Password is required",
                              validate: (value) =>
                                value === watch("password") ||
                                "Passwords do not match",
                            })}
                            onKeyDown={(e) => {
                              if (
                                e.key === " " &&
                                e.target.selectionStart === 0
                              ) {
                                e.preventDefault();
                              }
                            }}
                            defaultValue={MemberData?.password}
                          />
                          <Typography
                            sx={{
                              fontSize: "12px",
                              cursor: "pointer",
                              mt: "0px",
                              cursor: "pointer",
                              position: "absolute",
                              right: "2%",
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                setShowPasswordConfirm(!showPasswordConfirm);
                              }}
                            >
                              {showPasswordConfirm ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </Typography>
                        </Box>
                        {errors.confirmPassword && (
                          <p
                            style={{
                              color: "red",
                            }}
                          >
                            {errors.confirmPassword.message}
                          </p>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Box sx={Styles.AddmemberBorder}>
          <Box
            sx={{
              height: "100%",
              width: "100%",
              maxWidth: "450px",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              gap: "1rem",
              alignContent: "center",
            }}
          >
            <Button
              sx={Styles.AddMemberBtn}
              onClick={() => {
                navigate("/home/company_members");
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                ...Styles.AddMemberBtn,
                background: "#445FD2",
                color: "#fff",
                "&:hover": {
                  border: "1px solid #445FD2",
                  color: "#445FD2",
                },
              }}
              onClick={addUserFormSubmitHandler}
            >
              Edit Member
            </Button>
          </Box>
        </Box>
      </Paper>
    </Paper>
  );
};

const StyledLabel = styled("span")({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  color: "#6B7A99",
});

const adjInput = {
  height: "90px",
  display: "grid",
};
