import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableContainer,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LeftArrow from "../../assets/Images/payment/LeftArrow.png";
import stackofcoin from "../../assets/CartPage/unnamed 1.svg";
import radiocheck from "../../assets/Dashboard/radiocheck.svg";
import radiouncheck from "../../assets/Dashboard/radiouncheck.svg";
import PrintPurchaseOrder from "../../assets/Images/CommonImages/Print.png";
import doneIcon from "../../assets/Images/doneTick.svg";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import axios from "axios";
import DocDownloadImg from "../../assets/Images/CommonImages/DocDownload.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";
import CommaSeprator from "../../components/CommaSeprator";
import TransactionsCommaSeprator from "../../components/TransactionsCommaSeprator.js";
import useGetLoggedInUser from "../../Hooks/LoggedInUser/useGetLoggedInUser";
import sendEvents from "../../utils/sendEvents.js";
import LineChart from "./LineChartPage";
import { useGetAllCompanyProducts } from "./useGetAllCompanyProducts";
import GetCompanyNameNProfile from "../../components/common/GetCompanyNameNProfile.js";
import useGetAuthUser from "../../Hooks/LoggedInUser/useGetAuthUser.js";
import GlobalToast from "../../components/Toasts/GlobalToast.jsx";

function convertDate(inputFormat) {
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  var d = new Date(inputFormat);
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
}

const Dashboard = () => {
  const [balance, setBalance] = React.useState("");
  const [open, setOpen] = useState(false);
  const [ProductId, setProductId] = useState();
  const [CompanyId, setCompanyId] = useState();
  const [openSecond, setOpenSecond] = useState(false);
  const [sendNotification, setSendNotification] = useState("");
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [walletData, setWalletData] = useState([]);
  const [manualPoint, setManualPoints] = useState();
  const [listingtype, setListingType] = useState();
  const [TotalSalesdata, setTotalSales] = useState(0);
  const [TotalPurchase, setTotalPurchase] = useState(0);
  const [CheckCompanyType, setCheckCompanyType] = useState("");
  const [allcreditrequest, setallcreditrequest] = useState();
  const [OnHold, setOnHold] = useState(0);
  const [highestValue, setHighestValue] = useState(0);
  const [CreditEligibility, setCreditEligibility] = useState();

  const [Statement, setStatement] = useState([]);

  const [PurchaseOnHold, setPurchaseOnHold] = useState(0);
  const { data: AuthUserData } = useGetAuthUser();

  console.log("AuthUserData", AuthUserData?.data);

  const calculateSums = (productVariations) => {
    let totalValueSum = 0;
    let maxOrderQtySum = 0;

    productVariations?.forEach((item) => {
      const currentValue = item?.MaxOrderQuantity * item?.DiscountedPrice;

      totalValueSum += currentValue;
      maxOrderQtySum += item?.MaxOrderQuantity;
    });

    return {
      totalValueSum,
      maxOrderQtySum,
    };
  };

  const componentRef1 = useRef();
  const handlePrint1 = useReactToPrint({
    content: () => componentRef1.current,
    bodyClass: "printbody",
    pageStyle: "@page {size: landscape;}",
  });
  const pageBottom = useRef();

  const handleDownloadTokensFlow = () => {
    setTimeout(() => {
      html2canvas(componentRef1.current)
        .then((canvas) => {
          const pdf = new jsPDF();
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width + 20;

          pdf.addImage(
            canvas.toDataURL("image/png"),
            "PNG",
            0,
            0,
            pdfWidth,
            pdfHeight
          );

          const noteText = "";
          const noteX = 10; // X position of the note
          const noteY = pdf.internal.pageSize.getHeight() - 160; // Y position of the note (10 units from the bottom)
          const noteFontSize = 8; // Font size of the note
          const noteColor = "black"; // Color of the note

          pdf.setFontSize(noteFontSize);
          pdf.setTextColor(noteColor);
          pdf.text(noteText, noteX, noteY);

          pdf.save("download.pdf");
        })
        .catch((error) => {
          return error;
        });
    }, 1000);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const formatDateTime = (dateTimeString) => {
    const createdAtDateTime = new Date(dateTimeString);
    const timeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedTime = createdAtDateTime.toLocaleTimeString(
      "en-US",
      timeFormatOptions
    );

    return `${formattedTime}`;
  };

  useEffect(() => {
    const fetchWalletData = async () => {
      try {
        const response = await axios.get("transactions/purchase", {
          withCredentials: true,
        });
        setWalletData(response?.data);
        setFilteredData(response?.data);
      } catch (error) {
        return err;
      }
    };

    fetchWalletData();
  }, []);

  const { data: loggedInUserData } = useGetLoggedInUser();
  const {
    data: userData,
    isLoading: userLoading,
    isError: userError,
    isRefetching,
  } = useGetLoggedInUser();

  const fetchompanyType = async () => {
    await axios
      .get(
        `company_type/get_companyType/${loggedInUserData?.data?.companyType}`
      )
      .then((res) => {
        setCheckCompanyType(res.data.CompanyTypeName);
        localStorage.setItem("companyType", res?.data?.CompanyTypeName);
      })
      .catch((err) => {
        return err;
      });
  };

  const Getallcreditrequest = async () => {
    await axios
      .get(`wallet_funds/get-all-credit-request`)
      .then((res) => {
        setallcreditrequest(res.data);
      })
      .catch((err) => {
        return err;
      });
  };

  useEffect(() => {
    let companyTypeData = localStorage.getItem("companyType");
    setCheckCompanyType(companyTypeData);
    fetchompanyType();
    Getallcreditrequest();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const { data: CompaniesProducts } = useGetAllCompanyProducts();

  const reqBal = async (ProductId, CompanyId) => {
    if (CheckCompanyType === "Media") {
      setTimeout(() => {
        navigate("/home/credit", {
          state: {
            ProductId: ProductId,
            CompanyId: CompanyId,
            highestValue: highestValue,
            CheckCompanyType: CheckCompanyType,
          },
        });
      }, [3000]);
    } else {
      await axios
        .get(
          `wallet_funds/get_points?companyId=${CompanyId}&productId=${ProductId}`,
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          let points =
            (Number(res?.data?.totalPoints + manualPoint) / 120) * 100;
          setCreditEligibility(((highestValue * points) / 100).toFixed());
          setTimeout(() => {
            navigate("/home/credit", {
              state: {
                ProductId: ProductId,
                CompanyId: CompanyId,
                total: res?.data?.totalPoints,
                highestValue: highestValue,
                CheckCompanyType: CheckCompanyType,
              },
            });
          }, [3000]);
        })
        .catch((err) => {
          return err;
        });
    }
  };

  const reqBalance = async () => {
    await axios
      .get("wallet/get_wallet", {
        withCredentials: true,
      })
      .then((res) => {
        setBalance(res?.data?.body);
      });
  };
  const navigate = useNavigate();

  useEffect(() => {
    reqBalance();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  async function GetTotalSellValue() {
    await axios
      .get("soldAndbrought/total-sales-value", {
        withCredentials: true,
      })
      .then((res) => {
        setTotalSales(res?.data?.body);
      })
      .catch((err) => {
        return err;
      });
  }

  async function GetTotalBuyValue() {
    await axios
      .get("soldAndbrought/total-buy-value", {
        withCredentials: true,
      })
      .then((res) => {
        setTotalPurchase(res?.data?.body);
      })
      .catch((err) => {
        return err;
      });
  }

  async function GetOnHoldValue() {
    await axios
      .get("wallet/get_OnHold_Coins")
      .then((res) => {
        setOnHold(res?.data.body?.total_on_hold_balance);
      })
      .catch((err) => {
        return err;
      });

    await axios
      .get("wallet/get_purchase_onhold_balance")
      .then((res) => {
        setPurchaseOnHold(res?.data?.body?.total_on_hold_balance);
      })
      .catch((err) => {
        return err;
      });

    await axios
      .post("wallet/get_user_wallet_statement")
      .then((res) => {
        setStatement(res?.data?.transactionsData);
      })
      .catch((err) => {
        return err;
      });
  }

  useEffect(() => {
    GetTotalSellValue();
    GetTotalBuyValue();
    GetOnHoldValue();
  }, []);

  return (
    <Paper sx={{ width: "100%", bgcolor: "transparent" }} elevation={0}>
      <BreadCrumbHeader
        MainText="Dashboard"
        LinkText1="{splitedurl[1]}"
        LinkText2="{splitedurl[2]}"
        link1="Link1"
        link2="link2"
      />
      <Paper
        sx={{ bgcolor: "#fff", boxShadow: "none", p: 2, borderRadius: "20px" }}
        elevation={0}
      >
        <Grid container>
          <Grid
            item
            xl={3.8}
            lg={3.8}
            md={3.8}
            sm={12}
            xs={12}
            sx={{ bgcolor: "transparent" }}
          >
            <Typography
              sx={{
                ...TokenText,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Box
                component="img"
                src={LeftArrow}
                alt="LeftArrow"
                sx={{
                  width: {
                    xl: "22px",
                    lg: "22px",
                    md: "18px",
                    sm: "16px",
                    xs: "16px",
                  },
                  height: "auto",
                  cursor: "pointer",
                }}
                onClick={() => navigate(-1)}
              />{" "}
              Barter Coins Dashboard
            </Typography>
            <>
              {loading ? (
                <>
                  <Skeleton
                    variant="rectangular"
                    width={400}
                    height={170}
                    animation="wave"
                    sx={{ borderRadius: "10px" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={400}
                    height={400}
                    animation="wave"
                    sx={{ borderRadius: "10px", mt: 3 }}
                  />
                </>
              ) : (
                <Box sx={{ width: "100%", height: "auto", mt: 1.2 }}>
                  <Box sx={BXITokenCountBox}>
                    <Box
                      sx={{
                        padding: "1.5rem",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1.5rem",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "",
                            }}
                          >
                            <Typography
                              sx={{ ...TokenAmountStyle, color: "green" }}
                            >
                              <CommaSeprator
                                Price={balance?.balance ? balance?.balance : 0}
                              />
                              <img
                                src={stackofcoin}
                                style={{ marginLeft: "14px", height: "30px" }}
                                alt="stackofcoin"
                              />
                            </Typography>
                          </Box>

                          <Typography
                            sx={{
                              fontFamily: "Poppins",
                              fontSize: "12px",
                              color: " #000000",
                              fontWeight: 400,
                              lineHeight: "18px",
                            }}
                          >
                            Your Current Balance
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          width: "90%",
                          justifyContent: "space-between",
                          alignItems: "center",
                          // marginTop: "1.5rem",
                          mt: 2,
                        }}
                      >
                        {AuthUserData?.data?.superAdmin ? (
                          <Button
                            sx={requestCreditButtonStyle}
                            onClick={() => {
                              handleClickOpen();
                              sendEvents("Click on requestCredit");
                            }}
                          >
                            Request for Credit Line{" "}
                          </Button>
                        ) : (
                          <Button
                            sx={requestCreditButtonStyle}
                            onClick={() => {
                              return GlobalToast(
                                "You are not authorized to request credit line",
                                "error"
                              );
                            }}
                          >
                            Request for Credit Line{" "}
                          </Button>
                        )}

                        <Dialog
                          open={open}
                          onClose={handleClose}
                          fullWidth
                          maxWidth="lg"
                          sx={{
                            backdropFilter: "blur(2px)",
                          }}
                          PaperProps={{
                            sx: {
                              width: "60%",
                              maxHeight: 600,
                              borderRadius: "20px",
                            },
                          }}
                        >
                          <DialogTitle id="responsive-dialog-title">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "start",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  width: "80%",
                                  mx: "auto",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    fontSize: "18px",
                                    lineHeight: "28px",
                                    color: "#6B7A99",
                                    textAlign: "center",
                                  }}
                                >
                                  Please select the product for which you wish
                                  to request credit
                                </Typography>
                              </Box>
                              <Box>
                                <CloseIcon
                                  sx={{
                                    color: "#667085",
                                    fontSize: "25px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (open === false) {
                                      setOpen(true);
                                    } else {
                                      setOpen(false);
                                    }
                                    sendEvents("Click to close");
                                  }}
                                />
                              </Box>
                            </Box>
                          </DialogTitle>

                          <DialogContent
                            sx={{
                              overflow: "auto",
                              mr: 2,
                              "::-webkit-scrollbar": {
                                display: "flex",
                              },
                              "::-webkit-scrollbar-thumb": {
                                dynamic: "#8d8e90",
                                minHeight: "10px",
                                borderRadius: "3px",
                              },
                              "::-webkit-scrollbar-thumb:vertical": {
                                miaxHeight: "10px",
                              },
                            }}
                          >
                            {CompaniesProducts &&
                            CompaniesProducts.length > 0 ? (
                              CompaniesProducts?.slice()
                                ?.reverse()
                                ?.map((el, idx) => {
                                  const sums = calculateSums(
                                    el?.ProductsVariantions
                                  );
                                  return (
                                    <Box key={idx}>
                                      <Box
                                        sx={{
                                          width: "100%",
                                          height: "80px",
                                          maxHeight: "122px",
                                          background: "#FFFFFF",
                                          border: "1px solid #EDEFF2",
                                          borderRadius: "10px 10px 10px 10px",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          alignContent: "center",
                                          mt: 2,
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            width: "100%",
                                            maxWidth: {
                                              xl: "800px",
                                              lg: "800px",
                                              md: "800px",
                                              sm: "350px",
                                              xs: "350px",
                                            },
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            alignContent: "center",
                                            gap: "2rem",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              ml: "1%",
                                              height: "80px",
                                              width: "150px",
                                              maxHeight: "122px",
                                              minWidth: "150px",
                                              maxWidth: "150px",
                                              borderRadius:
                                                "25px 25px 25px 25px",
                                              backgroundImage:
                                                el?.ListingType === "Voucher"
                                                  ? `url(${
                                                      el?.VoucherImages?.at(0)
                                                        ?.url
                                                    })`
                                                  : `url(${
                                                      el?.ProductImages?.at(0)
                                                        ?.url
                                                    })`,
                                              backgroundSize: "contain",
                                              backgroundPosition: "center",
                                              backgroundRepeat: "no-repeat",
                                            }}
                                          ></Box>
                                          <Box
                                            sx={{
                                              width: "100%",

                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "start",
                                              alignContent: "start",
                                              flexDirection: "column",
                                              gap: "0.5rem",
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                width: "50%",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  ...ProductNameTextStyle,
                                                  display: "-webkit-box",
                                                  WebkitLineClamp: 1,
                                                  WebkitBoxOrient: "vertical",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                }}
                                              >
                                                {el?.ProductName}
                                              </Typography>
                                            </Box>
                                            <Box
                                              sx={{
                                                width: "50%",
                                              }}
                                            >
                                              <Typography
                                                sx={ProductMetaTextStyle}
                                              >
                                                {el?.ProductDescription}
                                              </Typography>
                                            </Box>
                                            <Box
                                              sx={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                alignContent: "center",
                                                width: "100%",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  ...ProductPriceTextStyle,
                                                  marginTop: "-03px",
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  gap: "2px",
                                                  width: "100%",
                                                }}
                                              >
                                                &nbsp;
                                                <span
                                                  style={{
                                                    ...ProductNameTextStyle,
                                                    fontSize: "15px",
                                                  }}
                                                >
                                                  Max Order Quantity :
                                                </span>{" "}
                                                &nbsp;
                                                <CommaSeprator
                                                  Price={sums?.maxOrderQtySum}
                                                />
                                                &nbsp;
                                                <span
                                                  style={{
                                                    ...ProductNameTextStyle,
                                                    fontSize: "15px",
                                                  }}
                                                >
                                                  Total Product Value :
                                                </span>{" "}
                                                &nbsp;
                                                <CommaSeprator
                                                  Price={sums?.totalValueSum}
                                                />
                                              </Typography>
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box
                                          sx={{
                                            width: "100%",
                                            maxWidth: "60px",
                                            display: "flex",
                                            justifyContent: "space-evenly",
                                            alignItems: "center",
                                            alignContent: "center",
                                          }}
                                        >
                                          {ProductId === el._id ? (
                                            <Box
                                              onClick={() => {
                                                setProductId(null);
                                                sendEvents(
                                                  "Click on radio check"
                                                );
                                              }}
                                              sx={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              <img src={radiocheck} size={30} />
                                            </Box>
                                          ) : (
                                            <Box
                                              onClick={() => {
                                                setProductId(el?._id);
                                                setManualPoints(
                                                  el?.ManualPoint
                                                );
                                                setListingType(el?.ListingType);
                                                setCompanyId(
                                                  el?.SellerCompanyId
                                                );
                                                sendEvents(
                                                  "Click on radio uncheck"
                                                );
                                                setHighestValue(
                                                  sums?.totalValueSum
                                                );
                                              }}
                                              sx={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              <img
                                                src={radiouncheck}
                                                size={30}
                                              />
                                            </Box>
                                          )}
                                        </Box>
                                      </Box>
                                    </Box>
                                  );
                                })
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  alignContent: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    fontSize: "18px",
                                    display: "flex",
                                    alignItems: "center",
                                    textAlign: "center",
                                    color: "#8A8A8A",
                                    lineHeight: 20,
                                  }}
                                >
                                  No Data Found
                                </Typography>
                              </Box>
                            )}
                          </DialogContent>
                          <DialogTitle id="responsive-dialog-title">
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <Button
                                variant="contained"
                                sx={{ ...reqbtn, width: "auto" }}
                                disabled={ProductId ? false : true}
                                onClick={() => {
                                  reqBal(ProductId, CompanyId);
                                  setSendNotification(ProductId);
                                  // setTimeout(() => {}, [3000]);
                                  setOpenSecond(true);
                                  setOpen(false);
                                  sendEvents("Click on request credit");
                                }}
                              >
                                {CheckCompanyType === "Media"
                                  ? "Request Credit"
                                  : "Check Credit Eligibility Amount"}
                              </Button>
                              <Button
                                variant="outlined"
                                sx={{
                                  ...reqbtn,
                                  fontWeight: 700,
                                  background: "none",
                                  border: "0.7px solid #EBEDEE",
                                  color: "#445FD2",
                                }}
                                onClick={() => {
                                  if (open === false) {
                                    setOpen(true);
                                  } else {
                                    setOpen(false);
                                  }
                                  sendEvents("Click on back");
                                }}
                              >
                                Back
                              </Button>
                            </Box>
                          </DialogTitle>
                        </Dialog>
                        <Dialog
                          open={openSecond}
                          onClose={() => {
                            setOpenSecond(false);
                          }}
                          sx={{
                            backdropFilter: "blur(2px)",
                          }}
                          fullWidth
                          maxWidth="lg"
                          PaperProps={{
                            sx: {
                              width: "35%",
                              height: "auto",
                              minHeight: "230px",
                              maxHeight: "230px",
                              borderRadius: "20px",
                              justifyContent: "center",
                            },
                          }}
                        >
                          <DialogTitle id="alert-dialog-title">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                width: "100%",
                                // bgcolor: "red",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  width: "90%",
                                }}
                              >
                                <CloseIcon
                                  sx={{
                                    color: "#c6cad2",
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (openSecond === false) {
                                      setOpenSecond(true);
                                    } else {
                                      setOpenSecond(false);
                                    }
                                  }}
                                />
                              </Box>
                            </Box>
                          </DialogTitle>
                          <DialogContent
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              // bgcolor: "green",
                            }}
                          >
                            <DialogContentText id="alert-dialog-description">
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "15px",
                                }}
                              >
                                <Box
                                  component={"img"}
                                  src={doneIcon}
                                  alt="done"
                                ></Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontFamily: "Poppins",
                                      fontStyle: "normal",
                                      fontWeight: 500,
                                      fontSize: "20px",
                                      lineHeight: "28px",
                                      textAlign: "center",
                                      color: "#6B7A99",
                                    }}
                                  >
                                    Request Sent to Admin for Approval
                                  </Typography>
                                  {CheckCompanyType === "Media" ? null : (
                                    <Typography
                                      sx={{
                                        fontFamily: "Poppins",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        fontSize: 14,
                                        lineHeight: "20px",
                                        textAlign: "center",
                                        color: "#475467",
                                      }}
                                    >
                                      You are eligible for credit amount of{" "}
                                      {CreditEligibility} Barter Coins
                                      {/* {credittoken <= 49
                                        ? "0"
                                        : credittoken > 49 && credittoken <= 74
                                        ? "2,50,000"
                                        : credittoken > 74 && credittoken <= 100
                                        ? "5,00,000"
                                        : null} */}
                                    </Typography>
                                  )}
                                </Box>
                              </Box>
                            </DialogContentText>
                          </DialogContent>
                        </Dialog>

                        {AuthUserData?.data?.superAdmin ? (
                          <Button
                            sx={requestCreditButtonStyle}
                            onClick={() => {
                              navigate("/home/creditstatus");
                            }}
                          >
                            View All Request
                          </Button>
                        ) : (
                          <Button
                            sx={requestCreditButtonStyle}
                            onClick={() => {
                              return GlobalToast(
                                "You are not authorized to view request",
                                "error"
                              );
                            }}
                          >
                            View All Request
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={RecenteUsersBox}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "89%",
                        // mx: "auto",
                        p: 2,
                      }}
                    >
                      <Typography
                        sx={{ ...TokenText, lineHeight: "10px", mt: 1 }}
                      >
                        Your Recent Transactions
                      </Typography>
                      <Link
                        to="/home/wallettransactions"
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <Typography
                          sx={{
                            ...StatMainText,
                            color: "#445FD2",
                            mt: 1,
                            textTransform: "none",
                          }}
                        >
                          View all
                        </Typography>
                      </Link>
                    </Box>
                    <Box
                      sx={{
                        padding: "0px 2rem",
                        display: "block",
                        justifyItems: "flex-start",
                        height: "100%",
                        maxHeight: "430px",
                        overflowY: "scroll",
                        overflow: "auto",
                        "::-webkit-scrollbar": {
                          display: "flex",
                        },
                        "::-webkit-scrollbar-thumb": {
                          dynamic: "#8d8e90",
                          minHeight: "10px",
                          borderRadius: "8px",
                        },
                      }}
                    >
                      {Statement?.slice(0, 10)?.map((row, index) => {
                        if (row?._type === "credit_line") {
                          return null;
                        } else {
                          return (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                width: "100%",
                                bgcolor: "transparent",
                                mt: "1rem",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  bgcolor: "transparent",
                                }}
                              >
                                <Box>
                                  <GetCompanyNameNProfile
                                    CompanyId={
                                      row?._from_wallet_id ===
                                      userData?.data?._id
                                        ? row?._to_wallet_id
                                        : row?._from_wallet_id
                                    }
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    ml: "2rem",
                                    width: "auto",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...ProfileNameStyle,
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      width: "auto",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-end",
                                      color:
                                        row?._from_wallet_id ===
                                        userData?.data?._id
                                          ? "red"
                                          : "green",
                                    }}
                                  >
                                    {row?._from_wallet_id ===
                                    userData?.data?._id
                                      ? "-"
                                      : "+"}
                                    <TransactionsCommaSeprator
                                      Price={row?._balance}
                                    />
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          );
                        }
                      })}
                    </Box>
                  </Box>
                </Box>
              )}
            </>
          </Grid>
          <Grid item xl={8.2} lg={8.2} md={8.2} sm={12} xs={12}>
            <>
              {loading ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                      mt: 3,
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      width={250}
                      height={100}
                      animation="wave"
                      sx={{ borderRadius: "10px" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={250}
                      height={100}
                      animation="wave"
                      sx={{ borderRadius: "10px" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={250}
                      height={100}
                      animation="wave"
                      sx={{ borderRadius: "10px" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={250}
                      height={100}
                      animation="wave"
                      sx={{ borderRadius: "10px" }}
                    />
                  </Box>
                  <Box>
                    <Skeleton
                      variant="rectangular"
                      width={880}
                      height={470}
                      animation="wave"
                      sx={{ borderRadius: "10px", mt: 3 }}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "end",
                      width: "98%",
                    }}
                  >
                    <Box
                      component="img"
                      src={PrintPurchaseOrder}
                      sx={{
                        width: "25px",
                        height: "25px",
                        cursor: "pointer",
                        mt: 0.5,
                      }}
                      onClick={() => {
                        handlePrint1();
                        sendEvents("Click on print purchase order");
                      }}
                    />
                    <Box
                      component="img"
                      src={DocDownloadImg}
                      sx={{
                        width: "25px",
                        height: "25px",
                        cursor: "pointer",
                        mt: 0.5,
                      }}
                      onClick={() => {
                        handlePrint1();
                        sendEvents("Click on doc download");
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "98%",
                      ml: "auto",
                      mr: "0px",
                      height: "auto",
                    }}
                    ref={componentRef1}
                  >
                    <Paper
                      elevation={0}
                      sx={{
                        width: "100%",
                        mx: "auto",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexWrap: "wrap",
                        // bgcolor: "red",
                      }}
                    >
                      {" "}
                      <StatsBox
                        name="Total Sales"
                        change={true}
                        childname="(Coins Earned)"
                        amount={balance?.total_sold_balance}
                      />
                      <StatsBox
                        name="Credit Issued / Due"
                        childname=""
                        change={false}
                        type="credit"
                        amount={
                          balance?.creditDue >= 0 ? balance?.creditDue : 0
                        }
                      />
                      <StatsBox
                        name="Total Purchase"
                        childname="(Coins Burnt)"
                        change={true}
                        amount={balance?.total_purchased_balance}
                      />
                      <StatsBox
                        name="Pending Receivable (Sale)"
                        change={false}
                        type="pending"
                        amount={OnHold}
                      />
                      <StatsBox
                        name="Pending Payable (Purchase)"
                        change={false}
                        type="pending"
                        amount={PurchaseOnHold}
                      />
                      <StatsBox
                        name="Account Balance"
                        change={false}
                        type="Balance"
                        amount={balance?._account_balance}
                      />
                    </Paper>
                    <Paper sx={DashboardChartStyle} elevation={0}>
                      {/* <DashboardChart /> */}
                      <LineChart />
                    </Paper>
                  </Box>
                </>
              )}
            </>
          </Grid>

          {Statement?.slice(0, 5)?.length > 0 ? (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} ref={pageBottom}>
              <Box sx={{ width: "100%", height: "auto" }}>
                <Box
                  sx={{
                    width: "100%",
                    height: "auto",
                    display: {
                      xl: "flex",
                      lg: "flex",
                      md: "flex",
                      sm: "block",
                      xs: "block",
                    },
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      height: "auto",
                      padding: "0px 1rem",
                      maxWidth: "400px",
                    }}
                  >
                    <Typography sx={{ ...TokenText, lineHeight: "30px" }}>
                      Last 5 Entries
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      height: "auto",
                      padding: "0px 1rem",
                      maxWidth: "200px",
                      gap: "5px",
                      mt: {
                        xl: "0px",
                        lg: "0px",
                        md: "0px",
                        sm: "1rem",
                        xs: "1rem",
                      },
                    }}
                  >
                    <Button
                      onClick={() => {
                        navigate("/home/wallettransactions");
                        sendEvents("Click on view all");
                      }}
                      sx={{
                        width: "120px",
                        height: "35px",
                        borderRadius: "12px",
                        textTransform: "capitalize",
                        bgcolor: "#445FD2",
                        ml: "10px",
                        "&:hover": {
                          bgcolor: "#156DB6",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "13px",
                          fontFamily: "poppins",
                          color: "white",
                          textTransform: "none",
                        }}
                      >
                        View All
                      </Typography>
                    </Button>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    height: "auto",
                  }}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      width: "100%",
                    }}
                  >
                    <TableContainer sx={TableContainerStyle}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Typography sx={TableTitleStyle}>No</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={TableTitleStyle}>Date</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={TableTitleStyle}>
                                Company Name
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={TableTitleStyle}>
                                Order Number
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={TableTitleStyle}>Type</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={TableTitleStyle}>
                                Debit
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={TableTitleStyle}>
                                Credit
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={TableTitleStyle}>TDS</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={TableTitleStyle}>
                                Account Balance
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={TableTitleStyle}>
                                Credit Due Balance
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={TableTitleStyle}>
                                Wallet Usable Balance
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={TableTitleStyle}>
                                Other
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={TableTitleStyle}>
                                BXI Commission
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={TableTitleStyle}>
                                Assessable Value
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={TableTitleStyle}>GST</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={TableTitleStyle}>
                                Total Invoice Value
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={TableTitleStyle}>TDS</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={TableTitleStyle}>
                                Net Receivable/(Payable)
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={TableTitleStyle}>INR</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={TableTitleStyle}>
                                Coins
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography sx={TableTitleStyle}>
                                TDS (26AS)
                              </Typography>
                            </TableCell>
                            <TableCell style={{ width: "300px" }}>
                              <Typography sx={TableTitleStyle}>
                                Invoice Value
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Statement?.slice(0, 5)?.map((row, index) => {
                            let price;
                            if (
                              row._from_wallet_id === "admin" ||
                              row._from_wallet_id === userData?.data?._id
                            ) {
                              price = row._from_wallet_balance;
                            } else {
                              price = row._to_wallet_balance;
                            }
                            const getTotalGST = (row) => {
                              if (
                                row?._from_wallet_id !== "admin" ||
                                row?._type === "add_coupon" ||
                                row?._type === "sell_coupon"
                              ) {
                                if (
                                  row?.OrderData?.BuyerChoosedTransportation
                                    ?.TransportationType === "Seller"
                                ) {
                                  return (
                                    (row?.INRDetails
                                      ?.totalTransportationCostGST ?? 0) +
                                    (row?.INRDetails?.bxiCommisionGST ?? 0) +
                                    (row?.INRDetails?.totalGstAmount ?? 0) +
                                    (row?.INRDetails?.InsuranceCostGstValue ??
                                      0) +
                                    (row?.INRDetails
                                      ?.bxiCommisionOnTransportationGST ?? 0) +
                                    (row?.INRDetails?.totalGstInrOfAdCost ??
                                      0) +
                                    (row?.INRDetails?.packagingCostGSTValue ??
                                      0)
                                  );
                                } else if (row?.OrderData?.IsMedia) {
                                  return (
                                    row?.INRDetails?.BxiCommisionGST +
                                    row?.INRDetails?.TotalGstAmount +
                                    row?.INRDetails?.TotalGstInrOfAdCost
                                  );
                                } else {
                                  return (
                                    (row?.INRDetails?.bxiCommisionGST ?? 0) +
                                    (row?.INRDetails?.totalGstAmount ?? 0) +
                                    (row?.INRDetails?.totalGstInrOfAdCost ?? 0)
                                  );
                                }
                              } else {
                                return null;
                              }
                            };

                            let Amount = row?._balance + row?.INRDetails?.TDS;

                            const getTotalBalance = (row) => {
                              if (
                                row?.OrderData?.BuyerChoosedTransportation
                                  ?.TransportationType === "Seller"
                              ) {
                                return (
                                  (Amount ?? 0) +
                                  (row?.INRDetails?.totalTransportationCost ??
                                    0) +
                                  (row?.INRDetails?.totalValueInrOfAdCost ??
                                    0) +
                                  (row?.INRDetails?.InsuranceCost ?? 0) +
                                  (row?.INRDetails?.packagingCost ?? 0) +
                                  (row?.INRDetails?.totalBxiCommission ?? 0)
                                );
                              } else if (row?.OrderData?.IsMedia) {
                                return (
                                  (Amount ?? 0) +
                                  (row?.INRDetails?.TotalValueInrOfAdCost ??
                                    0) +
                                  (row?.INRDetails?.TotalBxiCommission ?? 0)
                                );
                              } else {
                                return (
                                  (Amount ?? 0) +
                                  (row?.INRDetails?.totalValueInrOfAdCost ??
                                    0) +
                                  (row?.INRDetails?.totalBxiCommission ?? 0)
                                );
                              }
                            };

                            return (
                              <TableRow key={index}>
                                <TypographyComponent>
                                  {index + 1}
                                </TypographyComponent>
                                <TypographyComponent>
                                  {convertDate(row?._created_at)}
                                  <br />
                                  at {formatDateTime(row?._created_at)}
                                </TypographyComponent>
                                <TypographyComponent>
                                  <GetCompanyNameNProfile
                                    CompanyId={
                                      row?._from_wallet_id ===
                                      userData?.data?._id
                                        ? row?._to_wallet_id
                                        : row?._from_wallet_id
                                    }
                                  />
                                </TypographyComponent>
                                <TypographyComponent>
                                  {row?._order_number?.includes("BXI")
                                    ? row?._order_number
                                    : row?._clnumber || row?._order_number}
                                </TypographyComponent>
                                <TypographyComponent>
                                  {row?._type === "sell_recover_coupon"
                                    ? "Sell/PrivateDeal"
                                    : row?._type === "recover_coupon"
                                    ? "Buy/PrivateDeal"
                                    : row?._type === "sell_coupon" &&
                                      row?._from_wallet_id !==
                                        userData?.data?._id
                                    ? "Sell/PrivateDeal"
                                    : row?._type === "sell_coupon"
                                    ? "Buy/PrivateDeal"
                                    : row?._type === "add_coupon"
                                    ? "Buy/PrivateDeal"
                                    : row?.type === "refund"
                                    ? "Refund"
                                    : row?._type?.includes("credit_line")
                                    ? "Credit Line"
                                    : row?._from_wallet_id ===
                                      userData?.data?._id
                                    ? "Buy"
                                    : "Sell"}
                                </TypographyComponent>
                                <TypographyComponent>
                                  <TransactionsCommaSeprator
                                    Price={
                                      row?._from_wallet_id ===
                                      userData?.data?._id
                                        ? Amount
                                        : row?._type === "credit_line_recovery"
                                        ? Amount
                                        : null
                                    }
                                  />
                                </TypographyComponent>
                                <TypographyComponent>
                                  <TransactionsCommaSeprator
                                    Price={
                                      row?._from_wallet_id !==
                                      userData?.data?._id
                                        ? row?._type === "add_coupon"
                                          ? Amount
                                          : row?._from_wallet_id === "admin" &&
                                            row?._type === "sell_coupon"
                                          ? Amount
                                          : row?._from_wallet_id === "admin"
                                          ? null
                                          : Amount
                                        : null
                                    }
                                  />
                                </TypographyComponent>
                                <TypographyComponent>
                                  <TransactionsCommaSeprator
                                    Price={row?.INRDetails?.TDS}
                                  />
                                </TypographyComponent>
                                <TypographyComponent>
                                  {price < 0 ? (
                                    <>
                                      -
                                      <TransactionsCommaSeprator
                                        Price={Math.abs(price)}
                                      />
                                    </>
                                  ) : (
                                    <TransactionsCommaSeprator Price={price} />
                                  )}
                                </TypographyComponent>
                                <TypographyComponent>
                                  <TransactionsCommaSeprator
                                    Price={
                                      row?._type === "add_coupon"
                                        ? row?._from_wallet_credit_due
                                        : row?._from_wallet_id === "admin" &&
                                          row?._type === "sell_coupon"
                                        ? row?._from_wallet_credit_due?.toFixed(
                                            2
                                          )
                                        : row?._from_wallet_id === "admin"
                                        ? row?._to_wallet_credit_due?.toFixed(2)
                                        : row?._from_wallet_id ===
                                          userData?.data?._id
                                        ? row?._from_wallet_credit_due?.toFixed(
                                            2
                                          )
                                        : row?._to_wallet_credit_due?.toFixed(2)
                                    }
                                  />
                                </TypographyComponent>
                                <TypographyComponent>
                                  <TransactionsCommaSeprator
                                    Price={
                                      row?._type === "add_coupon"
                                        ? row?._from_wallet_usable_balance
                                        : row?._from_wallet_id === "admin" &&
                                          row?._type === "sell_coupon"
                                        ? row?._from_wallet_usable_balance?.toFixed(
                                            2
                                          )
                                        : row?._from_wallet_id === "admin"
                                        ? row?._to_wallet_usable_balance?.toFixed(
                                            2
                                          )
                                        : row?._from_wallet_id ===
                                          userData?.data?._id
                                        ? row?._from_wallet_usable_balance?.toFixed(
                                            2
                                          )
                                        : row?._to_wallet_usable_balance?.toFixed(
                                            2
                                          )
                                    }
                                  />
                                </TypographyComponent>
                                <TypographyComponent>
                                  {row?.OrderData?.BuyerChoosedTransportation
                                    ?.TransportationType === "Seller" ? (
                                    <TransactionsCommaSeprator
                                      Price={
                                        row?.INRDetails
                                          ?.totalTransportationCost +
                                          row?.INRDetails
                                            ?.totalValueInrOfAdCost +
                                          row?.INRDetails?.packagingCost +
                                          row?.INRDetails?.InsuranceCost || 0
                                      }
                                    />
                                  ) : (
                                    <TransactionsCommaSeprator
                                      Price={
                                        row?.INRDetails
                                          ?.totalValueInrOfAdCost ||
                                        row?.INRDetails
                                          ?.TotalValueInrOfAdCost ||
                                        0
                                      }
                                    />
                                  )}
                                </TypographyComponent>
                                <TypographyComponent>
                                  <TransactionsCommaSeprator
                                    Price={
                                      row?.INRDetails?.TotalBxiCommission ||
                                      row?.INRDetails?.totalBxiCommission ||
                                      0
                                    }
                                  />
                                </TypographyComponent>
                                <TypographyComponent>
                                  <TransactionsCommaSeprator
                                    Price={getTotalBalance(row)}
                                  />
                                </TypographyComponent>
                                <TypographyComponent>
                                  <TransactionsCommaSeprator
                                    Price={getTotalGST(row)}
                                  />
                                </TypographyComponent>
                                <TypographyComponent>
                                  <TransactionsCommaSeprator
                                    Price={
                                      getTotalBalance(row) + getTotalGST(row)
                                        ? (
                                            getTotalBalance(row) +
                                            getTotalGST(row)
                                          )?.toFixed(2)
                                        : 0
                                    }
                                  />
                                </TypographyComponent>
                                <TypographyComponent>
                                  <TransactionsCommaSeprator
                                    Price={row?.INRDetails?.TDS}
                                  />
                                </TypographyComponent>
                                <TypographyComponent>
                                  <TransactionsCommaSeprator
                                    Price={
                                      row?._from_wallet_id !==
                                      userData?.data?._id
                                        ? getTotalBalance(row) +
                                          getTotalGST(row) -
                                          row?.INRDetails?.TDS
                                        : getTotalBalance(row) +
                                          getTotalGST(row)
                                    }
                                  />
                                </TypographyComponent>
                                <TypographyComponent>
                                  <TransactionsCommaSeprator
                                    Price={row?.INRDetails?.TotalInrToPay}
                                  />
                                </TypographyComponent>
                                <TypographyComponent>
                                  <TransactionsCommaSeprator
                                    Price={row?._balance}
                                  />
                                </TypographyComponent>
                                <TypographyComponent>
                                  <TransactionsCommaSeprator
                                    Price={
                                      row?._from_wallet_id !==
                                      userData?.data?._id
                                        ? row?.INRDetails?.TDS
                                        : null
                                    }
                                  />
                                </TypographyComponent>
                                <TypographyComponent>
                                  <TransactionsCommaSeprator
                                    Price={
                                      getTotalBalance(row) + getTotalGST(row)
                                        ? (
                                            getTotalBalance(row) +
                                            getTotalGST(row)
                                          )?.toFixed(2)
                                        : 0
                                    }
                                  />
                                </TypographyComponent>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Box>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </Paper>
    </Paper>
  );
};

export default Dashboard;

const TypographyComponent = ({ children }) => {
  return (
    <TableCell>
      <Typography sx={TextStyleCommon}>{children}</Typography>
    </TableCell>
  );
};

const StatsBox = (props) => {
  return (
    <Box
      sx={{
        width: "100%",
        mx: "auto",
        height: "85px",
        border: "1px solid #E6E9EE",
        borderRadius: "12px",
        maxWidth: "170px",
        borderLeft: "4px solid #445FD2",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        alignContent: "flex-start",
        alignItems: "flex-start",
        mt: 1,
        pl: 1,
        flexDirection: "column",
        minWidth: "300px",
        maxWidth: "300px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          width: "90%",
        }}
      >
        <Box>
          <Typography sx={{ ...StatMainText, color: "#403d39" }}>
            {props.name}
          </Typography>
          <span
            style={{
              ...StatMainText,
              fontWeight: 400,
              fontSize: "10px",
              lineHeight: "0px",
              color: "#AFAFAF",
            }}
          >
            {props?.childname}
          </span>
        </Box>
        <Box></Box>
      </Box>
      <Typography
        sx={{
          ...MoneyTextStyle,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "2px",
        }}
      >
        <img
          src={stackofcoin}
          style={{ width: "20px", height: "20px" }}
          alt="stackofcoin"
        />
        {props?.type === "credit" ? (
          props?.amount > 1 ? (
            <span style={{ color: "red" }}>
              <CommaSeprator Price={props?.amount || 0} />
            </span>
          ) : (
            <span style={{ color: "green" }}>
              <CommaSeprator Price={props?.amount || 0} />
            </span>
          )
        ) : props?.type === "pending" ? (
          <span style={{ color: "#FFD700" }}>
            <CommaSeprator Price={props?.amount || 0} />
          </span>
        ) : props?.type === "Balance" ? (
          props?.amount < 0 ? (
            <span style={{ color: "red" }}>
              <CommaSeprator Price={props?.amount || 0} />
            </span>
          ) : (
            <span style={{ color: "green" }}>
              <CommaSeprator Price={props?.amount || 0} />
            </span>
          )
        ) : (
          <CommaSeprator Price={props?.amount || 0} />
        )}
      </Typography>
    </Box>
  );
};

const TableTitleStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  color: "#6B7A99",
};

const TableContainerStyle = {
  minHeight: "550px",
  maxHeight: "550px",
  overflowY: "auto",
  "::-webkit-scrollbar": {
    width: "3px",
    display: "flex",
    borderRadius: "3px",
    maxHeight: "6px",
    minHeight: "6px",
    minWidth: "6px",
    bgcolor: "#e8eaf1",
  },
  "::-webkit-scrollbar-thumb": {
    background: "#445fd2",
    borderRadius: "3px",
    cursor: "pointer",
  },
  "::-webkit-scrollbar-thumb:vertical": {
    maxWidth: "6px",
    minWidth: "6px",
  },
  "::-webkit-scrollbar-thumb:horizontal": {
    maxHeight: "6px",
    minHeight: "6px",
  },
};

const TextStyleCommon = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "18px",
  color: "#6B7A99",
};

const BXITokenCountBox = {
  width: "100%",
  height: "180px",
  background: "#FAFBFD",
  border: "1px solid #E6E9EE",
  borderRadius: "10px",
  mx: "auto",
};

const RecenteUsersBox = {
  width: "100%",
  height: "500px",
  background: "#ffffff",
  border: "1px solid #E6E9EE",
  borderRadius: "12px",
  mx: "auto",
  mt: 3,
};

const DashboardChartStyle = {
  width: "97%",
  mx: "auto",
  mt: 3,
  height: "494px",
  background: "#FFFFFF",
  border: "1px solid #E6E9EE",
  borderRadius: "12px",
  overflow: "auto",
};

const StatMainText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "18px",
  color: "darkgray",
  textTransform: "none",
};

const MoneyTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "18px", lg: "18px", md: "18px", sm: "16px", xs: "14px" },
  lineHeight: "30px",
  mt: 0.5,
  color: "#15223C",
};
const TokenText = {
  fontFamily: "Outfit",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: { xl: "17px", lg: "17px", md: "17px", sm: "16px", xs: "14px" },
  lineHeight: "25px",
  color: "#393D5E",
};
const TokenAmountStyle = {
  fontFamily: "Outfit",
  fontStyle: "normal",
  fontWeight: 800,
  fontSize: "28px",
  lineHeight: "40px",
  color: "#545454",
  display: "flex",
  alignItems: "center",
};
const requestCreditButtonStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "12px",
    lg: "10px",
    md: "8px",
    sm: "8px",
    xs: "8px",
  },
  lineHeight: {
    xl: "15px",
    lg: "12px",
    md: "8px",
    sm: "8px",
    xs: "8px",
  },
  color: "#FFFFFF",
  background: "#445FD2",
  borderRadius: "10px",
  textTransform: "none",
  padding: {
    xl: "1rem 2rem",
    lg: "0.8rem 1.8rem",
    md: "0.7rem 1.7rem",
    sm: "0.7rem 1.7rem",
    xs: "0.7rem 1.7rem",
  },
  "&:hover": {
    background: "#C3CAD9",
    color: "#545454",
  },
};

const ProfileNameStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "24px",
  color: "#15223C",
};

const ProductNameTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "18px",
    lg: "18px",
    md: "17px",
    sm: "13px",
    xs: "13px",
  },
  lineHeight: "25px",
  color: "#6B7A99",
  textAlign: "left",
};
const ProductMetaTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 300,
  fontSize: {
    xl: "12px",
    lg: "12px",
    md: "11px",
    sm: "9px",
    xs: "9px",
  },
  lineHeight: {
    xl: "18px",
    lg: "18px",
    md: "15px",
    sm: "14px",
    xs: "14px",
  },
  color: "#858585",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
const ProductPriceTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  color: "rgba(107, 122, 153, 0.5)",
  fontSize: {
    xl: "15px",
    lg: "15px",
    md: "15px",
    sm: "15px",
    xs: "15px",
  },
  lineHeight: {
    xl: "21px",
    lg: "21px",
    md: "19px",
    sm: "18px",
    xs: "17px",
  },
  alignContent: "center",
  alignItems: "center",
};

const reqbtn = {
  width: "114px",
  height: "42px",
  boxShadow: "none",
  background: "#445FD2",
  borderRadius: "8px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10px",
  textTransform: "none",
};
