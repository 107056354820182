import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  Input,
  MenuItem,
  Tooltip,
  Select,
  TextField,
  Typography,
  Fade,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { z } from "zod";
import ToolTip from "../../../../components/ToolTip";
import bxitoken from "../../../../assets/Images/CommonImages/BXIToken.svg";
import InfoIcon from "../../../../assets/InfoIcon.svg";
import { useLocation } from "react-router-dom";

export default function TextileProductInform(props) {
  const GSTOptions = [0, 5, 12, 18, 28];
  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      componentsProps={{ tooltip: { className: className } }}
    />
  ))(`
          background: #C64091;
          width:200px;
      `);

  const location = useLocation();
  console.log("sdhfgysdgufguudsgfgy", location.pathname?.includes("media"));

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(
      z.object({
        CostPrice: z.string().min(1),
        AdCostGST: z.coerce.number(),
        AdCostHSN: z
          .string()
          .regex(/^\d{4}$|^\d{6}$|^\d{8}$/, {
            message: "HSN must be 4, 6, or 8 digits",
          })
          .transform((value) => value?.trim()),
        ReasonOfCost: z.string().min(1).max(75),
        AdCostApplicableOn: z.string().min(1),
        currencyType: z.any(),
      })
    ),
  });

  useEffect(() => {
    if (props.defaultValue == null) {
      return;
    }
    for (const [key, value] of Object.entries(props.defaultValue)) {
      setValue(key, value);
    }
  }, [props.defaultValue]);

  return (
    <Box
      sx={{
        mt: 1,
        height: "auto",
        minHeight: "100px",
        position: "relative",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        flexDirection: "row",
        py: 2,
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontFamily: "Poppins",
          color: "#6B7A99",
          fontSize: "20px",
          marginBottom: "10px",
        }}
      >
        Additional Cost
        <span style={{ fontSize: "12px" }}>
          {" "}
          ( Additional cost is not mandatory )
        </span>
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            maxWidth: "200px",
          }}
        >
          <Typography sx={{ ...CommonTextStyle, whiteSpace: "nowrap" }}>
            Applicable On
          </Typography>
          <Select
            defaultValue={"All"}
            {...register("AdCostApplicableOn")}
            sx={{
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
              width: "199px",
              height: "48px",
              background: "#FFFFFF",
              borderRadius: "10px",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontSize: "12px",
              fontWeight: 400,
              color: "#c64091",
            }}
          >
            <MenuItem value="All">One Time Cost</MenuItem>
            <MenuItem value="PerUnit">Per Unit</MenuItem>
          </Select>
          <Typography sx={{ color: "red", height: "auto", width: "103%" }}>
            {errors["AdCostApplicableOn"]?.message}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "180px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                ...CommonTextStyle,
                fontSize: "13.5px",
                position: "relative",
              }}
            >
              Cost(Exc of GST)
            </Typography>
            <Box sx={{ width: "15px", height: "15px" }}>
              <CustomTooltip
                title={
                  <Typography sx={ToolTextStyle}>
                    Do you wish to collect this as Barter Coins OR INR?
                  </Typography>
                }
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 400 }}
              >
                <Box
                  component="img"
                  src={InfoIcon}
                  sx={{ width: "18px", height: "18px", cursor: "pointer" }}
                />
              </CustomTooltip>
            </Box>
          </div>

          <Box
            sx={{
              display: "flex",
              background: "#FFFFFF",
              borderRadius: "10px",
              width: "100%",
              alignItems: "center",
              border: errors["CostPrice"] ? "1px solid red" : null,
            }}
          >
            <Input
              disableUnderline
              // {...register("CostPrice")}
              {...register("CostPrice", {
                onChange: (event) => {
                  event.target.value = parseInt(
                    event.target.value.replace(/[^\d]+/gi, "") || 0
                  ).toLocaleString("en-US");
                },
              })}
              // type="number"
              placeholder="Eg. 1000"
              id="standard-basic"
              variant="standard"
              InputProps={{
                disableUnderline: "true",
                style: {
                  color: "#c64091",
                  fontSize: "13px",
                },
              }}
              sx={{
                width: "100%",
                height: "48px",
                background: "#FFFFFF",
                borderRadius: "10px",
                px: 1,
                fontSize: "12px",
                color: "#c64091",
              }}
            />

            <Select
              defaultValue={"₹"}
              {...register("currencyType")}
              sx={{
                height: "48px",
                width: "auto",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                background: "#FFFFFF",

                color: "#6B7A99",
                fontSize: "12px",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 400,
                borderRadius: "0px 10px 10px 0px",
              }}
            >
              <MenuItem value="BXITokens">
                <Box
                  component="img"
                  src={bxitoken}
                  alt="bxitoken"
                  sx={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </MenuItem>
              <MenuItem value="₹">₹</MenuItem>
            </Select>
          </Box>
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {errors["CostPrice"]?.message}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            maxWidth: "100px",
          }}
        >
          <Typography sx={CommonTextStyle}>HSN</Typography>

          <Box sx={{ position: "relative" }}>
            <Input
              disableUnderline
              // required={true}
              placeholder="998346"
              {...register("AdCostHSN", {
                onChange: (event) => {
                  const inputValue = event.target.value;

                  if (inputValue.match(/\D/g)) {
                    const filteredValue = inputValue.replace(/\D/g, "");
                    event.target.value = filteredValue;
                  }
                },
              })}
              // type="number"
              sx={{
                width: "100px",
                height: "48px",
                background: "#FFFFFF",
                borderRadius: "10px",
                px: 1,
                fontSize: "12px",
                color: "#c64091",
                border: errors["AdCostHSN"] ? "1px solid red" : null,
              }}
              inputProps={{ maxLength: 8 }}
              onKeyDown={(e) => {
                if (e.key === " " && e.target.selectionStart === 0) {
                  e.preventDefault();
                }
              }}
            />
          </Box>
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {errors?.AdCostHSN?.message}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            maxWidth: "100px",
          }}
        >
          <Typography sx={CommonTextStyle}>GST</Typography>

          <Box sx={{ position: "relative" }}>
            <Select
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                width: "100px",
                height: "48px",
                background: "#FFFFFF",
                borderRadius: "10px",
                fontSize: "12px",
                color: "#c64091",
                border: errors["AdCostGST"] ? "1px solid red" : null,
              }}
              defaultValue="0"
              {...register("AdCostGST")}
            >
              {GSTOptions.map((gst, idx) => {
                return (
                  <MenuItem sx={MenuItems} value={gst}>
                    {gst}
                  </MenuItem>
                );
              })}
            </Select>

            <Typography
              sx={{
                position: "absolute",
                right: "32%",
                bottom: "25%",
                color: "#979797",
                fontSize: "15px",
              }}
            >
              %
            </Typography>
          </Box>
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {errors["AdCostGST"]?.message}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <Typography sx={{ ...CommonTextStyle }}>Reason Of Cost</Typography>
          <Box
            sx={{
              display: "flex",
              background: "#fff",
              borderRadius: "10px",
            }}
          >
            <TextField
              {...register("ReasonOfCost")}
              placeholder={
                location.pathname?.includes("media")
                  ? "Content Management Charges, Printing Mounting Charges, Conversion Charges, Log Report Charges etc "
                  : "Customized Packaging"
              }
              id="standard-basic"
              variant="standard"
              InputProps={{
                disableUnderline: "true",
                style: {
                  // color: "rgba(107, 122, 153)",
                  fontSize: "14px",
                  padding: "10px",
                  height: "48px",
                  color: "#c64091",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "red",
                },
              }}
              sx={{
                width: "100%",
                background: "#FFFFFF",
                borderRadius: "10px",
                color: "#c64091",
                border: errors["ReasonOfCost"] ? "1px solid red" : null,
              }}
            />
          </Box>
          <Typography sx={{ color: "red", fontFamily: "Poppins" }}>
            {errors["ReasonOfCost"]?.message}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          sx={{
            color: "#ffffff",
            backgroundColor: "#C64091",
            textTransform: "none",
            fontSize: "14px",
            height: "41px",
            width: "100%",
            borderRadius: "10px",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 400,
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "#C64091",
            },
          }}
          onClick={async () => {
            if ((await trigger()) === false) {
              return;
            }
            props.append(getValues(), props.index);
            reset({
              AdCostName: "",
              CostPrice: "",
              AdCostHSN: "",
              ReasonOfCost: "",
            });
          }}
        >
          Add Additional Cost
        </Button>
      </Box>
    </Box>
  );
}

const CommonTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "21px",
  color: "#6B7A99",
};

const MenuItems = {
  fontSize: "12px",
  color: "#c64091",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
};

const ToolTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "10px",
  color: "#fff",
  textAlign: "center",
  cursor: "pointer",
};
