import React from "react";
import {
  Box,
  Card,
  Grid,
  Paper,
  Button,
  Typography,
  CardContent,
} from "@mui/material";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";
import Plus from "../../assets/Images/MembersPage/PlusImg.svg";
import Avatargenerator from "../../components/AvatarGenerator";
import MailIcon from "../../assets/Images/MembersPage/email.png";
import PhoneIcon from "../../assets/Images/MembersPage/phone.png";
import * as Styles from "../../components/common/Styled/Styles.js";
import useGetAuthUser from "../../Hooks/LoggedInUser/useGetAuthUser";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import useGetLoggedInUser from "../../Hooks/LoggedInUser/useGetLoggedInUser";
import useGetAllCompanyMembers from "../../Hooks/CompanyMember/useGetAllCompanyMembers";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import GlobalToast from "../../components/Toasts/GlobalToast.jsx";
export default function CompanyMembers() {
  let navigate = useNavigate();
  // Get company members
  const { data: companyMembers } = useGetAllCompanyMembers();
  // Who logged Here
  const {
    data: userData,
    isLoading: userLoading,
    isError: userError,
  } = useGetLoggedInUser();

  console.log("userData", userData);
  const { data: AuthUser } = useGetAuthUser();
  // Remove Users
  console.log("AuthUser", AuthUser?.data?.superAdmin);
  async function handelDeleteMember(id) {
    const confirmed = window.confirm(
      "Are you sure you want to delete this member?"
    );
    if (confirmed === true) {
      axios
        .delete("assign/deleteassign/" + id)
        .then((res) => {
          if (res.data === "Unauthorized") {
            window.alert("Unauthorized");
            return;
          }
          window.alert("Member deleted successfully.");
        })
        .catch(() => {});
    } else {
      return;
    }
  }

  return (
    <Paper
      sx={{ bgcolor: "transparent", width: "100%", boxShadow: "none" }}
      elevation={0}
    >
      <BreadCrumbHeader MainText="Users" />
      <Paper
        sx={{
          bgcolor: "transparent",
          mt: 3,
          display: "flex",
          flexDirection: "column",
        }}
        elevation={0}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            height: "70px",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              navigate("/home/profile");
            }}
            sx={{
              alignSelf: "flex-end",
              width: "auto",
              maxWidth: "100px",
              border: "none",
              ":hover": {
                border: "none",
              },
            }}
          >
            <KeyboardBackspaceIcon fontSize="large" />
          </Button>
          {AuthUser?.data?.superAdmin ? (
            <Button
              variant="outlined"
              onClick={() => {
                navigate("/home/deleted_members");
              }}
              sx={{
                ...Styles.ViewProfileBtn,
                alignSelf: "flex-end",
                width: "10%",
              }}
            >
              Deleted Users{" "}
            </Button>
          ) : null}
        </Box>
        <Grid container spacing={2} sx={{ mt: 0.5 }}>
          {AuthUser?.data?.roleAndPermission === "Yes" ||
          AuthUser?.data?.superAdmin ? (
            AuthUser?.data?.superAdmin ? (
              <Grid item xl={2.4} lg={2.4} md={3} sm={6} xs={12}>
                <Link
                  to="/home/addmember"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Card variant="outlined" sx={Styles.CardDesign}>
                    <CardContent sx={Styles.CardContent}>
                      <img
                        style={{
                          border: "2px dashed #C4C4C4",
                          borderRadius: "40px",
                        }}
                        src={Plus}
                      />
                      <Typography mt={2} sx={Styles.textProfile}>
                        Add New User
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ) : null
          ) : null}
          {companyMembers?.map((card, idx) => {
            console.log("cards", card);
            return (
              <Grid key={idx} item xl={2.4} lg={2.4} md={3} sm={6} xs={12}>
                <Card variant="outlined" sx={Styles.CardMap} key={card.id}>
                  <CardContent sx={Styles.CardContentMap}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                      }}
                    >
                      {card.superAdmin ? null : AuthUser?.data?.superAdmin ? (
                        <CloseIcon
                          sx={{
                            color: "#929EAE",
                            fontSize: "17px",
                            cursor: "pointer",
                            "&:hover": {
                              color: "#000",
                            },
                          }}
                          onClick={() => handelDeleteMember(card._id)}
                        />
                      ) : null}
                    </Box>
                    <Avatargenerator
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      companyname={userData?.data?.companyName}
                      AvatarUrl={userData?.data?.CompanyAvatar?.url}
                    />
                    <Box
                      sx={{
                        borderBottom: "1px solid #F5F5F5",
                        width: "90%",
                      }}
                    >
                      <Typography sx={Styles.CardName}>{card?.name}</Typography>
                      <Typography
                        sx={{
                          ...Styles.rolestyle,
                          mb: "10px",
                          marginTop: "3px",
                        }}
                      >
                        {card?.roleName === null || card?.roleName === undefined
                          ? "No Permission"
                          : card?.roleName}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignContent: "center",
                        alignItems: "flex-start",
                        marginTop: "10px",
                        mb: "10px",
                        width: "90%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "90%",
                        }}
                      >
                        <Typography sx={Styles.rolestyle2}>
                          <Box
                            component="img"
                            src={PhoneIcon}
                            sx={{
                              color: "#000",
                              cursor: "pointer",
                              height: "15px",
                              width: "15px",
                              fontSize: "12px",
                            }}
                          ></Box>
                          <span style={{ fontSize: "12px", color: "#000000" }}>
                            {card?.phone}
                          </span>
                        </Typography>
                        <Typography sx={Styles.rolestyle2}>
                          <Box
                            component="img"
                            src={MailIcon}
                            sx={{
                              color: "#000",
                              cursor: "pointer",
                              height: "15px",
                              width: "15px",
                              fontSize: "12px",
                            }}
                          />
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontSize: "12px",
                              color: "#000000",
                            }}
                          >
                            {card?.email}
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                    {AuthUser?.data?.roleAndPermission === "Yes" ||
                    AuthUser?.data?.superAdmin ? (
                      <Button
                        sx={Styles.ViewProfileBtn}
                        onClick={() => {
                          navigate(`/home/member_details/${card?._id}`);
                        }}
                      >
                        View Profile
                      </Button>
                    ) : (
                      <Button
                        sx={Styles.ViewProfileBtn}
                        onClick={() => {
                          return GlobalToast(
                            "You are not authorized to View Profile",
                            "error"
                          );
                        }}
                      >
                        View Profile
                      </Button>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Paper>
    </Paper>
  );
}
