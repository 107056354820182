import { Accordion, Box, Button, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import BreadCrumbHeader from "../../../components/Header/BreadCrumbHeader.jsx";
import { useDispatch } from "react-redux";
import { listPurchaseOrders } from "../../../redux/action/PurchaseOrder_Action.js";
import { useNavigate } from "react-router-dom";
import { useGetMediaOrderSummaryForBuyer } from "../../../Hooks/OrderActions/useGetOrderSummaryForBuyer.js";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Stack from "@mui/material/Stack";
import DownIcon from "../../../assets/Images/CommonImages/Down.png";
import GoLeft from "../../../assets/Images/CommonImages/GoLeft.png";
import PendingIcon from "../../../assets/Images/CommonImages/Pending.png";
import RejectedIcon from "../../../assets/Images/CommonImages/Rejected.png";
import SkeletonComponent from "../../../components/SkeletonComponent.jsx";
import stackofcoins from "../../../assets/Stack of Coins.svg";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/system";
import { useGetSellerSidePendingOrderForMedia } from "../../../Hooks/OrderActions/useGetSellerSidePendingOrder.js";
import CommaSeprator from "../../../components/CommaSeprator.js";
import Nodatafound from "../../../components/NoDataFound/NoDataFound.jsx";
import sendEvents from "../../../utils/sendEvents.js";
import AcceptedIcon from "../../../assets/Images/CommonImages/Accepted.png";
import ImageAndProductCarousel from "../../../components/Carousel/ImageAndProductCarousel.jsx";
import CompanyName from "../../../components/CompanyName.jsx";

function MediaPO() {
  const StyledPagination = styled(Pagination)({
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [DataIds, setDataIds] = useState([]);
  const [Order, setOrder] = React.useState([]);
  const [Switchtab, setSwitchtab] = useState("Purchased");
  const [currentPage, setCurrentPage] = useState(1);
  const [initialPaginationCount, setInitialPaginationCount] = useState(null);
  const [Allselected, setAllselected] = useState(false);
  const [AllOrders, setAllOrders] = useState();

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const {
    data: purchaseOrderDataBuyer,
    isLoading: purchaseOrderLoadingSeller,
    error: purchaseOrderErrorSeller,
    refetch: refetchBuyer,
  } = useGetMediaOrderSummaryForBuyer(currentPage);

  const {
    data: purchaseOrderDataSeller,
    isLoading: purchaseOrderLoadingSellerPending,
    error: purchaseOrderErrorSellerPending,
    refetch: refetchSellerPending,
  } = useGetSellerSidePendingOrderForMedia(currentPage);

  useEffect(() => {
    if (Switchtab === "Purchased") {
      setCurrentPage(1);
      refetchBuyer({ page: 1 });
    } else if (Switchtab === "Sold") {
      setCurrentPage(1);
      refetchSellerPending({ page: 1 });
    }
  }, [Switchtab]);

  useEffect(() => {
    const { finalCount: allCount } = purchaseOrderDataBuyer || {};
    const { finalCount: liveCount } = purchaseOrderDataSeller || {};

    if (allCount !== undefined && Switchtab === "Purchased") {
      setInitialPaginationCount(allCount);
    } else if (liveCount !== undefined && Switchtab === "Sold") {
      setInitialPaginationCount(liveCount);
    }
  }, [purchaseOrderDataBuyer, purchaseOrderDataSeller, Switchtab]);

  useEffect(() => {
    if (Switchtab === "Purchased") {
      refetchBuyer({ page: currentPage });
    } else if (Switchtab === "Sold") {
      refetchSellerPending({ page: currentPage });
    }
  }, [currentPage, Switchtab, refetchBuyer, refetchSellerPending]);

  let dataId = [];

  Order.map((el) => {
    dataId.push(el._id);
  });

  useEffect(() => {
    const pendingOrders = purchaseOrderDataSeller?.OrderSumm?.filter(
      (order) => order.BuyerOrderStatus === "Pending"
    );
    const pendingOrdersLength = pendingOrders ? pendingOrders.length : 0;

    if (pendingOrdersLength === Order?.length) {
      setAllselected(false);
    }
  }, []);

  const GetMediaPO = async (id) => {
    try {
      await axios
        .get("purchase/get_media_order_summary_for_buyer")
        .then((res) => {});
    } catch (e) {}
  };

  const GetOrdersId = async () => {
    return await axios
      .get(`order/get_all_orders_id`)
      .then((res) => {
        setAllOrders(res?.data?.orders);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    dispatch(listPurchaseOrders());
  }, [dispatch]);

  useEffect(() => {
    GetMediaPO();
    GetOrdersId();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      window.scrollTo(0, 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Paper
      sx={{
        boxShadow: "none",
        background: "transparent",
        width: "100%",
        mx: "auto",
      }}
      elevation={0}
    >
      <BreadCrumbHeader MainText={"Media PO (Purchase)"} />

      {purchaseOrderLoadingSeller ? (
        <>
          <SkeletonComponent />
        </>
      ) : (
        <>
          <Grid
            container
            sx={{
              background: "#FFFFFF",
              padding: "1rem",
              borderRadius: "20px",
              minHeight: "70vh",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              gap: 1,
              position: "relative",
            }}
          >
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                height: "50px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  maxWidth: "190px",
                }}
              >
                <Button
                  onClick={() => {
                    navigate("/home");
                    sendEvents("Navigate to home");
                  }}
                  sx={{
                    background: "transparent",
                    boxShadow: "none",
                  }}
                >
                  <img src={GoLeft} width="22px" />
                </Button>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#242120",
                    ml: "1rem",
                  }}
                >
                  {/* Product Details */}
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid
                container
                sx={{
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "99%",
                }}
              >
                <Grid
                  item
                  xl={0.5}
                  lg={0.5}
                  md={0.5}
                  sm={0.5}
                  xs={0.5}
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <Typography sx={tableheading}>No</Typography>
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                  <Typography sx={tableheading}>Media Name</Typography>
                </Grid>
                <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                  <Typography align="center" sx={tableheading}>
                    Order Number
                  </Typography>
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                  <Typography align="center" sx={tableheading}>
                    Total Products
                  </Typography>
                </Grid>
                <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                  <Typography align="center" sx={tableheading}>
                    Barter Coins
                  </Typography>
                </Grid>
                <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                  <Typography align="center" sx={tableheading}>
                    Order date
                  </Typography>
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                  <Typography align="center" sx={tableheading}>
                    Status
                  </Typography>
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                  <Typography align="center" sx={tableheading}>
                    Action
                  </Typography>
                </Grid>
              </Grid>
              {purchaseOrderDataBuyer?.OrderSumm?.length > 0 ? (
                purchaseOrderDataBuyer?.OrderSumm?.map((data, idx) => {
                  let totalPrice = 0;
                  data?.ProductData?.map((data) => {
                    let timesec = data?.BoughtSeconds;
                    totalPrice +=
                      data?.ProductTypeName === "Media"
                        ? data.DiscountedPrice *
                          data.ProductQuantity *
                          timesec *
                          data?.TimelineToBought
                        : data.DiscountedPrice * data.ProductQuantity;

                    return totalPrice;
                  });

                  function convertDate(inputFormat) {
                    function pad(s) {
                      return s < 10 ? "0" + s : s;
                    }
                    var d = new Date(inputFormat);
                    return [
                      pad(d.getDate()),
                      pad(d.getMonth() + 1),
                      d.getFullYear(),
                    ].join("/");
                  }
                  const OrderIdData = AllOrders?.filter(
                    (el) => el?.PurchaseOrderId === data?._id
                  );
                  return (
                    <Accordion
                      sx={{
                        p: 0,
                        boxShadow: "none",
                        border: "none",
                        borderBottom: "1px solid #F5F5F5",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <img src={DownIcon} style={{ width: "9px" }} />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ p: 0 }}
                      >
                        <Grid container>
                          <Grid
                            item
                            xl={0.5}
                            lg={0.5}
                            md={0.5}
                            sm={0.5}
                            xs={0.5}
                            sx={{
                              textAlign: "center",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              alignContent: "center",
                            }}
                          >
                            <Typography sx={TableBodyTextStyling}>
                              {" "}
                              {idx + 1}
                            </Typography>
                          </Grid>
                          <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                            <ImageAndProductCarousel
                              carouselData={data?.ProductData}
                            />
                          </Grid>
                          <Grid
                            item
                            xl={1.5}
                            lg={1.5}
                            md={1.5}
                            sm={1.5}
                            xs={1.5}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Typography sx={TableBodyTextStyling}>
                              {OrderIdData?.at(0)?.OrderId}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Typography sx={TableBodyTextStyling}>
                              {data?.ProductData?.length}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1.5}
                            lg={1.5}
                            md={1.5}
                            sm={1.5}
                            xs={1.5}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center", // Adjusted to "flex-end"
                              display: "flex",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBodyTextStyling,
                                display: "flex",
                                justifyContent: "flex-end", // Adjusted to "flex-end"
                                alignItems: "center",
                                gap: "2px",
                              }}
                            >
                              <img
                                src={stackofcoins}
                                alt="rupieicon"
                                style={{
                                  width: "15px",
                                  height: "auto",
                                }}
                              />
                              <CommaSeprator Price={totalPrice} />
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xl={1.5}
                            lg={1.5}
                            md={1.5}
                            sm={1.5}
                            xs={1.5}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Typography sx={TableBodyTextStyling}>
                              {convertDate(data?.PoDate)}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              gap: "5px",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBodyTextStyling,
                                fontSize: "14px",
                                color:
                                  data?.BuyerOrderStatus === "Pending"
                                    ? "#FFB600"
                                    : data?.BuyerOrderStatus === "Accepted"
                                    ? "#118A2C"
                                    : "#FF0000",

                                mx: "auto",
                              }}
                            >
                              {data?.BuyerOrderStatus}
                            </Typography>

                            {data?.BuyerOrderStatus === "Pending" ? (
                              <Box
                                component="img"
                                src={PendingIcon}
                                sx={{ width: "13px", height: "13px" }}
                              />
                            ) : data?.BuyerOrderStatus === "Accepted" ? (
                              <Box
                                component="img"
                                src={AcceptedIcon}
                                sx={{
                                  width: "13px",
                                  height: "13px",
                                  // pl: 10,
                                }}
                              />
                            ) : (
                              <Box
                                component="img"
                                src={RejectedIcon}
                                sx={{ width: "13px", height: "13px" }}
                              />
                            )}
                          </Grid>

                          <Grid
                            item
                            xl={2}
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Box
                              onClick={() => {
                                data.IsMedia === true
                                  ? navigate(
                                      `/home/mediapurchaseorderdetails/${data._id}`
                                    )
                                  : navigate(
                                      `/home/purchaseorderdetails/${data._id}`
                                    );
                                sendEvents("Click on view PO");
                              }}
                              sx={{
                                cursor: "pointer",
                                width: "100px",
                                mx: "auto",
                                height: "30px",
                                background: "#445FD2",
                                borderRadius: "4.39877px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                alignContent: "center",
                                marginLeft: "auto",
                              }}
                            >
                              <Typography
                                sx={{
                                  ...TableBodyTextStyling,
                                  color: "#fff",
                                }}
                              >
                                View P.O.
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails sx={{ p: 0, pb: 2 }}>
                        <Box sx={{ ml: "50px" }}>
                          <CompanyName
                            CompanyId={data?.SellerDetails?.SellerCompanyId}
                            CompanyName={data?.SellerDetails?.SellerCompanyName}
                            CompanyTypeName={
                              data?.SellerDetails?.CompanyTypeName
                            }
                          />
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  );
                })
              ) : (
                <Nodatafound />
              )}
            </Grid>
          </Grid>

          {purchaseOrderDataBuyer?.OrderSumm?.length > 0 ? (
            <Box sx={{ display: "flex", justifyContent: "center" }} mt={2}>
              <Stack spacing={2}>
                <StyledPagination
                  count={initialPaginationCount}
                  color="primary"
                  page={currentPage}
                  onChange={handlePageChange}
                  showFirstButton
                  showLastButton
                  strokeWidth={currentPage}
                />
              </Stack>
            </Box>
          ) : null}
        </>
      )}
    </Paper>
  );
}

export default MediaPO;

const tableheading = {
  fontFamily: "Poppins",
  color: "#7E8BA6",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  textTransform: "Capitalize",
};

const TableBodyTextStyling = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "21px",
  color: "#1B212D",
};
