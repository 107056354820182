import {
  Box,
  Grid,
  Checkbox,
  Typography,
  TextField,
  Button,
  BottomNavigation,
} from "@mui/material";
import { Stack } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";

import { useUpdateProductQuery } from "./ProductHooksQuery";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { styled } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "mui-daterange-picker";
import { useEffectOnce } from "react-use";
import RemoveIcon from "../../../../assets/Images/CommonImages/RemoveIcon.svg";
import addItemCartIcon from "../../../../assets/CartPage/addItemIcon.svg";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import defaultIcon from "../../../../assets/CartPage/defaultCheckBoxIcon.svg";
// import { useEffectOnce } from "react-use";
import moment from "moment";

import ToolTip from "../../../../components/ToolTip";

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
}));

function Day(props) {
  const { day, selectedDay, TimelineData, ...other } = props;

  if (selectedDay == null) {
    return <PickersDay day={day} {...other} />;
  }

  const start = selectedDay;
  const end = start.add(ReturnDaysFromTimeline(TimelineData), "day");
  // BoughtDatesArray.push(start);
  const dayIsBetween = day.isBetween(start, end, null, "[]");
  const isFirstDay = day.isSame(start, "day");
  const isLastDay = day.isSame(end, "day");

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={dayIsBetween ? { px: 2.5, mx: 0 } : {}}
      dayIsBetween={dayIsBetween}
      isFirstDay={isFirstDay}
      isLastDay={isLastDay}
    />
  );
}
function ReturnDaysFromTimeline(timeline) {
  if (timeline === "Day") {
    return 0;
  } else if (timeline === "Week") {
    return 6;
  } else if (timeline === "Month") {
    return 29;
  } else if (timeline === "Year") {
    return 364;
  }
}

function checkIfDateExists(dateArr, newStartDate, newEndDate) {
  for (let i = 0; i < dateArr.length; i++) {
    const { startDate, endDate } = dateArr[i];
    if (
      (newStartDate >= startDate && newStartDate <= endDate) ||
      (newEndDate >= startDate && newEndDate <= endDate)
    ) {
      return true;
    }
  }
  return false;
}
export default function TechInfo() {
  const ProductId = useParams().id;
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [dateArr, setDateArr] = useState([]);
  const [fetchproductData, setfetchProductData] = useState();
  const [BXISpace, setBXISpace] = useState(false);
  const [content, setContent] = useState("checkbox");
  const [storeUploadLink, setStoreUploadLink] = useState();

  const [value, setValueState] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [checkBoxes, setCheckBoxes] = useState({
    inspectionPass: false,
    LogReport: false,
    Videos: false,
    Pictures: false,
    ExhibitionCertificate: false,
    Other: false,
  });

  const toggle = () => setOpen(!open);
  const countDaysfromTimeline = (value, timeline) => {
    if (timeline === "Week") {
      return value * 7;
    } else if (timeline === "Month") {
      return value * 30;
    } else if (timeline === "Years") {
      return value * 365;
    } else if (timeline === "Day") {
      return value;
    } else if (fetchproductData?.mediaVariation?.unit === "Spot") {
      return fetchproductData?.mediaVariation?.maxOrderQuantityunit;
    } else if (timeline === "One Time") {
      return value;
    }
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: { errors, isValid },
  } = useForm({
    Values: {
      Dimensions: fetchproductData?.dimensions,
      UploadLink: fetchproductData?.uploadLink,
      WhatSupportingYouWouldGiveToBuyer:
        fetchproductData?.whatSupportingYouWouldGiveToBuyer,
      // InterStateGST: fetchproductData?.InterStateGST,
    },
    resolver: zodResolver(
      z.object({
        Dimensions: z.string().min(1),
        UploadLink: BXISpace === true ? z.any() : z.string().min(1),
        // InterStateGST: z.string().min(1),
        BXISpace: z.boolean(),
      })
    ),
  });

  const ContentChange = (event) => {
    if (event.target.value === "uploadLinkSet") {
      setContent("uploadLinkSet");
      setBXISpace("");
    } else {
      setContent(event.target.value); // Reset the custom input value when changing from 'custom' to predefined options
    }
    reset({
      UploadLink: "",
      BXISpace: false,
    });
  };

  const FetchProduct = async () => {
    await axios
      .get(`product/get_product_byId/${ProductId}`, {
        withCredentials: true,
      })
      .then((res) => {
        setfetchProductData(res.data);
        setValue("Dimensions", res?.data?.Dimensions);
        setValue("UploadLink", res?.data?.UploadLink);
        setStoreUploadLink(res?.data?.UploadLink);
        setCheckBoxes({
          inspectionPass:
            res?.data?.WhatSupportingYouWouldGiveToBuyer?.inspectionPass,
          LogReport: res?.data?.WhatSupportingYouWouldGiveToBuyer?.LogReport,
          Videos: res?.data?.WhatSupportingYouWouldGiveToBuyer?.Videos,
          Pictures: res?.data?.WhatSupportingYouWouldGiveToBuyer?.Pictures,
          ExhibitionCertificate:
            res?.data?.WhatSupportingYouWouldGiveToBuyer.ExhibitionCertificate,
          Other: res?.data?.WhatSupportingYouWouldGiveToBuyer?.Other,
        });
        // setValue("InterStateGST", res?.data?.InterStateGST);
        setDateArr(res?.data?.calender || []);
        setValue("BXISpace", res?.data?.BXISpace);
        setBXISpace(res?.data?.BXISpace);
      })
      .catch((err) => {});
  };
  function getDates(startDate, endDate) {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates.push({
        startDate: new Date(currentDate),
        endDate: new Date(currentDate),
        minOrderQuantityunit: Number(
          fetchproductData?.mediaVariation?.minOrderQuantityunit
        ),
        maxOrderQuantityunit: Number(
          fetchproductData?.mediaVariation?.maxOrderQuantityunit
        ),
        minTimeslotSeconds: Number(
          fetchproductData?.mediaVariation?.minTimeslotSeconds
        ),
        maxTimeslotSeconds: Number(
          fetchproductData?.mediaVariation?.maxTimeslotSeconds
        ),
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }
  useEffectOnce(() => {
    FetchProduct();
  }, []);
  function getDaysBetweenDates(startDate, endDate) {
    const startDateAndTime = moment(startDate);
    const endDateAndTime = moment(endDate);
    const daysDifference = endDateAndTime.diff(startDateAndTime, "days");
    return daysDifference;
  }
  const {
    mutate: updateProduct,
    data: productData,
    error: RegisterError,
  } = useUpdateProductQuery();

  const updateProductTechinfostatus = handleSubmit((data) => {
    const MaxDaysTobeadded = countDaysfromTimeline(
      fetchproductData?.mediaVariation?.maxOrderQuantitytimeline,
      fetchproductData?.mediaVariation?.Timeline
    );
    let Totaldays = 0;

    Totaldays = getDaysBetweenDates(
      dateArr?.at(0).startDate,
      dateArr?.at(-1).endDate
    );

    const datatobesent = {
      ...data,
      id: ProductId,
      WhatSupportingYouWouldGiveToBuyer: checkBoxes,
      calender: dateArr,
      ProductUploadStatus: "golive",
      // ListingType: "Media",
      BXISpace: BXISpace,
    };
    if (
      dateArr.length === 0 ||
      (checkBoxes.ExhibitionCertificate === false &&
        checkBoxes.LogReport === false &&
        checkBoxes.Other === false &&
        checkBoxes.Pictures === false &&
        checkBoxes.Videos === false &&
        checkBoxes.inspectionPass === false)
    ) {
      return toast.error("Please Select add all mandatory field", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (Number(Totaldays) >= Number(MaxDaysTobeadded)) {
      updateProduct(datatobesent, {
        onSuccess: (response) => {
          if (response.status === 200) {
            if (response?.data?.ProductCategoryName === "MediaOnline") {
              navigate(`/home/mediaonline/mediaonlinegolive/${ProductId}`);
            } else {
              navigate(`/home/mediaoffline/mediaofflinegolive/${ProductId}`);
            }
          }
        },
        onError: (error) => {},
      });
    } else {
      setError("dates", {
        type: "manual",
        message: `Please Select Dates According to your timeline you need to add ${MaxDaysTobeadded} or more days and you have added ${Totaldays} days`,
      });
    }
  });
  const options = { day: "2-digit", month: "short", year: "numeric" };

  return (
    <>
      <form onSubmit={updateProductTechinfostatus}>
        <Box
          sx={{
            width: "100%",
            maxWidth: "716px",
            mx: "auto",
            height: "100%",
          }}
        >
          <Box>
            <Box
              sx={{
                px: "30px",
                height: "auto",
                maxHeight: "100%",
                background: "#EEF1F6",
                overflow: "hidden",
                boxShadow: "0px 10px 20px rgba(220, 220, 220, 0.5)",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#EEF1F6",
                  width: "100%",
                  mx: "auto",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                  py: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: {
                      xs: "18px",
                      sm: "16px",
                      md: "16px",
                      lg: "14px",
                      xl: "14px",
                    },
                    color: "#6B7A99",
                  }}
                >
                  Technical Information
                </Typography>
                <ToolTip
                  info={
                    "Technical Information refers to specific details and specifications about a product's technical aspects, packaging Material, packing size, Dimensions, logistic or go live information for your offered product, This is Critical Information from Logistic & Buying Perspective for Making Informed Decisions"
                  }
                />
              </Box>

              <Box
                sx={{
                  width: "100%",
                  mt: 2,
                  height: "100%",
                  maxHeight: "100%",
                  overflowY: "hidden",
                }}
              >
                <Stack
                  sx={{
                    overflow: "auto",
                    "::-webkit-scrollbar": {
                      display: "flex",
                    },
                    "::-webkit-scrollbar-thumb": {
                      dynamic: "#8d8e90",
                      minHeight: "10px",
                      borderRadius: "8px",
                    },
                    "::-webkit-scrollbar-thumb:vertical": {
                      maxHeight: "30px",
                    },
                    maxHeight: "100%",
                    height: "100%",
                    p: 1,
                  }}
                >
                  <Box
                    onChange={(e) => {
                      setCheckBoxes(e?.target?.checked);
                    }}
                    sx={{ display: "grid", gap: "5px", py: "5px" }}
                  >
                    <Typography sx={{ ...CommonTextStyle }}>
                      What supporting document would you give to the Buyer?{" "}
                      <span style={{ color: "red" }}> *</span>
                    </Typography>
                    <Grid container>
                      <Grid
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                        }}
                      >
                        {checkBoxes.inspectionPass === "on" ? (
                          <Box sx={{ display: "flex", gap: "10px" }}>
                            <img
                              src={addItemCartIcon}
                              size={30}
                              onClick={() => {
                                setCheckBoxes({
                                  ...checkBoxes,
                                  inspectionPass: false,
                                });
                              }}
                              alt="Checkbox"
                            />
                            <Typography
                              sx={{ ...CommonTextStyle, color: "#445fd2" }}
                            >
                              Inspection pass
                            </Typography>
                          </Box>
                        ) : (
                          <Box sx={{ display: "flex", gap: "10px" }}>
                            <img
                              alt="checkBox"
                              src={defaultIcon}
                              onClick={() => {
                                setCheckBoxes({
                                  ...checkBoxes,
                                  inspectionPass: "on",
                                });
                              }}
                            />
                            <Typography sx={{ ...CommonTextStyle }}>
                              Inspection pass
                            </Typography>
                          </Box>
                        )}

                        {checkBoxes.LogReport === "on" ? (
                          <Box sx={{ display: "flex", gap: "10px" }}>
                            <img
                              src={addItemCartIcon}
                              size={30}
                              onClick={() => {
                                setCheckBoxes({
                                  ...checkBoxes,
                                  LogReport: false,
                                });
                              }}
                              alt="Checkbox"
                            />
                            <Typography
                              sx={{ ...CommonTextStyle, color: "#445fd2" }}
                            >
                              Log Report
                            </Typography>
                          </Box>
                        ) : (
                          <Box sx={{ display: "flex", gap: "10px" }}>
                            <img
                              alt="checkBox"
                              src={defaultIcon}
                              onClick={() => {
                                setCheckBoxes({
                                  ...checkBoxes,
                                  LogReport: "on",
                                });
                              }}
                            />
                            <Typography sx={{ ...CommonTextStyle }}>
                              Log Report
                            </Typography>
                          </Box>
                        )}
                        {checkBoxes.Videos === "on" ? (
                          <Box sx={{ display: "flex", gap: "10px" }}>
                            <img
                              src={addItemCartIcon}
                              size={30}
                              onClick={() => {
                                setCheckBoxes({
                                  ...checkBoxes,
                                  Videos: false,
                                });
                              }}
                              alt="Checkbox"
                            />
                            <Typography
                              sx={{ ...CommonTextStyle, color: "#445fd2" }}
                            >
                              Videos
                            </Typography>
                          </Box>
                        ) : (
                          <Box sx={{ display: "flex", gap: "10px" }}>
                            <img
                              alt="checkBox"
                              src={defaultIcon}
                              onClick={() => {
                                setCheckBoxes({
                                  ...checkBoxes,
                                  Videos: "on",
                                });
                              }}
                            />
                            <Typography sx={{ ...CommonTextStyle }}>
                              Videos
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                      <Grid
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                        }}
                      >
                        {checkBoxes.Pictures === "on" ? (
                          <Box sx={{ display: "flex", gap: "10px" }}>
                            <img
                              src={addItemCartIcon}
                              size={30}
                              onClick={() => {
                                setCheckBoxes({
                                  ...checkBoxes,
                                  Pictures: false,
                                });
                              }}
                              alt="Checkbox"
                            />
                            <Typography
                              sx={{ ...CommonTextStyle, color: "#445fd2" }}
                            >
                              Pictures
                            </Typography>
                          </Box>
                        ) : (
                          <Box sx={{ display: "flex", gap: "10px" }}>
                            <img
                              alt="checkBox"
                              src={defaultIcon}
                              onClick={() => {
                                setCheckBoxes({
                                  ...checkBoxes,
                                  Pictures: "on",
                                });
                              }}
                            />
                            <Typography sx={{ ...CommonTextStyle }}>
                              Pictures
                            </Typography>
                          </Box>
                        )}
                        {checkBoxes.ExhibitionCertificate === "on" ? (
                          <Box sx={{ display: "flex", gap: "10px" }}>
                            <img
                              src={addItemCartIcon}
                              size={30}
                              onClick={() => {
                                setCheckBoxes({
                                  ...checkBoxes,
                                  ExhibitionCertificate: false,
                                });
                              }}
                              alt="Checkbox"
                            />
                            <Typography
                              sx={{ ...CommonTextStyle, color: "#445fd2" }}
                            >
                              Exhibition Certificate
                            </Typography>
                          </Box>
                        ) : (
                          <Box sx={{ display: "flex", gap: "10px" }}>
                            <img
                              alt="checkBox"
                              src={defaultIcon}
                              onClick={() => {
                                setCheckBoxes({
                                  ...checkBoxes,
                                  ExhibitionCertificate: "on",
                                });
                              }}
                            />
                            <Typography sx={{ ...CommonTextStyle }}>
                              Exhibition Certificate
                            </Typography>
                          </Box>
                        )}
                        {checkBoxes.Other === "on" ? (
                          <Box sx={{ display: "flex", gap: "10px" }}>
                            <img
                              src={addItemCartIcon}
                              size={30}
                              onClick={() => {
                                setCheckBoxes({
                                  ...checkBoxes,
                                  Other: false,
                                });
                              }}
                              alt="Checkbox"
                            />
                            <Typography
                              sx={{ ...CommonTextStyle, color: "#445fd2" }}
                            >
                              Other
                            </Typography>
                          </Box>
                        ) : (
                          <Box sx={{ display: "flex", gap: "10px" }}>
                            <img
                              alt="checkBox"
                              src={defaultIcon}
                              onClick={() => {
                                setCheckBoxes({
                                  ...checkBoxes,
                                  Other: "on",
                                });
                              }}
                            />
                            <Typography sx={{ ...CommonTextStyle }}>
                              Other
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Box>

                  <Box sx={{ display: "grid", gap: "5px", py: "5px" }}>
                    <Typography sx={{ ...CommonTextStyle }}>
                      Dimensions of Ad / Content Needed{" "}
                      <span style={{ color: "red" }}> *</span>
                    </Typography>

                    <TextField
                      focused
                      multiline
                      variant="standard"
                      placeholder="Eg. 30 Sec"
                      {...register("Dimensions")}
                      sx={{
                        ...lablechange,
                        background: "#fff",
                        borderRadius: "10px",
                        height: "47px",
                        border: errors["Dimensions"]?.message
                          ? "1px solid red"
                          : null,
                      }}
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <Typography
                            variant="body1"
                            style={{ fontFamily: "Poppins", color: "#C64091" }}
                          ></Typography>
                        ),
                        style: {
                          fontFamily: "Poppins",
                          color: "#6B7A99",
                          fontSize: "12px",
                        },
                      }}
                    />
                  </Box>
                  <Typography sx={ErrorStyle}>
                    {errors["Dimensions"]?.message}
                  </Typography>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={content}
                    defaultValue={
                      storeUploadLink ? "uploadLinkSet" : "checkbox"
                    }
                    onChange={ContentChange}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",

                          gap: "5px",
                          color: "#6B7A99",
                        }}
                      >
                        <Typography sx={{ fontSize: "12px" }}>
                          Upload Link
                        </Typography>
                        <FormControlLabel
                          value="uploadLinkSet"
                          control={
                            <Radio
                              defaultChecked={storeUploadLink ? true : false}
                            />
                          }
                        />
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          fontSize: "15px",
                          gap: "5px",
                          color: "#6B7A99",
                        }}
                      >
                        <Typography sx={{ fontSize: "12px" }}>
                          Click here to use BXI Space
                        </Typography>
                        <FormControlLabel
                          value="checkbox"
                          control={<Radio />}
                        />
                      </Box>
                    </Box>
                  </RadioGroup>
                  {content !== "checkbox" ? (
                    <>
                      <Box sx={{ display: "grid", gap: "5px", py: "5px" }}>
                        <Typography sx={{ ...CommonTextStyle }}>
                          Content Upload Link ( Share a link where buyer can
                          drop a content ){" "}
                          <span style={{ color: "red" }}> *</span>
                        </Typography>

                        <TextField
                          focused
                          multiline
                          variant="standard"
                          placeholder="Uploaded content has to go to seller with PO & Confirmation"
                          {...register("UploadLink")}
                          sx={{
                            ...lablechange,
                            background: "#fff",
                            borderRadius: "10px",
                            height: "47px",
                            border: errors["UploadLink"]?.message
                              ? "1px solid red"
                              : null,
                          }}
                          InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                              <Typography
                                variant="body1"
                                style={{
                                  fontFamily: "Poppins",
                                  color: "#C64091",
                                }}
                              ></Typography>
                            ),
                            style: {
                              fontFamily: "Poppins",
                              color: " #6B7A99",
                              fontSize: "12px",
                            },
                          }}
                        />
                      </Box>
                      <Typography sx={ErrorStyle}>
                        {errors["UploadLink"]?.message}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Box sx={{ display: "flex", gap: "10px", mt: 2 }}>
                        <Checkbox
                          {...label}
                          {...register("BXISpace")}
                          checked={BXISpace === true ? true : false}
                          onChange={(e) => setBXISpace(e.target.checked)}
                        />
                        <Typography sx={CommonTextStyle}>
                          Click here to use BXI Space from you can download ,
                          though BXI does not take responsibility for the
                          content{" "}
                        </Typography>
                      </Box>
                      <Typography sx={ErrorStyle}>
                        {errors["UploadLink"]?.message}
                      </Typography>
                    </>
                  )}
                  <Box sx={{ display: "grid", gap: "5px", py: "5px", mt: 2 }}>
                    <Typography sx={{ ...CommonTextStyle }}>
                      Availability Calendar ( Select availability of Media){" "}
                      <span style={{ color: "red" }}> *</span>
                    </Typography>
                    <Box
                      variant="outlined"
                      onClick={handleClickOpen}
                      sx={{
                        ...lablechange,
                        background: "#fff",
                        border: "none",
                        borderRadius: "10px",
                        height: "47px",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <CalendarMonthIcon
                        sx={{
                          height: "30px",
                          width: "30px",
                          marginRight: "10px",
                        }}
                      />
                    </Box>
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      fullWidth
                      maxWidth="lg"
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      {fetchproductData?.mediaVariation?.Timeline === "Day" ||
                      fetchproductData?.mediaVariation?.Timeline ===
                        "One Time" ? (
                        <DateRangePicker
                          minDate={new Date()}
                          disablePast={true}
                          calender={1}
                          open={true}
                          definedRanges={[]}
                          toggle={toggle}
                          onChange={(range) => {
                            let isRangeValid = true;
                            for (const existingRange of dateArr) {
                              const { startDate, endDate } = existingRange;
                              if (
                                (range.startDate >= startDate &&
                                  range.startDate <= endDate) ||
                                (range.endDate >= startDate &&
                                  range.endDate <= endDate)
                              ) {
                                isRangeValid = false;
                                break;
                              }
                            }
                            let DayDateArr = getDates(
                              range.startDate,
                              range.endDate
                            );

                            if (isRangeValid) {
                              setDateArr([...dateArr, ...DayDateArr]);
                            } else {
                              alert(
                                "Dates Already Selected, Please Select Again"
                              );
                            }
                          }}
                        />
                      ) : (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Typography
                            sx={{
                              ...tableHeader,
                              fontWeight: 600,
                              fontSize: "18px",
                              color: "#156DB6",
                            }}
                          >
                            Select Week :
                          </Typography>
                          <DateCalendar
                            sx={{
                              "& .MuiPickersDay-day": {
                                color: "red",
                              },
                              "& .MuiPickersDay-daySelected": {
                                color: "green",
                              },
                              "& .MuiPickersDay-dayDisabled": {
                                color: "pink",
                              },
                              width: "90%",
                            }}
                            disablePast={true}
                            value={value}
                            // boughtDate
                            slots={{ day: Day }}
                            slotProps={{
                              day: {
                                selectedDay: value,
                                TimelineData:
                                  fetchproductData?.mediaVariation?.Timeline,
                              },
                            }}
                            onChange={(newValue) => {
                              if (
                                fetchproductData?.ProductSubCategory ===
                                  "643cda0c53068696706e3951" &&
                                newValue.day() !== 5
                              ) {
                                toast.error(
                                  "Only Fridays are allowed for selection.",
                                  {
                                    position: "top-center",
                                    autoClose: 2000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                  }
                                );
                                return;
                              }
                              setValueState(newValue);
                              let enddate = newValue.add(
                                ReturnDaysFromTimeline(
                                  fetchproductData?.mediaVariation?.Timeline
                                ),
                                "day"
                              );
                              const DateExists = checkIfDateExists(
                                dateArr,
                                newValue,
                                enddate
                              );
                              if (DateExists) {
                                return toast.error(`Date Already Selected`, {
                                  position: "top-center",
                                  autoClose: 2000,
                                  hideProgressBar: true,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  theme: "light",
                                });
                              } else {
                                setDateArr([
                                  ...dateArr,
                                  {
                                    startDate: new Date(newValue),
                                    endDate: new Date(enddate),
                                    minOrderQuantityunit: Number(
                                      fetchproductData?.mediaVariation
                                        ?.minOrderQuantityunit
                                    ),
                                    maxOrderQuantityunit: Number(
                                      fetchproductData?.mediaVariation
                                        ?.maxOrderQuantityunit
                                    ),
                                    minTimeslotSeconds: Number(
                                      fetchproductData?.mediaVariation
                                        ?.minTimeslotSeconds
                                    ),
                                    maxTimeslotSeconds: Number(
                                      fetchproductData?.mediaVariation
                                        ?.maxTimeslotSeconds
                                    ),
                                  },
                                ]);
                              }
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    </Dialog>
                  </Box>
                  <Typography sx={ErrorStyle}>
                    {errors["dates"]?.message}
                  </Typography>

                  <Box>
                    {(dateArr?.length > 0 &&
                      fetchproductData?.mediaVariation?.Timeline === "Day") ||
                    fetchproductData?.mediaVariation?.Timeline ===
                      "One Time" ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "20px",
                        }}
                      >
                        {dateArr?.map((item, idx) => {
                          return (
                            <Box
                              key={idx}
                              sx={{
                                justifyContent: "space-between",
                                display: "flex",
                                mt: "30px",
                                width: "150px",
                                gap: "20px",
                                border: "1px solid #E3E3E3",
                                borderRadius: "10px",
                                padding: "0px 20px",
                              }}
                            >
                              <Typography
                                id="standard-basic"
                                variant="standard"
                                InputProps={{
                                  disableUnderline: "true",
                                  style: {
                                    color: "rgba(107, 122, 153)",
                                    fontSize: "14px",
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: "red",
                                  },
                                }}
                                sx={{
                                  width: "100%",
                                  background: "transparent",
                                  padding: "10px",
                                  fontFamily: "Poppins",
                                }}
                              >
                                {" "}
                                Date :
                                {new Date(item?.startDate).toLocaleDateString(
                                  "en-US",
                                  options
                                )}
                              </Typography>

                              <Box
                                component="img"
                                src={RemoveIcon}
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  let temp = dateArr.filter(
                                    (items) => items !== item
                                  );
                                  setDateArr(temp);
                                }}
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    ) : (
                      dateArr?.map((item, idx) => {
                        return (
                          <Box
                            key={idx}
                            sx={{
                              justifyContent: "space-between",
                              display: "flex",
                              mt: "30px",
                              width: "auto",
                              gap: "20px",
                              border: "1px solid #E3E3E3",
                              borderRadius: "10px",
                              padding: "0px 30px",
                            }}
                          >
                            <Typography
                              id="standard-basic"
                              variant="standard"
                              InputProps={{
                                disableUnderline: "true",
                                style: {
                                  color: "rgba(107, 122, 153)",
                                  fontSize: "14px",
                                  padding: "7px",
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: "red",
                                },
                              }}
                              sx={{
                                width: "100%",
                                background: "transparent",
                                padding: "10px",
                                fontFamily: "Poppins",
                              }}
                            >
                              {" "}
                              From :
                              {new Date(item?.startDate).toLocaleDateString(
                                "en-US",
                                options
                              )}
                            </Typography>
                            <Typography
                              id="standard-basic"
                              variant="standard"
                              InputProps={{
                                disableUnderline: "true",
                                style: {
                                  color: "rgba(107, 122, 153)",
                                  fontSize: "14px",
                                  padding: "7px",
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: "red",
                                },
                              }}
                              sx={{
                                width: "100%",
                                background: "transparent",
                                padding: "10px",
                                fontFamily: "Poppins",
                              }}
                            >
                              To :{" "}
                              {new Date(item?.endDate).toLocaleDateString(
                                "en-US",
                                options
                              )}
                            </Typography>
                            <Box
                              component="img"
                              src={RemoveIcon}
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                let temp = dateArr.filter(
                                  (items) => items !== item
                                );
                                setDateArr(temp);
                              }}
                            />
                          </Box>
                        );
                      })
                    )}
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              mx: "auto",
              height: "100%",
              bgcolor: "transparent",
            }}
          >
            <BottomNavigation
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                bgcolor: "#EEF1F6",
                p: "10px",
                boxShadow: "0px 10px 20px rgba(220, 220, 220, 0.5)",
              }}
              showLabels
            >
              <Box sx={{ display: "flex", gap: "10px", p: 1, width: "50%" }}>
                <Button
                  sx={{
                    width: "100%",
                    height: "32px",
                    borderRadius: "10px",
                    background: "#fff",
                    color: "#636161",
                    fontSize: "14px",
                    textTransform: "none",
                    "&:hover": {
                      background: "#EEF1F6",
                      color: "#000",
                    },
                  }}
                  variant="contained"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  sx={{
                    width: "100%",
                    height: "32px",
                    borderRadius: "10px",
                    background: "#C64091",
                    fontSize: "14px",
                    textTransform: "none",
                    "&:hover": {
                      background: "#C64091",
                    },
                  }}
                  variant="contained"
                >
                  Next
                </Button>
              </Box>
            </BottomNavigation>
          </Box>
        </Box>
      </form>
    </>
  );
}

const CommonTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "21px",
  color: "#6B7A99",
};

const lablechange = {
  fontFamily: "Poppins",
  color: "#6B7A99",
  fontSize: "16px",
  display: "grid",
  textAlign: "left",
  fontWeight: "bold",
  paddingLeft: "10px",
  // borderBottom: "1px solid #E8E8E8",
  "&:focus": {
    border: "1px solid #E8E8E8",
  },
};

const ErrorStyle = {
  color: "red",
};
const tableHeader = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "1.5rem",
    lg: "1.5rem",
    md: "1.5rem",
    sm: "1.4rem",
    xs: "1rem",
  },
  color: "#6B7A99",
  textAlign: {
    x: "start",
    lg: "start",
    md: "start",
    sm: "start",
    xs: "center",
  },
};
