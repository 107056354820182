import { Box, Button, Grid, Paper, Skeleton, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import FilterIconImg from "../../assets/FilterIcons/FilterIcon.png";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import PreferenceModal from "../../components/Modals/PreferenceModal";
import CheckIcon from "@mui/icons-material/Check";
import { Autocomplete, TextField } from "@mui/material";
import Slider from "@mui/material/Slider";
import BXIToken from "../../assets/BXITokenIcon.png";
import CloseIcon from "../../assets/Images/FilterIcons/Close.png";
import SortIcon from "../../assets/Images/FilterIcons/Sort.png";
import AndhraPradeshStateIcon from "../../assets/Images/States/Andhra.png";
import ArunachalPradeshIcon from "../../assets/Images/States/ArunachalPradesh.png";
import AssamIcon from "../../assets/Images/States/Assam.png";
import BiharStateIcon from "../../assets/Images/States/Bihar.png";
import ChhattisgarhStateIcon from "../../assets/Images/States/Chhattisgarh.png";
import GoaIcon from "../../assets/Images/States/Goa.png";
import GujaratStateIcon from "../../assets/Images/States/Gujarat.png";
import HimachalPradeshIcon from "../../assets/Images/States/HP.png";
import HaryanaIcon from "../../assets/Images/States/Haryana.png";
import JammuKashmirIcon from "../../assets/Images/States/J&K.png";
import JharkhandStateIcon from "../../assets/Images/States/Jharkhand.png";
import KarnatakaStateIcon from "../../assets/Images/States/Karnataka.png";
import KeralaStateIcon from "../../assets/Images/States/Kerala.png";
import MadhyaPradeshStateIcon from "../../assets/Images/States/MP.png";
import MaharastraStateIcon from "../../assets/Images/States/Maharashtra.png";
import ManipurIcon from "../../assets/Images/States/Manipur.png";
import MeghalayaIcon from "../../assets/Images/States/Meghalaya.png";
import MizoramIcon from "../../assets/Images/States/Mizoram.png";
import NagalandIcon from "../../assets/Images/States/Nagaland.png";
import OdishaStateIcon from "../../assets/Images/States/Odisha.png";
import PunjabIcon from "../../assets/Images/States/Punjab.png";
import RajasthanStateIcon from "../../assets/Images/States/Rajasthan.png";
import SikkimIcon from "../../assets/Images/States/Sikkim.png";
import TamilNaduStateIcon from "../../assets/Images/States/TamilNadu.png";
import TripuraIcon from "../../assets/Images/States/Tripura.png";
import UttarPradeshStateIcon from "../../assets/Images/States/UP.png";
import UttarakhandIcon from "../../assets/Images/States/Uttrakhand.png";
import WestBengalStateIcon from "../../assets/Images/States/WestBengal.png";
import TelanganaStateIcon from "../../assets/Images/States/telanganaStateIcon.png";

import { useDispatch, useSelector } from "react-redux";
import ProductDetailsCarousel from "./ProductDetailsCarousel";
import { getProductExplore } from "../../redux/action/Products/products";

import { useNavigate } from "react-router-dom";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import axios from "axios";
import useGetLoggedInUser from "../../Hooks/LoggedInUser/useGetLoggedInUser";
import { getCompanyDetails } from "../../redux/action/CompanyActions";
import sendEvents from "../../utils/sendEvents";
import ExploreImagePoster from "../../Component/MarketPlaceExplore/ExploreImagePoster";

import ProductIcon2 from "../../assets/Images/MarketplaceImages/ProductIcon2.svg";
import SortIcon2 from "../../assets/Images/MarketplaceImages/SortIcon2.svg";
import VoucherIcon2 from "../../assets/Images/MarketplaceImages/VoucherIcon2.svg";
import CommaSeparator from "../../components/CommaSeprator";
const StateObjectArray = [
  { Img: MaharastraStateIcon, text: "Maharashtra" },
  { Img: RajasthanStateIcon, text: "Rajasthan" },
  { Img: GujaratStateIcon, text: "Gujarat" },
  { Img: MadhyaPradeshStateIcon, text: "Madhya Pradesh" },
  { Img: KarnatakaStateIcon, text: "Karnataka" },
  { Img: UttarPradeshStateIcon, text: "Uttar Pradesh" },
  { Img: TamilNaduStateIcon, text: "Tamil Nadu" },
  { Img: AndhraPradeshStateIcon, text: "Andhra Pradesh" },
  { Img: TelanganaStateIcon, text: "Telangana" },
  { Img: KeralaStateIcon, text: "Kerala" },
  { Img: WestBengalStateIcon, text: "West Bengal" },
  { Img: OdishaStateIcon, text: "Odisha" },
  { Img: JharkhandStateIcon, text: "Jharkhand" },
  { Img: BiharStateIcon, text: "Bihar" },
  { Img: ChhattisgarhStateIcon, text: "Chhattisgarh" },
  { Img: ArunachalPradeshIcon, text: "Arunachal Pradesh" },
  { Img: AssamIcon, text: "Assam" },
  { Img: ManipurIcon, text: "Manipur" },
  { Img: MeghalayaIcon, text: "Meghalaya" },
  { Img: MizoramIcon, text: "Mizoram" },
  { Img: NagalandIcon, text: "Nagaland" },
  { Img: SikkimIcon, text: "Sikkim " },
  { Img: TripuraIcon, text: "Tripura" },
  { Img: HaryanaIcon, text: "Haryana" },
  { Img: PunjabIcon, text: "Punjab" },
  { Img: JammuKashmirIcon, text: "Jammu & Kashmir" },
  { Img: HimachalPradeshIcon, text: "Himachal Pradesh" },
  { Img: UttarakhandIcon, text: "Uttarakhand" },
  { Img: GoaIcon, text: "Goa" },
];

export let States = null;
export let PriceRange = null;
export let Product_Qty = null;
export let Clear = null;
export let Refresh = null;
export let PriceShortHightToLow = null;
export let PriceShortLowToHight = null;
export let WhatsNew = null;
export let Popularity = null;
export let Voucher = null;
export let ProductsFilter = null;
export let IsBestSeller = null;
export let IsReadyStock = null;

const MarketPlaceExplore = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [storeFilter, setStoreFilter] = useState();
  const [sortByView, setSortByView] = useState();
  const [ProductLocationState, setstatefilter] = useState([]);
  const [pricerange, setPriceRange] = useState("");
  const [ProductRating, setStoreRating] = useState([]);
  const [ProductQty, setProductQty] = useState("");
  const [ProductDeliveryType, setStoreDeliveryType] = useState();
  const [storeAllFilterData, setStoreAllFilterData] = useState();
  const [clear, setClear] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [priceShortHightToLow, setPriceShortHightToLow] = useState("");
  const [priceShortLowToHight, setPriceShortLowToHight] = useState("");
  const [whatsNew, setWhatsNew] = useState("");
  const [isBestSeller, setIsBestSeller] = useState("");
  const [isReadyStock, setIsReadyStock] = useState("");
  const [popularity, setPopularity] = useState(false);
  const [voucher, setVoucher] = useState(false);
  const [productsFilter, setProductsFilter] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [sortIconChange, setSortIconChange] = useState(false);
  const [voucherIconChange, setVoucherIconChange] = useState(false);
  const [productIconChange, setProductIconChange] = useState(false);
  const [whishlistIconChange, setWhishlistIconChange] = useState(false);
  const [Activeexpanded, setActiveexpanded] = useState(false);
  const [allProductSearch, setAllProductSearch] = useState([]);

  const storeAllFilterDataFun = async () => {
    setStoreAllFilterData({
      sortByView: sortByView,
      ProductLocationState: ProductLocationState,
      pricerange: pricerange,
      ProductRating: ProductRating,
      ProductQty: ProductQty,
      ProductDeliveryType: ProductDeliveryType,
    });
    handleClose();
  };
  const { productExplore, loading } = useSelector(
    (state) => state.productExplore
  );

  window.onload = () => {
    setRefresh(true);
  };

  States = ProductLocationState;
  PriceRange = pricerange;
  Product_Qty = ProductQty;
  Clear = clear;
  Refresh = refresh;
  PriceShortHightToLow = priceShortHightToLow;
  PriceShortLowToHight = priceShortLowToHight;
  WhatsNew = whatsNew;
  Popularity = popularity;
  Voucher = voucher;
  ProductsFilter = productsFilter;
  IsBestSeller = isBestSeller;
  IsReadyStock = isReadyStock;

  const filterByCompany = async () => {
    await axios
      .get(`product/get_allproducts_bycompany`, { withCredentials: true })
      .then((res) => {
        setAllProductSearch(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    filterByCompany();
  }, []);

  const loggedInUserData = "";
  const CompanyDetails = "";
  useEffect(() => {
    dispatch(
      getProductExplore(
        "",
        pricerange,
        sortByView,
        ProductQty,
        clear,
        CompanyDetails,
        refresh,
        loggedInUserData,
        priceShortHightToLow,
        priceShortLowToHight,
        whatsNew,
        selectedCompany,
        "",
        popularity,
        voucher,
        "",
        productsFilter,
        isBestSeller,
        isReadyStock
      )
    );
  }, [
    dispatch,
    pricerange,
    sortByView,
    ProductQty,
    storeFilter,
    clear,
    CompanyDetails,
    refresh,
    priceShortHightToLow,
    priceShortLowToHight,
    whatsNew,
    selectedCompany,
    ProductLocationState,
    popularity,
    voucher,
    productsFilter,
    isBestSeller,
    isReadyStock,
  ]);

  useEffect(() => {
    dispatch(getCompanyDetails());
  }, [dispatch]);

  const handleSearch = (event, newValue) => {
    setSelectedCompany(newValue);
    const uniqueCompanyNames = productExplore?.products
      ?.map((option) => option?.SellerCompanyId?.companyName || "")
      .filter(Boolean);
    const filteredResults = uniqueCompanyNames.filter((name) =>
      name.toLowerCase().includes(newValue?.toLowerCase())
    );
  };
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
  };

  return (
    <Paper
      sx={{
        width: "92%",
        mx: "auto",
        background: "transparent",
        borderRadius: "5px",
        py: 1,
      }}
      elevation={0}
    >
      {/* <ToastComp /> */}
      <Grid container>
        <Grid
          item
          xl={11.5}
          lg={11.5}
          md={11}
          sm={10.5}
          xs={10}
          sx={{
            bgcolor: "transparent",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <ProductDetailsCarousel CategoryName={props.CategoryName} />
        </Grid>
        <Grid
          xl={0.5}
          lg={0.5}
          md={1}
          sm={1.5}
          xs={2}
          sx={{ display: "flex", justifyContent: "right" }}
        >
          <Button
            onClick={() => {
              handleOpen();
              sendEvents("Click on filter");
            }}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            sx={{
              float: "right",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <Box
              sx={{
                background: "#ffffff",
                transition: "box-shadow .3s",
                width: "30px",
                px: "10px",
                height: "80px",
                borderRadius: "20px",
                display: "grid",
                justifyContent: "space-evenly",
                alignItems: "center",
                alignContent: "center",
                float: "right",
                ml: "auto",
                mr: -1,
                "&:hover": {
                  boxShadow: "0 0 11px rgba(33,33,33,.2)",
                },
              }}
            >
              <img
                src={FilterIconImg}
                alt="filter"
                width="26px"
                height="auto"
                style={{ marginLeft: "auto", marginRight: "auto" }}
              />
            </Box>
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={FilterModalStyle}>
              <Paper
                sx={{
                  width: "80%",
                  bgcolor: "transparent",
                  mx: "auto",
                  boxShadow: "none",
                }}
                elevation={0}
              >
                <Box
                  onClick={() => {
                    sendEvents("Click on view options");
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                    mt: 2,
                    height: "70px",
                  }}
                >
                  <Typography sx={{ ...FilterTitle, cursor: "default" }}>
                    View Options
                  </Typography>
                  <button
                    style={{
                      width: "20px",
                      background: "none",
                      border: "none",
                      // cursor: "pointer",
                    }}
                    disableRipple
                    onClick={() => {
                      setOpen(false);
                      sendEvents("Click on close");
                    }}
                  >
                    <Box
                      component="img"
                      src={CloseIcon}
                      width={"23px"}
                      alt="img"
                      sx={{
                        cursor: "pointer",
                      }}
                    />
                  </button>
                </Box>

                <Accordion
                  elevation={0}
                  sx={{
                    WebkitBoxShadow: "none",
                    MozBoxShadow: "none",
                    boxShadow: "none",
                    // minHeight: "49px",
                    borderStyle: "hidden",
                    background: "border-box",
                    "&::before": {
                      display: "none",
                    },
                  }}
                  onChange={(e, expanded) => {
                    if (expanded) {
                      setActiveexpanded(true);
                    } else {
                      setActiveexpanded(false);
                    }
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => sendEvents("Click on sort")}
                    sx={{
                      border: "none",
                      borderStyle: "hidden",
                      background: "border-box",
                      backgroundColor: Activeexpanded ? "#E0F0FF" : null,
                      borderRadius: "10px",
                    }}
                    onMouseOver={() => {
                      setSortIconChange(true);
                    }}
                    onMouseOut={() => {
                      setSortIconChange(false);
                    }}
                  >
                    <img
                      src={
                        sortIconChange
                          ? SortIcon2
                          : Activeexpanded
                          ? SortIcon2
                          : SortIcon
                      }
                      alt="SortIcon"
                      style={{ height: "15px", width: "15px" }}
                    />
                    <Typography
                      sx={{
                        ...FilterTextStyleOne,
                        fontWeight: 500,
                        color: sortIconChange
                          ? "#445FD2"
                          : Activeexpanded
                          ? "#445FD2"
                          : "#B2BCCF",
                      }}
                    >
                      Sort by
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      ...FilterTextStyleOne,
                      background: "#F8FAFB",
                      borderRedius: "3px",
                      // cursor: "pointer",
                      boxShadow:
                        "0px 1.91702px 5.75107px rgba(146, 140, 151, 0.25)",
                    }}
                  >
                    <Typography
                      onClick={() => {
                        setIsReadyStock("IsReadyStock");
                        sendEvents("Click on whats new");
                      }}
                      sx={{
                        ...FilterTextStyleOne,
                        cursor: "pointer",
                        "&:hover": {
                          color: "#445FD2",
                        },
                        fontWeight: "500",
                        mt: 2,
                        width: "30%",
                      }}
                    >
                      Ready Stock
                    </Typography>
                    <Typography
                      onClick={() => {
                        setIsBestSeller("IsBestSeller");
                        sendEvents("Click on popularity");
                      }}
                      sx={{
                        ...FilterTextStyleOne,
                        cursor: "pointer",
                        "&:hover": {
                          color: "#445FD2",
                        },
                        fontWeight: "500",
                        mt: 2,
                        width: "30%",
                      }}
                    >
                      Bestseller
                    </Typography>
                    <Typography
                      onClick={() => {
                        setPriceShortHightToLow("ShortHightToLow");
                        sendEvents("Click on price high to low");
                      }}
                      sx={{
                        ...FilterTextStyleOne,
                        cursor: "pointer",
                        "&:hover": {
                          color: "#445FD2",
                        },
                        fontWeight: "500",
                        mt: 2,
                        width: "45%",
                      }}
                    >
                      Price - high to low
                    </Typography>
                    <Typography
                      onClick={() => {
                        setPriceShortLowToHight("ShortLowToHight");
                        sendEvents("Click on price low to high");
                      }}
                      sx={{
                        ...FilterTextStyleOne,
                        cursor: "pointer",
                        "&:hover": {
                          color: "#445FD2",
                        },
                        fontWeight: "500",
                        mt: 2,
                        width: "45%",
                      }}
                    >
                      Price - low to high
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Box
                  sx={{
                    width: "100%",
                    height: "40px",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    alignContent: "center",
                    background: "#FFFFFF",
                    pl: 2,
                    opacity: 0.35,
                    filter: "grayscale(70%)",
                    borderRadius: "10px",
                    "&:hover": {
                      opacity: 1,
                      filter: "grayscale(1%)",
                      opacity: 1,
                    },
                  }}
                  onClick={() => {
                    setVoucher(!voucher);
                    handleClose();
                    sendEvents("Click on voucher icon");
                  }}
                >
                  <img
                    src={VoucherIcon2}
                    alt="SortIcon"
                    onMouseOver={() => {
                      setVoucherIconChange(true);
                    }}
                    onMouseOut={() => {
                      setVoucherIconChange(false);
                    }}
                    style={{ height: "15px", width: "15px", cursor: "pointer" }}
                  />
                  <Typography
                    sx={{
                      ...FilterTextStyleOne,
                      color: "#445FD2",
                      cursor: "pointer",
                      fontWeight: 500,
                    }}
                  >
                    Voucher
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "40px",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    alignContent: "center",
                    background: "#FFFFFF",
                    pl: 2,
                    opacity: 0.35,
                    filter: "grayscale(70%)",
                    borderRadius: "10px",
                    "&:hover": {
                      opacity: 1,
                      filter: "grayscale(1%)",
                      opacity: 1,
                    },
                  }}
                  onClick={() => {
                    setProductsFilter(!productsFilter);
                    handleClose();
                    sendEvents("Click on product icon");
                  }}
                >
                  <img
                    src={ProductIcon2}
                    alt="SortIcon"
                    onMouseOver={() => {
                      setProductIconChange(true);
                    }}
                    onMouseOut={() => {
                      setProductIconChange(false);
                    }}
                    style={{ height: "15px", width: "15px", cursor: "pointer" }}
                  />
                  <Typography
                    sx={{
                      ...FilterTextStyleOne,
                      color: "#445FD2",
                      cursor: "pointer",
                      fontWeight: 500,
                    }}
                  >
                    Product
                  </Typography>
                </Box>
              </Paper>

              <Paper
                elevation={0}
                sx={{
                  width: "80%",
                  bgcolor: "transparent",
                  mx: "auto",
                  boxShadow: "none",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                    mt: 0,
                    height: "50px",
                  }}
                >
                  <Typography sx={{ ...FilterTitle, cursor: "default" }}>
                    Location
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                    height: "0px",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "110px",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    alignContent: "center",
                    maxWidth: "385px",

                    overflow: "auto",
                    "::-webkit-scrollbar": {
                      display: "flex",
                      height: "6px",
                    },
                    gap: "10px",
                    mt: 1,
                    cursor: "pointer",
                  }}
                >
                  {StateObjectArray?.map((res, idx) => {
                    return (
                      <Box
                        key={idx}
                        onClick={() => {
                          if (ProductLocationState.includes(res.text)) {
                            setstatefilter(
                              ProductLocationState.filter(
                                (item) => item !== res.text
                              )
                            );
                          } else {
                            setstatefilter([...ProductLocationState, res.text]);
                          }
                        }}
                        sx={{
                          width: "100%",
                          minWidth: "90px",
                          height: "90px",
                          display: "grid",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          borderRadius: "12px",
                          bgcolor: "rgba(243, 246, 249, 0.6)",
                          position: "relative",
                          "&:hover": {
                            border: "0.1px solid #445FD2",
                            boxShadow:
                              "inset 4px -4px 4px rgba(246, 246, 246, 0.25), inset -4px 4px 4px rgba(246, 246, 246, 0.25)",
                          },
                        }}
                      >
                        {ProductLocationState.includes(res.text) ? (
                          <CheckIcon
                            onClick={() => {
                              sendEvents("Click on check button");
                            }}
                            sx={{
                              position: "absolute",
                              marginBottom: "7rem",
                              marginLeft: "7rem",
                              fontSize: "12px",
                              padding: "4px",
                              color: "#445FD2",
                              background: "#FFFFFF",
                              border: "2px solid rgba(206, 206, 206, 0.5)",
                              boxShadow:
                                "inset 4px -4px 4px rgba(246, 246, 246, 0.25), inset -4px 4px 4px rgba(246, 246, 246, 0.25)",
                              borderRadius: 100,
                            }}
                          />
                        ) : null}
                        <Box>
                          <img
                            src={res.Img}
                            alt=""
                            width={"50px"}
                            height="auto"
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          />
                        </Box>
                        <Typography
                          sx={{
                            ...FilterTextStyleOne,
                            fontSize: "9px",
                            textAlign: "center",
                            ml: 0,
                            color: ProductLocationState.includes(res.text)
                              ? "#445FD2"
                              : "#B2BCCF",
                          }}
                        >
                          {res.text}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Paper>
              <Paper
                elevation={0}
                sx={{
                  width: "80%",
                  bgcolor: "transparent",
                  mx: "auto",
                  boxShadow: "none",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                    mt: 0,
                    height: "50px",
                  }}
                >
                  <Typography sx={FilterTitle}>Price Range</Typography>
                  {/*  <Typography sx={FilterTextStyleOne}>Show All</Typography> */}
                </Box>

                <Box
                  sx={{
                    width: "auto",
                    height: "60px",
                    alignItems: "center",
                    alignContent: "center",
                    maxWidth: "385px",
                    overflowX: "scroll",
                    gap: "10px",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                    px: 2,
                    mt: 1,
                    bgcolor: "#E0F0FF",
                    borderRadius: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      minWidth: "310px",
                      height: "1px",
                      pt: 1.5,
                      pb: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        ...FilterTitle,
                        fontSize: "12px",
                        lineHeight: "10px",
                      }}
                    >
                      Price Range
                    </Typography>
                    <Typography
                      sx={{
                        ...FilterTitle,
                        fontSize: "12px",
                        lineHeight: "10px",
                      }}
                    >
                      <Box
                        component="img"
                        sx={{ width: "12px", height: "12px", mr: "1px" }}
                        src={BXIToken}
                      />
                      {/* {pricerange} */}
                      <CommaSeparator Price={pricerange} />
                    </Typography>
                  </Box>
                  <Slider
                    value={pricerange}
                    aria-label="Small steps"
                    size="small"
                    step={100000}
                    min={0}
                    max={1000000}
                    onChange={(e) => setPriceRange(e.target.value)}
                  />
                </Box>
              </Paper>

              <Paper
                elevation={0}
                sx={{
                  width: "80%",
                  bgcolor: "transparent",
                  mx: "auto",
                  boxShadow: "none",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                    mt: 2,
                    height: "auto",
                  }}
                >
                  <Typography sx={{ ...FilterTitle, cursor: "default" }}>
                    Company Name
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "60px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                    mt: 0,
                    borderRadius: "10px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    sx={{
                      width: "100%",
                      height: "52px",
                      background: "#F8FAFB",
                      borderRadius: "10px",
                      border: "none",
                    }}
                    options={
                      Array?.from(
                        new Set(
                          allProductSearch?.map(
                            (option) => option?.SellerCompanyName || ""
                          )
                        )
                      ) || []
                    }
                    value={selectedCompany}
                    onChange={handleSearch}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Company"
                        sx={{
                          width: "100%",
                          height: "52px",
                          background: "#F8FAFB",
                          borderRadius: "10px",
                          // border: "none",
                          "& fieldset": { border: "none" },
                          color: "#445FD2",
                        }}
                      />
                    )}
                  />
                </Box>
              </Paper>

              <Paper
                elevation={0}
                sx={{
                  width: "80%",
                  bgcolor: "transparent",
                  mx: "auto",
                  boxShadow: "none",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100px",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    alignContent: "center",
                    mt: 0,
                    borderRadius: "10px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      width: "95px",
                      height: "42px",
                      background: "#445FD2",
                      borderRadius: "6px",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "13px",
                      lineHeight: "20px",
                      textAlign: "center",
                      textTransform: "none",
                      "&:hover": {
                        background: "#445FD2",
                        color: "#fff",
                      },
                    }}
                    onClick={() => {
                      storeAllFilterDataFun();
                      sendEvents("Click on confirm button");
                    }}
                  >
                    Confirm
                  </Button>

                  <Button
                    value="reset"
                    onClick={(e) => {
                      setstatefilter([]);
                      setPriceRange(0);
                      setProductQty("");
                      setSelectedCompany("");
                      setClear(e.target.value);
                      sendEvents("Click on Reset");
                    }}
                    sx={{
                      width: "95px",
                      height: "42px",
                      background: "#fff",
                      borderRadius: "6px",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "13px",
                      lineHeight: "20px",
                      textAlign: "center",
                      color: "#B5B5C3",
                      ml: 2,
                      textTransform: "none",
                      "&:hover": {
                        background: "rgb(248, 250, 251)",
                        // color: "#fff",
                      },
                    }}
                  >
                    Reset
                  </Button>
                </Box>
              </Paper>
            </Box>
          </Modal>
        </Grid>
      </Grid>
      <Box
        sx={{
          mt: 1,
        }}
      >
        <ExploreImagePoster />
      </Box>
    </Paper>
  );
};

export default MarketPlaceExplore;

const FilterModalStyle = {
  position: "absolute",
  width: "90%",
  maxWidth: { xl: "430px", lg: "430px", md: "430px", sm: "400px", xs: "350px" },
  height: "100%",
  right: "5%",
  top: "04%",
  maxHeight: "90vh",
  overflowY: "scroll",
  background: "#FFFFFF",
  borderRadius: "10px",
  "&::-webkit-scrollbar": {
    display: "none",
  },
};

const FilterTextStyleOne = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  color: "#B2BCCF",
  ml: 1.5,
};

const FilterTitle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "28px",

  color: "#2E2E2E",
};
