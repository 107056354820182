import {
  Box,
  Card,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputBase,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  BottomNavigation,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import useGetCompanyTypeData from "../../../Hooks/CompanyData/useGetCompanyTypeData";
import { useGetCompanyDetails } from "../../../Hooks/Auth";
import { usePostProductQuery } from "../../../Hooks/Products/AddProduct";
import { useEffect, useState } from "react";
import RedoIcon from "../../../assets/Images/CommonImages/RedoIcon.svg";
import { useDropzone } from "react-dropzone";
import UploadtoCloud from "../../../assets/UploadtoCloud.svg";
import RadioIcon from "@mui/icons-material/Radio";
import FlatwareIcon from "@mui/icons-material/Flatware";
import ApartmentIcon from "@mui/icons-material/Apartment";
import IcecreamIcon from "@mui/icons-material/Icecream";
import LunchDiningIcon from "@mui/icons-material/LunchDining";
import LiquorIcon from "@mui/icons-material/Liquor";
import LocalCafeIcon from "@mui/icons-material/LocalCafe";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import IronIcon from "@mui/icons-material/Iron";
import LocalPizzaIcon from "@mui/icons-material/LocalPizza";
import React, { useRef } from "react";
import * as htmlToImage from "html-to-image";
import ToolTip from "../../../components/ToolTip";
import { Stack } from "@mui/system";
// import draftToHtml from "draftjs-to-html";
import { CircularProgress } from "@mui/material";
import Template from "./Template";
import { voucherStyle } from "./EditVoucherStyle";
import { styles } from "../../../components/common/voucherTemplates/styles/commonStyles";
import EditIcon from "@mui/icons-material/Edit";
import TemplateCustomOptions from "./TemplateCustomOptions";
import EditVoucherForm from "./EditVoucherForm";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import TostMessagesTop from "../../../Component/OTPToast";
import useGetLoggedInUser from "../../../Hooks/LoggedInUser/useGetLoggedInUser";

const VoucherCard = () => {
  let id;
  id = useParams().id;
  const navigate = useNavigate();
  const myRefFront = useRef(null);
  const myRefFrontThree = useRef(null);
  const myRefBack = useRef(null);
  const myRefBackThree = useRef(null);

  const {
    data: datahere,
    isLoading: DataLoading,
    error: DataError,
    refetch: refetchData,
    isRefetching,
  } = useGetLoggedInUser();

  const { data: CompanyData } = useGetCompanyDetails();
  const { mutateAsync, isLoading } = usePostProductQuery();
  const {
    data: CompanyTypeData,
    isLoading: CompanyTypeDataLoading,
    error: CompanyTypeDataError,
  } = useGetCompanyTypeData(CompanyData?.data?.companyType);

  const classes = voucherStyle();
  const cls = styles();

  const [dataUrlFront, setDataUrlFront] = useState("");
  const [dataUrlBack, setDataUrlBack] = useState("");
  const [files, setFiles] = useState([]);
  const [cardBgColor, setCardBgColor] = useState("");
  const [activeColor, setActiveColor] = useState("");
  const [activeAction, setActiveAction] = useState("");
  const [category, setCategory] = useState("");
  const [value, setValue] = useState("Template1");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("Choose wisely");
  const [open, setOpen] = useState(false);
  const [hoveredText, setHoveredText] = useState("");
  const [productData, setProductData] = useState(null);
  const [checked, setChecked] = useState(null);
  const [invertIconChecked, setInvertIconChecked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [ListThisProductForAmount, setListThisProductForAmount] =
    useState(null);
  const [ListThisProductForUnitOfTime, setListThisProductForUnitOfTime] =
    useState("Days");
  const [hasStartedTyping, setHasStartedTyping] = useState(false);
  // Left Card

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setListThisProductForAmount(inputValue);
    setHasStartedTyping(true);
  };

  const validateInput = (value) => {
    const parsedValue = parseInt(value, 10);
    return parsedValue > 0 && parsedValue <= 365;
  };

  useEffect(() => {
    GetProductByid();
  }, []);

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const handleOpen = (event) => {
    const text = event.target.innerText;
    setHoveredText(text);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const downloadCardFront = () => {
    htmlToImage
      .toBlob(myRefFront.current)
      .then((blob) => {
        setDataUrlFront(blob);
      })
      .catch((error) => {});
  };
  const downloadCardFront2 = () => {
    htmlToImage
      .toBlob(myRefFrontThree.current)
      .then((blob) => {
        setDataUrlFront(blob);
      })
      .catch((error) => {});
  };
  const downloadCardBack = () => {
    htmlToImage
      .toBlob(myRefBack.current)
      .then((blob) => {
        setDataUrlBack(blob);
      })
      .catch((error) => {});
  };
  const downloadCardBack2 = () => {
    htmlToImage
      .toBlob(myRefBackThree.current)
      .then((blob) => {
        setDataUrlBack(blob);
      })
      .catch((error) => {});
  };

  async function GetProductByid() {
    await axios
      .get(
        `product/get_product_byId/${id}
        `,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res?.data?.ProductsVariantions?.at(0)?.validityOfVoucherValue) {
          setShowSpinner(false);
        }
        if (res?.data) {
          let response = res?.data;
          let productDetails = {
            productName: response?.ProductName,
            productSubtitle: response?.ProductSubtitle,
            validityOfVoucherUnit: "",
            validityOfVoucherValue:
              res?.data?.ProductsVariantions?.at(0)?.validityOfVoucherValue,
            pricePerUnit: "",
            redemptionType: response.redemptionType,
            inclusions: response.Inclusions,
            exclusions: response.Exclusions,
            redemptionURL: response?.Link,
            termsAndConditions: response?.TermConditions,
            voucherType:
              localStorage.getItem("digitalData") == "Offer Specific"
                ? "Offer Specific"
                : "Gift Card",
          };
          setListThisProductForAmount(
            res?.data?.ProductsVariantions?.at(0)?.validityOfVoucherValue
          );
          if (response?.ProductsVariantions?.length > 0) {
            let variations = response.ProductsVariantions[0];
            productDetails.pricePerUnit = variations.PricePerUnit;
            productDetails.validityOfVoucherUnit =
              variations?.validityOfVoucherUnit;
            productDetails.validityOfVoucherValue =
              variations?.validityOfVoucherValue;
          }
          setProductData(productDetails);
        }
      });
  }

  useEffect(() => {
    if (dataUrlFront && dataUrlBack && isSubmitted) {
      setShowSpinner(true);
      let files = [];

      let formDataLeftImage = new FormData();
      formDataLeftImage.append("file", dataUrlFront);

      axios({
        method: "post",
        url: "/file/upload",
        data: formDataLeftImage,
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
      })
        .then((res) => {
          if (res?.data?.url) {
            let imgObj = {
              id: "123",
              url: res?.data?.url,
              typeOfFile: "image",
            };
            files.push(imgObj);

            let formDataRightImage = new FormData();
            formDataRightImage.append("file", dataUrlBack);

            axios({
              method: "post",
              url: "/file/upload",
              data: formDataRightImage,
              headers: { "Content-Type": "multipart/form-data" },
              withCredentials: true,
            })
              .then((res) => {
                if (res?.data?.url) {
                  let imgObj = {
                    id: "456",
                    url: res?.data?.url,
                    typeOfFile: "image",
                  };
                  files.push(imgObj);

                  let dataTopass = {
                    ListThisProductForAmount: ListThisProductForAmount,
                    ListThisProductForUnitOfTime: ListThisProductForUnitOfTime,
                    VoucherImages: files,
                    id: id,
                  };

                  axios({
                    method: "post",
                    url: "/product/product_mutation",
                    data: dataTopass,
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                  })
                    .then((response) => {
                      if (response?.data._id) {
                        if (
                          localStorage.getItem("digitalData") ===
                          "Offer Specific"
                        ) {
                          navigate(
                            `/home/spacificvoucher/${response?.data._id}`
                          );
                        } else {
                          navigate(
                            `/home/valueandgiftvoucher/${response?.data._id}`
                          );
                        }
                      } else {
                        alert("Somethings has gone wrong");
                      }
                    })
                    .catch((err) => console.log(err));
                }
              })
              .catch((err) => console.log(err));
          }
        })
        .catch((err) => console.log(err));
    }
  }, [dataUrlFront, dataUrlBack]);

  const uploadTemplate = () => {
    setShowSpinner(true);
    setDataUrlFront("");
    setIsSubmitted(false);
    if (value == "Template1") {
      setIsSubmitted(true);
      downloadCardFront();
      downloadCardBack();
    }
    if (value == "Template3") {
      setIsSubmitted(true);
      downloadCardFront2();
      downloadCardBack2();
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          maxWidth: "100%",
          height: "100%",
          overflowY: "hidden",
        }}
      >
        <Box
          sx={{
            px: "30px",
            height: "auto",
            maxHeight: "auto",
            background: "#EEF1F6",
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#EEF1F6",
              width: "100%",
              mx: "auto",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "0",
              py: "10px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "20px",
                color: "#6B7A99",
              }}
            >
              Go Live
            </Typography>
            <ToolTip
              sx={{ ml: "10px" }}
              info={
                "Go Live is the time at which something becomes available to use and purchased by other members on the platform."
              }
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              maxHeight: "100%",
              overflowY: "auto",
              // bgcolor: "red",
            }}
          >
            <Stack
              sx={{
                overflow: "auto",
                "::-webkit-scrollbar": {
                  display: "flex",
                },
                "::-webkit-scrollbar-thumb": {
                  dynamic: "#8d8e90",
                  minHeight: "10px",
                  borderRadius: "8px",
                },
                "::-webkit-scrollbar-thumb:vertical": {
                  maxHeight: "30px",
                },
                maxHeight: "auto",
                height: "auto",
                p: 1,
                display: "flex",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <Box>
                <RadioGroup
                  name="Template"
                  onChange={handleRadioChange}
                  className={classes.templateHeader}
                >
                  <FormControlLabel
                    checked={value == "Template1" ? true : false}
                    value="Template1"
                    control={
                      <Radio
                        sx={{
                          color: "#2d8ae0",
                          "& .MuiSvgIcon-root": {
                            fontSize: 28,
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        className={classes.templateLabel}
                        sx={{ fontSize: "18px", color: "#315794" }}
                      >
                        {" "}
                        Template 1{" "}
                      </Typography>
                    }
                    sx={{ color: "#2d8ae0" }}
                  />
                  {value == "Template1" && (
                    <button
                      className={classes.templateEditIconButton}
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      <EditIcon fontSize={"large"} />
                    </button>
                  )}
                </RadioGroup>
                <Box sx={{ width: "100%" }}>
                  <Template
                    tempOne
                    cardBgColor={value == "Template1" ? cardBgColor : ""}
                    cardImage={value == "Template1" ? files[0]?.preview : null}
                    category={
                      value == "Template1" ? (category ? category : 0) : 0
                    }
                    templateId="Template1"
                    productData={productData}
                    textInverted={value == "Template1" ? checked : true}
                    iconInverted={
                      value == "Template1" ? invertIconChecked : true
                    }
                    myRefBack={myRefBack}
                    myRefFront={myRefFront}
                  />
                </Box>
              </Box>
              <Box>
                {value == "Template1" && (
                  <TemplateCustomOptions
                    updateFile={(e) => {
                      setFiles(e);
                    }}
                    updateBGColor={(e) => {
                      setCardBgColor(e);
                    }}
                    updateIcon={(e) => {
                      setCategory(e);
                    }}
                    updateTextColor={(e) => {
                      setChecked(e);
                    }}
                    updateInvertIcon={(e) => {
                      setInvertIconChecked(e);
                    }}
                  />
                )}
              </Box>
            </Stack>
            <Stack
              sx={{
                overflow: "auto",
                "::-webkit-scrollbar": {
                  display: "flex",
                },
                "::-webkit-scrollbar-thumb": {
                  dynamic: "#8d8e90",
                  minHeight: "10px",
                  borderRadius: "8px",
                },
                "::-webkit-scrollbar-thumb:vertical": {
                  maxHeight: "30px",
                },
                maxHeight: "auto",
                height: "auto",
                p: 1,
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                marginTop: "40px",
              }}
            >
              <Box>
                <RadioGroup
                  name="Template"
                  onChange={handleRadioChange}
                  className={classes.templateHeader}
                >
                  <FormControlLabel
                    checked={value == "Template3" ? true : false}
                    value="Template3"
                    control={
                      <Radio
                        sx={{
                          color: "#2d8ae0",
                          "& .MuiSvgIcon-root": {
                            fontSize: 28,
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        className={classes.templateLabel}
                        sx={{ fontSize: "18px", color: "#315794" }}
                      >
                        {" "}
                        Template 2{" "}
                      </Typography>
                    }
                    sx={{ color: "#2d8ae0" }}
                  />
                  {value == "Template3" && (
                    <button
                      className={classes.templateEditIconButton}
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      <EditIcon fontSize={"large"} />
                    </button>
                  )}
                </RadioGroup>
                <Box>
                  <Template
                    cardBgColor={value == "Template3" ? cardBgColor : ""}
                    cardImage={value == "Template3" ? files[0]?.preview : null}
                    category={
                      value == "Template3" ? (category ? category : 0) : 0
                    }
                    templateId="Template3"
                    productData={productData}
                    textInverted={value == "Template3" ? checked : true}
                    iconInverted={
                      value == "Template3" ? invertIconChecked : true
                    }
                    myRefBack={myRefBackThree}
                    myRefFront={myRefFrontThree}
                  />
                </Box>
              </Box>
              <Box>
                {value == "Template3" && (
                  <TemplateCustomOptions
                    updateFile={(e) => {
                      setFiles(e);
                    }}
                    updateBGColor={(e) => {
                      setCardBgColor(e);
                    }}
                    updateIcon={(e) => {
                      setCategory(e);
                    }}
                    updateTextColor={(e) => {
                      setChecked(e);
                    }}
                    updateInvertIcon={(e) => {
                      setInvertIconChecked(e);
                    }}
                  />
                )}
              </Box>
            </Stack>
            <Box className={cls.fieldBox} sx={{ width: "550px", ml: "10px" }}>
              <label className={cls.fieldLabel} style={{ fontSize: "16px" }}>
                List this voucher for number of days ( maximum 365 days )
                <span style={{ color: "red" }}> *</span>
              </label>
              <lable
                style={{
                  fontSize: "12px",
                  color: "#000",
                  fontWeight: "400",
                  fontFamily: "Poppins",
                }}
              >
                Note: Activation of Voucher after you sell is when the expiry
                should start
              </lable>
              <Typography
                className={cls.fieldLabel}
                sx={{
                  fontSize: "14px",
                  color: "#C64091",
                  marginBottom: "5px",
                }}
              >
                {productData?.validityOfVoucherValue
                  ? `You have already added validity of Voucher of ${productData.validityOfVoucherValue}`
                  : null}
              </Typography>
              <Box className={cls.goLiveFieldBox}>
                <TextField
                  variant="standard"
                  type="number"
                  name="ListThisProductForAmount"
                  placeholder="30"
                  InputProps={{
                    disableUnderline: "true",
                    style: {
                      fontSize: "14px",
                      padding: "10px",
                      color: "#C64091",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "red",
                    },
                  }}
                  defaultValue={productData?.validityOfVoucherValue}
                  value={
                    ListThisProductForAmount ||
                    productData?.validityOfVoucherValue
                  }
                  onChange={handleChange}
                  // onChange={(e) => { setListThisProductForAmount(e.target.value) }}
                  className={cls.goLivetextField}
                  error={
                    hasStartedTyping && !validateInput(ListThisProductForAmount)
                  }
                  helperText={
                    hasStartedTyping && !validateInput(ListThisProductForAmount)
                      ? "Please enter valid days!"
                      : ""
                  }
                />
                <Select
                  className={cls.goLiveSelectBox}
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: 0,
                      },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: 0,
                      },
                  }}
                  defaultValue={"Days"}
                  name="ListThisProductForUnitOfTime"
                  // {...register("ListPeriod")}
                  InputProps={{
                    disableUnderline: "true",
                    style: {
                      fontSize: "16px",
                      padding: "10px",
                      marginRight: "5px",
                      color: "#C64091",
                    },
                  }}
                  onChange={(e) => {
                    setListThisProductForUnitOfTime(e.target.value);
                  }}
                >
                  <MenuItem className={cls.goLiveMenuItems} value="Days">
                    Days
                  </MenuItem>
                </Select>
              </Box>
            </Box>

            <Dialog
              fullWidth
              maxWidth="md"
              open={open}
              onClose={handleClose}
              sx={{ zIndex: 100 }}
            >
              <DialogTitle style={{ background: "#EEF1F6" }}>
                Edit Content on Voucher
              </DialogTitle>
              <DialogContent style={{ background: "#EEF1F6" }}>
                <Box>
                  <EditVoucherForm
                    cardData={productData}
                    closePopup={() => {
                      setOpen(false);
                    }}
                    updateFormData={(e) => {
                      setProductData(e);
                    }}
                  />
                </Box>
              </DialogContent>
              {/* <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={null}>Save</Button>
          </DialogActions> */}
            </Dialog>
          </Box>
          <div className={cls.formNavigation}>
            <div
              className={cls.formNavigationBar}
              style={{ padding: "0 30px" }}
            >
              <button className={cls.resetLabel}>
                &nbsp;
                {/* <Box component="img" sx={{ width: "23px", height: "23px" }} src={RedoIcon} alt="" /> Reset to Default */}
              </button>
              <div
                className={cls.navigationButtonSection}
                style={{ padding: "10px" }}
              >
                <button
                  className={cls.navigationCancelButton}
                  variant="contained"
                  onClick={() => {
                    let confirm = window.confirm(
                      "Are you sure you want to cancel the product?"
                    );
                    if (confirm) {
                      navigate("/home/physical");
                    }
                  }}
                >
                  Cancel
                </button>

                <button
                  disabled={
                    !value ||
                    !ListThisProductForAmount ||
                    !validateInput(ListThisProductForAmount) ||
                    files.length == 0
                  }
                  onClick={() => uploadTemplate()}
                  type="submit"
                  className={cls.navigationSubmitButton}
                >
                  {showSpinner ? <CircularProgress size={20} /> : "Next"}
                </button>
              </div>
            </div>
          </div>
          <TostMessagesTop
            PageContent="Create your own vouchers!"
            PageCompanyName={datahere?.data?.companyName}
          />
        </Box>
        {/* <button onClick={convertRefToBlob}>Convert to Blob</button> */}
      </Box>
    </>
  );
};

export default VoucherCard;

const FirstBoxChildStyle = {
  width: "100%",
  mx: "auto",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "10px",
  py: "10px",
};

const TextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "10px",
  textTransform: "none",
  color: "#6B7A99",
};

const FirstBoxStyle = {
  boxShadow: "0px 10px 20px rgba(220, 220, 220, 0.5)",
  padding: "20px 20px",
  backgroundColor: "#f3f6f9",
};
