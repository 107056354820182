import React, { useState } from "react";
import { Box, Typography, FormControlLabel, Switch } from "@mui/material";
import { useDropzone } from "react-dropzone";
import UploadtoCloud from "../../../assets/UploadtoCloud.svg";
import LightIcon1 from "../../../assets/voucher-preview/light-icon1.svg";
import LightIcon2 from "../../../assets/voucher-preview/light-icon2.svg";
import LightIcon3 from "../../../assets/voucher-preview/light-icon3.svg";
import LightIcon4 from "../../../assets/voucher-preview/light-icon4.svg";
import LightIcon5 from "../../../assets/voucher-preview/light-icon5.svg";
import LightIcon6 from "../../../assets/voucher-preview/light-icon6.svg";
import LightIcon7 from "../../../assets/voucher-preview/light-icon7.svg";
import LightIcon8 from "../../../assets/voucher-preview/light-icon8.svg";
import LightIcon9 from "../../../assets/voucher-preview/light-icon9.svg";
import LightIcon10 from "../../../assets/voucher-preview/light-icon10.svg";
import LightIcon11 from "../../../assets/voucher-preview/light-icon11.svg";
import LightIcon12 from "../../../assets/voucher-preview/light-icon12.svg";
import LightIcon13 from "../../../assets/voucher-preview/light-icon13.svg";
import LightIcon14 from "../../../assets/voucher-preview/light-icon14.svg";
import LightIcon15 from "../../../assets/voucher-preview/light-icon15.svg";
import LightIcon16 from "../../../assets/voucher-preview/light-icon16.svg";
import LightIcon17 from "../../../assets/voucher-preview/light-icon17.svg";
import LightIcon18 from "../../../assets/voucher-preview/light-icon18.svg";
import LightIcon19 from "../../../assets/voucher-preview/light-icon19.svg";
import LightIcon20 from "../../../assets/voucher-preview/light-icon20.svg";
import LightIcon21 from "../../../assets/voucher-preview/light-icon21.svg";
import LightIcon22 from "../../../assets/voucher-preview/light-icon22.svg";
import LightIcon23 from "../../../assets/voucher-preview/light-icon23.svg";
import LightIcon24 from "../../../assets/voucher-preview/light-icon24.svg";
import LightIcon25 from "../../../assets/voucher-preview/light-icon25.svg";
import LightIcon26 from "../../../assets/voucher-preview/light-icon26.svg";

import { useEffect } from "react";

const TemplateCustomOptions = ({
  updateFile,
  updateBGColor,
  updateIcon,
  updateTextColor,
  updateInvertIcon,
}) => {
  const [files, setFiles] = useState([]);
  const [cardBgColor, setCardBgColor] = useState(null);
  const [activeColor, setActiveColor] = useState(null);
  const [activeAction, setActiveAction] = useState("");
  const [category, setCategory] = useState("");
  const [checked, handleChange] = useState(true);
  const [invertIconChecked, setInvertIconChecked] = useState(true);

  const handleClickNonColor = () => {
    setActiveColor(null);
    setCardBgColor("#ffffff");
  };

  // dropzone
  const { getRootProps, getInputProps } = useDropzone({
    accept: ".jpg, .png, .xlsx",
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const colors = [
    //LIGHT
    "#FBF2C880",
    "#F9B68F80",
    "#F68B9180",
    "#F695C780",
    "#8BB5DB80",
    "#89D6A480",
    "#C5A1CB80",
    "#B1AFB480",
    // "#0000001a",
    "#FFFDFE80", // white

    // MIDDLE
    "#F8EA94",
    "#F8A879",
    "#F4757C",
    "#F481BC",
    "#8BB5DB",
    "#71CF92",
    "#BA8FC2",
    "#A2A0A5",
    "#FFFDFE",

    // DARK
    "#806E00", // red
    "#8A3300", // orange
    "#8B0008", // yellow
    "#8C0048", // green
    "#004788", // cyan
    "#008C31", // blue
    "#76008C", // magenta
    "#37008A", // purple
    "#040002", // black
  ];

  const imageIcons = [
    LightIcon1,
    LightIcon2,
    LightIcon3,
    LightIcon4,
    LightIcon5,
    LightIcon6,
    LightIcon7,
    LightIcon8,
    LightIcon9,
    LightIcon10,
    LightIcon11,
    LightIcon12,
    LightIcon13,
    LightIcon14,
    LightIcon15,
    LightIcon16,
    LightIcon17,
    LightIcon18,
    LightIcon19,
    LightIcon20,
    LightIcon21,
    LightIcon22,
    LightIcon23,
    LightIcon24,
    LightIcon25,
    LightIcon26,
  ];

  function chunk(array, size) {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size));
    }
    return chunks;
  }

  useEffect(() => {
    updateBGColor(cardBgColor);
  }, [cardBgColor]);

  useEffect(() => {
    updateIcon(category);
  }, [category]);

  useEffect(() => {
    updateFile(files);
  }, [files]);

  useEffect(() => {
    updateTextColor(checked);
  }, [checked]);

  useEffect(() => {
    updateInvertIcon(invertIconChecked);
  }, [invertIconChecked]);

  return (
    <Box sx={{ ml: "20px" }}>
      <Box {...getRootProps({ className: "dropzone", marginTop: "10px" })}>
        <input {...getInputProps()} />
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: 400,
            color: "#6B7A99",
            fontSize: "14px",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          Upload Image
        </Typography>
        <Box
          border={"2px dashed "}
          sx={{
            padding: "3rem",
            marginTop: "1rem",
            textAlign: "center",
            "&:hover": { cursor: "pointer" },
            borderColor: "#2d8ae0",
          }}
        >
          <Box component="img" src={UploadtoCloud} sx={{}} />
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "10px",
              color: "#6B7A99",
              cursor: "pointer",
              "&:hover": {
                color: "blue",
              },
            }}
          >
            Drag & drop to upload or browse to choose a file
            <span style={{ color: "red" }}> *</span>
          </Typography>
          <Typography
            sx={{
              fontFamily: "Mulish",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "8px",
              color: "#676767",
              textAlign: "center",
            }}
          >
            Supported format : JPEG, PNG
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "12px",
              color: "#445FD2",
              textAlign: "center",
            }}
          >
            Mandatory Photos : Product related photo ( Compulsory){" "}
          </Typography>
        </Box>
      </Box>

      <Typography
        sx={{
          fontFamily: "Poppins",
          fontWeight: 400,
          color: "#6B7A99",
          fontSize: "14px",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        Choose Background color
      </Typography>

      <Box>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {chunk(colors, 9).map((rowColors, index) => (
            <Box key={index} sx={{ display: "flex" }}>
              {rowColors.map((color) => (
                <Box
                  onClick={() => {
                    setActiveColor(color);
                    setCardBgColor(color);
                  }}
                  className={activeColor === color ? "active" : ""}
                  key={color}
                  sx={{
                    width: "4rem",
                    height: "4rem",
                    backgroundColor: color,
                    borderRadius: "2px",
                    border: "1px solid lightgray",
                    margin: "0.5rem",
                    cursor: "pointer",
                    "&:hover": {
                      border: "1px solid blue",
                    },
                    "&.active": {
                      border: "1.5px solid blue",
                    },
                  }}
                />
              ))}
            </Box>
          ))}
          <Box
            onClick={handleClickNonColor}
            className={activeColor === null ? "active" : ""}
            sx={{
              width: "4rem",
              height: "4rem",
              backgroundColor: "#ffffff",
              borderRadius: "2px",
              border: "1px solid lightgray",
              margin: "0.5rem",
              cursor: "pointer",
              fontFamily: "Poppins",
              fontSize: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                border: "1px solid blue",
              },
              "&.active": {
                border: "1.5px solid blue",
              },
            }}
          >
            None
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          fontFamily: "Poppins",
          fontWeight: 400,
          color: "#6B7A99",
          fontSize: "14px",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: 400,
            color: "#6B7A99",
            fontSize: "14px",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          Choose Element
        </Typography>
        <FormControlLabel
          control={
            <Switch
              checked={invertIconChecked}
              defaultChecked
              onChange={(e) => setInvertIconChecked(!invertIconChecked)}
            />
          }
          label="Invert Icons"
        />
      </Box>

      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {imageIcons.map((icon, ind) => (
          <Box
            key={ind}
            onClick={() => {
              setActiveAction(ind);
              setCategory(ind);
            }}
            sx={{
              width: "37px",
              height: "37px",
              border: "1px solid black",
              borderRadius: "2px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0.5rem",
              cursor: "pointer",
              "&:hover": {
                border: "1px solid blue",
              },
              "&.active": {
                border: "1.5px solid blue",
              },
            }}
          >
            <img src={icon} />
          </Box>
        ))}
      </Box>
      <Box sx={{ marginBottom: "-10px" }}>
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              defaultChecked
              onChange={(e) => handleChange(!checked)}
            />
          }
          label="Invert Text"
        />
      </Box>
    </Box>
  );
};

export default TemplateCustomOptions;
