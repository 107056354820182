import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Menu,
  Paper,
  Stack,
  Button,
  MenuItem,
  TextField,
  Typography,
  Pagination,
} from "@mui/material";
import axios from "axios";
import useStyles from "./Styles";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import { Edit } from "@mui/icons-material";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";
import sendEvents from "../../utils/sendEvents.js";
import DialogTitle from "@mui/material/DialogTitle";
import BxiToken from "../../assets/BXITokanSVG.svg";
import { alpha, styled } from "@mui/material/styles";
import CloseIconDialog from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import FilterListIcon from "@mui/icons-material/FilterList";
import CommaSeparator from "../../components/CommaSeprator";
import * as Styles from "../../components/common/Styled/Styles.js";
import Nodatafound from "../../components/NoDataFound/NoDataFound";
import LeftArrow from "../../assets/Images/CommonImages/Left Arrow.png";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import DeleteIcon from "../../assets/Images/CommonImages/DeleteIcon.svg";
import useGetLoggedInUser from "../../Hooks/LoggedInUser/useGetLoggedInUser";
import { SendMessageOnWhatsApp } from "../../redux/action/WhatApp/SendMessages";
import useGetCompanyTypeData from "../../Hooks/CompanyData/useGetCompanyTypeData";
import { useGetAllSellerorderTracking } from "../../Hooks/OrderActions/useGetAllOrdersForTracking.js";
import GlobalToast from "../../components/Toasts/GlobalToast.jsx";

const ConfirmationBox = ({ message, onConfirm, onCancel }) => {
  return (
    <div style={styles.overlay}>
      <div style={styles.box}>
        <p style={styles.textStyle}>{message}</p>
        <div style={styles.buttons}>
          <button onClick={onConfirm} style={styles.confirmButton}>
            Confirm
          </button>
          <button onClick={onCancel} style={styles.cancelButton}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const StyledContainer = styled(Paper)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  width: "100%",
  backgroundColor: "transparent",
  position: "relative",
}));

const BorderBox = styled(Box)(() => ({
  border: `1px solid #415bca`,
  borderRadius: "8px",
  padding: "40px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#fff",
  textAlign: "center",
  width: "600px",
  height: "200px",
}));

const StyledButton = styled(Button)(({ variant }) => ({
  padding: "12px 35px",
  fontSize: "16px",
  fontWeight: "bold",
  borderRadius: "8px",
  textTransform: "none",
  position: "relative",
  transition: "0.3s",
  margin: "8px",
  ...(variant === "confirm" && {
    backgroundColor: "#415bca",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
      border: "1px solid #415bca",
      color: "#415bca",
    },
  }),
}));

const ConfirmationPopup = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>Are you sure you want to proceed?</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const OrderTracking = () => {
  // pagination styles
  const StyledPagination = styled(Pagination)({
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#c64091",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#c64091",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#c64091",
      fontWeight: 800,
    },
  });

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: "16px",
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [orderId, setOrderId] = useState("");
  const [open, setOpen] = useState(false);
  const [MediaId, setMediaId] = useState();
  const [update, setUpdate] = useState(false);
  const [OrderData, setOrderData] = useState();
  const [FilterData, setFilterData] = useState();
  const [AddOrderId, setAddOrderId] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [trackingIds, setTrackingIds] = useState();
  const [trackingUrl, setTrackingUrl] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [UploadChange, setUploadChange] = useState("");
  const [supportingData, setSupportingData] = useState();
  const [uploadedStatus, setUploadedStatus] = useState({});
  const [paginationCount, setPaginationCount] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [storeOrderId, setStoreOrderId] = useState();

  const [storeIfMandateExists, setStoreIfMandateExists] = useState(false);

  const { data: loggedInUserData } = useGetLoggedInUser();

  const { data: companyTypeData } = useGetCompanyTypeData(
    loggedInUserData?.data?.companyType
  );

  const [CompanyTypeName, setCompanyTypeName] = useState();

  const [activePanel, setActivePanel] = useState(
    CompanyTypeName === "Airlines Tickets" ||
      CompanyTypeName === "Entertainment & Events" ||
      CompanyTypeName === "Hotel"
      ? "Voucher"
      : CompanyTypeName === "Media"
      ? "Media"
      : "Product"
  );

  const {
    data: GetAllSellerorderTracking,
    isLoading: GetAllSellerorderTrackingLoading,
    error: GetAllSellerorderTrackingError,
    refetch: refetchTrackingData,
  } = useGetAllSellerorderTracking(activePanel, currentPage, FilterData);

  useEffect(() => {
    setPaginationCount(GetAllSellerorderTracking?.finalCount);
  }, [GetAllSellerorderTracking]);

  useEffect(() => {
    setCompanyTypeName(companyTypeData?.data?.CompanyTypeName);
  }, [companyTypeData]);

  useEffect(() => {
    if (!activePanel || !CompanyTypeName) {
      refetchTrackingData();
    }
  }, []);

  useEffect(() => {
    refetchTrackingData();
  }, [activePanel]);

  useEffect(() => {
    CompanyTypeName === "Airlines Tickets" ||
    CompanyTypeName === "Entertainment & Events" ||
    CompanyTypeName === "Hotel"
      ? setActivePanel("Voucher")
      : CompanyTypeName === "Media"
      ? setActivePanel("Media")
      : setActivePanel("Product");

    refetchTrackingData(activePanel, currentPage, FilterData);
  }, [CompanyTypeName]);

  useEffect(() => {
    setTimeout(() => {
      refetchTrackingData(activePanel, currentPage, FilterData);
    }, 100);
  }, [GetAllSellerorderTracking]);

  const openfl = Boolean(anchorEl);
  const handleClickfl = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosefl = () => {
    setAnchorEl(null);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleConfirmationBox = (orderId) => {
    setShowConfirmation(true);
    setStoreOrderId(orderId);
  };

  const handleConfirm = () => {
    navigate(`/home/regeneratemandate/${storeOrderId}`);
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  // Add tracking id in product
  const handleAddTracking = async () => {
    if (
      !trackingIds ||
      trackingIds === null ||
      !trackingUrl ||
      trackingUrl === null
    ) {
      <GlobalToast
        ToastMessage="Please enter a Tracking ID & Tracking Url"
        type="error"
      />;
      return;
    } else if (
      !trackingUrl.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      )
    ) {
      <GlobalToast
        ToastMessage={"Please enter Proper Tracking Url"}
        type="error"
      />;
      return;
    } else {
      try {
        const response = await axios.post(
          `order/add_tracking_id_by_order_id/${AddOrderId}`,
          {
            trackingId: trackingIds,
            trackingUrl: trackingUrl,
          },
          {
            withCredentials: true,
          }
        );
        toast.success("Tracking ID Added Successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        handleClose();
        await refetchTrackingData();
      } catch (error) {}
    }
  };

  // calculate expiration
  const calculateEndDate = (startDate, durationString) => {
    const numberOfMonthsToAdd = parseInt(durationString.match(/\d+/)[0], 10);
    if (isNaN(numberOfMonthsToAdd)) {
      return startDate;
    }
    const startDateObject = new Date(startDate);
    const endDate = new Date(
      startDateObject.setMonth(startDateObject.getMonth() + numberOfMonthsToAdd)
    );
    return new Date(endDate).toLocaleDateString("en-IN");
  };

  // update the product status
  const updateOrderStatus = async (orderId, newStatus) => {
    try {
      const data = {
        OrderStatus: newStatus,
      };
      let confirmCheck = window.confirm(
        "Are you sure you want to update this order?"
      );
      if (confirmCheck) {
        await axios
          .put(`order/update_seller_order_tracking/${orderId}`, data)
          .then(async (res) => {
            await refetchTrackingData();
            if (res?.data?.OrderStatus === "Ready To Ship") {
              for (let Order of GetAllSellerorderTracking) {
                if (Order?._id === orderId) {
                  let WhatsAppNo =
                    Order?.invoiceId?.SellerDetails?.SellerCompanyContact;
                  // let WhatsAppNo = "8554887187"
                  let TempletName = "seller_loading_advise";
                  dispatch(
                    SendMessageOnWhatsApp(
                      WhatsAppNo,
                      TempletName,
                      "parameterName1",
                      "parameterValue1",
                      "parameterName2",
                      "parameterValue2"
                    )
                  );
                }
              }
            }

            if (res?.data?.OrderStatus == "Out For delivery") {
              for (let Order of GetAllSellerorderTracking) {
                if (Order?._id === orderId) {
                  let WhatsAppNo =
                    Order?.invoiceId?.SellerDetails?.SellerCompanyContact;
                  let TempletName = "order_shipped";

                  dispatch(
                    SendMessageOnWhatsApp(
                      WhatsAppNo,
                      TempletName,
                      "address",
                      "Ayodahaya nagar",
                      "tracking_code",
                      "ORID23456FG78"
                    )
                  );
                }
              }
            }

            if (res?.data?.OrderStatus === "Out For delivery") {
              for (let Order of GetAllSellerorderTracking) {
                if (Order?._id === orderId) {
                  let WhatsAppNo =
                    Order?.invoiceId?.SellerDetails?.SellerCompanyContact;
                  let TempletName = "video_recording_of_packages";

                  dispatch(
                    SendMessageOnWhatsApp(
                      WhatsAppNo,
                      TempletName,
                      "parameterName1",
                      "parameterValue1",
                      "parameterName2",
                      "parameterValue2"
                    )
                  );
                }
              }
            }

            if (res?.data?.OrderStatus === "Delivered") {
              for (let Order of GetAllSellerorderTracking) {
                if (Order?._id === orderId) {
                  let WhatsAppNo =
                    Order?.invoiceId?.SellerDetails?.SellerCompanyContact;
                  let TempletName = "order_is_delivered";

                  dispatch(
                    SendMessageOnWhatsApp(
                      WhatsAppNo,
                      TempletName,
                      "parameterName1",
                      "parameterValue1",
                      "parameterName2",
                      "parameterValue2"
                    )
                  );
                }
              }
            }
          });
        setUpdate(!update);
        await refetchTrackingData();
      }
    } catch (error) {}
  };

  // Get TimeLine from Bought Date
  const GetTimeline = (timeline, boughtDates) => {
    let result = [];
    if (timeline === "Month") {
      const months = Math.ceil(boughtDates.length / 30);
      for (let i = 0; i < months; i++) {
        const startIndex = i * 30;
        const endIndex = Math.min((i + 1) * 30, boughtDates.length);
        result.push({
          startDate: boughtDates[startIndex],
          endDate: boughtDates[endIndex - 1],
        });
      }
    } else if (timeline === "Week") {
      const weeks = Math.ceil(boughtDates.length / 7);
      for (let i = 0; i < weeks; i++) {
        const startIndex = i * 7;
        const endIndex = Math.min((i + 1) * 7, boughtDates.length);
        result.push({
          startDate: boughtDates[startIndex],
          endDate: boughtDates[endIndex - 1],
        });
      }
    } else if (timeline === "Day") {
      result = boughtDates.map((date) => ({
        startDate: date,
        endDate: date,
      }));
    } else if (timeline === "Years") {
      const years = Math.ceil(boughtDates.length / 365);
      for (let i = 0; i < years; i++) {
        const startIndex = i * 365;
        const endIndex = Math.min((i + 1) * 365, boughtDates.length);
        result.push({
          startDate: boughtDates[startIndex],
          endDate: boughtDates[endIndex - 1],
        });
      }
    }

    return result;
  };

  // update the Voucher status
  const updateVoucherOrderStatus = async (orderId, newStatus) => {
    try {
      const data = {
        status: newStatus,
      };
      let confirmCheck = window.confirm(
        "Are you sure you want to update this order?"
      );
      if (confirmCheck) {
        await axios
          .post(`voucher/update_voucher_status/${orderId}`, data)
          .then((res) => {});
      }
      await refetchTrackingData();
    } catch (error) {}
  };

  // Update Media Status
  const updateMediaOrderStatus = async (orderId, newStatus) => {
    try {
      const data = {
        status: newStatus,
      };
      let confirmCheck = window.confirm(
        "Are you sure you want to update this order?"
      );
      if (confirmCheck) {
        await axios
          .post(`order/update_media_status_in_order/${orderId}`, data)
          .then((res) => {});
      }
      await refetchTrackingData();
    } catch (error) {}
  };

  // upload files
  const inputRefs = useRef([]);
  const [filesByDate, setFilesByDate] = useState({});

  const handleClose = () => {
    setOpen(false);
    setTrackingIds();
    setTrackingUrl();
    setAddOrderId();
    setFilesByDate({});
    setUploadedStatus({});
    setOrderData();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDrop = (event, date) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;

    setFilesByDate((prevFiles) => ({
      ...prevFiles,
      [`${date.startDate},${date.endDate}`]: {
        StartDate: date.startDate,
        EndDate: date.endDate,
        files: [
          ...(prevFiles[`${date.startDate},${date.endDate}`]?.files || []),
          ...droppedFiles,
        ],
      },
    }));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleChangeFiles = (event, date) => {
    const selectedFiles = event.target.files;

    setFilesByDate((prevFiles) => ({
      ...prevFiles,
      [`${date.startDate},${date.endDate}`]: {
        StartDate: date.startDate,
        EndDate: date.endDate,
        files: selectedFiles,
      },
    }));
  };

  const handleDeleteFile = (date, index) => {
    setFilesByDate((prevFiles) => {
      const newFiles = { ...prevFiles };
      delete newFiles[`${date.startDate},${date.endDate}`];
      return newFiles;
    });
  };

  const handleUpload = async (item) => {
    const formData = new FormData();
    formData.append(
      "file",
      filesByDate[`${item.startDate},${item.endDate}`]?.files[0]
    );
    formData.append("Key", "MediaImg/");
    formData.append("ContentType", "image/png");
    formData.append("ACL", "public-read");

    try {
      if (UploadChange === "UploadSupporting") {
        const res = await axios.post(
          `upload/media_add_supporting/${MediaId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        await axios.post(`order/updated_media_supporting/${MediaId}`, {
          data: {
            StartDate: item.startDate,
            EndDate: item.endDate,
            fileUrl: res?.data?.file,
          },
        });
      } else if (UploadChange === "UploadProof") {
        const res = await axios.post(
          `upload/media_add_proof/${MediaId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        await axios.post(`order/updated_media_proof/${MediaId}`, {
          data: {
            StartDate: item.startDate,
            EndDate: item.endDate,
            fileUrl: res?.data?.file,
          },
        });
      }
      setUploadedStatus((prevStatus) => ({
        ...prevStatus,
        [`${item.startDate},${item.endDate}`]: true,
      }));
      setFilesByDate((prevFiles) => {
        const newFiles = { ...prevFiles };
        delete newFiles[`${item.startDate},${item.endDate}`];
        return newFiles;
      });
      await refetchTrackingData();
    } catch (error) {}
  };

  useEffect(() => {
    const fetchData = async () => {
      await refetchTrackingData();
    };
    fetchData();
  }, [update, currentPage, activePanel, FilterData]);

  const checkSignedMandate = async (orderId) => {
    try {
      await axios
        .post(`enach_mandate/check_already_signed_mandate`, {
          OrderId: orderId,
        })
        .then((response) => {
          if (!response.data.mandateExist) {
            handleConfirmationBox(orderId);
            setStoreIfMandateExists(false);
          } else if (response.data.mandateExist) {
            setStoreIfMandateExists(true);
          }
        });
    } catch (error) {
      return false;
    }
  };

  // Product Status
  let OrderStatus = [
    "Ready To Ship",
    "Shipped",
    "In Transit",
    "Reached Nearest hub",
    "Out For delivery",
    "Delivered",
  ];

  // Media Status
  let MediaOrderStatus = [
    "Creative Approved",
    "Executed",
    "Completed",
    "Cancelled",
  ];

  // Voucher Status
  let VoucherOrderStatus = ["InActive", "Active"];

  // filter
  const orderType = [
    "All",
    "Progress",
    "Ready To Ship",
    "Shipped",
    "In Transit",
    "Reached Nearest hub",
    "Out For delivery",
    "Delivered",
  ];

  // Product order information
  const ProductData = () => {
    return (
      <React.Fragment>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            p: 1,
            overflowX: "auto",
            overflowY: "auto",
            borderBottom: "1px solid #F5F5F5",
          }}
        >
          <Grid
            item
            xl={0.3}
            lg={0.3}
            md={0.3}
            sm={0.3}
            xs={0.3}
            align="left"
            sx={tablehead}
          >
            No.
          </Grid>
          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            align="left"
            sx={tablehead}
          >
            Purchase Date
          </Grid>
          <Grid
            item
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            align="left"
            sx={tablehead}
          >
            Buyer Name
          </Grid>
          <Grid
            item
            xl={1.5}
            lg={1.5}
            md={1.5}
            sm={1.5}
            xs={1.5}
            align="left"
            sx={tablehead}
          >
            Order Id
          </Grid>

          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            align="center"
            sx={tablehead}
          >
            Total Products
          </Grid>
          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            align="center"
            sx={tablehead}
          >
            Barter Value
          </Grid>
          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            align="center"
            sx={tablehead}
          >
            E Way Bill Number
          </Grid>
          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            align="center"
            sx={tablehead}
          >
            Delivery <br /> Partner
          </Grid>
          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            align="left"
            sx={tablehead}
          >
            Status
          </Grid>
          <Grid
            item
            xl={1.5}
            lg={1.5}
            md={1.5}
            sm={1.5}
            xs={1.5}
            align="center"
            sx={{ ...tablehead, textAlign: "center" }}
          >
            Update Order Status
          </Grid>
          <Grid
            item
            xl={1.2}
            lg={1.2}
            md={1.2}
            sm={1.2}
            xs={1.2}
            align="center"
            sx={{ ...tablehead, textAlign: "center" }}
          >
            Update <br /> Tracking ID
          </Grid>
        </Grid>
        {GetAllSellerorderTrackingLoading ? (
          <>
            {[...Array(7)].map((data, idx) => {
              return (
                <Skeleton
                  key={idx}
                  animation="wave"
                  height={80}
                  sx={{
                    borderRadius: "10px",
                    width: "100%",
                    bgcolor: "#edf1f5",
                    spacing: "10px",
                    mx: "auto",
                  }}
                />
              );
            })}
          </>
        ) : GetAllSellerorderTracking?.length <= 0 ? (
          <Box align="center">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                py: 2,
              }}
            >
              <Nodatafound />
            </div>
          </Box>
        ) : (
          GetAllSellerorderTracking?.order?.map((el, idx) => {
            const orderTrackingData = el?.OrderTracking?.reverse() || [];
            const completedStatuses = orderTrackingData?.map(
              (step) => step.Status
            );

            const lastCompletedStatus = completedStatuses.find(
              (status) => status
            );

            let options = [];
            let display = false;
            OrderStatus.forEach((status) => {
              if (display) {
                options.push(status);
              }
              if (status === lastCompletedStatus) {
                display = true;
              }
            });
            if (lastCompletedStatus === "Delivered") {
              options = ["Delivered"];
            }
            if (el?.OrderStatus === "Progress") {
              options = OrderStatus;
            }
            return (
              <Grid
                item
                key={idx}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  p: 1.5,
                  overflowX: "auto",
                  borderBottom: "1px solid #F5F5F5",
                }}
              >
                <Grid
                  item
                  xl={0.3}
                  lg={0.3}
                  md={0.3}
                  sm={0.3}
                  xs={0.3}
                  align="left"
                >
                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      color: "#929EAE",
                    }}
                  >
                    {idx + 1}
                  </Typography>
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1} align="left">
                  {" "}
                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#1B212D",
                    }}
                  >
                    {new Date(el?.createdAt).toLocaleDateString("en-IN")}
                  </Typography>
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={2} align="left">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    gap={2}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontFamily: "Kumbh Sans",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "12px",
                          color: "#1B212D",
                          width: "90%",
                          minWidth: "200px",
                        }}
                      >
                        {el?.PurchaseOrderData?.BuyerDetails?.BuyerCompanyName}
                      </Typography>
                    </Box>
                  </Box>{" "}
                </Grid>

                <Grid
                  item
                  xl={1.5}
                  lg={1.5}
                  md={1.5}
                  sm={1.5}
                  xs={1.5}
                  align="left"
                >
                  {" "}
                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "12px",
                      alignItems: "center",
                      color: "#1B212D",
                    }}
                  >
                    {`${el?.OrderId}`}
                  </Typography>
                </Grid>

                <Grid item xl={1} lg={1} md={1} sm={1} xs={1} align="center">
                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#1B212D",
                    }}
                  >
                    {el.PurchaseOrderData?.POTotals?.TotalQuantity}
                  </Typography>
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1} align="center">
                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "12px",
                      ml: 4,
                      color: "#1B212D",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component="img"
                      src={BxiToken}
                      sx={{
                        width: "15px",
                        height: "auto",
                      }}
                    />
                    <CommaSeparator
                      Price={el.PurchaseOrderData?.POTotals?.TotalProductCoins}
                    />
                  </Typography>
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1} align="center">
                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#1B212D",
                    }}
                  >
                    {el?.shippingData?.waybill_no
                      ? el?.shippingData?.waybill_no
                      : "--"}
                  </Typography>
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1} align="center">
                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#1B212D",
                    }}
                  >
                    {el?.BuyerChoosedTransportation?.TransportationType ===
                    "pickrr"
                      ? el?.shippingData?.delivery_partner?.name
                      : "Seller"}
                  </Typography>
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1} align="left">
                  {" "}
                  <Button
                    sx={{
                      width: "100%",
                      bgcolor:
                        el?.OrderStatus === "Out For delivery"
                          ? "#FFEFEF"
                          : el?.OrderStatus === "Ready To Ship" ||
                            el?.OrderStatus === "Progress" ||
                            el?.OrderStatus === "In Transit" ||
                            el?.OrderStatus === "Reached Nearest hub"
                          ? "#FFF1E5"
                          : el?.OrderStatus === "Delivered" ||
                            el?.OrderStatus === "Shipped"
                          ? "#D9FFE9"
                          : "",
                      boxShadow: "none",
                      textTransform: "none",
                      color:
                        el?.OrderStatus === "Out For delivery"
                          ? "#EB5757"
                          : el?.OrderStatus === "Ready To Ship" ||
                            el?.OrderStatus === "Progress" ||
                            el?.OrderStatus === "In Transit" ||
                            el?.OrderStatus === "Reached Nearest hub"
                          ? "#F2994A"
                          : el?.OrderStatus === "Delivered" ||
                            el?.OrderStatus === "Shipped"
                          ? "#27AE60"
                          : "",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Kumbh Sans",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "12px",
                      }}
                    >
                      {el?.OrderStatus}
                    </Typography>
                  </Button>
                </Grid>

                <Grid
                  item
                  xl={1.5}
                  lg={1.5}
                  md={1.5}
                  sm={1.5}
                  xs={1.5}
                  align="center"
                >
                  <Box sx={{ minWidth: 120, mx: "auto" }}>
                    {el?.OrderStatus === "Delivered" ? (
                      <Button
                        sx={{
                          width: "100%",
                          color:
                            el?.OrderStatus === "Out For delivery"
                              ? "#EB5757"
                              : el?.OrderStatus === "Ready To Ship" ||
                                el?.OrderStatus === "Progress" ||
                                el?.OrderStatus === "In Transit" ||
                                el?.OrderStatus === "Reached Nearest hub"
                              ? "#F2994A"
                              : el?.OrderStatus === "Delivered" ||
                                el?.OrderStatus === "Shipped"
                              ? "#27AE60"
                              : "",
                          ":hover": {
                            backgroundColor: "white",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Kumbh Sans",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "12px",
                          }}
                        >
                          Delivered
                        </Typography>
                      </Button>
                    ) : (
                      <select
                        style={opt}
                        name="filter"
                        value={el?.OrderStatus}
                        label="OrderStatus"
                        onChange={(e) => {
                          setOrderId(el?._id);
                          updateOrderStatus(el?._id, e.target.value);
                        }}
                      >
                        <option value={el?.OrderStatus}>Select</option>

                        {options.map((statusData, idx) => (
                          <option key={idx} value={statusData}>
                            {statusData}
                          </option>
                        ))}
                      </select>
                    )}
                  </Box>
                </Grid>
                <Grid
                  item
                  xl={1.2}
                  lg={1.2}
                  md={1.2}
                  sm={1.2}
                  xs={1.2}
                  align="center"
                  sx={{ ...tablehead, textAlign: "center" }}
                >
                  {el?.trackingId && el?.trackingUrl ? (
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: "10px",
                        textTransform: "none",
                        background: "#c64091",
                      }}
                      onClick={() => {
                        handleClickOpen();
                        setOrderData(el);
                      }}
                    >
                      View
                    </Button>
                  ) : (
                    <Edit
                      sx={{
                        width: "25px",
                        height: "auto",
                        cursor: "pointer",
                        color: "#c64091",
                      }}
                      onClick={() => {
                        handleClickOpen();
                        setAddOrderId(el?._id);
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            );
          })
        )}
      </React.Fragment>
    );
  };

  // Voucher order information
  const VoucherData = () => {
    return (
      <React.Fragment>
        {showConfirmation && (
          <ConfirmationBox
            message="We are redirecting you to Mandate Sign page."
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
        )}
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            p: 1,

            borderBottom: "1px solid #F5F5F5",
          }}
        >
          <Grid
            item
            xl={0.3}
            lg={0.3}
            md={0.3}
            sm={0.3}
            xs={0.3}
            align="left"
            sx={tablehead}
          >
            No.
          </Grid>
          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            align="left"
            sx={tablehead}
          >
            Purchase Date
          </Grid>
          <Grid
            item
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            align="left"
            sx={tablehead}
          >
            Buyer Name
          </Grid>
          <Grid
            item
            xl={1.5}
            lg={1.5}
            md={1.5}
            sm={1.5}
            xs={1.5}
            align="left"
            sx={tablehead}
          >
            Order Id
          </Grid>

          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            align="left"
            sx={tablehead}
          >
            Type of <br /> Voucher
          </Grid>

          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            align="left"
            sx={tablehead}
          >
            Barter Value
          </Grid>

          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            align="left"
            sx={tablehead}
          >
            Codes <br /> Generated By
          </Grid>

          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            align="left"
            sx={tablehead}
          >
            Download <br /> Codes
          </Grid>

          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            align="left"
            sx={tablehead}
          >
            Status
          </Grid>

          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            align="left"
            sx={tablehead}
          >
            Activation Date
          </Grid>

          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            align="left"
            sx={tablehead}
          >
            Expiry Date
          </Grid>

          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            align="left"
            sx={tablehead}
          >
            Order Status
          </Grid>
        </Grid>
        {GetAllSellerorderTrackingLoading ? (
          <>
            {[...Array(7)].map((data, idx) => {
              return (
                <Skeleton
                  key={idx}
                  animation="wave"
                  height={80}
                  sx={{
                    borderRadius: "10px",
                    width: "100%",
                    bgcolor: "#edf1f5",
                    spacing: "10px",
                    mx: "auto",
                  }}
                />
              );
            })}
          </>
        ) : GetAllSellerorderTracking?.length <= 0 ? (
          <Box align="center">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                py: 2,
              }}
            >
              <Nodatafound />
            </div>
          </Box>
        ) : (
          GetAllSellerorderTracking?.order?.map((el, idx) => {
            // pdf downloading
            async function generatePDF(link) {
              if (link) {
                await axios
                  .post(
                    "voucher/get_voucher_pdf_files",
                    {
                      fileUrl: link,
                    },
                    { responseType: "blob" }
                  )
                  .then((response) => {
                    const blob = new Blob([response.data], {
                      type: "application/pdf",
                    });

                    // Create a download link
                    const downloadLink = document.createElement("a");
                    downloadLink.href = window.URL.createObjectURL(blob);
                    downloadLink.download = "VoucherCodes.pdf";

                    // Append the link to the body
                    document.body.appendChild(downloadLink);

                    // Trigger a click on the link to start the download
                    downloadLink.click();

                    // Remove the link from the body
                    document.body.removeChild(downloadLink);
                  })
                  .catch((error) => {});
              }
            }

            let options = [];
            if (el?.VoucherStatus === "Active") {
              options = [];
            } else if (el?.VoucherStatus === "InActive" || !el?.VoucherStatus) {
              options = ["Active"];
            } else {
              options = VoucherOrderStatus;
            }

            const voucherCodeGenrationType =
              el?.PurchaseOrderData?.ProductData[0]?.VoucherCodeGenrationType;

            return (
              <Grid
                item
                key={idx}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  p: 1.5,
                  borderBottom: "1px solid #F5F5F5",
                }}
              >
                <Grid
                  item
                  xl={0.3}
                  lg={0.3}
                  md={0.3}
                  sm={0.3}
                  xs={0.3}
                  align="left"
                  sx={tablehead}
                >
                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      color: "#929EAE",
                    }}
                  >
                    {idx + 1}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  align="left"
                  sx={tablehead}
                >
                  {" "}
                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#1B212D",
                    }}
                  >
                    {new Date(el?.createdAt).toLocaleDateString("en-IN")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  align="left"
                  sx={tablehead}
                >
                  <Box sx={{ display: "flex", flexDirection: "row" }} gap={2}>
                    <Box>
                      <Typography
                        sx={{
                          fontFamily: "Kumbh Sans",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "12px",
                          color: "#1B212D",
                          width: "90%",
                          minWidth: "200px",
                        }}
                      >
                        {el?.PurchaseOrderData?.BuyerDetails?.BuyerCompanyName}
                      </Typography>
                    </Box>
                  </Box>{" "}
                </Grid>

                <Grid
                  item
                  xl={1.5}
                  lg={1.5}
                  md={1.5}
                  sm={1.5}
                  xs={1.5}
                  align="left"
                  sx={tablehead}
                >
                  {" "}
                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#1B212D",
                    }}
                  >
                    {`${el?.OrderId ? el?.OrderId : "--"}`}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  align="left"
                  sx={tablehead}
                >
                  {" "}
                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#1B212D",
                    }}
                  >
                    {el?.PurchaseOrderData?.ProductData[0]?.VoucherType
                      ? el?.PurchaseOrderData?.ProductData[0]?.VoucherType
                      : "--"}
                  </Typography>
                </Grid>

                <Grid item xl={1} lg={1} md={1} sm={1} xs={1} align="left">
                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#1B212D",
                      display: "flex",
                      alignItems: "left",
                    }}
                  >
                    <Box
                      component="img"
                      src={BxiToken}
                      sx={{
                        width: "15px",
                        height: "auto",
                      }}
                    />
                    <CommaSeparator
                      Price={
                        el.PurchaseOrderData?.BXITokenDetails?.TotalBXiCoins
                      }
                    />
                  </Typography>
                </Grid>

                <Grid
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  align="left"
                  sx={tablehead}
                >
                  {" "}
                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "12px",
                      ml: 3,
                      color: "#1B212D",
                    }}
                  >
                    {voucherCodeGenrationType
                      ? voucherCodeGenrationType === "bxi"
                        ? "BXI"
                        : voucherCodeGenrationType === "self"
                        ? "Self"
                        : voucherCodeGenrationType
                      : "--"}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  align="center"
                  sx={tablehead}
                >
                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#1B212D",
                    }}
                  >
                    {el?.SellerVoucherFile?.url ? (
                      <>
                        <Button
                          variant="contained"
                          onClick={() => {
                            generatePDF(el?.SellerVoucherFile?.url);
                          }}
                          sx={{
                            textTransform: "none",
                            borderRadius: "10px",
                            width: "35px",
                            background: "#c64091",
                            border: "1px solid #c64091",
                            ":hover": {
                              background: "#fff",
                              border: "1px solid #c64091",
                              color: "#c64091",
                            },
                          }}
                        >
                          Voucher Codes
                        </Button>
                      </>
                    ) : (
                      "--"
                    )}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  align="left"
                  sx={tablehead}
                >
                  {" "}
                  <Button
                    sx={{
                      width: "100%",
                      bgcolor:
                        el?.VoucherStatus === "InActive"
                          ? "#FFEFEF"
                          : el?.VoucherStatus === "Active"
                          ? "#D9FFE9"
                          : "#FFEFEF",
                      boxShadow: "none",
                      textTransform: "none",
                      color:
                        el?.VoucherStatus === "InActive"
                          ? "#EB5757"
                          : el?.VoucherStatus === "Active"
                          ? "#27AE60"
                          : "#EB5757",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Kumbh Sans",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "12px",
                      }}
                    >
                      {el?.VoucherStatus ? el?.VoucherStatus : "InActive"}
                    </Typography>
                  </Button>
                </Grid>

                <Grid
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  align="center"
                  sx={tablehead}
                >
                  {" "}
                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#1B212D",
                    }}
                  >
                    {new Date(
                      el?.PurchaseOrderData?.OrderDate
                    ).toLocaleDateString("en-IN")}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  align="center"
                  sx={tablehead}
                >
                  {" "}
                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#1B212D",
                    }}
                  >
                    {el?.PurchaseOrderData?.ProductData?.at(0)?.VoucherValidity
                      ? calculateEndDate(
                          el?.PurchaseOrderData?.OrderDate,
                          el?.PurchaseOrderData?.ProductData?.at(0)
                            ?.VoucherValidity
                        )
                      : "--"}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  align="center"
                  sx={tablehead}
                >
                  {el?.escrowPayment &&
                  el?.escrowPayment?.success === "true" ? (
                    el?.VoucherStatus === "Active" ? (
                      <Typography>Activated</Typography>
                    ) : (
                      <Box sx={{ minWidth: 120 }}>
                        <select
                          style={opt}
                          name="filter"
                          value={el?.OrderStatus}
                          label="OrderStatus"
                          onChange={(e) => {
                            setOrderId(el?._id);
                            updateVoucherOrderStatus(el?._id, e.target.value);
                          }}
                        >
                          <option value="" style={{ fontSize: "14px" }}>
                            Select
                          </option>
                          {options?.map((statusData, idx) => {
                            return (
                              <option
                                key={idx}
                                value={statusData}
                                style={{ fontSize: "14px" }}
                              >
                                {statusData}
                              </option>
                            );
                          })}
                        </select>

                        <Typography
                          sx={{
                            fontFamily: "Kumbh Sans",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "#1B212D",
                          }}
                        ></Typography>
                      </Box>
                    )
                  ) : (
                    <Typography>Waiting for payment</Typography>
                  )}
                </Grid>
              </Grid>
            );
          })
        )}
      </React.Fragment>
    );
  };

  // Media order information
  const MediaData = () => {
    return (
      <React.Fragment>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            p: 1,

            borderBottom: "1px solid #F5F5F5",
          }}
        >
          <Grid
            item
            xl={0.2}
            lg={0.2}
            md={0.2}
            sm={0.2}
            xs={0.2}
            align="left"
            sx={tablehead}
          >
            No.
          </Grid>
          <Grid
            item
            xl={1.5}
            lg={1.5}
            md={1.5}
            sm={1.5}
            xs={1.5}
            align="left"
            sx={tablehead}
          >
            Order Confirmation Date
          </Grid>
          <Grid
            item
            xl={1.7}
            lg={1.7}
            md={1.7}
            sm={1.7}
            xs={1.7}
            align="left"
            sx={tablehead}
          >
            Buyer Name
          </Grid>
          <Grid
            item
            xl={1.3}
            lg={1.3}
            md={1.3}
            sm={1.3}
            xs={1.3}
            align="left"
            sx={tablehead}
          >
            Order Id
          </Grid>

          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            align="left"
            sx={tablehead}
          >
            Start Date
          </Grid>
          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            align="left"
            sx={tablehead}
          >
            End Date
          </Grid>

          <Grid
            item
            xl={1.5}
            lg={1.5}
            md={1.5}
            sm={1.5}
            xs={1.5}
            align="left"
            sx={tablehead}
          >
            Upload Supporting
          </Grid>

          <Grid
            item
            xl={1.5}
            lg={1.5}
            md={1.5}
            sm={1.5}
            xs={1.5}
            align="left"
            sx={tablehead}
          >
            Upload Proof of Execution
          </Grid>

          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            align="center"
            sx={tablehead}
          >
            Branding Status
          </Grid>
          <Grid
            item
            xl={1.3}
            lg={1.3}
            md={1.3}
            sm={1.3}
            xs={1.3}
            align="center"
            sx={tablehead}
          >
            Update Branding Status
          </Grid>
        </Grid>
        {GetAllSellerorderTrackingLoading ? (
          [...Array(7)].map((data, idx) => {
            return (
              <Skeleton
                key={idx}
                animation="wave"
                height={80}
                sx={{
                  borderRadius: "10px",
                  width: "100%",
                  bgcolor: "#edf1f5",
                  spacing: "10px",
                  mx: "auto",
                }}
              />
            );
          })
        ) : GetAllSellerorderTracking?.length <= 0 ? (
          <Box align="center">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                py: 2,
              }}
            >
              <Nodatafound />
            </div>
          </Box>
        ) : (
          GetAllSellerorderTracking?.order?.map((el, idx) => {
            const orderTrackingData = el?.OrderTracking?.reverse() || [];
            const completedStatuses = orderTrackingData?.map(
              (step) => step.Status
            );

            const lastCompletedStatus = completedStatuses.find(
              (status) => status
            );

            let options = [];
            let display = false;
            MediaOrderStatus.forEach((status) => {
              if (display) {
                options.push(status);
              }
              if (status === lastCompletedStatus) {
                display = true;
              }
            });
            if (lastCompletedStatus === "Creative Approved") {
              options = ["Creative Approved"];
            }
            if (el?.OrderStatus === "In Progress") {
              options = MediaOrderStatus;
            }
            return (
              <Grid
                container
                key={idx}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                  borderBottom: "1px solid #E0E0E0",
                  p: 1.5,
                }}
              >
                <Grid
                  item
                  xl={0.2}
                  lg={0.2}
                  md={0.2}
                  sm={0.2}
                  xs={0.2}
                  align="left"
                  sx={tablehead}
                >
                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      color: "#929EAE",
                    }}
                  >
                    {idx + 1}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xl={1.5}
                  lg={1.5}
                  md={1.5}
                  sm={1.5}
                  xs={1.5}
                  align="left"
                  sx={tablehead}
                >
                  {" "}
                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#1B212D",
                    }}
                  >
                    {new Date(el?.createdAt).toLocaleDateString("en-IN")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xl={1.7}
                  lg={1.7}
                  md={1.7}
                  sm={1.7}
                  xs={1.7}
                  align="left"
                  sx={tablehead}
                >
                  <Box sx={{ display: "flex", flexDirection: "row" }} gap={2}>
                    <Box>
                      <Typography
                        sx={{
                          fontFamily: "Kumbh Sans",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "12px",
                          color: "#1B212D",
                          width: "100%",
                          minWidth: "200px",
                        }}
                      >
                        {el?.PurchaseOrderData?.BuyerDetails?.BuyerCompanyName}
                      </Typography>
                    </Box>
                  </Box>{" "}
                </Grid>

                <Grid
                  item
                  xl={1.3}
                  lg={1.3}
                  md={1.3}
                  sm={1.3}
                  xs={1.3}
                  align="left"
                  sx={tablehead}
                >
                  {" "}
                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#1B212D",
                    }}
                  >
                    {`${el?.OrderId ? el?.OrderId : "--"}`}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  align="left"
                  sx={tablehead}
                >
                  {" "}
                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#1B212D",
                    }}
                  >
                    {el?.PurchaseOrderData?.ProductData?.at(0)?.BoughtDates?.at(
                      0
                    )
                      ? new Date(
                          el?.PurchaseOrderData?.ProductData?.at(
                            0
                          )?.BoughtDates?.at(0)
                        ).toLocaleDateString("en-IN")
                      : "--"}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  align="left"
                  sx={tablehead}
                >
                  {" "}
                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#1B212D",
                    }}
                  >
                    {el?.PurchaseOrderData?.ProductData?.at(0)?.BoughtDates?.at(
                      0
                    )
                      ? new Date(
                          el?.PurchaseOrderData?.ProductData?.at(
                            0
                          )?.BoughtDates?.at(
                            el?.PurchaseOrderData?.ProductData?.at(0)
                              ?.BoughtDates?.length - 1
                          )
                        ).toLocaleDateString("en-IN")
                      : "--"}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xl={1.5}
                  lg={1.5}
                  md={1.5}
                  sm={1.5}
                  xs={1.5}
                  align="left"
                  sx={tablehead}
                >
                  {" "}
                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#1B212D",
                    }}
                  >
                    {el?.PurchaseOrderData?.ProductData?.at(0)?.BoughtDates?.at(
                      0
                    ) ? (
                      <>
                        {GetTimeline(
                          el?.PurchaseOrderData?.ProductData?.at(0)?.Timeline,
                          el?.PurchaseOrderData?.ProductData?.at(0)?.BoughtDates
                        )?.length <= el?.MediaSupporting?.length ? (
                          "Already uploaded"
                        ) : (
                          <Button
                            variant="contained"
                            sx={{
                              borderRadius: "10px",
                              textTransform: "none",
                              background: "#c64091",
                            }}
                            onClick={() => {
                              handleClickOpen();
                              setUploadChange("UploadSupporting");
                              setOrderData(el);
                              setMediaId(el?._id);
                              setSupportingData(
                                GetTimeline(
                                  el?.PurchaseOrderData?.ProductData?.at(0)
                                    ?.Timeline,
                                  el?.PurchaseOrderData?.ProductData?.at(0)
                                    ?.BoughtDates
                                )
                              );
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Kumbh Sans",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "12px",
                              }}
                            >
                              Upload Supporting
                            </Typography>
                          </Button>
                        )}
                      </>
                    ) : (
                      "--"
                    )}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xl={1.5}
                  lg={1.5}
                  md={1.5}
                  sm={1.5}
                  xs={1.5}
                  align="center"
                  sx={tablehead}
                >
                  {" "}
                  <Typography
                    sx={{
                      fontFamily: "Kumbh Sans",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#1B212D",
                    }}
                  >
                    {el?.PurchaseOrderData?.ProductData?.at(0)?.BoughtDates?.at(
                      0
                    ) ? (
                      <>
                        {GetTimeline(
                          el?.PurchaseOrderData?.ProductData?.at(0)?.Timeline,
                          el?.PurchaseOrderData?.ProductData?.at(0)?.BoughtDates
                        )?.length <= el?.MediaProofs?.length ? (
                          "Already uploaded"
                        ) : (
                          <Button
                            variant="contained"
                            sx={{
                              borderRadius: "10px",
                              textTransform: "none",
                              background: "#c64091",
                            }}
                            onClick={() => {
                              handleClickOpen();
                              setUploadChange("UploadProof");
                              setOrderData(el);
                              setMediaId(el?._id);
                              setSupportingData(
                                GetTimeline(
                                  el?.PurchaseOrderData?.ProductData?.at(0)
                                    ?.Timeline,
                                  el?.PurchaseOrderData?.ProductData?.at(0)
                                    ?.BoughtDates
                                )
                              );
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Kumbh Sans",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "12px",
                              }}
                            >
                              Upload Proof
                            </Typography>
                          </Button>
                        )}
                      </>
                    ) : (
                      "--"
                    )}
                  </Typography>
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1} align="center">
                  {" "}
                  <Button
                    // variant="contained"
                    sx={{
                      width: "100%",
                      bgcolor:
                        el?.MediaStatus === "Cancelled"
                          ? "#FFEFEF"
                          : el?.MediaStatus === "Executed"
                          ? "#FFF1E5"
                          : el?.MediaStatus === "Creative Approved" ||
                            el?.MediaStatus === "Completed"
                          ? "#D9FFE9"
                          : "",
                      boxShadow: "none",
                      textTransform: "none",
                      color:
                        el?.MediaStatus === "Cancelled"
                          ? "#EB5757"
                          : el?.MediaStatus === "Executed"
                          ? "#F2994A"
                          : el?.MediaStatus === "Creative Approved" ||
                            el?.MediaStatus === "Completed"
                          ? "#27AE60"
                          : "",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Kumbh Sans",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "10px",
                      }}
                    >
                      {el?.MediaStatus}
                    </Typography>
                  </Button>
                </Grid>
                <Grid
                  item
                  xl={1.3}
                  lg={1.3}
                  md={1.3}
                  sm={1.3}
                  xs={1.3}
                  align="center"
                  sx={tablehead}
                >
                  {" "}
                  <Box sx={{ minWidth: 120 }}>
                    <select
                      style={opt}
                      name="filter"
                      value={el?.OrderStatus}
                      label="OrderStatus"
                      onChange={(e) => {
                        setOrderId(el?._id);
                        updateMediaOrderStatus(el?._id, e.target.value);
                      }}
                    >
                      <option value="">Select</option>
                      {MediaOrderStatus?.map((statusData, idx) => {
                        return (
                          <option key={idx} value={statusData}>
                            {statusData}
                          </option>
                        );
                      })}
                    </select>

                    <Typography
                      sx={{
                        fontFamily: "Kumbh Sans",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "12px",
                        color: "#1B212D",
                      }}
                    ></Typography>
                  </Box>
                </Grid>
              </Grid>
            );
          })
        )}
      </React.Fragment>
    );
  };

  return (
    <>
      <Paper elevation={0} sx={{ bgcolor: "transparent", boxShadow: "none" }}>
        <BreadCrumbHeader
          MainText={
            CompanyTypeName === "Media"
              ? "Update Live Status of Media Campaign"
              : "Update Tracking Status"
          }
        />
        <Grid container>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: "17px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              textAlign: "center",
              px: "2rem",
              py: "1rem",
            }}
            gap={2}
          >
            <Box
              sx={{
                width: "100%",
                // bgcolor: "red",
                display: "flex",
                justifyContent: "space-between",
                mt: "20px",
              }}
            >
              <Box
                component="img"
                src={LeftArrow}
                alt="img"
                sx={{ height: "25px", width: "25px", cursor: "pointer" }}
                onClick={() => {
                  navigate("/home");
                  sendEvents("Navigate to home");
                }}
              />
              {CompanyTypeName === "Media" ? null : (
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  Update Live Status of Product Shipments/ Voucher Activations
                </Typography>
              )}

              {activePanel === "Voucher" || activePanel === "Media" ? (
                <p>
                  {" "}
                  <br /> <br />{" "}
                </p>
              ) : (
                <>
                  <Button
                    id="demo-customized-button"
                    aria-controls={openfl ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openfl ? "true" : undefined}
                    // variant="contained"
                    disableElevation
                    onClick={handleClickfl}
                    variant="outlined"
                    sx={{
                      ...Styles.filterbutton,
                      "&:hover": {
                        color: "#c64091",
                      },
                      color: openfl ? "#c64091" : "#1B212D",
                      border: openfl
                        ? "1px solid #c64091"
                        : "1px solid #F5F5F5",
                    }}
                    startIcon={<FilterListIcon />}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Kumbh Sans",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "1.4rem",
                        textTransform: "none",
                        m: 1,
                      }}
                    >
                      Filters
                    </Typography>
                  </Button>
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={openfl}
                    onClose={handleClosefl}
                  >
                    {activePanel === "Product" ? (
                      <>
                        {orderType?.map((res, idx) => {
                          return (
                            <MenuItem
                              value={res}
                              key={idx}
                              onClick={() => {
                                setFilterData(res);
                                handleClosefl();
                              }}
                            >
                              {res}
                            </MenuItem>
                          );
                        })}
                      </>
                    ) : null}
                  </StyledMenu>
                </>
              )}
            </Box>

            <Box
              sx={{
                width: "100%",
                height: "35px",
                bgcolor: "teransparent",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Box
                sx={{
                  width: "auto",
                  border: "1px solid #c64091",
                  borderRadius: "10px",
                }}
              >
                {CompanyTypeName === "Airlines Tickets" ||
                CompanyTypeName === "Entertainment & Events" ||
                CompanyTypeName === "Hotel" ||
                CompanyTypeName === "Media" ? null : (
                  <Button
                    onClick={() => {
                      setActivePanel("Product");
                      sendEvents("Clicked on Product Order");
                    }}
                    style={{
                      borderRadius: "9px",
                      fontSize: "12px",
                      height: "30px",
                      width: "100px",
                      fontFamily: "Poppins",
                      background:
                        activePanel === "Product" ? "#c64091" : "#fff",
                      color: activePanel === "Product" ? "#fff" : "#c64091",
                      textTransform: "none",
                    }}
                  >
                    {CompanyTypeName === "Media" ||
                    CompanyTypeName === "Airlines Tickets" ||
                    CompanyTypeName === "Entertainment & Events" ||
                    CompanyTypeName === "Hotel"
                      ? null
                      : "Product"}
                  </Button>
                )}
                {CompanyTypeName === "Media" ? null : (
                  <Button
                    onClick={() => {
                      setActivePanel("Voucher");
                      sendEvents("Clicked on Voucher Order");
                    }}
                    style={{
                      borderRadius: "9px",
                      fontSize: "12px",
                      height: "30px",
                      width: "100px",
                      fontFamily: "Poppins",
                      background:
                        activePanel === "Voucher" ? "#c64091" : "#fff",
                      color: activePanel === "Voucher" ? "#fff" : "#c64091",
                      textTransform: "none",
                    }}
                  >
                    Voucher
                  </Button>
                )}
                {CompanyTypeName === "Media" ? (
                  <Button
                    onClick={() => {
                      setActivePanel("Media");
                      sendEvents("Clicked on Media Order");
                    }}
                    style={{
                      borderRadius: "9px",
                      fontSize: "12px",
                      height: "30px",
                      width: "100px",
                      fontFamily: "Poppins",
                      background: activePanel === "Media" ? "#c64091" : "#fff",
                      color: activePanel === "Media" ? "#fff" : "#c64091",
                      textTransform: "none",
                    }}
                  >
                    Media
                  </Button>
                ) : null}
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
              }}
            >
              {activePanel === "Product" && (
                <div>
                  <ProductData />
                </div>
              )}
              {activePanel === "Voucher" && (
                <div>
                  <VoucherData />
                </div>
              )}
              {activePanel === "Media" && (
                <div>
                  <MediaData />
                </div>
              )}
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "center" }} mt={2}>
          <Stack spacing={2}>
            <StyledPagination
              count={paginationCount}
              color="primary"
              page={currentPage}
              onChange={handlePageChange}
              showFirstButton
              showLastButton
            />
          </Stack>
        </Box>{" "}
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth={activePanel === "Media" ? "md" : "xs"}
          sx={{
            backdropFilter: "blur(2px)",
          }}
          PaperProps={{
            sx: {
              maxHeight: 600,
              borderRadius: "20px",
            },
          }}
        >
          <DialogTitle id="responsive-dialog-title">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  width: "80%",
                  mx: "auto",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "15px",
                    lineHeight: "28px",
                    color: "#6B7A99",
                    textAlign: "center",
                  }}
                >
                  {activePanel === "Media" ? (
                    <Box>
                      {UploadChange === "UploadSupporting"
                        ? "Upload Supporting"
                        : "Upload Proof"}
                    </Box>
                  ) : (
                    <Box>
                      {OrderData?.trackingId && OrderData.trackingUrl
                        ? "Your Tracking website Url and ID"
                        : "Here you add your Tracking website Url and ID"}
                    </Box>
                  )}
                </Typography>
              </Box>
              <Box>
                <CloseIconDialog
                  sx={{
                    color: "#667085",
                    fontSize: "25px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleClose();
                  }}
                />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              overflow: "auto",
            }}
          >
            {activePanel === "Media" ? (
              <Box>
                {UploadChange === "UploadSupporting" ? (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {supportingData?.map((item, idx) => {
                      const MatchedStartDate = OrderData?.MediaSupporting?.some(
                        (res) => res?.StartDate === item?.startDate
                      );
                      const MatchedEndDate = OrderData?.MediaSupporting?.some(
                        (res) => res?.EndDate === item?.endDate
                      );

                      const isUploaded =
                        uploadedStatus[`${item.startDate},${item.endDate}`];
                      return (
                        <Box
                          key={idx}
                          sx={{
                            mt: "5px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "15px",
                                lineHeight: "28px",
                                color: "#6B7A99",
                                textAlign: "center",
                              }}
                            >
                              StartDate:-{" "}
                              {new Date(item?.startDate).toLocaleDateString(
                                "en-IN"
                              )}
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "15px",
                                lineHeight: "28px",
                                color: "#6B7A99",
                                textAlign: "center",
                              }}
                            >
                              End Date:-{" "}
                              {new Date(item?.endDate).toLocaleDateString(
                                "en-IN"
                              )}
                            </Typography>
                          </Box>
                          {(MatchedStartDate && MatchedEndDate) ||
                          isUploaded ? (
                            <>
                              <Typography
                                sx={{
                                  width: "30%",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                Already Uploaded
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Box
                                sx={{
                                  width: "30%",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  onClick={() => inputRefs.current[idx].click()}
                                  sx={{
                                    ...ImageSelectBoxStyle,
                                    cursor: "pointer",
                                  }}
                                  onDragOver={(e) => handleDragOver(e)}
                                  onDrop={(e) => handleDrop(e, item)}
                                >
                                  <Typography
                                    sx={{
                                      cursor: "pointer",
                                      "&:hover": {
                                        color: "#c64091",
                                      },
                                      textAlign: "center",
                                      fontFamily: "Poppins",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "10px",
                                      textTransform: "none",
                                      color: "#6B7A99",
                                    }}
                                  >
                                    Drag & Drop upload or browse to choose a
                                    file
                                  </Typography>

                                  <input
                                    type="file"
                                    onChange={(e) => handleChangeFiles(e, item)}
                                    hidden
                                    accept=".png,.mp4,.jpeg,.gif"
                                    ref={(el) => (inputRefs.current[idx] = el)}
                                  />
                                </Box>
                                {filesByDate[
                                  `${item.startDate},${item.endDate}`
                                ]?.files && (
                                  <>
                                    <Box
                                      sx={{
                                        minWidth: "80%",
                                        width: "auto",
                                        my: "10px",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          bgcolor: "white",
                                        }}
                                      >
                                        <Box sx={ImageMapBox}>
                                          <Typography sx={TextStyle}>
                                            {
                                              filesByDate[
                                                `${item.startDate},${item.endDate}`
                                              ]?.files[0]?.name
                                            }
                                          </Typography>
                                          <Box
                                            component="img"
                                            src={DeleteIcon}
                                            alt="dlt"
                                            sx={{
                                              height: "20px",
                                              width: "20px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              handleDeleteFile(item, idx)
                                            }
                                          />
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Button
                                      onClick={() => handleUpload(item)}
                                      sx={ButtonStyleForUpload}
                                    >
                                      Upload
                                    </Button>
                                  </>
                                )}
                              </Box>
                            </>
                          )}
                        </Box>
                      );
                    })}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {supportingData?.map((item, idx) => {
                      const MatchedStartDate = OrderData?.MediaProofs?.some(
                        (res) => res?.StartDate === item?.startDate
                      );
                      const MatchedEndDate = OrderData?.MediaProofs?.some(
                        (res) => res?.EndDate === item?.endDate
                      );

                      const isUploaded =
                        uploadedStatus[`${item.startDate},${item.endDate}`];
                      return (
                        <Box
                          key={idx}
                          sx={{
                            mt: "5px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "15px",
                                lineHeight: "28px",
                                color: "#6B7A99",
                                textAlign: "center",
                              }}
                            >
                              StartDate:-{" "}
                              {new Date(item?.startDate).toLocaleDateString(
                                "en-IN"
                              )}
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "15px",
                                lineHeight: "28px",
                                color: "#6B7A99",
                                textAlign: "center",
                              }}
                            >
                              End Date:-{" "}
                              {new Date(item?.endDate).toLocaleDateString(
                                "en-IN"
                              )}
                            </Typography>
                          </Box>
                          {(MatchedStartDate && MatchedEndDate) ||
                          isUploaded ? (
                            <>
                              <Typography
                                sx={{
                                  width: "30%",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                Already Uploaded
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Box
                                sx={{
                                  width: "30%",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  onClick={() => inputRefs.current[idx].click()}
                                  sx={{
                                    ...ImageSelectBoxStyle,
                                    cursor: "pointer",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      cursor: "pointer",
                                      "&:hover": {
                                        color: "#c64091",
                                      },
                                      textAlign: "center",
                                      fontFamily: "Poppins",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "10px",
                                      textTransform: "none",
                                      color: "#6B7A99",
                                    }}
                                  >
                                    Drag & Drop upload or browse to choose a
                                    file
                                  </Typography>

                                  <input
                                    type="file"
                                    onChange={(e) => handleChangeFiles(e, item)}
                                    hidden
                                    accept=".png,.mp4,.jpeg,.gif"
                                    ref={(el) => (inputRefs.current[idx] = el)}
                                  />
                                </Box>
                                {filesByDate[
                                  `${item.startDate},${item.endDate}`
                                ]?.files && (
                                  <>
                                    <Box
                                      sx={{
                                        minWidth: "80%",
                                        width: "auto",
                                        my: "10px",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          bgcolor: "white",
                                        }}
                                      >
                                        <Box sx={ImageMapBox}>
                                          <Typography sx={TextStyle}>
                                            {
                                              filesByDate[
                                                `${item.startDate},${item.endDate}`
                                              ]?.files[0]?.name
                                            }
                                          </Typography>
                                          <Box
                                            component="img"
                                            src={DeleteIcon}
                                            alt="dlt"
                                            sx={{
                                              height: "20px",
                                              width: "20px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              handleDeleteFile(item, idx)
                                            }
                                          />
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Button
                                      onClick={() => handleUpload(item)}
                                      sx={ButtonStyleForUpload}
                                    >
                                      Upload
                                    </Button>
                                  </>
                                )}
                              </Box>
                            </>
                          )}
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "200px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  overflow: "auto",
                }}
              >
                {OrderData?.trackingId && OrderData.trackingUrl ? (
                  <>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "15px",
                        lineHeight: "28px",
                        color: "#6B7A99",
                        textAlign: "center",
                      }}
                    >
                      Tracking Id : {OrderData?.trackingId}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "15px",
                        lineHeight: "28px",
                        color: "#6B7A99",
                        textAlign: "center",
                      }}
                    >
                      Tracking Url :{" "}
                      <span
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${OrderData.trackingUrl}`
                          );
                          toast.info("URL Copied", {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                        }}
                      >
                        {OrderData.trackingUrl}
                      </span>
                    </Typography>
                  </>
                ) : (
                  <>
                    <TextField
                      autoFocus
                      sx={{
                        width: "80%",
                        height: "40px",
                        fontFamily: "Kumbh Sans",
                        fontStyle: "normal",
                        fontWeight: 400,
                        background: "#F8F8F8",
                        borderRadius: "15px",
                        "& fieldset": { border: "none" },
                      }}
                      placeholder="Eg. Tr01abcd"
                      value={trackingIds}
                      onChange={(e) => setTrackingIds(e.target.value)}
                    />
                    <TextField
                      sx={{
                        width: "80%",
                        height: "40px",
                        fontFamily: "Kumbh Sans",
                        fontStyle: "normal",
                        fontWeight: 400,
                        background: "#F8F8F8",
                        borderRadius: "15px",
                        "& fieldset": { border: "none" },
                      }}
                      placeholder="Eg. www.demo.com"
                      value={trackingUrl}
                      onChange={(e) => setTrackingUrl(e.target.value)}
                    />
                  </>
                )}
              </Box>
            )}
          </DialogContent>
          <DialogTitle id="responsive-dialog-title">
            {activePanel === "Media" ? null : (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {OrderData?.trackingId && OrderData.trackingUrl ? (
                  ""
                ) : (
                  <>
                    <Button
                      variant="contained"
                      sx={{ ...Styles.reqbtn, backgroundColor: "#c64091" }}
                      onClick={() => {
                        handleAddTracking();
                      }}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{
                        ...Styles.reqbtn,
                        fontWeight: 700,
                        background: "none",
                        border: "0.7px solid #c64091",
                        color: "#c64091",
                        ":hover": {
                          border: "1px solid #c64091",
                        },
                      }}
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </Box>
            )}
          </DialogTitle>
        </Dialog>
      </Paper>
    </>
  );
};

export default OrderTracking;

const tablehead = {
  fontFamily: "Kumbh Sans",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "13px",
  color: "#929EAE",
};

const opt = {
  height: "35px",
  borderRadius: "12px",
  border: "1px solid #E6E9EE",
  paddingLeft: "15px",
  paddingRight: "15px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  color: "#AFAFAF",
  width: "80%",
  // maxWidth: 250,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const ImageSelectBoxStyle = {
  border: "1px dashed #c64091",
  borderRadius: "5px",
  background: "#fff",
  width: "80%",
  p: "1%",
};

const TextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "10px",
  textTransform: "none",
  color: "#6B7A99",
};

const ImageMapBox = {
  background: "#fff",
  border: "1px solid green",
  borderRadius: "9px",
  height: "30px",
  width: "auto",
  display: "flex",
  gap: "5px",
  alignItems: "center",
  justifyContent: "space-between",
  px: "8px",
};

const ButtonStyleForUpload = {
  width: "100%",
  height: "30px",
  maxWidth: "100px",
  borderRadius: "6px",
  bgcolor: "#2261A2",
  textTransform: "none",
  color: "#fff",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  color: "#FFFFFF",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  curso0r: "pointer",
  my: 1,
  border: "1px solid #fff",
  "&:hover": {
    color: "#2261A2",
    border: "1px solid #2261A2",
  },
};

const styles = {
  overlay: {
    position: "absolute",
    top: "0%",
    left: "0%",
    width: "100%",
    height: "100%",
    zIndex: "10",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  box: {
    backgroundColor: "white",
    padding: "20px",
    width: "450px",
    height: "170px",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },
  textStyle: {
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "18px",
    fontWeight: "500",
    color: "#415bca",
    marginBottom: "20px",
  },
  buttons: {
    marginTop: "50px",
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "300px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  confirmButton: {
    backgroundColor: "#415bca",
    color: "white",
    padding: "12px 30px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    "&:hover": {
      boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`,
    },
  },
  cancelButton: {
    backgroundColor: "#fff",
    color: "#415bca",
    padding: "12px 30px",
    border: "1px solid #415bca",
    borderRadius: "4px",
    cursor: "pointer",
  },
};
