import React, { useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const GridItem = ({ imgSrc, imgSrcOnHover, label, name, mt, width }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    navigate("/explore", {
      state: {
        label: name,
      },
    });
  };

  return (
    <Grid item xs={6} sm={6} md={2.8} lg={2.8} xl={2.8}>
      <Box
        sx={{
          ...categoriesBoxDesign,
          backgroundColor: isHovered ? "rgba(34, 97, 162, 0.8)" : null,
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          handleClick();
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // bgcolor: "red",
            gap: "5px",
            width: "98%",
            height: "98%",
            borderRadius: "15px",
          }}
        >
          <img
            src={isHovered ? imgSrcOnHover : imgSrc}
            alt="Airline"
            style={{ width: width, height: "auto" }}
          />
          <Typography
            variant="h6"
            sx={{
              ...categoriesTextDesign,
              color: isHovered ? "#FFFFFF" : "#2261A2",
              mt: mt,
            }}
          >
            {label}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};
export default GridItem;

const categoriesBoxDesign = {
  border: "1.5px solid #2261A2",
  borderRadius: " 15.121169090270996px",
  width: "100%",
  // width: "276.6182556152344px",
  height: "94.1357421875px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  transition: "background-color 0.3s ease",
};
const categoriesTextDesign = {
  fontFamily: "Poppins",
  fontSize: { xl: "18px", lg: "18px", md: "18px", sm: "16px", xs: "14px" },
  fontWeight: 400,
  lineHeight: { xl: "27px", lg: "27px", md: "27px", sm: "24px", xs: "24px" },
  letterSpacing: "0em",
  transition: "color 0.3s ease",
};
