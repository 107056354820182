import {
  BottomNavigation,
  Box,
  Button,
  Fade,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom/dist";
import { toast } from "react-toastify";
import { useEffectOnce } from "react-use";
import { useGetCompanyDetails } from "../../../../Hooks/Auth";
import useGetCompanyTypeData from "../../../../Hooks/CompanyData/useGetCompanyTypeData";
import help from "../../../../assets/Help.svg";
import DeleteIcon from "../../../../assets/Images/CommonImages/DeleteIcon.svg";
import UploadtoCloud from "../../../../assets/UploadtoCloud.svg";
import ToolTip from "../../../../components/ToolTip";
import TostMessagesTop from "../../../../Component/OTPToast";
import useGetLoggedInUser from "../../../../Hooks/LoggedInUser/useGetLoggedInUser";
import { notifications } from "../../../../redux/action/Notification/notification";
import GoLiveProductImageCarousel from "../../../../components/Carousel/GoLiveProductImageCarousel";

export default function TextileGoLive(props) {
  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      componentsProps={{ tooltip: { className: className } }}
    />
  ))(`
          background: #C64091;
          width:200px;
      `);

  const navigate = useNavigate();
  const id = useParams().id;
  const [loader, setLOader] = useState(false);
  const [files, setFiles] = useState([]);
  const [sizechart, setSizeChart] = useState(null);
  const [progress, setProgress] = React.useState(0);
  const [storeImages, setStoreImages] = useState();
  const [formError, setFormError] = useState(false);
  const [ListPeriod, setListPeriod] = useState();
  const [ProductData, setProductData] = useState("");
  const [FileNameErrorfordimanesion, setFileNameErrorForDimension] = useState();
  const [FileNameErrorForSize, setFileNameErrorForSize] = useState();
  const [imagePreviews, setImagePreviews] = useState([]);

  const { data: loggedInUserData } = useGetLoggedInUser();

  const { data: datahere } = useGetLoggedInUser();

  const inputRef = useRef();
  const sizechartRef = useRef();
  const productId = useParams().id;

  const FetchProduct = async () => {
    await axios
      .get("product/get_product_byId/" + id)
      .then((response) => {
        console.log("liveresponse", response?.data);
        setProductData(response?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffectOnce(() => {
    FetchProduct();
  });

  useEffect(() => {
    if (files) {
      let NewData = [];
      for (let i = 0; i < files.length; i++) {
        NewData.push(files[i]);
      }
      setStoreImages(NewData);
    }
  }, [files]);
  useEffect(() => {
    if (props?.IsMedia) {
      setListPeriod(1);
    }
  }, []);

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDropSizechart = (event) => {
    event.preventDefault();
    setSizeChart(event.target.files[0]);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setFiles(event.dataTransfer.files);
  };

  const handleChangeFiles = (event) => {
    const newFiles = Array.from(event.target.files);
    const duplicateFiles = newFiles.filter((file) =>
      files.some((existingFile) => existingFile.name === file.name)
    );
    setImagePreviews;

    if (duplicateFiles.length > 0) {
      return toast.error("Duplicate Files are not allowed", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    const newImagePreviews = newFiles.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onload = () => resolve(reader.result);
      });
    });

    Promise.all(newImagePreviews).then((previews) => {
      setImagePreviews((prevFiles) => [...prevFiles, ...previews]);
    });
  };

  const uploadData = async (event) => {
    event.preventDefault();
    const formData = new FormData();

    if (files?.length < 3) {
      setFormError(true);
      return toast.error("Please upload at least 3 images", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (files?.length > 6) {
      setFormError(true);
      return toast.error("More than 6 images are not accepted", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (
      ProductData?.ProductCategoryName === "Textile" ||
      ProductData?.ProductCategoryName === "Lifestyle"
    ) {
      if (
        ListPeriod?.length === 0 ||
        ListPeriod === undefined ||
        ListPeriod === "" ||
        ListPeriod === null
      ) {
        setFormError(true);
        return toast.error("Please Enter the valid list days", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }

    let FilarrayForDimension = [];
    let FilearrayforSize = [];
    const MAX_FILE_SIZE = 10000000;
    let ReadyToUpload = false;
    for (let i = 0; i < files?.length; i++) {
      const file = files[i];
      try {
        const dataUrl = await readFileAsDataURL(file);
        const img = await loadImage(dataUrl);
        const width = img.width;
        const height = img.height;
        const aspectRatio = width / height;

        if (
          (ProductData?.ProductCategoryName === "Textile" ||
            ProductData?.ProductCategoryName === "Office Supply" ||
            ProductData?.ProductCategoryName === "Lifestyle" ||
            ProductData?.ProductCategoryName === "Other") &&
          file.size > Number(1000000)
        ) {
          FilearrayforSize.push(file.name);
          ReadyToUpload = false;
        } else if (file.size > MAX_FILE_SIZE) {
          FilearrayforSize.push(file.name);
        } else if (
          aspectRatio.toFixed(2) - 2 / 3 < 2 ||
          aspectRatio.toFixed(2) - 3 / 4 < 2 ||
          aspectRatio.toFixed(2) - 9 / 16 < 2
        ) {
          console.log("The image has an aspect ratio of 2:3");
        } else if (
          file.size <= Number(1000000) &&
          (aspectRatio.toFixed(2) - 4 / 3 < 2 ||
            aspectRatio.toFixed(2) - 3 / 2 < 2 ||
            aspectRatio.toFixed(2) - 16 / 9 < 2)
        ) {
          console.log("passs");
        } else {
          FilarrayForDimension.push(file.name);
        }
      } catch (error) {
        ReadyToUpload = false;
      }

      formData.append("files", file);
    }

    formData.append("id", productId);
    formData.append("sizechart", sizechart);
    formData.append("ProductUploadStatus", "productpreview");
    formData.append("listperiod", ListPeriod);

    setFileNameErrorForDimension(FilarrayForDimension);
    setFileNameErrorForSize(FilearrayforSize);

    function ListOfDaysLimit() {
      if (!props?.IsMedia) {
        const ManufacturingDate = ProductData?.ManufacturingData
          ? new Date(ProductData?.ManufacturingData)
          : new Date(ProductData?.ManufacturingDate);
        const ExpiryDate =
          ProductData?.ExpiryDate === null ||
          ProductData?.ExpiryDate === undefined
            ? ""
            : new Date(ProductData?.ExpiryDate);
        const Difference_In_Days =
          ProductData?.ExpiryDate === null ||
          ProductData?.ExpiryDate === undefined
            ? ""
            : Math.round(
                (ExpiryDate.getTime() - ManufacturingDate.getTime()) /
                  (1000 * 3600 * 24)
              );
        return Difference_In_Days;
      } else {
        return 1;
      }
    }

    const NumberOfListDays = ListOfDaysLimit();

    if (
      (NumberOfListDays && Number(ListPeriod) > NumberOfListDays) ||
      Number(ListPeriod) > 365
    ) {
      return toast.error(
        "Please select the list period less than " +
          (NumberOfListDays ? NumberOfListDays : "365") +
          " Days",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    } else if (
      ListPeriod?.length === 0 ||
      ListPeriod === undefined ||
      ListPeriod === "" ||
      ListPeriod === null
    ) {
      return toast.error("Please Fill the listing Days", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      if (FilarrayForDimension.length <= 0 && FilearrayforSize.length <= 0) {
        axios({
          method: "post",
          url: "/product/product_mutation",
          data: formData,
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
          },
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        })
          .then((res) => {
            if (props?.IsMedia) {
              navigate("/home/mediaonlineproductpreview/" + id);
            } else {
              navigate("/home/allproductpreview/" + id);
            }

            const AdminId = "650ac9c7b29820230639ab99";
            let receiver = AdminId;
            let sender = loggedInUserData?.data?._id;
            let message = `Product request generated by ${loggedInUserData?.data?.companyName} Company`;
            let type = "Product";

            dispatch(notifications(receiver, sender, message, type));
          })
          .catch((err) => console.log(err));
      } else {
        ReadyToUpload = false;
        return toast.error("Image dimension or Size issue", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
      img.src = src;
    });
  };

  return (
    <Box>
      <form>
        <Box sx={FirstBoxStyle}>
          <Paper sx={{ width: "700px", mx: "auto", padding: "50px" }}>
            <Box sx={FirstBoxChildStyle}>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: {
                    xs: "18px",
                    sm: "16px",
                    md: "16px",
                    lg: "14px",
                    xl: "14px",
                  },
                  color: "#6B7A99",
                }}
              >
                Go Live
              </Typography>
              <ToolTip
                info={
                  "Go Live is the time at which something becomes available to use and purchased by other members on the platform."
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                px: "20px",
                width: "90%",
              }}
            >
              {" "}
              <Typography sx={{ ...TextStyle, fontSize: "12px" }}>
                Upload Image & Video <span style={{ color: "red" }}> *</span>
              </Typography>
              <CustomTooltip
                title={
                  <Typography
                    sx={ToolTextStyle}
                  >{`Mandatory Photos : 3 Clear Product Photos with White Background , High Resolution , Close View , Distant View , If on Display / in Use View , Kindly add Size Chart / Dimension Photos Separetly in the Below Link. ( 5 Photos + 1 Video ) Mandatory`}</Typography>
                }
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 400 }}
              >
                <img
                  src={help}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </CustomTooltip>
            </Box>
            <Box
              onClick={() => inputRef.current.click()}
              sx={{ ...ImageSelectBoxStyle, cursor: "pointer" }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <Box
                sx={{
                  display: "grid",
                  width: "60%",
                  mx: " auto",
                }}
              >
                <Box sx={DisplayGridStyle}>
                  <Box component="img" src={UploadtoCloud} sx={ImageBox} />
                  <Typography
                    sx={{
                      ...TextStyle,
                      display: "flex",
                      justifyContent: "center",
                      color: "#445FD2",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#445FD2",
                      },
                      textalign: "center",
                    }}
                  >
                    Drag & Drop | Upload OR
                    <span style={{ color: "#445FD2", fontWeight: 500 }}>
                      &nbsp; Browse &nbsp;
                    </span>
                    to choose a file
                  </Typography>
                  <Typography sx={MulishFontTextStyle}>
                    Size Limit & Format ( JPEG , PNG - 1 MB , PDF - 5 MB , GIF ,
                    MP4 - 5 MB )
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ textAlign: "center", mt: "1%" }}>
                {ProductData?.ProductCategoryName === "Textile" ||
                ProductData?.ProductCategoryName === "Office Supply" ||
                ProductData?.ProductCategoryName === "Lifestyle" ||
                ProductData?.ProductCategoryName === "Other" ? (
                  <Typography sx={{ ...TextStyle, color: "#445FD2" }}>
                    Aspect Ratio 3 : 4 (1080 X 1920 )
                  </Typography>
                ) : (
                  <Typography sx={{ ...TextStyle, color: "#445FD2" }}>
                    Aspect Ratio 32 : 9 (3840 X 1080 ) OR 16 : 9 ( 1920 X 1080)
                  </Typography>
                )}

                <Typography
                  sx={{
                    ...TextStyle,
                    color: "#445FD2",
                  }}
                >
                  Mandatory Photos : Front View, Back View, Close Fabric View,
                </Typography>
              </Box>
              <input
                type="file"
                multiple
                onChange={handleChangeFiles}
                hidden
                accept=".png,.pdf,.mp4,.jpeg,.gif"
                ref={inputRef}
              />
            </Box>
            {files ? (
              <Box
                sx={{
                  width: "90%",
                  my: "2%",
                  mx: "auto",
                }}
              >
                {/* <Typography sx={TextStyle}>Uploaded</Typography> */}

                {Array?.from(files)?.map((file, idx) => {
                  const hasDimensionError =
                    FileNameErrorfordimanesion?.includes(file.name);

                  const hasSizeError = FileNameErrorForSize?.includes(
                    file.name
                  );

                  return (
                    <Box key={idx}>
                      <Box sx={ImageMapBox}>
                        <Typography key={idx} sx={TextStyle}>
                          {file?.name}
                        </Typography>
                        <Box
                          component="img"
                          src={DeleteIcon}
                          sx={{
                            height: "20px",
                            width: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            const newFiles = Array.from(files);
                            newFiles.splice(idx, 1);
                            setFiles(newFiles);
                            // Remove the image from error arrays if it exists
                            setFileNameErrorForDimension((prevErrors) =>
                              prevErrors?.filter((error) => error !== file.name)
                            );
                            setFileNameErrorForSize((prevErrors) =>
                              prevErrors?.filter((error) => error !== file.name)
                            );
                          }}
                        />
                      </Box>
                      {
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "7px",
                          }}
                        >
                          {FileNameErrorfordimanesion?.includes(file.name) && (
                            <Typography sx={{ color: "black" }}>
                              Dimension Issue:{" "}
                              <span style={{ color: "red" }}>{file.name}</span>
                            </Typography>
                          )}

                          {FileNameErrorForSize?.includes(file.name) && (
                            <Typography sx={{ color: "black" }}>
                              Size Issue:{" "}
                              <span style={{ color: "red" }}>{file.name}</span>
                            </Typography>
                          )}
                        </Box>
                      }
                    </Box>
                  );
                })}
              </Box>
            ) : (
              " "
            )}

            {!props?.IsMedia && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    px: "20px",
                    width: "90%",
                  }}
                >
                  {" "}
                  <Typography sx={{ ...TextStyle, fontSize: "12px" }}>
                    Upload Size chart, Price Tags-separately here
                  </Typography>
                  <CustomTooltip
                    title={
                      <Typography
                        sx={ToolTextStyle}
                      >{`Important Picture : ( Size Chart / Dimensions/ Certificate / Technical Information ) Add Here +`}</Typography>
                    }
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 400 }}
                  >
                    <img
                      src={help}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </CustomTooltip>
                </Box>
                <Box
                  onClick={() => sizechartRef?.current?.click()}
                  sx={{ ...SizeChartBox, cursor: "pointer" }}
                  onDragOver={handleDragOver}
                  onDrop={handleDropSizechart}
                >
                  <Box
                    sx={{
                      display: "grid",
                      width: "60%",
                      mx: " auto",
                    }}
                  >
                    <Box sx={DisplayGridStyle}>
                      <Box
                        component="img"
                        src={UploadtoCloud}
                        sx={{
                          position: "absolute",
                          left: "5%",
                          textalign: "center",
                        }}
                      />
                      <Typography sx={{ ...TextStyle, color: "#445FD2" }}>
                        Drag & Drop | Upload OR
                        <span style={{ color: "#445FD2", fontWeight: 500 }}>
                          {" "}
                          Browse{" "}
                        </span>{" "}
                        to choose a file
                      </Typography>
                      <Typography sx={MulishFontTextStyle}>
                        Supported format : PDF - 5 MB
                      </Typography>
                      <Typography sx={MulishFontTextStyle}>
                        Size Limit : 10 MB
                      </Typography>
                    </Box>
                  </Box>

                  <input
                    type="file"
                    // multiple
                    onChange={(event) => setSizeChart(event.target.files[0])}
                    hidden
                    accept=".png,.pdf,.mp4,.jpeg,.gif"
                    ref={sizechartRef}
                  />
                </Box>
              </>
            )}
            {sizechart ? (
              <Box
                sx={{
                  width: "90%",
                  my: "2%",
                }}
              >
                <Typography sx={TextStyle}>Uploaded</Typography>
                <Box sx={SizeChartBoxStyle}>
                  <Typography sx={TextStyle}>{sizechart?.name}</Typography>
                  <Box
                    component="img"
                    src={DeleteIcon}
                    sx={{
                      height: "20px",
                      width: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSizeChart(null);
                    }}
                  />
                </Box>
              </Box>
            ) : (
              " "
            )}

            {loader ? (
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                  backgroundColor: "transparent",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#445FD2",
                  },
                }}
              />
            ) : null}

            {!props?.IsMedia && (
              <Box sx={{ width: "90%", mx: "auto", mt: 2 }}>
                <Typography sx={Text}>
                  {" "}
                  List this product for number of days ( maximum 365 days ){" "}
                  <span style={{ color: "red" }}> *</span>
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: "42px",
                    mt: "1%",
                    borderRadius: "10px",
                  }}
                >
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    placeholder="70"
                    InputProps={{
                      disableUnderline: "true",
                      style: {
                        color: "#C64091",
                        fontSize: "14px",
                        padding: "7px",
                      },
                      inputProps: {
                        maxLength: 3, // Limit input length to 3 characters
                        minVal: 1,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "red",
                      },
                    }}
                    onChange={(e) => {
                      const inputValue = e.target.value;

                      if (inputValue.match(/^(?!0)\d*$/)) {
                        const filteredValue = inputValue.replace(/\D/g, "");
                        e.target.value = filteredValue;
                      } else {
                        e.target.value = "";
                      }

                      console.log("filteredValue", e.target.value);

                      setListPeriod(e.target.value);
                    }}
                    sx={{
                      width: "80%",
                      height: "100%",
                      background: "#FFFFFF",
                      borderRadius: "10px 0px 0px 10px",
                    }}
                    onKeyDown={(e) => {
                      if (e.key === " " && e.target.selectionStart === 0) {
                        e.preventDefault();
                      }
                    }}
                  />

                  <Select sx={GW} defaultValue={"Days"}>
                    <MenuItem sx={MenuItems} value="Days">
                      Days
                    </MenuItem>
                  </Select>
                </Box>
              </Box>
            )}
            <Box
              sx={{
                width: "100%",
                mx: "auto",
                height: "100%",
                bgcolor: "transparent",
                marginBottom: "21px",
              }}
            >
              <BottomNavigation
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  bgcolor: "transparent",
                  p: "10px",
                }}
                showLabels
              >
                <Box sx={{ display: "flex", gap: "10px", p: 1, width: "50%" }}>
                  <Button
                    sx={CancelButtonStyle}
                    variant="contained"
                    onClick={() => {
                      let confirm = window.confirm(
                        "Are you sure you want to cancel the product?"
                      );
                      if (confirm) {
                        navigate("/home/sellerhub");
                      }
                    }}
                  >
                    Cancel
                  </Button>
                  {ProductData?.ProductImages &&
                  ProductData?.ProductImages?.length > 0 ? (
                    <Button
                      type="submit"
                      sx={GoLiveButton}
                      variant="contained"
                      onClick={() => {
                        if (props?.IsMedia) {
                          navigate("/home/mediaonlineproductpreview/" + id);
                        } else {
                          navigate("/home/allproductpreview/" + id);
                        }
                      }}
                    >
                      Go Live
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      sx={GoLiveButton}
                      variant="contained"
                      onClick={uploadData}
                    >
                      Go Live
                    </Button>
                  )}
                </Box>
              </BottomNavigation>
            </Box>
          </Paper>

          <Box
            sx={{
              width: "100%",
              maxWidth: "1100px",
              mx: "auto",
              height: "100%",
              bgcolor: "transparent",
              marginBottom: "5px",
              marginTop: "50px",
            }}
          >
            <Typography sx={{ fontSize: "16px", fontFamily: "Poppins" }}>
              {" "}
              Selected Images
            </Typography>

            <div style={{ marginTop: "10px" }}>
              {imagePreviews.map((preview, index) => (
                <img
                  key={index}
                  src={preview}
                  alt={`Selected ${index}`}
                  style={{ width: "300px", height: "auto", margin: "10px" }}
                />
              ))}
            </div>

            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "Poppins",
                marginTop: "40px",
              }}
            >
              {" "}
              Previous Uploaded Images
            </Typography>
            <Box
              sx={{
                display: "flex",
                my: {
                  xl: "0px",
                  lg: "0px",
                  md: "5px",
                  sm: "10px",
                  xs: "20px",
                },
                width: "100%",
              }}
            >
              <GoLiveProductImageCarousel
                ImageDataArray={ProductData?.ProductImages}
              />
            </Box>
          </Box>
        </Box>
        <TostMessagesTop
          PageContent="Upload high resolution images, as per the given ratio!."
          PageCompanyName={datahere?.data?.companyName}
        />
      </form>
    </Box>
  );
}

const FirstBoxStyle = {
  maxWidth: "1100px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  boxShadow: "0px 10px 20px rgba(220, 220, 220, 0.5)",
  px: "20px",
  backgroundColor: "#EEF1F6",
  mx: "auto",
};

const FirstBoxChildStyle = {
  width: "100%",
  mx: "auto",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "10px",
  py: "10px",
};

const TextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "10px",
  textTransform: "none",
  color: "#6B7A99",
};

const ImageBox = {
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  left: "5%",
  textalign: "center",
};

const ImageMapBox = {
  background: "#fff",
  border: "1px solid green",
  borderRadius: "10px",
  height: "42px",
  width: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  my: "10px",
  px: "8px",
};

const SizeChartBox = {
  border: "2px dashed #445FD2",
  background: "#fff",
  width: "80%",
  maxWidth: "670px",
  p: "4%",
  mx: "auto",
  position: "relative",
};

const DisplayGridStyle = {
  display: "grid",
  justifyContent: "center",
  alignItems: "center",
  tetxAlign: "center",
  mx: "auto",
};

const MulishFontTextStyle = {
  fontFamily: "Mulish",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "8px",
  color: "#676767",
  textAlign: "center",
};
const ImageSelectBoxStyle = {
  border: "2px dashed #445FD2",
  background: "#fff",
  width: "80%",
  maxWidth: "670px",
  p: "4%",
  mx: "auto",
  position: "relative",
};
const Text = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 12,
  ml: "1%",
  mb: "1%",
  pt: "2%",
  // py: 1,
  color: "#6B7A99",
};

const CancelButtonStyle = {
  width: "100%",
  height: "32px",
  borderRadius: "10px",
  background: "#fff",
  color: "#636161",
  fontSize: "14px",
  textTransform: "none",
  fontSize: "14px",
  textTransform: "none",
  "&:hover": {
    background: "#FAFBFD",
    color: "#000",
  },
};

const GoLiveButton = {
  width: "100%",
  height: "32px",
  borderRadius: "10px",
  background: "#C64091",
  fontSize: "14px",
  textTransform: "none",
  "&:hover": {
    background: "#C64091",
  },
};

const SizeChartBoxStyle = {
  background: "#fff",
  border: "1px solid green",
  borderRadius: "9px",
  height: "42px",
  width: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  my: "10px",
  px: "8px",
};

const MenuItems = {
  fontSize: "12px",
  color: "#C64091",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
};
const GW = {
  width: "20%",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  background: "#FFFFFF",
  height: "100%",
  color: "#C64091",
  fontSize: "12px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  borderRadius: "0px 10px 10px 0px",
};

const ToolTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "10px",
  lineHeight: "13px",
  color: "#fff",
  textAlign: "center",
  cursor: "pointer",
};
