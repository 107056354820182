import { default as React, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { z } from "zod";
import axios from "axios";
import { useEffect } from "react";
import MainLogo from "./MainLogo.jsx";
import { useForm } from "react-hook-form";
import Stepper from "../../components/Stepper";
import TostMessagesTop from "../../Component/Toast";
import { zodResolver } from "@hookform/resolvers/zod";
import VerifiedRej from "../../components/VerifiedRej";
import { useNavigate, useParams } from "react-router-dom";
import cashback from "../../assets/Images/verified/cashback.svg";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import UnderDraw from "../../assets/undraw_completed_re_cisp 1.svg";
import mainImg from "../../assets/Images/register/companydetails.svg";
import {
  useCompanyStepDetails,
  useGetCompanyDetails,
  useSentNotification,
} from "../../Hooks/Auth";
import * as StlyesOnBoarding from "../../components/common/Styled/StylesOnBoarding.js";
import useGetAuthUser from "../../Hooks/LoggedInUser/useGetAuthUser.js";
import Status from "../../utils/status.js";

const BankDetails = () => {
  const { id } = useParams();
  const { data: companyData } = useGetCompanyDetails();
  const [bankresponse, setBankResponse] = useState(false);
  const navigate = useNavigate();
  const [companyDetails, setCompanyDetailsData] = useState();

  // get Company here
  const FecthCompanyDetails = async () => {
    await axios
      .get("/api/v1/onboard-company")
      .then((res) => {
        setCompanyDetailsData(res.data);
      })
      .catch((err) => console.log(err));
  };
  // this is company step details
  const { mutate, isLoading } = useCompanyStepDetails();

  const { data: AuthUserData } = useGetAuthUser();
  const { mutate: SendNotification } = useSentNotification();

  const handleSendNotification = () => {
    SendNotification({
      id: AuthUserData?.data?.companyId,
      status: Status.BANK_DETAILS,
    });
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      handleSendNotification();
      e.returnValue =
        "Please click 'Stay on this Page' and we will give you candy";
      return "Please click 'Stay on this Page' and we will give you candy";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: zodResolver(
      z.object({
        pan: z.string().length(10),
      })
    ),
  });
  useEffect(() => {
    FecthCompanyDetails();
  }, []);

  const submitForm = handleSubmit((data) => {
    const newData = { bankdetails: data, id };
    mutate(newData, {
      onSuccess: (res) => {
        setBankResponse(true);
        setTimeout(() => {
          navigate("/home/terms");
        }, 3000);
      },
      onError: (err) => {
        console.log("sdhiugfysdfsdf", err);
      },
    });
  });

  if (companyData?.data?.companyOnboardingStatus === "UNDER_REVIEW") {
    navigate("/under_review");
  } else if (companyData?.data?.companyOnboardingStatus === "BANK_DETAILS") {
  }

  return (
    <form onSubmit={submitForm}>
      <Paper elevation={0} sx={StlyesOnBoarding.mainCommonPage}>
        <Stepper />
        <Grid
          mt={0}
          container
          sx={{
            background: "#fff",
            height: "100vh",
            width: {
              xl: "110%",
              lg: "110%",
              md: "110%",
              sm: "100%",
              xs: "100%",
            },
          }}
        >
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              mt: 0,
            }}
          >
            <Typography sx={{ ...StlyesOnBoarding.login, mt: 0 }}>
              Company Pan Details{" "}
            </Typography>
            <Box
              mt={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                width: "75%",
                gap: "15px",
                maxWidth: "450px",
              }}
            >
              {/* all input box here */}
              <ThemeProvider theme={outerTheme}>
                <Box sx={StlyesOnBoarding.BankInput}>
                  <Box sx={StlyesOnBoarding.inputbox}>
                    <label style={labelStyle}>Pan No.</label>
                  </Box>
                  <input
                    type={"text"}
                    {...register("pan")}
                    placeholder="HNM51*****"
                    style={StlyesOnBoarding.inputStyles}
                  />
                </Box>
              </ThemeProvider>

              <Grid
                container
                mt={1}
                sx={{
                  width: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  sx={StlyesOnBoarding.CommonBtn}
                  type="submit"
                >
                  {isLoading ? (
                    <CircularProgress
                      size="20px"
                      sx={{ color: "white", width: "40%" }}
                    />
                  ) : (
                    "Next"
                  )}
                </Button>

                {bankresponse ? (
                  <Box
                    sx={{
                      height: "auto",
                      width: "100%",
                      alignItems: "center",
                      padding: "50px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      textAlignLast: "center",
                    }}
                  >
                    <img src={UnderDraw} alt="UnderDraw" />

                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontFamily: "Poppins",
                        fontSize: "32px",
                        lineHeight: "48px",
                        color: "#6B7A99",
                        width: "90%",
                      }}
                    >
                      Congratulations !{" "}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontFamily: "Poppins",
                        color: "#6B7A99",
                        LineHeight: "21px",
                        textAlign: "center",
                        width: "95%",
                        fontSize: "14px",
                      }}
                    >
                      You have successfully completed the second step of your
                      registration process.
                    </Typography>
                  </Box>
                ) : null}
              </Grid>
            </Box>
          </Grid>
          {/* image Grid */}
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: {
                xl: "flex",
                lg: "flex",
                md: "flex",
                sm: "none",
                xs: "none",
              },
            }}
          >
            <img
              src={mainImg}
              alt="img"
              style={{
                ...StlyesOnBoarding.CommonImgStyle,
                marginRight: "150px",
              }}
            />
          </Grid>
        </Grid>
      </Paper>
      <TostMessagesTop PageLocation="Bank Details" />
    </form>
  );
};

export default BankDetails;

const note = {
  fontFamily: "Poppins",
  fontSize: "12px",
  width: "101%",
  fontWeight: 400,
  lineHeight: "21px",
  color: "#6B7A99",
};

const errTypo = {
  ml: 1,
  fontSize: "10px",
  color: "red",
};

const labelStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  color: "#6B7A99",
};

const outerTheme = createTheme({
  palette: {
    primary: {
      main: "#6B7A99",
    },
  },
  typography: {
    fontSize: 20,
  },
});
